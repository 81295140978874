import { Listing } from "models/listings/listing.model";
import styles from "./listing-details.module.scss";
import { ListingType } from "enums/Listings/ListingType.enum";
import { ListingsHelper } from "utils/ListingsHelper";

const ListingDetails = ({ listing, estimatedPrice, restricted = false }: { listing: Listing, estimatedPrice: number | null, restricted: boolean }) => {
  const { beds, baths, garages, openParkings, totalParkings } =
    ListingsHelper.getListingRooms(listing);

  return (
    <div className={styles.container}>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">List price</div>
        <div className={`aecorn-listing-detail-tab_value ${restricted && 'scattered'}`}>
          {ListingsHelper.getFormattedPrice(+listing.listPrice)}
        </div>
      </div>
      {listing.type === ListingType.Sale && estimatedPrice && (
        <div className="aecorn-listing-detail-tab">
          <div className="aecorn-listing-detail-tab_label">Estimated value</div>
          <div className={`aecorn-listing-detail-tab_value ${restricted && 'scattered'}`}>
            {ListingsHelper.getFormattedPrice(estimatedPrice)}
          </div>
        </div>
      )}
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Status</div>
        <div className={`aecorn-listing-detail-tab_value ${restricted && 'scattered'}`}>
          {listing.lastStatus}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Street address</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.address.streetNumber} {listing.address.streetName}{" "}
          {listing.address.streetSuffix}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Unit number</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.address.unitNumber || "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Area / Community</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.address.area}
          {listing.address.neighborhood && ` / ${listing.address.neighborhood}`}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Postal code</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.address.zip}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Main intersection</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.address.majorIntersection}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Property style</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.details.style}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Building age</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.details.yearBuilt || "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Lot size</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.lot.size ? `${listing.lot.size} sqft` : "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Bedrooms</div>
        <div className="aecorn-listing-detail-tab_value">{beds || "-"}</div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Bathrooms</div>
        <div className="aecorn-listing-detail-tab_value">{baths || "-"}</div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Garage type</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.details.garage}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Garage parking</div>
        <div className="aecorn-listing-detail-tab_value">{garages || "-"}</div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Open parking</div>
        <div className="aecorn-listing-detail-tab_value">
          {openParkings || "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">
          Total parking spaces
        </div>
        <div className="aecorn-listing-detail-tab_value">
          {totalParkings || "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Heat</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.details.heating || "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Air conditioning</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.details.airConditioning || "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Sewer</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.details.sewer || "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Swimming pool</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.details.swimmingPool || "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Amenities</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.condominium.ammenities &&
          listing.condominium.ammenities.length > 0
            ? listing.condominium.ammenities.map(
                (item) => item.length > 0 && item + ", "
              )
            : "-"}
        </div>
      </div>
    </div>
  );
};

export default ListingDetails;
