import { Listing } from "models/listings/listing.model";
import styles from "../charts.module.scss";
import { ListingStatisticsCount } from "models/listings/listing-statistics.model";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const SoldAndActiveListingsCount = ({
  listing,
  data,
  numOfYears,
}: {
  listing: Listing;
  data: {
    new: ListingStatisticsCount;
    closed: ListingStatisticsCount;
    active: ListingStatisticsCount;
  };
  numOfYears: 5 | 10 | 15;
}) => {
  const newData = Object.keys(data.new.mth)
    .sort()
    .slice(-(numOfYears * 12));

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <div className={styles.tooltip}>
          <span>{`${label}`}</span>
          <span>{`New Listings: ${payload[0]?.value || "N/A"}`}</span>
          <span>{`Sold Listings: ${payload[1]?.value || "N/A"}`}</span>
          <span>{`Active Listings: ${payload[2]?.value || "N/A"}`}</span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.container}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={newData.map((month) => ({
            month,
            "New Listings": data.new.mth[month]?.count,
            "Sold Listings": data.closed.mth[month]?.count,
            "Active Listings": data.active.mth[month],
          }))}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            scale="band"
            angle={-20}
            fontSize={10}
            tickMargin={10}
            color="#878787"
            stroke="#6e6e6e"
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="bottom" align="center" />
          <Line dot={false} dataKey="New Listings" stroke="#878787" />
          <Line dot={false} dataKey="Sold Listings" stroke="#00D5AA" />
          <Bar barSize={10} dataKey="Active Listings" fill="#000" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SoldAndActiveListingsCount;
