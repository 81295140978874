import { INITIAL_STATE } from "redux/filters/filters.reducer";
import { ListingsClass } from "enums/Listings/ListingClass.enum";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import { ListingType } from "enums/Listings/ListingType.enum";
import { isEqual, reduce } from "lodash";
import moment from "moment";
import { FiltersActionTypes } from "redux/filters/filters.types";
import { store } from "redux/store";
import { ListingsHelper } from "utils/ListingsHelper";
import { API } from "aws-amplify";
import { GeneralConstants } from "utils/constants";
import { LocationActionTypes } from "redux/locations/locations.types";
import { Area, Neighborhood } from "components/shared/Modals/ListingsFiltersModal/components/RegionsFilter/regions-filter.component";

const getLastStatus = (): "sale" | "sold" | "delisted" => {
  const filters = store.getState().filters.value;

  if (filters.lastStatus) {
    if (filters.lastStatus.includes("Sld")) {
      return "sold";
    } else if (filters.lastStatus.includes("Dft")) {
      return "delisted";
    }
  }

  return "sale";
};

const updateStatus = (status: ListingStatus) => {
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      status,
      transactionStatus: "sale",
      minListDate: null,
      maxListDate: null,
      minSoldDate: null,
      maxSoldDate: null,
      minUpdatedOn: null,
      maxUpdatedOn: null,
      lastStatus: null,
    },
  });
};

const updateLastStatus = (lastStatus: "sale" | "sold" | "delisted") => {
  const filters = store.getState().filters.value;
  const thirtyDaysAgo = moment().subtract(29, "days").format("YYYY-MM-DD");
  let date = filters.date;

  if (lastStatus !== "sale") {
    if (!filters.minSoldDate) {
      date = 30;
    }
  } else {
    date = 0;
  }

  switch (lastStatus) {
    case "sale":
      store.dispatch({
        type: FiltersActionTypes.UPDATE_FILTERS,
        payload: {
          status: "A",
          date,
          lastStatus: null,
          transactionStatus: lastStatus,
          minListDate: null,
          maxListDate: null,
          minSoldDate: null,
          maxSoldDate: null,
          minUpdatedOn: null,
          maxUpdatedOn: null,
        },
      });
      break;
    case "sold":
      store.dispatch({
        type: FiltersActionTypes.UPDATE_FILTERS,
        payload: {
          status: "U",
          date,
          lastStatus: filters.type === ListingType.Sale ? ["Sld"] : ["Lsd"],
          transactionStatus: lastStatus,
          minSoldDate: filters.minSoldDate
            ? filters.minSoldDate
            : thirtyDaysAgo,
          maxSoldDate: filters.maxSoldDate ? filters.maxSoldDate : null,
          minListDate: null,
          maxListDate: null,
          minUpdatedOn: null,
          maxUpdatedOn: null,
        },
      });
      break;
    case "delisted":
      store.dispatch({
        type: FiltersActionTypes.UPDATE_FILTERS,
        payload: {
          status: "U",
          date,
          lastStatus: ["Dft", "Ter", "Exp", "Sus"],
          transactionStatus: lastStatus,
          minUpdatedOn: filters.minUpdatedOn
            ? filters.minUpdatedOn
            : thirtyDaysAgo,
          maxUpdatedOn: filters.maxUpdatedOn ? filters.maxUpdatedOn : null,
          minListDate: null,
          maxListDate: null,
          minSoldDate: null,
          maxSoldDate: null,
        },
      });
      break;
  }
};

const updateDate = (value: number) => {
  const lastStatus = getLastStatus();

  if (value === 0) {
    store.dispatch({
      type: FiltersActionTypes.UPDATE_FILTERS,
      payload: {
        date: value,
        minListDate: null,
        maxListDate: null,
        minSoldDate: null,
        maxSoldDate: null,
        minUpdatedOn: null,
        maxUpdatedOn: null,
      },
    });
    return;
  }

  switch (lastStatus) {
    case "sale": {
      const date = moment()
        .subtract(Math.abs(value), "days")
        .add(1, "days")
        .format("YYYY-MM-DD");

      store.dispatch({
        type: FiltersActionTypes.UPDATE_FILTERS,
        payload: {
          date: value,
          minListDate: value > 0 ? date : null,
          maxListDate: value < 0 ? date : null,
          minSoldDate: null,
          maxSoldDate: null,
          minUpdatedOn: null,
          maxUpdatedOn: null,
        },
      });
      break;
    }
    case "sold":
    case "delisted": {
      if (value.toString().length === 4) {
        const startOfSelectedYear = moment()
          .set("year", value)
          .startOf("year")
          .format("YYYY-MM-DD");
        const endOfSelectedYear = moment()
          .set("year", value)
          .endOf("year")
          .format("YYYY-MM-DD");

        store.dispatch({
          type: FiltersActionTypes.UPDATE_FILTERS,
          payload: {
            date: value,
            minSoldDate: lastStatus === "sold" ? startOfSelectedYear : null,
            maxSoldDate: lastStatus === "sold" ? endOfSelectedYear : null,
            minListDate: null,
            maxListDate: null,
            minUpdatedOn:
              lastStatus === "delisted" ? startOfSelectedYear : null,
            maxUpdatedOn: lastStatus === "delisted" ? endOfSelectedYear : null,
          },
        });
      } else {
        const date = moment()
          .subtract(Math.abs(value), "days")
          .add(1, "days")
          .format("YYYY-MM-DD");

        store.dispatch({
          type: FiltersActionTypes.UPDATE_FILTERS,
          payload: {
            date: value,
            minSoldDate: lastStatus === "sold" ? date : null,
            maxSoldDate: null,
            minListDate: null,
            maxListDate: null,
            minUpdatedOn: lastStatus === "delisted" ? date : null,
            maxUpdatedOn: null,
          },
        });
      }
      break;
    }
  }
};

const updateClass = (listingClass: ListingsClass) => {
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      class: listingClass,
    },
  });
};

const updateType = (type: ListingType) => {
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      type,
      minPrice: null,
      maxPrice: null,
    },
  });
};

const updatePriceRange = (min: number, max: number) => {
  const type = store.getState().filters.value.type;

  const minPrice = ListingsHelper.convertPercentageToPrice(min, type);
  const maxPrice = ListingsHelper.convertPercentageToPrice(max, type);
  console.log(min, type, minPrice, maxPrice);
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      minPrice,
      maxPrice,
    },
  });
};

const updateSqftRange = (min: number, max: number) => {
  const minSqft = ListingsHelper.convertPercentageToSqft(min);
  const maxSqft = ListingsHelper.convertPercentageToSqft(max);

  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      minSqft,
      maxSqft,
    },
  });
};

const updateMinPrice = (minPrice: number) => {
  const maxPrice = store.getState().filters.value.maxPrice;

  if (minPrice > maxPrice) {
    store.dispatch({
      type: FiltersActionTypes.UPDATE_FILTERS,
      payload: {
        minPrice: maxPrice,
        maxPrice: minPrice,
      },
    });
  } else {
    store.dispatch({
      type: FiltersActionTypes.UPDATE_FILTERS,
      payload: {
        minPrice,
      },
    });
  }
};

const updateMaxPrice = (maxPrice: number) => {
  const minPrice = store.getState().filters.value.minPrice;

  if (maxPrice < minPrice) {
    store.dispatch({
      type: FiltersActionTypes.UPDATE_FILTERS,
      payload: {
        minPrice: maxPrice,
        maxPrice: minPrice,
      },
    });
  } else {
    store.dispatch({
      type: FiltersActionTypes.UPDATE_FILTERS,
      payload: {
        maxPrice,
      },
    });
  }
};

const updateMinBeds = (minBeds: number) => {
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      minBeds: minBeds === 0 ? null : minBeds,
    },
  });
};

const updateMinBaths = (minBaths: number) => {
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      minBaths: minBaths === 0 ? null : minBaths,
    },
  });
};

const updateMinParkingSpaces = (minParkingSpaces: number) => {
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      minParkingSpaces: minParkingSpaces === 0 ? null : minParkingSpaces,
    },
  });
};

const updateMaxMaintenanceFee = (maxMaintenanceFee: number) => {
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      maxMaintenanceFee: maxMaintenanceFee === 0 ? null : maxMaintenanceFee,
    },
  });
};

const updatePropertyType = (type: string | null) => {
  const selectedTypes = store.getState().filters.value.propertyType;

  if (!type) {
    store.dispatch({
      type: FiltersActionTypes.UPDATE_FILTERS,
      payload: {
        propertyType: [],
      },
    });
  } else {
    const updatedTypes = selectedTypes.includes(type)
      ? selectedTypes.filter((selectedType: string) => selectedType !== type)
      : [...selectedTypes, type];

    store.dispatch({
      type: FiltersActionTypes.UPDATE_FILTERS,
      payload: {
        propertyType: updatedTypes,
      },
    });
  }
};

const updateRegions = (
  area: string | undefined,
  cities: string[],
  neighborhoods: string[]
) => {
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      area: area,
      city: cities.length ? cities : undefined,
      neighborhood: neighborhoods.length ? neighborhoods : undefined,
    },
  });
};

const updateMultiple = (updates: {
  [key: string]: string | number | undefined | string[];
}) => {
  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: updates,
  });
};

const addKeyword = (keyword: string) => {
  const keywords = store.getState().filters.value.keywords;

  if (keywords.includes(keyword)) {
    return;
  }

  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      keywords: [...keywords, keyword],
    },
  });
};

const removeKeyword = (keyword: string) => {
  const keywords = store.getState().filters.value.keywords;

  const updatedKeywords = keywords.filter(
    (selectedKeyword: string) => selectedKeyword !== keyword
  );

  store.dispatch({
    type: FiltersActionTypes.UPDATE_FILTERS,
    payload: {
      keywords: updatedKeywords,
    },
  });
};

const hasActiveFilters = () => {
  const filters = store.getState().filters.value;
  const initialFilters: any = INITIAL_STATE.value;

  const differences = reduce(
    filters,
    (result: any, value: any, key: any) => {
      if (
        !Object.prototype.hasOwnProperty.call(initialFilters, key) ||
        key === "map" ||
        key === "type"
      ) {
        return result;
      }
      return isEqual(value, initialFilters[key]) ? result : result.concat(key);
    },
    []
  );

  return differences.length > 0;
};

const reset = () => {
  store.dispatch({
    type: FiltersActionTypes.RESET_FILTERS,
  });
};

const fetchRegionsFilterItems = async () => {
  const response = await API.get(
    GeneralConstants.REST_API_NAME,
    "/listings/locations",
    {}
  );

  store.dispatch({
    type: LocationActionTypes.FETCH_LOCATIONS_SUCCESS,
    payload: response.data,
  });

  return response.data;
};

const getNeighborhoodsLocationValues = () => {
  const filters = store.getState().filters.value;
  const locations = store.getState().locations;
  const regions: Area[] =
    locations.locations?.map((location: any) => ({
      ...location.value[0],
      center: location.center,
    })) || [];
  const area = filters.area;
  const city = filters.city;
  const neighborhood = filters.neighborhood;
  const selectedArea = regions.find((region) => region.name === area);
  const selectedCities =
    selectedArea?.cities.filter((c) => city?.includes(c.name)) || [];

  const selectedNeighborhoods: Neighborhood[] = [];

  selectedCities.forEach((selectedCity) => {
    const selectedCityNeighborhoods = selectedCity.neighborhoods.filter((n) =>
      neighborhood?.includes(n.name)
    );
    selectedNeighborhoods.push(...selectedCityNeighborhoods);
  });

  return selectedNeighborhoods;
};

export const ListingsFiltersService = {
  getLastStatus,
  updateStatus,
  updateLastStatus,
  updateDate,
  updateClass,
  updateType,
  updatePriceRange,
  updateSqftRange,
  updateMinPrice,
  updateMaxPrice,
  updateMinBeds,
  updateMinBaths,
  updateMinParkingSpaces,
  updateMaxMaintenanceFee,
  updatePropertyType,
  updateRegions,
  updateMultiple,
  addKeyword,
  removeKeyword,
  hasActiveFilters,
  reset,
  fetchRegionsFilterItems,
  getNeighborhoodsLocationValues,
};
