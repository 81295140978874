import { Listing } from "models/listings/listing.model";
import styles from "./listing-market-data.module.scss";
import { ListingStatistics } from "models/listings/listing-statistics.model";
import { IonItem, IonSelect, IonSelectOption, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { formatPrice } from "utils/functions";
import { ListingsConstants } from "constants/Listings.constants";
import MedianSoldPriceWithAverageDOMChart from "./charts/MedianSoldPriceWithAverageDOM/median-sold-price-with-average-dom.component";
import SoldAndActiveListingsCount from "./charts/SoldAndActiveListingsCount/sold-and-active-listings-count.component";
import { DeviceService } from "services/deviceInfoService";

const ListingMarketData = ({
  loading,
  data,
  listing,
}: {
  loading: boolean;
  data: ListingStatistics | null;
  listing: Listing;
}) => {
  const isNative = DeviceService.isApp();
  const [years, setYears] = useState<5 | 10 | 15>(5);
  const [latestMedianSoldPrice, setLatestMedianSoldPrice] = useState<{
    month: string;
    price: number | string;
  }>();
  const [latestNumberOfNewListings, setLatestNumberOfNewListings] = useState<{
    month: string;
    count: number;
  }>();
  const [valueChange, setValueChange] = useState<{
    1: number;
    5: number;
    10: number;
  }>();

  useEffect(() => {
    if (data) {
      const lastSoldMonth = Object.keys(data.soldPrice.mth).sort().reverse()[0];
      setLatestMedianSoldPrice({
        month: lastSoldMonth,
        price: data.soldPrice.mth[lastSoldMonth].med || "N/A",
      });

      const lastNewListingsMonth = Object.keys(data.new.mth)
        .sort()
        .reverse()[0];
      setLatestNumberOfNewListings({
        month: lastNewListingsMonth,
        count: data.new.mth[lastNewListingsMonth].count || 0,
      });

      const soldCurrentYear = Object.keys(data.soldPrice.yr)
        .sort()
        .reverse()[0];
      const soldPreviousYear = Object.keys(data.soldPrice.yr)
        .sort()
        .reverse()[1];
      const sold5YearsAgo = Object.keys(data.soldPrice.yr).sort().reverse()[5];
      const sold10YearsAgo = Object.keys(data.soldPrice.yr)
        .sort()
        .reverse()[10];

      const soldCurrentYearAvgPrice =
        data.soldPrice.yr[soldCurrentYear].avg || 0;
      const soldPreviousYearAvgPrice =
        data.soldPrice.yr[soldPreviousYear].avg || 0;
      const sold5YearsAgoAvgPrice = data.soldPrice.yr[sold5YearsAgo].avg || 0;
      const sold10YearsAgoAvgPrice = data.soldPrice.yr[sold10YearsAgo].avg || 0;

      const oneYearAvgChange =
        ((soldCurrentYearAvgPrice - soldPreviousYearAvgPrice) /
          soldPreviousYearAvgPrice) *
        100;

      const fiveYearsAvgChange =
        ((soldCurrentYearAvgPrice - sold5YearsAgoAvgPrice) /
          sold5YearsAgoAvgPrice) *
        100;

      const tenYearsAvgChange =
        ((soldCurrentYearAvgPrice - sold10YearsAgoAvgPrice) /
          sold10YearsAgoAvgPrice) *
        100;

      setValueChange({
        1: +oneYearAvgChange.toFixed(1),
        5: +fiveYearsAvgChange.toFixed(1),
        10: +tenYearsAvgChange.toFixed(1),
      });
    }
  }, [data]);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <IonSpinner name="crescent" />
        </div>
      ) : (
        <div className={styles.marketDataContianer}>
          <div className={styles.title}>
            Overfiew of community stats for{" "}
            <span>
              {
                ListingsConstants.AllPropertyTypes.find(
                  (type) => type.value === listing.details.propertyType
                )?.label
              }
            </span>{" "}
            in <span>{listing.address.neighborhood}</span>,{" "}
            <span>{listing.address.area}</span>
          </div>

          {data ? (
            <>
              <div className={styles.summary}>
                <div className="aecorn-listing-detail-tab vertical">
                  <div className="aecorn-listing-detail-tab_label">
                    Median sold price
                  </div>
                  <div className="aecorn-listing-detail-tab_value">
                    {latestMedianSoldPrice?.month || "-"}
                  </div>
                  <div className="aecorn-listing-detail-tab_value">
                    {latestMedianSoldPrice
                      ? formatPrice(latestMedianSoldPrice.price)
                      : "-"}
                  </div>
                </div>
                <div className="aecorn-listing-detail-tab vertical">
                  <div className="aecorn-listing-detail-tab_label">
                    Number of new listings
                  </div>
                  <div className="aecorn-listing-detail-tab_value">
                    {latestNumberOfNewListings?.month || "-"}
                  </div>
                  <div className="aecorn-listing-detail-tab_value">
                    {latestNumberOfNewListings
                      ? latestNumberOfNewListings.count
                      : "-"}
                  </div>
                </div>
                <div className="aecorn-listing-detail-tab vertical">
                  <div className="aecorn-listing-detail-tab_label">
                    Value change
                  </div>
                  <div className={styles.valueChanges}>
                    {valueChange &&
                      Object.entries(valueChange).map(([key, value]) => {
                        return (
                          <div key={key} className={styles.change}>
                            <span>{key} year</span>
                            <span
                              className={
                                value > 0 ? styles.increase : styles.decrease
                              }>
                              {value}%
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className={styles.header}>
                <IonItem className="aecorn-select border" lines="none">
                  <IonSelect
                    value={years}
                    interface={isNative ? "alert" : "popover"}
                    interfaceOptions={{
                      cssClass: isNative
                        ? "aecorn-alert"
                        : "aecorn-select-popover",
                    }}
                    justify="space-between"
                    toggleIcon={chevronDownOutline}
                    expandedIcon={chevronUpOutline}
                    onIonChange={(e) => setYears(e.detail.value)}>
                    <IonSelectOption value={5}>5 Years</IonSelectOption>
                    <IonSelectOption value={10}>10 Years</IonSelectOption>
                    <IonSelectOption value={15}>15 Years</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <p className={styles.chartsTitle}>
                  Access market data for a selected time period. Tap on the
                  graph to see more details.
                </p>
              </div>
              <div className={styles.charts}>
                <div className="aecorn-listing-detail-tab vertical">
                  <div className={styles.chartLabel}>
                    Median sold price and average days on market in this area*
                  </div>
                  <div className={styles.chart}>
                    <MedianSoldPriceWithAverageDOMChart
                      data={{
                        soldPrice: data.soldPrice,
                        dom: data.daysOnMarket,
                      }}
                      listing={listing}
                      numOfYears={years}
                    />
                  </div>
                </div>
                <div className="aecorn-listing-detail-tab vertical">
                  <div className={styles.chartLabel}>
                    Sold, active and new listings in this area*
                  </div>
                  <div className={styles.chart}>
                    <SoldAndActiveListingsCount
                      data={{
                        new: data.new,
                        closed: data.closed,
                        active: data.available,
                      }}
                      listing={listing}
                      numOfYears={years}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.noData}>No data available</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ListingMarketData;
