import { Tour, TourPendingClient, TourRole, User } from "API";
import styles from "./agent-tour-attendees.module.scss";
import {
  IonIcon,
  IonLabel,
  IonLoading,
  useIonToast,
} from "@ionic/react";
import { TourService } from "services/tourService";
import EditIcon from "assets/svg/edit.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { UserService } from "services/userService";
import { isNil } from "lodash";
import TourLeadAgentModal from "components/shared/Modals/TourLeadAgentModal/tour-lead-agent-modal.component";
import TourShowingAgentsModal from "components/shared/Modals/TourShowingAgentsModal/tour-showing-agents-modal.component";
import TourClientModal from "components/shared/Modals/TourClientModal/tour-client-modal.component";
import TourGuestsModal from "components/shared/Modals/TourGuestsModal/tour-guests-modal.component";
import { selectCurrentUsername } from "redux/user/user.selectors";
const AgentTourAttendees = ({ tour }: { tour: Tour }) => {
  const userId = useSelector(selectCurrentUsername);
  const isUserTourCreator = tour.creator === userId;

  const primaryAgent = TourService.getTourPrimaryAgent(tour);
  const showingAgents = TourService.getTourShowingAgents(tour) as User[];
  const client = TourService.getTourClient(tour);
  const guests = TourService.getTourGuests(tour) as User[];
  const pendingClients = TourService.getTourPendingClients(
    tour
  ) as TourPendingClient[];
  const pendingPrimaryClient = pendingClients.find(
    (client) => client.role === TourRole.client
  );

  const allGuests = [
    ...guests.map((g) => g.displayName),
    ...pendingClients
      .filter((client) => client.role === TourRole.guest)
      .map((client) => client.email),
  ];

  const [presentToast] = useIonToast();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [guestsLoading, setGuestsLoading] = useState(false);
  const [isPrimaryAgentModalOpen, setIsPrimaryAgentModalOpen] = useState(false);
  const [isShowingAgentsModalOpen, setIsShowingAgentsModalOpen] =
    useState(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isGuestsModalOpen, setIsGuestsModalOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<User[]>([]);

  const [tempPrimaryAgent, setTempPrimaryAgent] = useState<
    User | undefined | null
  >();
  const [tempShowingAgents, setTempShowingAgents] = useState<User[]>([]);
  const [tempClient, setTempClient] = useState<{
    email: string;
    user?: User;
  } | null>();

  const [tempGuests, setTempGuests] = useState<
    { email: string; user?: User }[]
  >([]);

  const resetSearch = () => {
    setSearchResults([]);
    setSearchTerm("");
  };

  const onTogglePrimaryAgentModal = (open: boolean) => {
    setTempPrimaryAgent(open ? primaryAgent : null);
    setIsPrimaryAgentModalOpen(open);
    if (!open) {
      resetSearch();
    }
  };

  const onToggleShowingAgentsModal = (open: boolean) => {
    setTempShowingAgents(open ? showingAgents : []);
    setIsShowingAgentsModalOpen(open);
    if (!open) {
      resetSearch();
    }
  };

  const onToggleClientModal = (open: boolean) => {
    const pendingClient = pendingClients.find(
      (c) => c.role === TourRole.client
    );
    setTempClient(
      client
        ? { email: client.email, user: client }
        : pendingClient
        ? { email: pendingClient.email }
        : null
    );
    setIsClientModalOpen(open);
    if (!open) {
      resetSearch();
    }
  };

  const onToggleGuestsModal = (open: boolean) => {
    setTempGuests(
      open
        ? [
            ...guests.map((g) => ({ email: g.email, user: g })),
            ...pendingClients
              .filter((client) => client.role === TourRole.guest)
              .map((client) => ({ email: client.email })),
          ]
        : []
    );
    setIsGuestsModalOpen(open);
    if (!open) {
      resetSearch();
    }
  };

  const onSavePrimaryAgent = async () => {
    try {
      setSubmitLoading(true);
      const users = [];
      if (client) {
        users.push({ id: client.id, role: TourRole.client });
      }
      if (tempPrimaryAgent) {
        users.push({ id: tempPrimaryAgent.id, role: TourRole.primaryagent });
      }

      guests.forEach((g) => {
        users.push({ id: g!.id, role: TourRole.guest });
      });

      showingAgents.forEach((a) => {
        users.push({ id: a!.id, role: TourRole.showingagent });
      });

      await TourService.updateTourUsers(tour.id, users);

      presentToast({
        message: "Primary agent updated successfully",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
      onTogglePrimaryAgentModal(false);
    } catch (err) {
      console.log(err);
      presentToast({
        message: "Failed to update primary agent",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const onSaveShowingAgents = async () => {
    try {
      setSubmitLoading(true);
      const users = [];
      if (client) {
        users.push({ id: client.id, role: TourRole.client });
      }
      if (primaryAgent) {
        users.push({ id: primaryAgent.id, role: TourRole.primaryagent });
      }

      guests.forEach((g) => {
        users.push({ id: g!.id, role: TourRole.guest });
      });

      tempShowingAgents.forEach((a) => {
        users.push({ id: a!.id, role: TourRole.showingagent });
      });

      await TourService.updateTourUsers(tour.id, users);

      presentToast({
        message: "Showing agents updated successfully",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
      onToggleShowingAgentsModal(false);
    } catch (err) {
      console.log(err);
      presentToast({
        message: "Failed to update showing agents",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const onSaveClient = async () => {
    try {
      setSubmitLoading(true);
      const users = [];
      if (tempClient) {
        if (tempClient.user) {
          users.push({ id: tempClient.user.id, role: TourRole.client });
        } else {
          const clients: {
            email: string;
            role: TourRole.client | TourRole.guest;
          }[] = [
            { email: tempClient.email, role: TourRole.client },
            ...(pendingClients
              .filter((c) => c.role === TourRole.guest)
              .map((c) => ({ email: c.email, role: TourRole.guest })) as {
              email: string;
              role: TourRole.client | TourRole.guest;
            }[]),
          ];
          await onUpdatePendingClients(clients);
        }
      }

      if (primaryAgent) {
        users.push({ id: primaryAgent.id, role: TourRole.primaryagent });
      }

      guests.forEach((g) => {
        users.push({ id: g!.id, role: TourRole.guest });
      });

      showingAgents.forEach((a) => {
        users.push({ id: a!.id, role: TourRole.showingagent });
      });

      await TourService.updateTourUsers(tour.id, users);

      presentToast({
        message: "Client updated successfully",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
      onToggleClientModal(false);
    } catch (err) {
      console.log(err);
      presentToast({
        message: "Failed to update client",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const onSaveGuests = async () => {
    try {
      setSubmitLoading(true);
      const users = [];
      if (client) {
        users.push({ id: client.id, role: TourRole.client });
      }
      if (primaryAgent) {
        users.push({ id: primaryAgent.id, role: TourRole.primaryagent });
      }
      tempGuests
        .filter((g) => !isNil(g.user))
        .forEach((g) => {
          users.push({ id: g.user!.id, role: TourRole.guest });
        });
      showingAgents.forEach((a) => {
        users.push({ id: a!.id, role: TourRole.showingagent });
      });
      await TourService.updateTourUsers(tour.id, users);

      const pendingClients: {
        email: string;
        role: TourRole.client | TourRole.guest;
      }[] = [];

      tempGuests
        .filter((g) => isNil(g.user))
        .forEach((g) => {
          pendingClients.push({ email: g.email, role: TourRole.guest });
        });

      const pendingPrimaryClient = pendingClients.find(
        (client) => client.role === TourRole.client
      );

      if (!client && pendingPrimaryClient) {
        pendingClients.push({
          email: pendingPrimaryClient.email,
          role: TourRole.client,
        });
      }

      await onUpdatePendingClients(pendingClients);

      presentToast({
        message: "Guests updated successfully",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
      onToggleGuestsModal(false);
    } catch (err) {
      console.log(err);
      presentToast({
        message: "Failed to update guests",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const onUpdatePendingClients = async (
    clients: { email: string; role: TourRole.client | TourRole.guest }[]
  ) => {
    await TourService.updateTourPendingClients(tour.id, clients);
  };

  const searchForAgents = async (searchTerm: string) => {
    try {
      if (!searchTerm) {
        setSearchResults([]);
        return;
      }
      const results = await UserService.getAgentUsers(searchTerm);
      setSearchResults(results as User[]);
    } catch (err) {
      console.log(err);
    }
  };

  const searchForClients = async (searchTerm: string) => {
    try {
      if (!searchTerm) {
        setSearchResults([]);
        return;
      }
      const results = await UserService.getClientUsers(searchTerm);
      setSearchResults(results as User[]);
    } catch (err) {
      console.log(err);
    }
  };

  const searchForGuests = async (
    searchTerm: string,
    newTempGuests: { email: string; user?: User }[]
  ) => {
    try {
      setGuestsLoading(true);
      if (!searchTerm) {
        setSearchResults([]);
        return;
      }
      const results = await UserService.getClientUsers(searchTerm);
      setTempGuests(
        newTempGuests.map((g) => ({
          ...g,
          user: g.user
            ? g.user
            : results.find((r) => r!.email === g.email) || undefined,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setGuestsLoading(false);
    }
  };

  const onAddGuestEmail = async () => {
    const email = searchTerm.trim();
    const newTempGuests = [...tempGuests, { email, user: undefined }];
    setSearchTerm("");
    await searchForGuests(email, newTempGuests);
  };

  return (
    <div className={styles.container}>
      <IonLoading isOpen={submitLoading} message="Please wait..." />
      <TourLeadAgentModal
        isOpen={isPrimaryAgentModalOpen}
        onDismiss={() => {
          onTogglePrimaryAgentModal(false);
          resetSearch();
        }}
        searchTerm={searchTerm}
        onSearchTermChange={(term: string) => {
          setSearchTerm(term);
          searchForAgents(term);
        }}
        searchResults={searchResults}
        selectedAgent={tempPrimaryAgent}
        onUpdateAgent={setTempPrimaryAgent}
        onSave={onSavePrimaryAgent}
      />
      <TourShowingAgentsModal
        isOpen={isShowingAgentsModalOpen}
        onDismiss={() => {
          onToggleShowingAgentsModal(false);
          resetSearch();
        }}
        searchTerm={searchTerm}
        onSearchTermChange={(term: string) => {
          setSearchTerm(term);
          searchForAgents(term);
        }}
        searchResults={searchResults}
        selectedAgents={tempShowingAgents}
        onUpdateAgents={setTempShowingAgents}
        onSave={onSaveShowingAgents}
      />
      <TourClientModal
        isOpen={isClientModalOpen}
        onDismiss={() => {
          onToggleClientModal(false);
          resetSearch();
        }}
        searchTerm={searchTerm}
        onSearchTermChange={(term: string) => {
          setSearchTerm(term);
          searchForClients(term);
        }}
        searchResults={searchResults}
        selectedClient={tempClient}
        onUpdateClient={setTempClient}
        onSave={onSaveClient}
      />
      <TourGuestsModal 
        isOpen={isGuestsModalOpen}
        onDismiss={() => {
          onToggleGuestsModal(false);
          resetSearch();
        }}
        searchTerm={searchTerm}
        onSearchTermChange={(term: string) => {
          setSearchTerm(term);
        }}
        searchResults={searchResults}
        selectedGuests={tempGuests}
        onUpdateGuests={setTempGuests}
        onAddEmail={onAddGuestEmail}
        onSave={onSaveGuests}
        loading={guestsLoading}
        client={client}
        primaryAgent={primaryAgent}
        showingAgents={showingAgents}
      />
      <section className={styles.group}>
        <IonLabel>Agent</IonLabel>
        <section className={styles.section}>
          <IonLabel>Lead agent</IonLabel>
          <div className={styles.content}>
            <div className={styles.row}>
              <div
                className={`${styles.card} ${
                  !primaryAgent && styles.emptyCard
                } ${!isUserTourCreator && styles.readOnlyCard}`}>
                {!primaryAgent ? "No Data" : primaryAgent.displayName}
                {isUserTourCreator && (
                  <IonIcon
                    icon={EditIcon}
                    onClick={() => onTogglePrimaryAgentModal(true)}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        <section className={styles.section}>
          <IonLabel>Showing agent</IonLabel>
          <div className={styles.content}>
            <div className={styles.row}>
              <div
                className={`${styles.card} ${
                  !showingAgents.length && styles.emptyCard
                }`}>
                <span className={styles.text}>
                  {!showingAgents.length
                    ? "No Data"
                    : showingAgents
                        .map((agent) => agent?.displayName)
                        .join(", ")}
                </span>
                <IonIcon
                  icon={EditIcon}
                  onClick={() => onToggleShowingAgentsModal(true)}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className={styles.group}>
        <IonLabel>Client</IonLabel>
        <section className={styles.section}>
          <IonLabel>Client</IonLabel>
          <div className={styles.content}>
            <div className={styles.row}>
              <div
                className={`${styles.card} ${
                  !client && !pendingPrimaryClient && styles.emptyCard
                }`}>
                {client
                  ? client.displayName
                  : pendingPrimaryClient
                  ? pendingPrimaryClient.email
                  : "No Data"}
                <IonIcon
                  icon={EditIcon}
                  onClick={() => onToggleClientModal(true)}
                />
              </div>
            </div>
          </div>
        </section>
        <section className={styles.section}>
          <IonLabel>Guests</IonLabel>
          <div className={styles.content}>
            <div className={styles.row}>
              <div
                className={`${styles.card} ${
                  !allGuests.length && styles.emptyCard
                }`}>
                <span className={styles.text}>
                  {!allGuests.length ? "No Data" : allGuests.join(", ")}
                </span>
                <IonIcon
                  icon={EditIcon}
                  onClick={() => onToggleGuestsModal(true)}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default AgentTourAttendees;
