import { Redirect, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { App } from "@capacitor/app";
import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonModal,
} from "@ionic/react";
import { Route } from "react-router-dom";
import styles from "./styles.module.scss";
import { updateTab } from "../redux/ui/ui.actions";
import { useEffect, useRef, useState, useCallback } from "react";
import HomeIcon from "assets/svg/tabs/home.svg";
import AgentHomeIcon from "assets/svg/tabs/agent-home.svg";
import MapIcon from "assets/svg/tabs/map.svg";
import NotificationsIcon from "assets/svg/tabs/notifications.svg";
import ToursIcon from "assets/svg/tabs/tours.svg";
import MoreIcon from "assets/svg/tabs/profile.svg";
import Listing from "pages/shared/Listing/listing.page";
import Home from "pages/shared/Home/home.page";
import {
  selectIsAgent,
  selectIsAuthenticated,
} from "redux/user/user.selectors";
import Dashboard from "pages/shared/Dashboard/dashboard.page";
import AuthenticationModal from "components/shared/Modals/AuthenticationModal/authentication-modal.component";
import Search from "pages/app/Search/search.page";
import { Haptics } from "@capacitor/haptics";
import Tours from "pages/shared/Tours/tours.page";
import Activities from "pages/shared/Activities/activities.page";
import { selectCurrentClient } from "redux/client/client.selectors";
import { ActivityService } from "services/activityService";
import { ActivityStatus } from "API";
import { TourService } from "services/tourService";
import emitter from "services/emitterService";
import { Events } from "enums/General/Events.enum";
import usePushNotificationListener from "utils/appPushNotificationListener";

const RESTRICTED_TABS = ["activities", "dashboard", "tours"];

const AppTabs = () => {
  const tabRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentClient);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAgent = useSelector(selectIsAgent);
  const [activities, setActivities] = useState([]);
  const [pendingTours, setPendingTours] = useState([]);
  const subscriptions = useRef([]);
  usePushNotificationListener();

  const path = history.location.pathname;

  const [presentAuthenticationModal, dismiss] = useIonModal(
    AuthenticationModal,
    {
      onDismiss: () => {
        dismiss();
      },
      mode: "login",
    }
  );

  const checkUserAccess = (e) => {
    const tab = e.detail.tab;
    if (RESTRICTED_TABS.includes(tab) && !isAuthenticated) {
      Haptics.impact({
        style: "light",
      });
      presentAuthenticationModal({
        cssClass: "auth-modal",
      });
      throw new Error("User not authenticated");
    }
  };

  const onTabChange = (e) => {
    const tab = e.detail.tab;
    dispatch(updateTab(tab));
  };

  useEffect(() => {
    dispatch(updateTab(tabRef.current?.ionTabContextState.activeTab));
  }, [dispatch]);

  const fetchActivities = useCallback(async () => {
    if (user) {
      const result = await ActivityService.getActivities({
        status: {
          eq: ActivityStatus.sent,
        },
      });
      setActivities(result.activities);
    }
  }, [user]);

  const fetchPendingTours = useCallback(async () => {
    if (user) {
      const result = await TourService.getPendingToursByEmail(user.email);
      setPendingTours(result);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchActivities();
      fetchPendingTours();
      const newActivitySubscription = ActivityService.onNewActivity(
        user.id,
        () => {
          fetchActivities();
        }
      );
      const updateActivitySubscription = ActivityService.onUpdateActivity(
        user.id,
        (activity) => {
          setActivities((prev) =>
            prev.map((a) => (a.id === activity.id ? activity : a))
          );
        }
      );

      subscriptions.current = [
        newActivitySubscription,
        updateActivitySubscription,
      ];
    } else {
      subscriptions.current.forEach((subscription) =>
        subscription.unsubscribe()
      );
      setActivities([]);
      setPendingTours([]);
    }

    return () => {
      subscriptions.current.forEach((subscription) =>
        subscription.unsubscribe()
      );
    };
  }, [user, fetchActivities]);

  useEffect(() => {
    App.addListener("resume", () => {
      fetchActivities();
      fetchPendingTours();
    });
  }, [fetchActivities]);

  useEffect(() => {
    emitter.on(Events.UPDATE_PENDING_TOURS, fetchPendingTours);
    emitter.on(Events.UPDATE_ACTIVITIES, fetchActivities);

    App.addListener("appStateChange", async (state) => {
      if (state.isActive) {
        fetchActivities();
        fetchPendingTours();
      }
    });

    return () => {
      emitter.off(Events.UPDATE_PENDING_TOURS, fetchPendingTours);
      emitter.off(Events.UPDATE_ACTIVITIES, fetchActivities);
    }
  },[]);

  return (
    <IonTabs
      onIonTabsDidChange={onTabChange}
      onIonTabsWillChange={checkUserAccess}
      ref={tabRef}>
      <IonRouterOutlet animated id="tabs">
        <Route exact path="/tabs/home" component={Home} />
        <Route exact path="/tabs/home/:id" component={Listing} />
        <Route
          exact
          path="/tabs/listings"
          render={(props) => {
            return <Search {...props} />;
          }}
        />
        <Route exact path="/tabs/activities" component={Activities} />
        <Route exact path="/tabs/tours" component={Tours} />
        <Route exact path="/tabs/dashboard" component={Dashboard} />
        <Route exact path="/">
          <Redirect to="/tabs/home" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className={`${styles.tabBar} ${isAgent ? styles.agent : ""}`}>
        <IonTabButton
          tab="home"
          href="/tabs/home"
          layout={path !== "/tabs/home" ? "label-hide" : "icon-top"}>
          <IonIcon
            icon={isAgent ? AgentHomeIcon : HomeIcon}
            className={path.includes("/tabs/home") ? styles.active : ""}
          />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="map"
          href="/tabs/listings"
          layout={path !== "/tabs/listings" ? "label-hide" : "icon-top"}>
          <IonIcon
            className={path.includes("/tabs/listings") ? styles.active : ""}
            icon={MapIcon}
          />
          <IonLabel>Explore</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="activities"
          href="/tabs/activities"
          layout={path !== "/tabs/activities" ? "label-hide" : "icon-top"}>
          <IonIcon
            icon={NotificationsIcon}
            className={path.includes("/tabs/activities") ? styles.active : ""}
          />
          {activities.some((a) => a.status === ActivityStatus.sent) && (
            <IonBadge className={styles.notificationBadge}>
              {
                activities.filter((a) => a.status === ActivityStatus.sent)
                  .length
              }
            </IonBadge>
          )}
          <IonLabel>Activities</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="tours"
          href="/tabs/tours"
          layout={path !== "/tabs/tours" ? "label-hide" : "icon-top"}>
          <IonIcon
            className={path.includes("/tabs/tours") ? styles.active : ""}
            icon={ToursIcon}
          />
          {pendingTours.length > 0 && (
            <IonBadge className={styles.notificationBadge}>
              {pendingTours.length}
            </IonBadge>
          )}
          <IonLabel>Tours</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="dashboard"
          href="/tabs/dashboard"
          layout={path !== "/tabs/dashboard" ? "label-hide" : "icon-top"}>
          <IonIcon
            style={{ fill: "#fff" }}
            className={path.includes("/tabs/dashboard") ? styles.active : ""}
            icon={MoreIcon}
          />
          <IonLabel>Dashboard</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AppTabs;
