import { Link } from "react-router-dom";
import styles from "./quick-searches.module.scss";

const QuickSearches = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Popular quick searches</div>
      <div className={styles.items}>
        <div className={styles.item}>
          <div className={styles.title}>Toronto</div>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale">
              Toronto homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale">
              Toronto houses for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Condo Apt,Condo townhouse&type=Sale">
              Toronto condos for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Att/Row/Twnhouse&type=Sale">
              Toronto townhouses for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Detached&type=Sale">
              Toronto detached homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Semi-detached&type=Sale">
              Toronto semi-detached homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Condo Apt,Condo townhouse&type=Lease">
              Toronto condos for rent
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Lease">
              Toronto houses for rent
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Lease">
              Toronto homes for rent
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Condo Apt,Condo townhouse&type=Sale&lat=43.642567&lng=-79.387054">
              Downtown Toronto condos for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?area=Toronto&propertyType=Condo Apt,Condo townhouse&type=Lease&lat=43.642567&lng=-79.387054">
              Downtown Toronto condos for rent
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&lat=43.7615&lng=-79.4111">
              North York condos for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&lat=43.7615&lng=-79.4111">
              North York houses for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&lat=43.6205&lng=-79.5132">
              Etobicoke homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Lease&lat=43.6205&lng=-79.5132">
              Etobicoke condos for rent
            </Link>
          </span>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>GTA</div>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=Durham&city=Ajax&lat=43.8509&lng=-79.0204">
              Ajax homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=Peel&city=Brampton&lat=43.7315&lng=-79.7624">
              Brampton homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=Halton&city=Burlington&lat=43.3255&lng=-79.7990">
              Burlington homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=York&city=Markham&lat=43.8561&lng=-79.3370">
              Markham homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Lease&area=York&city=Markham&lat=43.8561&lng=-79.3370">
              Markham homes for rent
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=Peel&city=Mississauga&lat=43.5890&lng=-79.6441">
              Mississauga homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Lease&area=Peel&city=Mississauga&lat=43.5890&lng=-79.6441">
              Mississauga homes for rent
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=Halton&city=Oakville&lat=43.4675&lng=-79.6877">
              Oakville homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Lease&area=Halton&city=Oakville&lat=43.4675&lng=-79.6877">
              Oakville homes for rent
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=Durham&city=Oshawa&lat=43.8971&lng=-79.8658">
              Oshawa homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=York&city=Richmond Hill&lat=43.8828&lng=-79.4403">
              Richmond Hill homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Lease&area=York&city=Richmond Hill&lat=43.8828&lng=-79.4403">
              Richmond Hill homes for rent
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=York&city=Vaughan&lat=43.837208&lng=-79.508278">
              Vaughan homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Lease&area=York&city=Vaughan&lat=43.837208&lng=-79.508278">
              Vaughan homes for rent
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&area=Durham&city=Whitby&lat=43.897545&lng=-78.942932">
              Whitby homes for sale
            </Link>
          </span>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Keyword</div>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale">
              Houses for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale">
              Homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached&type=Sale">
              Detached homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Semi-detached&type=Sale">
              Semi-detached homes for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale">
              Condos for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo townhouse,Att/Row/Twnhouse&type=Sale">
              Townhouses for sale
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&minBeds=4&maxBeds=4">
              4-bed homes
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&minBeds=3&maxBeds=3">
              3-bed homes
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt&type=Sale&minSqft=1000">
              Penthouse condos
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minBeds=3&maxBeds=3">
              3-bed condos
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minBeds=2&maxBeds=2">
              2-bed condos
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minBeds=1&maxBeds=1">
              1-bed condos
            </Link>
          </span>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Price</div>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minPrice=0&maxPrice=500000">
              Condos under $500k
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minPrice=0&maxPrice=700000&lat=43.642567&lng=-79.387054">
              Downtown Toronto condos under $700k
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached&type=Sale&minPrice=0&maxPrice=1000000&area=Toronto&city=Toronto">
              Toronto detached homes under $1M
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&minPrice=0&maxPrice=1000000">
              Houses under $1M
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minPrice=0&maxPrice=1000000">
              Condos under $1M
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&minPrice=3500000">
              Most expensive houses
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minPrice=2000000">
              Most expensive condos
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minPrice=0&maxPrice=750000&area=Toronto&city=Toronto">
              Most affordable condos in Toronto
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&minPrice=0&maxPrice=1000000&area=Toronto&city=Toronto">
              Most affordable houses in Toronto
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minPrice=0&maxPrice=700000">
              Most affordable condos in GTA
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&minPrice=0&maxPrice=800000">
              Most affordable houses in GTA
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Condo Apt,Condo townhouse&type=Sale&minPrice=2500000">
              Luxury condos
            </Link>
          </span>
          <span className={styles.search}>
            <Link to="/listings?propertyType=Detached,Att/Row/Twnhouse,Semi-detached&type=Sale&minPrice=5000000">
              Luxury houses
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuickSearches;
