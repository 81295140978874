import { Review, Tour } from "API";
import styles from "./tour-review.module.scss";
import { useSelector } from "react-redux";
import { TourService } from "services/tourService";
import { useState } from "react";
import { IonIcon, useIonToast } from "@ionic/react";
import StarIcon from "assets/svg/star-light.svg";
import { TourItemService } from "services/tourItemService";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { selectCurrentUsername } from "redux/user/user.selectors";

const TourReview = ({
  tour,
  tourItemId,
  review,
  setUserReview,
}: {
  tour: Tour;
  tourItemId: string;
  review?: Review;
  setUserReview?: (review: Review) => void;
}) => {
  const [presentToast] = useIonToast();
  const currentUserId = useSelector(selectCurrentUsername);
  const isUserReview = !review || review.userId === currentUserId;
  const tourUsers = TourService.getTourUsers(tour);

  const rating = review?.rate || 0;

  const [rate, setRate] = useState<number>(rating);

  const onUpdateReview = async (rate: number) => {
    if (!isUserReview || !setUserReview) {
      return;
    }
    
    const previousRate = rate;
    setRate(rate);
    Haptics.impact({ style: ImpactStyle.Light });
    try {
      if (review) {
        await TourItemService.updateReview(review.id, rate);
      } else {
        const review = await TourItemService.addReview(tourItemId, rate);
        if (review) {
          setUserReview(review);
        }
      }
    } catch (error) {
      console.log(error);
      presentToast({
        message: "Failed to update review",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
      setRate(previousRate);
    }
  };

  return (
    <div className={`${styles.review} ${isUserReview ? styles.userReview : ""}`}>
      <div className={styles.owner}>
        {isUserReview
          ? "My review"
          : `${
              tourUsers.find((user) => user.id === review.userId)?.displayName
            }'s review`}
      </div>
      <div className={styles.rating}>
        {[1, 2, 3, 4, 5].map((r, index) => (
          <IonIcon
            key={index}
            className={rate >= r ? styles.filled : ""}
            icon={StarIcon}
            onClick={() => onUpdateReview(r)}
          />
        ))}
      </div>
    </div>
  );
};

export default TourReview;
