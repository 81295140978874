import { memo } from "react";
import styles from "./marker.styles.module.scss";

type MarkerProps = React.PropsWithChildren<{zIndex: number, lat: number, lng: number}>;

const Marker = memo((marker: MarkerProps) => {
  return <div className={styles.marker} style={{zIndex: marker.zIndex || 1}}>{marker.children}</div>;
});

export default Marker;
