import { IonIcon, IonButton } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import MediaQuery, { useMediaQuery } from "react-responsive";
import styles from "../modals.module.scss";
import modalStyles from "./add-listing-to-existing-tour-modal.module.scss";
import Modal from "components/ui/modal/modal.component";
import { Tour } from "API";
import { Listing } from "models/listings/listing.model";
import { ListingsHelper } from "utils/ListingsHelper";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import { ReactComponent as LocationPin } from "assets/svg/filledLocation.svg";
import Marker from "components/shared/Map/Map/components/Marker/marker.component";
import { googleMapStyles } from "components/shared/Map/Map/google-map.styles";
import { useRef } from "react";

const AddListingToExistingTourModal = ({
  isOpen,
  onDismiss,
  tour,
  listingToAdd,
  listings,
  onSave,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  tour: Tour;
  listingToAdd: Listing;
  listings: Listing[];
  onSave: () => void;
}) => {
  const mapRef = useRef<any>();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
console.log(tour)
  const onMapReadyHandler = (map: any, maps: any) => {
    mapRef.current = map;
    const bounds = new maps.LatLngBounds();
    [...listings, listingToAdd].forEach((listing) => {
      bounds.extend(
        new maps.LatLng(+listing.map.latitude, +listing.map.longitude)
      );
    });
    map.fitBounds(bounds);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      breakpoints={isMobile ? [0, 1] : undefined}
      initialBreakpoint={isMobile ? 1 : undefined}>
      <div className={styles.sheetContent}>
        <div className={styles.modalHeader}>
          Add #{listingToAdd.mlsNumber} to {tour.title}
          <MediaQuery minWidth={768}>
            <IonIcon icon={closeOutline} onClick={onDismiss} />
          </MediaQuery>
        </div>
        <div className={modalStyles.content}>
          <div className={modalStyles.note}>
            You are adding{" "}
            {ListingsHelper.getListingAddress(listingToAdd.address)} to{" "}
            {tour.title}.
          </div>
          <div className={modalStyles.date}>
            <span>{tour.title}</span>
            <span>
              {tour.date && moment(tour.date).format("ddd, DD MMM")}
              {tour.startTime &&
                tour.endTime &&
                `, ${moment(tour.startTime, "HH:mm").format(
                  "HH:mm A"
                )}-${moment(tour.endTime, "HH:mm").format("HH:mm A")}`}
            </span>
          </div>
          <div className={modalStyles.map}>
            <GoogleMapReact
              onGoogleApiLoaded={({ map, maps }) =>
                onMapReadyHandler(map, maps)
              }
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
              }}
              defaultCenter={{
                lat: +listingToAdd.map.latitude,
                lng: +listingToAdd.map.longitude,
              }}
              options={{
                disableDefaultUI: true,
                zoomControl: false,
                draggable: false,
                scrollwheel: false,
                panControl: false,
                styles: googleMapStyles,
                keyboardShortcuts: false,
              }}
              defaultZoom={15}>
              {listings.map((listing) => (
                <Marker
                  key={listing.mlsNumber}
                  zIndex={1}
                  lat={+listing.map.latitude}
                  lng={+listing.map.longitude}>
                  <div className={modalStyles.marker}>
                    <LocationPin className={modalStyles.pin} />
                    <span className={modalStyles.order}>
                      {
                        tour.tourItems?.items.find(
                          (i) => i?.mlsNumber === listing.mlsNumber
                        )?.order
                      }
                    </span>
                  </div>
                </Marker>
              ))}
              <Marker
                zIndex={2}
                lat={+listingToAdd.map.latitude}
                lng={+listingToAdd.map.longitude}>
                <div className={`${modalStyles.marker} ${modalStyles.new}`}>
                  <LocationPin className={modalStyles.pin} />
                  <span className={modalStyles.order}>
                    {
                      (tour.tourItems?.items.length || 0) + 1
                    }
                  </span>
                </div>
              </Marker>
            </GoogleMapReact>
          </div>
        </div>
        <IonButton className="aecorn-button" onClick={onSave}>
          Confirm
        </IonButton>
      </div>
    </Modal>
  );
};

export default AddListingToExistingTourModal;
