import { FiltersActionTypes } from "./filters.types";

export const INITIAL_STATE = {
  value: {
    area: undefined,
    city: undefined,
    neighborhood: undefined,
    type: "Sale",
    class: ["residential", "condo"],
    status: "A",
    transactionStatus: "sale",
    date: 0,
    lastStatus: null,
    style: null,
    propertyType: [],
    minListDate: null,
    maxListDate: null,
    minSoldDate: null,
    maxSoldDate: null,
    minUpdatedOn: null,
    maxUpdatedOn: null,
    listDate: null,
    displayPublic: "Y",
    displayAddressOnInternet: "Y",
    map: null,
    minPrice: null,
    maxPrice: null,
    minBeds: null,
    maxBeds: null,
    minBaths: null,
    maxBaths: null,
    minParkingSpaces: null,
    minSqft: null,
    maxSqft: null,
    keywords: [],
    hasImages: null,
    resultsPerPage: 2500,
    pageNum: 1,
    preAvailability: null,
    builder: null,
    projectName: null,
    yearOfOccupancy: null,
    minPricePerSqft: "0",
    maxPricePerSqft: "max",
    maxMaintenanceFee: null,
    amenities: [],
  },
  extra: {
    lotWidth: { min: null, max: null },
    lotDepth: { min: null, max: null },
    locker: null,
    basement: {
      finished: false,
      walkOut: false,
      sepEntrance: false,
      any: true,
    },
    maintenance: null,
  },
};

const filtersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FiltersActionTypes.UPDATE_FILTERS:
      return {
        ...state,
        value: { ...state.value, ...action.payload },
      };
    case FiltersActionTypes.UPDATE_MULTIPLE_FILTERS:
      return {
        ...state,
        value: { ...state.value, ...action.payload },
      };
    case FiltersActionTypes.UPDATE_EXTRA_FILTERS:
      return { ...state, extra: { ...state.extra, ...action.payload } };
    case FiltersActionTypes.RESET_FILTERS:
      return {
        value: {
          ...INITIAL_STATE.value,
          type: state.value.type,
          class: state.value.class,
          status: state.value.status,
          transactionStatus: state.value.transactionStatus,
          minListDate: state.value.minListDate,
          maxListDate: state.value.maxListDate,
          minSoldDate: state.value.minSoldDate,
          maxSoldDate: state.value.maxSoldDate,
          minUpdatedOn: state.value.minUpdatedOn,
          maxUpdatedOn: state.value.maxUpdatedOn,
          map: state.value.map,
        },
        extra: { ...INITIAL_STATE.extra },
        pre: { ...INITIAL_STATE.pre },
      };
    default:
      return state;
  }
};

export default filtersReducer;
