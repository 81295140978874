import { Listing } from "models/listings/listing.model";
import styles from "./listing-comparables.module.scss";
import ListingCard from "components/shared/Listings/ListingCard/listing-card.component";
import { Swiper, SwiperSlide } from "swiper/react";

const ListingComparables = ({
  active,
  sold,
}: {
  active: Listing[];
  sold: Listing[];
}) => {
  return (
    <div className={styles.container}>
      {active.length > 0 && (
        <div className={styles.section}>
          <span className={styles.title}>Similar active listings</span>
          <div className={styles.swiper}>
            <Swiper spaceBetween={12} slidesPerView={"auto"}>
              {active.map((listing, index) => (
                <SwiperSlide key={index} className={styles.swiperSlide}>
                  <ListingCard listing={listing} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
      {sold.length > 0 && (
        <div className={styles.section}>
          <span className={styles.title}>Similar sold listings</span>
          <div className={styles.swiper}>
            <Swiper spaceBetween={12} slidesPerView={"auto"}>
              {sold.map((listing, index) => (
                <SwiperSlide key={index} className={styles.swiperSlide}>
                  <ListingCard listing={listing} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingComparables;
