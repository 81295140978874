import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import {
  Activity,
  ActivityStatus,
  ActivityType,
  DeleteActivityMutation,
  GetActivityQuery,
  ListActivitiesQuery,
  ModelActivityFilterInput,
  OnCreateActivityByRecipientSubscription,
  OnUpdateActivityByRecipientSubscription,
  UpdateActivityMutation,
  User,
} from "API";
import { API } from "aws-amplify";
import { store } from "redux/store";
import { GeneralConstants } from "utils/constants";
import { UserService } from "./userService";
import { getActivity, listActivities } from "graphql/queries";
import {
  updateActivity,
  deleteActivity as deleteActivityMutation,
} from "graphql/mutations";
import {
  onCreateActivityByRecipient,
  onUpdateActivityByRecipient,
} from "graphql/subscriptions";

const createTourRequest = async (request: {
  name: string;
  email: string;
  phone?: string;
  message?: string;
  mlsNumber: string;
  availability: string[];
  guests: string[];
}) => {
  const user: User = store.getState().client.currentClient;
  let agentId: string | null | undefined;

  if (user) {
    agentId = user.client?.agentId;
  } else {
    const response = await API.get(
      GeneralConstants.REST_API_NAME,
      "/default-agent",
      {}
    );
    agentId = response.defaultAgent;
  }

  const response = await API.post(GeneralConstants.REST_API_NAME, "/activity", {
    body: {
      recipient: agentId,
      sender: user?.id,
      type: ActivityType.tourRequest,
      content: request,
    },
  });

  if (user) {
    await UserService.updateClientAvailability(user.id, request.availability);
  }

  return response;
};

const createListingInformationRequest = async (request: {
  name: string;
  email: string;
  phone?: string;
  message?: string;
  mlsNumber: string;
}) => {
  const user: User = store.getState().client.currentClient;
  let agentId: string | null | undefined;

  if (user) {
    agentId = user.client?.agentId;
  } else {
    const response = await API.get(
      GeneralConstants.REST_API_NAME,
      "/default-agent",
      {}
    );
    agentId = response.defaultAgent;
  }

  const response = await API.post(GeneralConstants.REST_API_NAME, "/activity", {
    body: {
      recipient: agentId,
      sender: user?.id,
      type: ActivityType.listingInformation,
      content: request,
    },
  });

  return response;
};

const createOfferRequest = async (request: {
  name: string;
  email: string;
  phone?: string;
  offer?: number;
  date?: string;
  mlsNumber: string;
  message: string;
}) => {
  const user: User = store.getState().client.currentClient;
  let agentId: string | null | undefined;

  if (user) {
    agentId = user.client?.agentId;
  } else {
    const response = await API.get(
      GeneralConstants.REST_API_NAME,
      "/default-agent",
      {}
    );
    agentId = response.defaultAgent;
  }

  const response = await API.post(GeneralConstants.REST_API_NAME, "/activity", {
    body: {
      recipient: agentId,
      sender: user?.id,
      type: ActivityType.offer,
      content: request,
    },
  });

  return response;
};

const getActivities = async (
  filter?: ModelActivityFilterInput,
  nextToken?: string | null
) => {
  const response = await API.graphql<GraphQLQuery<ListActivitiesQuery>>({
    query: listActivities,
    variables: { ...(nextToken && { nextToken }), ...(filter && { filter }) },
  });

  return {
    activities: (
      (response.data?.listActivities?.items as Activity[]) || []
    ).sort((a, b) => b.createdAt.localeCompare(a.createdAt)),
    nextToken: response.data?.listActivities?.nextToken,
  };
};

const getActivityById = async (id: string) => {
  const response = await API.graphql<GraphQLQuery<GetActivityQuery>>({
    query: getActivity,
    variables: { id },
  });

  return response.data?.getActivity as Activity;
};

const markActivityAsRead = async (id: string) => {
  const response = await API.graphql<GraphQLQuery<UpdateActivityMutation>>({
    query: updateActivity,
    variables: {
      input: {
        id,
        status: ActivityStatus.read,
      },
    },
  });

  return response.data?.updateActivity as Activity;
};

const markActivityAsUnread = async (id: string) => {
  const response = await API.graphql<GraphQLQuery<UpdateActivityMutation>>({
    query: updateActivity,
    variables: {
      input: {
        id,
        status: ActivityStatus.sent,
      },
    },
  });

  return response.data?.updateActivity as Activity;
};

const deleteActivity = async (id: string) => {
  await API.graphql<GraphQLQuery<DeleteActivityMutation>>({
    query: deleteActivityMutation,
    variables: { input: { id } },
  });
};

// #region Subscriptions
const onNewActivity = (
  userId: string,
  callback: (activity: Activity) => void
) => {
  const result = API.graphql<
    GraphQLSubscription<OnCreateActivityByRecipientSubscription>
  >({
    query: onCreateActivityByRecipient,
    variables: { recipient: userId },
  });

  const subscription = result.subscribe(async (data) => {
    const activity = data.value.data?.onCreateActivityByRecipient as Activity;
    callback(activity);
  });

  return subscription;
};

const onUpdateActivity = (
  userId: string,
  callback: (activity: Activity) => void
) => {
  const result = API.graphql<
    GraphQLSubscription<OnUpdateActivityByRecipientSubscription>
  >({
    query: onUpdateActivityByRecipient,
    variables: { recipient: userId },
  });

  const subscription = result.subscribe(async (data) => {
    const activity = data.value.data?.onUpdateActivityByRecipient as Activity;
    callback(activity);
  });

  return subscription;
};

export const ActivityService = {
  createTourRequest,
  createListingInformationRequest,
  createOfferRequest,
  getActivities,
  getActivityById,
  markActivityAsRead,
  markActivityAsUnread,
  deleteActivity,
  onNewActivity,
  onUpdateActivity,
};
