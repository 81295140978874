import { useState } from "react";
import styles from "./tour-availability.module.scss";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

export const Days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const DaysAbbreviation = [
  "Mon.",
  "Tue.",
  "Wed.",
  "Thu.",
  "Fri.",
  "Sat.",
  "Sun.",
];

export const TimeSlots = ["8-10", "10-12", "12-14", "14-16", "16-18", "18-20"];

export const renderSlotText = (day: string, slots: string[], useAbbreviation = false) => {
  const times = slots.map((slot) => slot.split(":")[1]);
  const isAllDay = times.length === TimeSlots.length;
  const dayText = useAbbreviation ? DaysAbbreviation[parseInt(day)] : `${Days[parseInt(day)]}:`;
  return `${dayText} ${
    isAllDay ? "the whole day" : times.join(", ")
  }`;
};

export const groupSlotsByDay = (selectedSlots: string[]) => {
  const groupedSlots: { [key: string]: string[] } = {};
  selectedSlots.forEach((slot) => {
    const [day] = slot.split(":");
    if (!groupedSlots[day]) {
      groupedSlots[day] = [];
    }
    groupedSlots[day].push(slot);
  });
  return groupedSlots;
};

const TourAvailability = ({
  onAvailabilityUpdate,
  availability = []
}: {
  onAvailabilityUpdate: (availability: string[]) => void;
  availability?: string[];
}) => {
  const [selectedSlots, setSelectedSlots] = useState<string[]>(availability);

  const onToggleSlot = (slot: string) => {
    if (selectedSlots.includes(slot)) {
      const updatedValue = selectedSlots.filter((s) => s !== slot);
      setSelectedSlots(updatedValue);
      onAvailabilityUpdate(updatedValue);
    } else {
      const updatedValue = [...selectedSlots, slot].sort();
      setSelectedSlots(updatedValue);
      onAvailabilityUpdate(updatedValue);
    }
  };

  const onRemoveDay = (day: string) => {
    const updatedSlots = selectedSlots.filter((slot) => !slot.startsWith(day));
    setSelectedSlots(updatedSlots);
    onAvailabilityUpdate(updatedSlots);
  };

  const showCheckmark = (day: number, slot: string) => {
    // return true if: slot is selected and has no left or right neighbor selected, or if slot is selected and is in the middle of a selected range, or its not selected
    const index = TimeSlots.indexOf(slot);
    const leftNeighbor = index > 0 ? TimeSlots[index - 1] : null;
    const rightNeighbor = index < TimeSlots.length - 1 ? TimeSlots[index + 1] : null;
    const isLeftNeighborSelected = leftNeighbor ? selectedSlots.includes(`${day}:${leftNeighbor}`) : false;
    const isRightNeighborSelected = rightNeighbor ? selectedSlots.includes(`${day}:${rightNeighbor}`) : false;
    const isSelected = selectedSlots.includes(`${day}:${slot}`);
    let numberOfSelectedSlotsOnLeft: number = 0;
    let numberOfSelectedSlotsOnRight: number = 0;

   

    for (let i = index - 1; i >= 0; i--) {
      if (selectedSlots.includes(`${day}:${TimeSlots[i]}`)) {
        numberOfSelectedSlotsOnLeft++;
      } else {
        break;
      }
    }



    for (let i = index + 1; i < TimeSlots.length; i++) {
      if (selectedSlots.includes(`${day}:${TimeSlots[i]}`)) {
        numberOfSelectedSlotsOnRight++;
      } else {
        break;
      }
    }



    const totalSelectedInARow = numberOfSelectedSlotsOnLeft + numberOfSelectedSlotsOnRight + 1;
    const indexOfFirstSelectedSlotInThisRow = index - numberOfSelectedSlotsOnLeft;
    const indexOfLastSelectedSlotInThisRow = index + numberOfSelectedSlotsOnRight;
    const middleIndex = indexOfFirstSelectedSlotInThisRow + Math.floor(totalSelectedInARow / 2);

    const isMiddleOfRange = totalSelectedInARow % 2 === 0 ? index === middleIndex - 1 : index === middleIndex;

    return isSelected && (isMiddleOfRange || (!isLeftNeighborSelected && !isRightNeighborSelected));
  };

  return (
    <div className={styles.container}>
      <table>
        <tbody>
          <tr>
            <td></td>
            {TimeSlots.map((slot) => (
              <td key={slot}>{slot}</td>
            ))}
          </tr>
          {[0, 1, 2, 3, 4, 5, 6].map((day) => (
            <tr key={day} className={styles.day}>
              <td>{DaysAbbreviation[day]}</td>

              {TimeSlots.map((slot) => (
                <td
                  key={slot}
                  className={`${styles.slot} ${
                    selectedSlots.includes(`${day}:${slot}`)
                      ? styles.selectedSlot
                      : ""
                  }`}
                  onClick={() => onToggleSlot(`${day}:${slot}`)}>
                  {showCheckmark(day, slot) && '✓'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.selectedSlots}>
        <label>
          Selected times: {selectedSlots.length === 0 && "Any time"}
        </label>
        <div className={styles.slots}>
          {Object.entries(groupSlotsByDay(selectedSlots)).map(([day, slots]) => (
            <div key={day} className={styles.slot}>
              {renderSlotText(day, slots)}
              <IonIcon icon={closeOutline} onClick={() => onRemoveDay(day)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TourAvailability;
