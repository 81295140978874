import { User, UserRole } from "API";
import styles from "../../profile.module.scss";
import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonNote,
  IonRouterLink,
  useIonToast,
} from "@ionic/react";
import {
  callOutline,
  chevronDown,
  chevronUp,
  closeOutline,
  informationCircleOutline,
  mailOutline,
} from "ionicons/icons";
import EditIcon from "assets/svg/edit.svg";
import { useMediaQuery } from "react-responsive";
import Modal from "components/ui/modal/modal.component";
import { useEffect, useState } from "react";
import { UserService } from "services/userService";
import DefaultProfile from "assets/svg/Avatar.svg";
import { Storage } from "aws-amplify";

const PersonalInformation = ({
  user,
  toggled,
}: {
  user: User;
  toggled: boolean;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [presentToast] = useIonToast();

  const [isLoading, setIsLoading] = useState(false);

  const [agent, setAgent] = useState<User | null>(null);
  const [agentProfilePicture, setAgentProfilePicture] =
    useState<string>(DefaultProfile);

  const [isUserIdInfoModalOpen, setIsUserIdInfoModalOpen] = useState(false);
  const [isGivenNameModalOpen, setIsGivenNameModalOpen] = useState(false);
  const [isFamilyNameModalOpen, setIsFamilyNameModalOpen] = useState(false);
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false);

  const [tempGivenName, setTempGivenName] = useState(user.givenName);
  const [tempFamilyName, setTempFamilyName] = useState(user.familyName);
  const [tempPhoneNumber, setTempPhoneNumber] = useState(user.phone ? user.phone.replace("+1", "") : "");

  useEffect(() => {
    if (user.client && user.client.agentId) {
      UserService.getUserPublicProfileById(user.client.agentId).then(
        async (agent) => {
          if (agent && agent.profilePicture) {
            const profilePicture = await Storage.get(agent.profilePicture);
            console.log(profilePicture);
            setAgentProfilePicture(profilePicture);
          }
          setAgent(agent as User);
        }
      );
    }
  }, [user.client]);

  const onSaveGivenName = async () => {
    try {
      setIsLoading(true);
      await UserService.updateGivenName(tempGivenName);
      presentToast({
        message: "Given name updated successfully.",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
      setIsGivenNameModalOpen(false);
    } catch (err) {
      presentToast({
        message: "Failed to update given name.",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSaveFamilyName = async () => {
    try {
      setIsLoading(true);
      await UserService.updateFamilyName(tempFamilyName);
      presentToast({
        message: "Family name updated successfully.",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
      setIsFamilyNameModalOpen(false);
    } catch (err) {
      presentToast({
        message: "Failed to update family name.",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSavePhoneNumber = async () => {
    try {
      if (!tempPhoneNumber) {
        return;
      }
      setIsLoading(true);
      await UserService.updatePhoneNumber(`+1${tempPhoneNumber}`);
      presentToast({
        message: "Phone number updated successfully.",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
      setIsPhoneNumberModalOpen(false);
    } catch (err) {
      presentToast({
        message: "Failed to update phone number.",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IonLoading isOpen={isLoading} message="Loading..." />
      <Modal
        isOpen={isAgentModalOpen}
        onDidDismiss={() => setIsAgentModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <div className={styles.sheetContent}>
          <div className={styles.modalHeader}>
            Your AECORN Agent
            {!isMobile && (
              <IonIcon
                icon={closeOutline}
                onClick={() => setIsAgentModalOpen(false)}
              />
            )}
          </div>
          {agent && (
            <div className={styles.agentCard}>
              <IonAvatar>
                <img src={agentProfilePicture} alt={agent.displayName} />
              </IonAvatar>
              <div className={styles.agentName}>{agent.displayName}</div>
              <div className={styles.buttons}>
                <IonButton
                  className="aecorn-button"
                  href={`mailto:${agent.email}`}>
                    <IonIcon icon={mailOutline} slot="start" />
                  {agent.email}
                </IonButton>
                {agent?.phone && (
                  <IonButton
                    className="aecorn-button clear border"
                    href={`tel:${agent.phone}`}>
                      <IonIcon icon={callOutline} slot="start" />
                    {agent.phone}
                  </IonButton>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={isUserIdInfoModalOpen}
        onDidDismiss={() => setIsUserIdInfoModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <div className={styles.sheetContent}>
          <div className={styles.modalHeader}>
            What is my user ID?
            {!isMobile && (
              <IonIcon
                icon={closeOutline}
                onClick={() => setIsUserIdInfoModalOpen(false)}
              />
            )}
          </div>
          <p>
            Did you know that to view data on sold properties in some areas, you
            must register and agree to certain{" "}
            <IonRouterLink
              onClick={() => setIsUserIdInfoModalOpen(false)}
              routerLink="/terms">
              Terms of Service
            </IonRouterLink>{" "}
            and{" "}
            <IonRouterLink
              onClick={() => setIsUserIdInfoModalOpen(false)}
              routerLink="/privacy">
              Privacy Policy
            </IonRouterLink>
            ? Your user ID is the email with which you agreed to these terms.
          </p>
          <IonButton
            className="aecorn-button"
            onClick={() => setIsUserIdInfoModalOpen(false)}>
            Got it
          </IonButton>
        </div>
      </Modal>
      <Modal
        isOpen={isGivenNameModalOpen}
        onDidDismiss={() => setIsGivenNameModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <div className={styles.sheetContent}>
          <div className={styles.modalHeader}>
            Given name
            {!isMobile && (
              <IonIcon
                icon={closeOutline}
                onClick={() => setIsGivenNameModalOpen(false)}
              />
            )}
          </div>
          <div>
            <IonLabel className={styles.label}>Given name</IonLabel>
            <IonInput
              value={tempGivenName}
              autocapitalize="words"
              onIonInput={(e) => setTempGivenName(e.detail.value!)}
              className="aecorn-input dark"
              placeholder="Enter your given name"></IonInput>
          </div>
          <IonButton
            className="aecorn-button"
            onClick={onSaveGivenName}
            disabled={!tempGivenName || tempGivenName.length < 2}>
            Submit changes
          </IonButton>
        </div>
      </Modal>
      <Modal
        isOpen={isFamilyNameModalOpen}
        onDidDismiss={() => setIsFamilyNameModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <div className={styles.sheetContent}>
          <div className={styles.modalHeader}>
            Family name
            {!isMobile && (
              <IonIcon
                icon={closeOutline}
                onClick={() => setIsFamilyNameModalOpen(false)}
              />
            )}
          </div>
          <div>
            <IonLabel className={styles.label}>Family name</IonLabel>
            <IonInput
              value={tempFamilyName}
              autocapitalize="words"
              onIonInput={(e) => setTempFamilyName(e.detail.value!)}
              className="aecorn-input dark"
              placeholder="Enter your family name"></IonInput>
          </div>
          <IonButton
            className="aecorn-button"
            onClick={onSaveFamilyName}
            disabled={!tempFamilyName || tempFamilyName.length < 2}>
            Submit changes
          </IonButton>
        </div>
      </Modal>
      <Modal
        isOpen={isPhoneNumberModalOpen}
        onDidDismiss={() => setIsPhoneNumberModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <div className={styles.sheetContent}>
          <div className={styles.modalHeader}>
            Phone number
            {!isMobile && (
              <IonIcon
                icon={closeOutline}
                onClick={() => setIsPhoneNumberModalOpen(false)}
              />
            )}
          </div>
          <div>
            <IonLabel className={styles.label}>Phone number</IonLabel>
            <IonInput
              value={tempPhoneNumber}
              onIonInput={(e) => setTempPhoneNumber(e.detail.value!)}
              className="aecorn-input dark"
              placeholder="Enter your phone number">
                <span slot="start">+1</span>
              </IonInput>
              <IonNote style={{ fontSize: "10px" }}>
                Please use the international format, e.g. +1234567890
              </IonNote>
          </div>
          <IonButton
            className="aecorn-button"
            onClick={onSavePhoneNumber}
            disabled={!tempPhoneNumber || !new RegExp(/^\d{10}$/).test(tempPhoneNumber)
            }>
            Submit changes
          </IonButton>
        </div>
      </Modal>
      <div slot="header" className={styles.accordionHeader}>
        <span className={styles.sectionTitle}>Personal information</span>
        <IonIcon icon={toggled ? chevronUp : chevronDown} />
      </div>
      <div slot="content" className={styles.accordionContent}>
        <div className={styles.item}>
          <IonLabel className={styles.label}>User ID</IonLabel>
          <IonInput
            value={user.email}
            className="aecorn-input dark readonly"
            readonly>
            <IonIcon
              size="large"
              slot="end"
              icon={informationCircleOutline}
              onClick={() => setIsUserIdInfoModalOpen(true)}
            />
          </IonInput>
        </div>
        <div className={styles.item}>
          <IonLabel className={styles.label}>Profile type</IonLabel>
          <IonInput
            value={
              user.role === UserRole.client
                ? "Client"
                : UserRole.agent
                ? "Agent"
                : "Admin"
            }
            className="aecorn-input dark readonly"
            readonly></IonInput>
        </div>
        <div className={styles.item}>
          <IonLabel className={styles.label}>Given name</IonLabel>
          <div className={styles.input}>
            <div>{user.givenName}</div>
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsGivenNameModalOpen(true)}
            />
          </div>
        </div>
        <div className={styles.item}>
          <IonLabel className={styles.label}>Family name</IonLabel>
          <div className={styles.input}>
            <div>{user.familyName}</div>
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsFamilyNameModalOpen(true)}
            />
          </div>
        </div>
        <div className={styles.item}>
          <IonLabel className={styles.label}>Phone number</IonLabel>
          <div className={styles.input}>
            <div>{user.phone}</div>
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsPhoneNumberModalOpen(true)}
            />
          </div>
        </div>
        {user.role === UserRole.client && (
          <div className={styles.item}>
            <IonLabel className={styles.label}>My agent</IonLabel>
            <div className={`${styles.input} ${styles.card}`}>
              <div>{agent ? agent.displayName : ""}</div>
              <IonButton
                className="aecorn-button clear-dark"
                onClick={() => setIsAgentModalOpen(true)}>
                {user.client?.agent ? "View" : "Get an agent"}
              </IonButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalInformation;
