import {
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemSliding,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToggle,
  IonItemOption,
  IonItemOptions,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import styles from "./activities.module.scss";
import MediaQuery from "react-responsive";
import Header from "components/web/Header/header.component";
import { useEffect, useRef, useState } from "react";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import { ActivityStatus, Activity as ActivityType } from "API";
import { ActivityService } from "services/activityService";
import Activity from "./components/Activity/activity.component";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "redux/client/client.selectors";
import { mailOpenOutline, mailOutline, trashOutline } from "ionicons/icons";

const Activities = () => {
  const user = useSelector(selectCurrentClient);
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState<ActivityType[]>([]);
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const subscriptions = useRef<any[]>([]);
  const token = useRef<string | undefined | null>();
  const ionListRef = useRef<HTMLIonListElement>(null);

  const fetchActivities = async () => {
    try {
      if (!token.current) {
        setLoading(true);
      }
      const { activities, nextToken } = await ActivityService.getActivities(
        showUnreadOnly
          ? {
              status: { eq: ActivityStatus.sent },
            }
          : undefined,
        token.current
      );
      setActivities(activities);
      token.current = nextToken;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onRefresh = async (ev: CustomEvent) => {
    await fetchActivities();
    ev.detail.complete();
  };

  const onDeleteActivity = async (id: string) => {
    closeSlidingItems();
    try {
      await ActivityService.deleteActivity(id);
      setActivities((prevActivities) =>
        prevActivities.filter((activity) => activity.id !== id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onMarkActivityAsRead = async (id: string) => {
    closeSlidingItems();
    try {
      await ActivityService.markActivityAsRead(id);
    } catch (error) {
      console.log(error);
    }
  };

  const onMarkActivityAsUnread = async (id: string) => {
    closeSlidingItems();
    try {
      await ActivityService.markActivityAsUnread(id);
    } catch (error) {
      console.log(error);
    }
  };

  const closeSlidingItems = () => {
    if (ionListRef.current) {
      ionListRef.current.closeSlidingItems();
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [user, showUnreadOnly]);

  useEffect(() => {
    if (user) {
      const newActivitySubscription = ActivityService.onNewActivity(
        user.id,
        () => {
          fetchActivities();
        }
      );
      const updateActivitySubscription = ActivityService.onUpdateActivity(
        user.id,
        (activity) => {
          setActivities((prevActivities) => {
            const index = prevActivities.findIndex((a) => a.id === activity.id);
            if (index !== -1) {
              prevActivities[index] = activity;
            }
            return [...prevActivities];
          });
        }
      );
      subscriptions.current.push(newActivitySubscription);
      subscriptions.current.push(updateActivitySubscription);
    } else {
      setActivities([]);
      subscriptions.current.forEach((subscription) => subscription.unsubscribe());
      subscriptions.current = [];
    }

    return () => {
      subscriptions.current.forEach((subscription) =>
        subscription.unsubscribe()
      );
    };
  }, [user]);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.header}>
        <MediaQuery minWidth={768}>
          <Header />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <div className={styles.topSection}>
            <div className={styles.title}>Activity center</div>
            <div className={styles.toggleContainer}>
              <IonLabel className={styles.label}>Unread only</IonLabel>
              <IonToggle
                checked={showUnreadOnly}
                onIonChange={() => setShowUnreadOnly(!showUnreadOnly)}
                className="aecorn-toggle"
              />
            </div>
          </div>
        </MediaQuery>
      </IonHeader>
      <IonContent className={styles.content}>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <MediaQuery minWidth={768}>
          <div className={styles.topbar}>
            <div className={styles.title}>Activity center</div>
            <div className={styles.toggleContainer}>
              <IonLabel className={styles.label}>Unread only</IonLabel>
              <IonToggle
                checked={showUnreadOnly}
                onIonChange={() => setShowUnreadOnly(!showUnreadOnly)}
                className="aecorn-toggle"
              />
            </div>
          </div>
        </MediaQuery>
        <div className={styles.container}>
          {loading ? (
            <LoadingScreen />
          ) : activities.length === 0 ? (
            <div className={styles.noActivities}>You have no activities.</div>
          ) : (
            <>
              <IonList ref={ionListRef}>
                <MediaQuery minWidth={768}>
                  {activities.map((activity) => (
                    <Activity key={activity.id} activity={activity} onDelete={(id: string) => {
                      setActivities((prevActivities) =>
                        prevActivities.filter((activity) => activity.id !== id)
                      );
                    }} />
                  ))}
                </MediaQuery>
                <MediaQuery maxWidth={767}>
                  {activities.map((activity) => (
                    <IonItemSliding key={activity.id}>
                      <IonItem button lines="none" detail routerLink={`/activities/${activity.id}`}>
                        <Activity activity={activity} hideButtons />
                      </IonItem>
                      <IonItemOptions slot="end">
                        <IonItemOption color="light">
                          <IonIcon
                            slot="icon-only"
                            icon={
                              activity.status === ActivityStatus.sent
                                ? mailOpenOutline
                                : mailOutline
                            }
                            onClick={() => {
                              if (activity.status === ActivityStatus.sent) {
                                onMarkActivityAsRead(activity.id);
                              } else {
                                onMarkActivityAsUnread(activity.id);
                              }
                            }}
                          />
                        </IonItemOption>
                        <IonItemOption color="danger">
                          <IonIcon
                            slot="icon-only"
                            icon={trashOutline}
                            onClick={() => onDeleteActivity(activity.id)}
                          />
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  ))}
                </MediaQuery>
              </IonList>
              <IonInfiniteScroll
                disabled={!token.current}
                onIonInfinite={(ev) => {
                  fetchActivities();
                  setTimeout(() => ev.target.complete(), 500);
                }}>
                <IonInfiniteScrollContent
                  loadingText="Please wait..."
                  loadingSpinner="bubbles"></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Activities;
