import styles from "./map-button.module.scss";

const MapButton = ({
  icon,
  onClick,
  active = false,
  disabled = false,
  hasUpdates = false,
}: {
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
  hasUpdates?: boolean;
}) => {

  return (
    <button
      className={`${styles.button} ${active && styles.active} ${
        disabled && styles.disabled
      }`}
      onClick={onClick}
      disabled={disabled}>
      {hasUpdates && <div className={styles.update} />}
      {icon}
    </button>
  );
};

export default MapButton;
