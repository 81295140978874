import {
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonNote,
  IonSpinner,
} from "@ionic/react";
import styles from "../../authentication-modal.module.scss";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { AuthenticationService } from "services/authenticationService";

const SignInForm = ({
  onSuccess,
  onError,
  onSwitch,
}: {
  onSuccess: () => void;
  onError: (message: string | null) => void;
  onSwitch: (
    mode: "register" | "forgotPassword" | "registerConfirmation",
    userInfo?: {
      email: string;
      password: string;
    }
  ) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<{
    email: string;
    password: string;
  }>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && isValid) {
      handleSubmit(onLogin);
    }
  };

  const onLogin = async (data: { email: string; password: string }) => {
    try {
      clearErrors();
      onError(null);
      setLoading(true);
      await AuthenticationService.login(data.email, data.password);
      onSuccess();
    } catch (error: any) {
      console.log(error);
      if (error?.message === "User is not confirmed.") {
        AuthenticationService.resendConfirmationCode(data.email);
        onError("User is not confirmed.");
        onSwitch("registerConfirmation", {
          email: data.email,
          password: data.password,
        });
      } else {
        onError("Username or password is incorrect");
      }
    } finally {
      setLoading(false);
    }
  };

  const emailFormControl = register("email", {
    required: {
      value: true,
      message: "Email is required",
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: "Invalid email address",
    },
  });
  const passwordFormControl = register("password", {
    required: {
      value: true,
      message: "Password is required",
    },
  });

  return (
    <form onSubmit={handleSubmit(onLogin)} onKeyUp={handleKeyPress} className={styles.form}>
      <div className={styles.formItem}>
        <IonLabel>Email address*</IonLabel>
        <IonInput
          className={`aecorn-input dark ${
            errors.email?.message && "ion-invalid ion-touched"
          }`}
          required
          placeholder="Enter your email address"
          {...emailFormControl}
          onIonInput={emailFormControl.onChange}
          disabled={loading}
          type="email"
        />
        {errors.email && <IonNote>{errors.email.message}</IonNote>}
      </div>
      <div className={styles.formItem}>
        <IonLabel>Password*</IonLabel>
        <IonInput
          className={`aecorn-input dark ${
            errors.password?.message && "ion-invalid ion-touched"
          }`}
          required
          placeholder="Enter your password"
          {...passwordFormControl}
          onIonInput={passwordFormControl.onChange}
          disabled={loading}
          type={isPasswordVisible ? "text" : "password"}>
          <IonIcon
            icon={isPasswordVisible ? eyeOffOutline : eyeOutline}
            slot="end"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        </IonInput>
        {errors.password && <IonNote>{errors.password.message}</IonNote>}
        <div
          onClick={() => onSwitch("forgotPassword")}
          className={styles.forgotPassword}>
          Forgot password?
        </div>
      </div>
      <IonButton
        className="aecorn-button primary"
        expand="block"
        type="submit"
        disabled={!isValid || loading}>
        {loading ? <IonSpinner name="crescent" /> : "Sign in"}
      </IonButton>
      <span className={styles.switch}>
        Don't have an account?{" "}
        <span onClick={() => onSwitch("register")}>Sign up</span>
      </span>
    </form>
  );
};

export default SignInForm;
