import React from "react";
import styles from "./modal.module.scss";
import { IonModal } from "@ionic/react";

const Modal = (props: {
  isOpen: boolean;
  onDidDismiss: () => void;
  backdropDismiss?: boolean;
  id?: string;
  canDismiss?: boolean;
  breakpoints?: number[] | undefined;
  initialBreakpoint?: number | undefined;
  style?: {
    [key: string]: any;
  };
  ref?: any;
  presentingElement?: HTMLElement | undefined;
  children: React.ReactNode;
}) => {
  return (
    <IonModal
      className={`${props.breakpoints ? styles.sheet : `${styles.modal} aecorn-modal`}`}
      {...props}>
      {props.children}
    </IonModal>
  );
};

export default Modal;
