import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import styles from "./sort-button.module.scss";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { SortOption, SortOptionValue } from "types/shared/SortOption.type";

const SortButton = ({
  items,
  value,
  order,
  onSelect,
}: {
  items: SortOption[];
  value: string | number;
  order: "asc" | "desc";
  onSelect: (value: SortOptionValue) => void;
}) => {
  const itemsWithSortOrder = items
    .map((item) => {
      return [
        { value: `${item.value}-asc`, label: `${item.label} Ascending` },
        {
          value: `${item.value}-desc`,
          label: `${item.label} Descending`,
        },
      ];
    })
    .flat();
  return (
    <IonItem lines="none" className="aecorn-select">
      <IonSelect
        className={styles.select}
        toggleIcon={chevronDownOutline}
        expandedIcon={chevronUpOutline}
        value={`${value}-${order}`}
        onIonChange={(e) => onSelect(e.detail.value)}
        interface="popover"
        interfaceOptions={{ cssClass: "aecorn-select-popover" }}
        placeholder="Sort by">
        {itemsWithSortOrder.map((item, i) => (
          <IonSelectOption key={i} value={item.value}>
            {item.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export default SortButton;
