import { NotificationType, Search } from "API";
import styles from "./saved-searches.module.scss";
import {
  IonButton,
  IonChip,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLoading,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
  useIonAlert,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import AddSearchIcon from "assets/svg/icons/add-search.svg";
import MediaQuery from "react-responsive";
import {
  chevronForwardOutline,
  notificationsOutline,
  searchOutline,
} from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "redux/client/client.selectors";
import { ListingsHelper } from "utils/ListingsHelper";
import SortIcon from "assets/svg/sort.svg";
import { Listing } from "models/listings/listing.model";
import { SavedSearchesService } from "services/savedSearchesService";
import ListingListItem from "components/shared/Listings/ListingListItem/listing-list-item.component";

type SavedSearch = {
  filters: Object;
  id: string;
  notifications: boolean;
  repliersID: string;
  search: Search;
};

const SavedSearches = ({ savedSearches }: { savedSearches: Search[] }) => {
  const route = useIonRouter();
  const user = useSelector(selectCurrentClient);

  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [selectedSearch, setSelectedSearch] = useState<SavedSearch | null>(
    null
  );
  const [listings, setListings] = useState<Listing[]>([]);
  const [sortBy, setSortBy] = useState<
    "date" | "price" | "beds" | "baths" | "status"
  >("date");
  const [page, setPage] = useState<number>(1);
  const totalPages = useRef<number | null>(null);

  const onNavigateToListing = (listing: Listing) => {
    route.push(`/listings/${listing.mlsNumber}`);
  };

  const onRemove = () => {
    presentAlert({
      header: "Remove saved search",
      message: `Are you sure you want to remove this saved search?`,
      cssClass: "aecorn-alert",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "aecorn-alert-button-cancel",
        },
        {
          text: "Remove",
          handler: async () => {
            setSubmitLoading(true);
            try {
              await SavedSearchesService.deleteSavedSearch(
                selectedSearch!.search
              );
              presentToast({
                message: "Saved search removed.",
                duration: 2000,
                cssClass: "aecorn-success-toast",
              });
              setSelectedSearch(null);
            } catch (error) {
              console.error(error);
              presentToast({
                message: "Failed to remove saved search.",
                duration: 2000,
                cssClass: "aecorn-error-toast",
              });
            } finally {
              setSubmitLoading(false);
            }
          },
          cssClass: "aecorn-alert-button-danger",
        },
      ],
    });
  };

  const onSelectSavedSearch = (search: Search) => {
    setSelectedSearch({
      filters: search.value && JSON.parse(search.value).filters,
      id: search.id,
      notifications: search.notification || false,
      repliersID: search.repliersID || "",
      search,
    });
  };

  const onNextPage = (ev: any) => {
    if (totalPages.current === null || page < totalPages.current) {
      setPage(page + 1);
    }
    setTimeout(() => ev.target.complete(), 500);
  };

  const fetchListings = useCallback(async () => {
    try {
      if (selectedSearch?.repliersID) {
        setLoading(true);
        const result = await SavedSearchesService.getListingsBySavedSearch(
          selectedSearch.repliersID,
          page
        );
        setListings((prev) => [
          ...prev,
          ...result.matches.map((match: any) => match.listing),
        ]);
        totalPages.current = result.numPages;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [selectedSearch, page]);

  useEffect(() => {
    const sorted = [...listings].sort((a, b) => {
      let result = 0;
      switch (sortBy) {
        case "price":
          result = +b.listPrice - +a.listPrice;
          break;
        case "date":
          result =
            new Date(b.updatedOn || b.listDate).getTime() -
            new Date(a.updatedOn || a.listDate).getTime();
          break;
        case "status":
          result = b.lastStatus.localeCompare(a.lastStatus);
          break;
        case "beds":
          result = +b.details.numBedrooms - +a.details.numBedrooms;
          break;
        case "baths":
          result = +b.details.numBathrooms - +a.details.numBathrooms;
          break;
        default:
          result = 0;
      }

      if (result === 0) {
        return a.mlsNumber.localeCompare(b.mlsNumber);
      }

      return result;
    });

    setListings(sorted);
  }, [sortBy]);

  useEffect(() => {
    if (page > 1) {
      fetchListings();
    }
  }, [page]);

  useEffect(() => {
    totalPages.current = null;
    setPage(1);
    setListings([]);
    fetchListings();
  }, [selectedSearch]);

  const SearchCard = ({
    search,
    onClick,
  }: {
    search: Search;
    onClick?: (search: Search) => void;
  }) => {
    return (
      <div
        className={`${styles.card} ${
          selectedSearch?.id === search.id ? styles.selected : ""
        }`}
        {...(onClick ? { onClick: () => onClick(search) } : {})}>
        <div className={styles.cardHeader}>
          <div className={styles.title}>
            <span>{search.name}</span> <IonIcon icon={chevronForwardOutline} />
          </div>
          <IonButton className="aecorn-button clear-dark">Details</IonButton>
        </div>
        <IonButton
          className="aecorn-button border clear"
          onClick={() => onSelectSavedSearch(search)}>
          <IonIcon slot="start" icon={searchOutline} />
          View listings updates
        </IonButton>
      </div>
    );
  };

  const SortButton = ({id}:{id: string}) => {
    return (
      <IonButton
        id={id}
        className="aecorn-button white">
        <IonIcon icon={SortIcon} />
      </IonButton>
    );
};

const PopoverContent = () => {
    return (<IonContent className={styles.popoverContent}>
        <IonRadioGroup
          className="aecorn-radio"
          value={sortBy}
          onIonChange={(e) => setSortBy(e.detail.value)}>
          <IonItem lines="none">
            <IonRadio labelPlacement="end" value={"price"}>
              Price
            </IonRadio>
          </IonItem>
          <IonItem lines="none">
            <IonRadio labelPlacement="end" value={"date"}>
              Date
            </IonRadio>
          </IonItem>
          <IonItem lines="none">
            <IonRadio labelPlacement="end" value={"status"}>
              Status
            </IonRadio>
          </IonItem>
          <IonItem lines="none">
            <IonRadio labelPlacement="end" value={"beds"}>
              Beds
            </IonRadio>
          </IonItem>
          <IonItem lines="none">
            <IonRadio labelPlacement="end" value={"baths"}>
              Baths
            </IonRadio>
          </IonItem>
        </IonRadioGroup>
      </IonContent>);
}

  return (
    <div className={styles.container}>
      <IonPopover
        dismissOnSelect
        trigger="saved-searches-sort-mobile-trigger"
        triggerAction="click"
        showBackdrop={false}>
        <PopoverContent />
      </IonPopover>
      <IonPopover
        dismissOnSelect
        trigger="saved-searches-sort-web-trigger"
        triggerAction="click"
        showBackdrop={false}>
        <PopoverContent />
      </IonPopover>
      <IonLoading isOpen={submitLoading} title="Loading..." />
      <div className={styles.note}>
        You can only have 5 saved searches. You can manage alerts for each saved
        search here and in your alert settings in your profile.
      </div>
      {!loading && savedSearches.length === 0 && (
        <div className={styles.noSearches}>
          <IonIcon icon={AddSearchIcon} />
          No saved searches yet
        </div>
      )}
      <div className={styles.searches}>
        {savedSearches.length > 0 && (
          <MediaQuery minWidth={768}>
            <div className={styles.list}>
              {savedSearches.map((search, index) => (
                <SearchCard
                  search={search}
                  key={index}
                  onClick={onSelectSavedSearch}
                />
              ))}
            </div>
            <div className={`${styles.card} ${styles.searchCard}`}>
              {selectedSearch && (
                <div className={styles.searchCardHeader}>
                  <IonButton
                    className="aecorn-button clear danger"
                    onClick={onRemove}>
                    Delete saved search
                  </IonButton>
                  {/* <IonButton className="aecorn-button">Edit</IonButton> */}
                </div>
              )}
              {selectedSearch && (
                <div className={styles.summary}>
                  <div className={styles.filters}>
                    <div>Parameters saved for applicable area</div>
                    <div className={styles.chips}>
                      {ListingsHelper.getAppliedFilters(
                        selectedSearch.filters
                      ).map((filter, index) => (
                        <IonChip key={index} className="aecorn-chip">
                          {filter}
                        </IonChip>
                      ))}
                    </div>
                  </div>
                  <div className={styles.alerts}>
                    <div>
                      <IonIcon icon={notificationsOutline} />{" "}
                      <span>Alerts for this search</span>
                    </div>
                    <div>
                      <span>{user?.notifications?.schedule}</span>
                      <span>
                        {user?.notifications?.type?.length &&
                          `By ${
                            user.notifications.type.includes(
                              NotificationType.email
                            )
                              ? "Email"
                              : ""
                          } ${
                            user.notifications.type.length > 1 ? "and " : ""
                          } ${
                            user.notifications.type.includes(
                              NotificationType.push
                            )
                              ? "Push Notification"
                              : ""
                          }`}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {selectedSearch && (
                <div className={styles.listings}>
                  <div className={styles.listingsHeader}>
                    <span>Listings</span>
                    <SortButton id="saved-searches-sort-web-trigger" />
                  </div>
                  <div className={styles.listingsList}>
                    {loading && (
                      <div className={styles.loading}>
                        <IonSpinner name="crescent" />
                      </div>
                    )}
                    {!loading && listings.length === 0 && (
                      <div className={styles.noListings}>No listings found</div>
                    )}

                    {listings.length > 0 && (
                      <>
                        <div className={styles.items}>
                          {listings.map((listing, index) => (
                            <ListingListItem
                              listing={listing}
                              key={index}
                              onClick={onNavigateToListing}
                            />
                          ))}
                          <IonInfiniteScroll
                            disabled={page === totalPages.current}
                            onIonInfinite={onNextPage}>
                            <IonInfiniteScrollContent></IonInfiniteScrollContent>
                          </IonInfiniteScroll>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </MediaQuery>
        )}
        <MediaQuery maxWidth={767}>
          {selectedSearch ? (
            <div className={styles.listings}>
              <div className={styles.listingsHeader}>
                <div>
                  <span>Saved search {selectedSearch.search.name}</span>
                  <SortButton id="saved-searches-sort-mobile-trigger" />
                </div>
                <div>Listings</div>
              </div>
              {listings.length > 0 && (
                <>
                  <div className={styles.items}>
                    {listings.map((listing, index) => (
                      <ListingListItem
                        listing={listing}
                        key={index}
                        onClick={onNavigateToListing}
                      />
                    ))}
                    <IonInfiniteScroll
                      disabled={page === totalPages.current}
                      onIonInfinite={onNextPage}>
                      <IonInfiniteScrollContent></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                  </div>
                </>
              )}
            </div>
          ) : (
            savedSearches.map((search, index) => (
              <SearchCard search={search} key={index} />
            ))
          )}
        </MediaQuery>
      </div>
    </div>
  );
};

export default SavedSearches;
