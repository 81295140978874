import { IonButton, IonDatetime, IonIcon } from "@ionic/react";
import styles from "../modals.module.scss";
import Modal from "components/ui/modal/modal.component";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { closeOutline } from "ionicons/icons";

const TourDateModal = ({
  isOpen,
  onDismiss,
  selectedDate,
  onUpdateDate,
  onSave,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  selectedDate: string;
  onUpdateDate: (date: string) => void;
  onSave: () => void;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Modal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      breakpoints={isMobile ? [0, 1] : undefined}
      initialBreakpoint={isMobile ? 1 : undefined}>
      <div className={styles.sheetContent}>
        <div className={styles.modalHeader}>
          Select date
          <MediaQuery minWidth={768}>
            <IonIcon icon={closeOutline} onClick={onDismiss} />
          </MediaQuery>
        </div>
        <IonDatetime
          size="cover"
          color="dark"
          className="aecorn-datetime"
          presentation="date"
          value={selectedDate || new Date().toISOString()}
          onIonChange={(ev) => {
            onUpdateDate(ev.detail.value! as string);
          }}
        />
        <IonButton className="aecorn-button" onClick={onSave}>
          Save changes
        </IonButton>
      </div>
    </Modal>
  );
};

export default TourDateModal;
