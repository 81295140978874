import { IonLabel, IonInput, IonIcon, IonList, IonButton } from "@ionic/react";
import { closeOutline, addOutline } from "ionicons/icons";
import styles from "../modals.module.scss";
import Modal from "components/ui/modal/modal.component";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { User } from "API";

const TourLeadAgentModal = ({
  isOpen,
  onDismiss,
  searchTerm,
  onSearchTermChange,
  searchResults,
  selectedAgent,
  onUpdateAgent,
  onSave,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  searchTerm: string;
  onSearchTermChange: (value: string) => void;
  searchResults: User[];
  selectedAgent?: User | null;
  onUpdateAgent: (agent: User | null) => void;
  onSave: () => void;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Modal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      breakpoints={isMobile ? [0, 1] : undefined}
      initialBreakpoint={isMobile ? 1 : undefined}>
      <div className={styles.sheetContent}>
        <div className={styles.modalHeader}>
          Lead agent
          <MediaQuery minWidth={768}>
            <IonIcon icon={closeOutline} onClick={onDismiss} />
          </MediaQuery>
        </div>
        <div className={styles.modalInput}>
          <IonLabel>Lead agent</IonLabel>
          <IonInput
            debounce={500}
            value={searchTerm}
            onIonInput={(e) => {
              onSearchTermChange(e.target.value?.toString() || "");
            }}
            clearInput
            className="aecorn-input dark"
            placeholder="Enter lead agent name or email"></IonInput>
        </div>
        {selectedAgent && (
          <div className={styles.item}>
            <span>{selectedAgent.displayName}</span>
            <IonIcon icon={closeOutline} onClick={() => onUpdateAgent(null)} />
          </div>
        )}
        <IonList>
          {searchResults
            .filter((user) => user.id !== selectedAgent?.id)
            .map((user) => (
              <div key={user.id} className={styles.item}>
                <span>{user.displayName}</span>
                <IonIcon
                  icon={addOutline}
                  onClick={() => onUpdateAgent(user)}
                />
              </div>
            ))}
        </IonList>
        <IonButton
          disabled={!selectedAgent}
          className="aecorn-button"
          onClick={onSave}>
          Save changes
        </IonButton>
      </div>
    </Modal>
  );
};

export default TourLeadAgentModal;
