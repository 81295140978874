import { Tour } from "API";
import styles from "./agent-tour-info.module.scss";
import {
  IonButton,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonModal,
  IonTextarea,
  useIonToast,
} from "@ionic/react";
import {
  groupSlotsByDay,
  renderSlotText,
} from "components/shared/Tours/TourAvailability/tour-availability.component";
import EditIcon from "assets/svg/edit.svg";
import { useRef, useState } from "react";
import { TourService } from "services/tourService";
import moment from "moment";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Modal from "components/ui/modal/modal.component";
import TourAvailabilityModal from "components/shared/Modals/TourAvailabilityModal/tour-availability-modal.component";
import TourTimeModal from "components/shared/Modals/TourTimeModal/tour-time-modal.component";
import TourDateModal from "components/shared/Modals/TourDateModal/tour-date-modal.component";
import { closeOutline } from "ionicons/icons";

const AgentTourInfo = ({ tour }: { tour: Tour }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [availability, setAvailability] = useState<string[]>(
    tour.availability ? JSON.parse(tour.availability) : []
  );
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);
  const tempAvailability = useRef<string[]>([]);

  const [startTime, setStartTime] = useState<string>(tour.startTime || "");
  const [isStartTimeModalOpen, setIsStartTimeModalOpen] = useState(false);
  const tempStartTime = useRef<string>();
  const [endTime, setEndTime] = useState<string>(tour.endTime || "");
  const [isEndTimeModalOpen, setIsEndTimeModalOpen] = useState(false);
  const tempEndTime = useRef<string>();
  const [date, setDate] = useState<string>(tour.date || "");
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const tempDate = useRef<string>();
  const [meetup, setMeetup] = useState<{
    location: string;
    description: string;
    time: string;
  }>({
    location: tour.meetupLocation || "",
    description: tour.meetupDescription || "",
    time: tour.meetupTime || "",
  });
  const [isMeetupModalOpen, setIsMeetupModalOpen] = useState(false);
  const tempMeetupLocation = useRef<string>();
  const tempMeetupDescription = useRef<string>();
  const tempMeetupTime = useRef<string>();
  const [generalNote, setGeneralNote] = useState<string>(
    tour.generalNote || ""
  );
  const [isGeneralNoteModalOpen, setIsGeneralNoteModalOpen] = useState(false);
  const tempGeneralNote = useRef<string>();
  const [privateNote, setPrivateNote] = useState<string>(
    tour.privateNote || ""
  );
  const [isPrivateNoteModalOpen, setIsPrivateNoteModalOpen] = useState(false);
  const tempPrivateNote = useRef<string>();

  const [presentToast] = useIonToast();

  const handleSaveAvailability = async (update: string[]) => {
    try {
      setAvailability(update);
      setIsAvailabilityModalOpen(false);
      await TourService.updateTour({
        id: tour.id,
        availability: JSON.stringify(update),
      });
    } catch (error) {
      console.error(error);
      setAvailability(tour.availability ? JSON.parse(tour.availability) : []);
      presentToast({
        message: "An error occurred while saving the availability",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const handleSaveStartTime = async () => {
    try {
      setIsStartTimeModalOpen(false);
      if (
        moment(tempStartTime.current, "HH:mm").isAfter(moment(endTime, "HH:mm"))
      ) {
        presentToast({
          message: "Start time cannot be after end time",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
        return;
      }
      const updatedTour = await TourService.updateTour({
        id: tour.id,
        startTime: tempStartTime.current,
      });
      setStartTime(updatedTour?.startTime || "");
    } catch (error) {
      console.error(error);
      setStartTime(tour.startTime || "");
      presentToast({
        message: "An error occurred while saving the start time",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const handleSaveEndTime = async () => {
    try {
      setIsEndTimeModalOpen(false);
      if (
        moment(startTime, "HH:mm").isAfter(moment(tempEndTime.current, "HH:mm"))
      ) {
        presentToast({
          message: "End time cannot be before start time",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
        return;
      }
      const updatedTour = await TourService.updateTour({
        id: tour.id,
        endTime: tempEndTime.current,
      });
      setEndTime(updatedTour?.endTime || "");
    } catch (error) {
      console.error(error);
      setEndTime(tour.endTime || "");
      presentToast({
        message: "An error occurred while saving the end time",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const handleSaveDate = async () => {
    try {
      setIsDateModalOpen(false);
      const updatedTour = await TourService.updateTour({
        id: tour.id,
        date: tempDate.current,
      });
      setDate(updatedTour?.date || "");
    } catch (error) {
      console.error(error);
      setDate(tour.date || "");
      presentToast({
        message: "An error occurred while saving the date",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const handleSaveMeetup = async () => {
    try {
      setIsMeetupModalOpen(false);
      const updatedTour = await TourService.updateTour({
        id: tour.id,
        meetupLocation: tempMeetupLocation.current,
        meetupDescription: tempMeetupDescription.current,
        meetupTime: tempMeetupTime.current,
      });
      setMeetup({
        location: updatedTour?.meetupLocation || "",
        description: updatedTour?.meetupDescription || "",
        time: updatedTour?.meetupTime || "",
      });
    } catch (error) {
      console.error(error);
      setMeetup({
        location: tour.meetupLocation || "",
        description: tour.meetupDescription || "",
        time: tour.meetupTime || "",
      });
      presentToast({
        message: "An error occurred while saving the meetup",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const handleSaveGeneralNote = async () => {
    try {
      setIsGeneralNoteModalOpen(false);
      const updatedTour = await TourService.updateTour({
        id: tour.id,
        generalNote: tempGeneralNote.current,
      });
      setGeneralNote(updatedTour?.generalNote || "");
    } catch (error) {
      console.error(error);
      setGeneralNote(tour.generalNote || "");
      presentToast({
        message: "An error occurred while saving the general note",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const handleSavePrivateNote = async () => {
    try {
      setIsPrivateNoteModalOpen(false);
      const updatedTour = await TourService.updateTour({
        id: tour.id,
        privateNote: tempPrivateNote.current,
      });
      setPrivateNote(updatedTour?.privateNote || "");
    } catch (error) {
      console.error(error);
      setPrivateNote(tour.privateNote || "");
      presentToast({
        message: "An error occurred while saving the private note",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const MeetupModalContent = () => (
    <div className={styles.sheetContent}>
      <div className={styles.modalHeader}>
        Meetup
        <MediaQuery minWidth={768}>
          <IonIcon
            icon={closeOutline}
            onClick={() => setIsMeetupModalOpen(false)}
          />
        </MediaQuery>
      </div>
      <div className={styles.input}>
        <IonLabel>Meetup address</IonLabel>
        <IonInput
          className="aecorn-input dark"
          placeholder="Address"
          value={meetup.location}
          onIonChange={(ev) => {
            tempMeetupLocation.current = ev.detail.value!;
          }}
        />
      </div>
      <div className={styles.input}>
        <IonLabel>Meetup description</IonLabel>
        <IonTextarea
          className="aecorn-input dark"
          placeholder="Description"
          value={meetup.description}
          onIonChange={(ev) => {
            tempMeetupDescription.current = ev.detail.value!;
          }}
        />
      </div>
      <div className={styles.input}>
        <IonLabel>Meetup time</IonLabel>
        <IonDatetimeButton datetime="meetup-time"></IonDatetimeButton>
        <IonModal keepContentsMounted>
          <IonDatetime
            size="fixed"
            color="dark"
            className="aecorn-datetime"
            presentation="time"
            minuteValues={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
            id="meetup-time"
            showDefaultButtons
            value={meetup.time ? meetup.time : tour.startTime ? tour.startTime : "12:00"}
            onIonChange={(ev) => {
              tempMeetupTime.current = ev.detail.value! as string;
            }}></IonDatetime>
        </IonModal>
      </div>
      <IonButton className="aecorn-button" onClick={handleSaveMeetup}>
        Save changes
      </IonButton>
    </div>
  );

  const GeneralNoteModalContent = () => (
    <div className={styles.sheetContent}>
      <div className={styles.modalHeader}>
        General notes for client
        <MediaQuery minWidth={768}>
          <IonIcon
            icon={closeOutline}
            onClick={() => setIsGeneralNoteModalOpen(false)}
          />
        </MediaQuery>
      </div>
      <div className={styles.input}>
        <IonTextarea
          className="aecorn-input dark"
          placeholder="Notes"
          value={generalNote}
          onIonChange={(ev) => {
            tempGeneralNote.current = ev.detail.value!;
          }}
        />
      </div>
      <IonButton className="aecorn-button" onClick={handleSaveGeneralNote}>
        Save changes
      </IonButton>
    </div>
  );

  const PrivateNoteModalContent = () => (
    <div className={styles.sheetContent}>
      <div className={styles.modalHeader}>
        Private agent tour notes
        <MediaQuery minWidth={768}>
          <IonIcon
            icon={closeOutline}
            onClick={() => setIsPrivateNoteModalOpen(false)}
          />
        </MediaQuery>
      </div>
      <div className={styles.input}>
        <IonTextarea
          className="aecorn-input dark"
          placeholder="Notes"
          value={privateNote}
          onIonChange={(ev) => {
            tempPrivateNote.current = ev.detail.value!;
          }}
        />
      </div>
      <IonButton className="aecorn-button" onClick={handleSavePrivateNote}>
        Save changes
      </IonButton>
    </div>
  );

  const AvailableTimeFrames = () => (
    <section className={styles.section}>
      <IonLabel>Available timeframes</IonLabel>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.card}>
            <div>
              {availability.length
                ? Object.entries(groupSlotsByDay(availability)).map(
                  ([day, slots]) => (
                    <div key={day} className={styles.slot}>
                      {renderSlotText(day, slots, true)}
                    </div>
                  )
                )
                : "Any time"}
            </div>
            <IonIcon
              icon={EditIcon}
              onClick={() => {
                tempAvailability.current = availability;
                setIsAvailabilityModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );

  const DateAndTime = () => (
    <section className={styles.section}>
      <IonLabel>Date and time</IonLabel>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.card}>
            <div>
              Start time:{" "}
              {startTime ? moment(startTime, "HH:mm").format("hh:mm A") : ""}
            </div>
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsStartTimeModalOpen(true)}
            />
          </div>
          <div className={styles.card}>
            <div>
              End time:{" "}
              {endTime ? moment(endTime, "HH:mm").format("hh:mm A") : ""}
            </div>
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsEndTimeModalOpen(true)}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.card}>
            <div>Date: {date ? moment(date).format("MMM DD YYYY") : ""}</div>
            <IonIcon icon={EditIcon} onClick={() => setIsDateModalOpen(true)} />
          </div>
        </div>
      </div>
    </section>
  );

  const Meetup = () => (
    <section className={styles.section}>
      <IonLabel>Meetup</IonLabel>
      <div className={styles.content}>
        <div className={styles.row}>
          <div
            className={`${styles.card} ${!meetup.location &&
              !meetup.description &&
              !meetup.time &&
              styles.emptyCard
              }`}>
            {!meetup.location && !meetup.description && !meetup.time ? (
              "No Data"
            ) : (
              <div>
                <div>Address: {meetup.location}</div>
                <div>
                  Time:{" "}
                  {meetup.time
                    ? moment(meetup.time, "HH:mm").format("hh:mm A")
                    : ""}
                </div>
                <div>Description: {meetup.description}</div>
              </div>
            )}
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsMeetupModalOpen(true)}
            />
          </div>
        </div>
      </div>
    </section>
  );

  const GeneralNotes = () => (
    <section className={styles.section}>
      <IonLabel>Client general notes</IonLabel>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={`${styles.card} ${!generalNote && styles.emptyCard}`}>
            {!generalNote ? "No Data" : generalNote}
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsGeneralNoteModalOpen(true)}
            />
          </div>
        </div>
      </div>
    </section>
  );

  const PrivateNotes = () => (
    <section className={styles.section}>
      <IonLabel>Private agent tour notes</IonLabel>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={`${styles.card} ${!privateNote && styles.emptyCard}`}>
            {!privateNote ? "No Data" : privateNote}
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsPrivateNoteModalOpen(true)}
            />
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <div className={styles.container}>
      <TourAvailabilityModal
        isOpen={isAvailabilityModalOpen}
        onDismiss={() => setIsAvailabilityModalOpen(false)}
        availability={availability}
        onSave={handleSaveAvailability}
      />
      <TourTimeModal
        title="Start time"
        isOpen={isStartTimeModalOpen}
        onDismiss={() => setIsStartTimeModalOpen(false)}
        selectedTime={startTime}
        onUpdateTime={(time) => (tempStartTime.current = time)}
        onSave={handleSaveStartTime}
      />
      <TourTimeModal
        title="End time"
        isOpen={isEndTimeModalOpen}
        onDismiss={() => setIsEndTimeModalOpen(false)}
        selectedTime={endTime}
        onUpdateTime={(time) => (tempEndTime.current = time)}
        onSave={handleSaveEndTime}
      />
      <TourDateModal
        isOpen={isDateModalOpen}
        onDismiss={() => setIsDateModalOpen(false)}
        selectedDate={date}
        onUpdateDate={(date) => (tempDate.current = date)}
        onSave={handleSaveDate}
      />
      <Modal
        isOpen={isMeetupModalOpen}
        onDidDismiss={() => setIsMeetupModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <MeetupModalContent />
      </Modal>
      <Modal
        isOpen={isGeneralNoteModalOpen}
        onDidDismiss={() => setIsGeneralNoteModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <GeneralNoteModalContent />
      </Modal>
      <Modal
        isOpen={isPrivateNoteModalOpen}
        onDidDismiss={() => setIsPrivateNoteModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <PrivateNoteModalContent />
      </Modal>
      <AvailableTimeFrames />
      <DateAndTime />
      <Meetup />
      <GeneralNotes />
      <PrivateNotes />
    </div>
  );
};

export default AgentTourInfo;
