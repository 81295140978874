import { ListingsConstants } from "constants/Listings.constants";
import { ListingsClass } from "enums/Listings/ListingClass.enum";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import millify from "millify";
import { Listing } from "models/listings/listing.model";
import { store } from "redux/store";
import { numberWithCommas, renderCurrencySign } from "./functions";
import moment from "moment";
import { AuthenticationService } from "services/authenticationService";
import { ListingType } from "enums/Listings/ListingType.enum";

export class ListingsHelper {
  static convertPercentageToPrice(percentage: number, type: ListingType) {
    if (percentage === 0 || percentage === 100) {
      return null;
    }

    switch (type) {
      case ListingType.Sale:
        return percentage * 50000;
      case ListingType.Lease:
        return percentage * 100;
      default:
        return percentage;
    }
  }

  static convertPriceToPercentage(
    price: number | null,
    type: ListingType,
    bound: "lower" | "upper"
  ) {
    if (price === null) {
      return bound === "lower" ? 0 : 100;
    }
    switch (type) {
      case ListingType.Sale:
        return price / 50000;
      case ListingType.Lease:
        return price / 100;
      default:
        return price;
    }
  }

  static convertPercentageToSqft(percentage: number) {
    if (percentage === 0 || percentage === 100) {
      return null;
    }

    return percentage * 100;
  }

  static convertSqftToPercentage(
    sqft: number | null,
    bound: "lower" | "upper"
  ) {
    if (sqft === null) {
      return bound === "lower" ? 0 : 100;
    }

    return sqft / 100;
  }

  static convertDateFilterToFilterOption() {
    const filters = store.getState().filters.value;
    const {
      minListDate,
      maxListDate,
      minUpdatedOn,
      maxUpdatedOn,
      minSoldDate,
      maxSoldDate,
    } = filters;

    if (minSoldDate && !maxSoldDate) {
      const numberOfDaysFromCurrentDate = Math.floor(
        (new Date().getTime() - new Date(minSoldDate).getTime()) /
          (1000 * 60 * 60 * 24)
      );
      return ListingsConstants.UnavailableListingsDateFilterOptions.find(
        (option) => option.value === numberOfDaysFromCurrentDate
      )?.value;
    }

    if (minSoldDate && maxSoldDate) {
      const yearOfMinSoldDate = new Date(minSoldDate).getFullYear();
      return ListingsConstants.UnavailableListingsDateFilterOptions.find(
        (option) => option.value === yearOfMinSoldDate
      )?.value;
    }

    if (minUpdatedOn && !maxUpdatedOn) {
      const numberOfDaysFromCurrentDate = Math.floor(
        (new Date().getTime() - new Date(minUpdatedOn).getTime()) /
          (1000 * 60 * 60 * 24)
      );
      return ListingsConstants.UnavailableListingsDateFilterOptions.find(
        (option) => option.value === numberOfDaysFromCurrentDate
      )?.value;
    }

    if (minUpdatedOn && maxUpdatedOn) {
      const yearOfMinUpdatedOn = new Date(minUpdatedOn).getFullYear();
      return ListingsConstants.UnavailableListingsDateFilterOptions.find(
        (option) => option.value === yearOfMinUpdatedOn
      )?.value;
    }

    if (minListDate && !maxListDate) {
      const numberOfDaysFromCurrentDate = Math.floor(
        (new Date().getTime() - new Date(minListDate).getTime()) /
          (1000 * 60 * 60 * 24)
      );

      return ListingsConstants.ActiveListingsDateFilterOptions.find(
        (option) => option.value === numberOfDaysFromCurrentDate
      )?.value;
    }

    if (maxListDate && !minListDate) {
      const numberOfDaysFromCurrentDate = Math.floor(
        (new Date().getTime() - new Date(maxListDate).getTime()) /
          (1000 * 60 * 60 * 24)
      );
      return ListingsConstants.ActiveListingsDateFilterOptions.find(
        (option) => option.value === -numberOfDaysFromCurrentDate
      )?.value;
    }

    return 0;
  }

  static getListingDateFilterOptions() {
    const filters = store.getState().filters.value;
    const status: "A" | "U" = filters.status;

    if (status === "A") {
      return ListingsConstants.ActiveListingsDateFilterOptions;
    } else {
      return ListingsConstants.UnavailableListingsDateFilterOptions;
    }
  }

  static getListingPropertyTypesByClass() {
    const filters = store.getState().filters.value;
    const listingsClass: ListingsClass[] = filters.class;

    const types: { label: string; value: string }[] = [];

    if (listingsClass.includes(ListingsClass.Freehold)) {
      types.push(...ListingsConstants.ResidentialPropertyTypes);
    }

    if (listingsClass.includes(ListingsClass.Condo)) {
      types.push(...ListingsConstants.CondoPropertyTypes);
    }

    if (listingsClass.includes(ListingsClass.Commercial)) {
      types.push(...ListingsConstants.CommercialPropertyTypes);
    }

    return types;
  }

  static getListingPropertyTypeLabel() {
    const filters = store.getState().filters.value;
    const selectedValue: string[] = filters.propertyType;
    const allTypes = ListingsHelper.getListingPropertyTypesByClass();
    if (selectedValue.length === 0) {
      return "Property type";
    } else {
      if (selectedValue.length === 1) {
        return allTypes.find((type) => type.value === selectedValue[0])?.label;
      } else {
        const firstType = allTypes.find(
          (type) => type.value === selectedValue[0]
        )?.label;

        return `${firstType} +${selectedValue.length - 1}`;
      }
    }
  }

  static getListingPriceLabel() {
    const filters = store.getState().filters.value;
    const minPrice = filters.minPrice;
    const maxPrice = filters.maxPrice;

    if (minPrice && maxPrice) {
      return `$${millify(+minPrice)} - $${millify(+maxPrice)}`;
    } else if (minPrice) {
      return `$${millify(+minPrice)}+`;
    } else if (maxPrice) {
      return `$${millify(+maxPrice)}+`;
    } else {
      return "Price";
    }
  }

  static getListingPriceOptions(
    type: ListingType,
    disabledCondition?: (price: number) => boolean
  ) {
    const salePriceOptions = [
      250000, 500000, 750000, 1000000, 1500000, 2000000, 2500000, 3000000,
      3500000, 4000000, 4500000, 5000000, 6000000, 7000000, 8000000, 9000000,
      10000000,
    ].map((price) => ({
      text: `$${millify(price)}`,
      value: price,
      disabled: disabledCondition ? disabledCondition(price) : false,
    }));

    const leasePriceOptions = [
      500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 6000, 7000,
      8000, 9000, 10000,
    ].map((price) => ({
      text: `$${millify(price)}`,
      value: price,
      disabled: disabledCondition ? disabledCondition(price) : false,
    }));
    if (type === ListingType.Sale) {
      return [
        {
          text: "Any",
          value: null,
        },
        ...salePriceOptions,
      ];
    } else {
      return [
        {
          text: "Any",
          value: null,
        },
        ...leasePriceOptions,
      ];
    }
  }

  static getListingNumberOfBedsLabel() {
    const filters = store.getState().filters.value;
    const minBeds = filters.minBeds;
    const maxBeds = filters.maxBeds;

    if (minBeds && maxBeds) {
      return `${minBeds} - ${maxBeds} beds`;
    } else if (minBeds) {
      return `${minBeds}+ beds`;
    } else if (maxBeds) {
      return `${maxBeds}+ beds`;
    } else {
      return "Beds";
    }
  }

  static getListingAddress(
    address: any,
    accurate = false,
    isNavigation = false
  ) {
    let fullAddress = "";
    if (address.unitNumber && !accurate && !isNavigation)
      fullAddress += `${address.unitNumber} - `;
    if (address.streetNumber) fullAddress += `${address.streetNumber} `;
    if (address.streetName) fullAddress += `${address.streetName} `;
    if (address.streetSuffix) fullAddress += `${address.streetSuffix} `;
    if (address.streetDirection) fullAddress += `${address.streetDirection} `;
    if (accurate && address.zip) fullAddress += `${address.zip}`;

    return fullAddress.trim();
  }

  static getListingThumbnail(listing: Listing, size = "small") {
    const image = listing.images ? listing.images[0] : "";

    return image ? `https://cdn.repliers.io/${image}?class=${size}` : "";
  }

  static buildImageUrl(fileName: string, size = "medium") {
    return `https://cdn.repliers.io/${fileName}?class=${size}`;
  }

  static getListingOpenHouses(listing: Listing) {
    const openHouses = listing.openHouse;
    
    if (!openHouses) {
      return [];
    } else {
      return Object.keys(openHouses)
        .filter((key) => openHouses[key].date)
        .map((key) => {
          const openHouse = openHouses[key];

          return {
            date: moment(openHouse.date).format("ddd, MMM DD"),
            time: `${openHouse.startTime}-${openHouse.endTime}`,
          };
        });
    }
  }

  static getListingFormattedPrice(listing: Listing) {
    const shouldScatter = !ListingsHelper.displayData(listing);
    let price: number = 0;

    if (listing.status === ListingStatus.Active) {
      price = +listing.listPrice;
    } else {
      const soldPrice = listing.soldPrice ? +listing.soldPrice : 0;

      price = soldPrice ? soldPrice : +listing.listPrice;
    }

    if (shouldScatter) {
      price = +price
        .toString()
        .split("")
        .map((_, i) => i + 1)
        .join("");
    }

    const currentClient = store.getState().client.currentClient;
    const clientCurrency = currentClient?.currency;
    const rates = store.getState().currency.rates;

    return clientCurrency
      ? `${renderCurrencySign(clientCurrency, false)}${millify(
          price * rates[clientCurrency.toUpperCase()]
        )}`
      : `${renderCurrencySign(clientCurrency, false)}${numberWithCommas(
          price
        )}`;
  }

  static getFormattedPrice(price: number) {
    const currentClient = store.getState().client.currentClient;
    const clientCurrency = currentClient?.currency;
    const rates = store.getState().currency.rates;

    return clientCurrency
      ? `${renderCurrencySign(clientCurrency, false)}${millify(
          price * rates[clientCurrency.toUpperCase()]
        )}`
      : `${renderCurrencySign(clientCurrency, false)}${numberWithCommas(
          price
        )}`;
  }

  static getListingRooms(listing: Listing) {
    let beds = listing.details.numBedrooms;
    if (listing.details.numBedroomsPlus) {
      beds += `+ ${listing.details.numBedroomsPlus}`;
    }
    let baths = listing.details.numBathrooms;
    if (listing.details.numBathroomsPlus) {
      baths += `+ ${listing.details.numBathrooms}`;
    }
    const garages = listing.details.numGarageSpaces
      ? listing.details.numGarageSpaces.includes(".")
        ? listing.details.numGarageSpaces.split(".")[0]
        : listing.details.numGarageSpaces
      : 0;
    const openParkings = listing.details.numParkingSpaces
      ? listing.details.numParkingSpaces.includes(".")
        ? listing.details.numParkingSpaces.split(".")[0]
        : listing.details.numParkingSpaces
      : 0;
    const totalParkings = +garages + +openParkings;
    const sqft = listing.details.sqftRange
      ? listing.details.sqftRange
      : listing.details.sqft;

    return { beds, baths, garages, openParkings, totalParkings, sqft };
  }

  static getMapListingFormattedPrice(listing: Listing) {
    let price: number = 0;

    if (listing.status === ListingStatus.Active) {
      price = +listing.listPrice;
    } else {
      const soldPrice = listing.soldPrice ? +listing.soldPrice : 0;

      price = soldPrice ? soldPrice : +listing.listPrice;
    }

    const currentClient = store.getState().client.currentClient;
    const currency = currentClient?.currency;
    const rates = store.getState().currency.rates;

    const convertedPrice = currency
      ? (price / rates.CAD) * rates[currency.toUpperCase()]
      : price;

    const precision =
      ["Sale", "Sold", "Delisted"].includes(listing.type) &&
      convertedPrice >= 1000000
        ? 1
        : 0;

    const formattedPrice = millify(convertedPrice, { precision });

    return formattedPrice === "1000K" ? "1M" : formattedPrice;
  }

  static getListingFormattedPriceDifference(listing: Listing) {
    const currentClient = store.getState().client.currentClient;
    const clientCurrency = currentClient?.currency;
    const rates = store.getState().currency.rates;

    const soldPrice = listing.soldPrice ? +listing.soldPrice : 0;
    const listPrice = +listing.listPrice;

    const difference = listPrice - soldPrice;

    return clientCurrency
      ? `${renderCurrencySign(clientCurrency, false)}${millify(
          difference * rates[clientCurrency.toUpperCase()]
        )}`
      : `${renderCurrencySign(clientCurrency, false)}${numberWithCommas(
          difference
        )}`;
  }

  static getListingFormattedListDate(listing: Listing) {
    return moment().diff(moment(listing.listDate), "days");
  }

  static getListingFormattedDate(listing: Listing) {
    if (listing.status === ListingStatus.Active) {
      return moment().diff(moment(listing.listDate), "days");
    } else {
      const soldDate = listing.soldDate ? listing.soldDate : listing.updatedOn;
      return moment().diff(moment(soldDate), "days");
    }
  }

  static getListingPropertyType = (type: string) => {
    const allTypes = [
      ...ListingsConstants.ResidentialPropertyTypes,
      ...ListingsConstants.CondoPropertyTypes,
      ...ListingsConstants.CommercialPropertyTypes,
    ];
    const t = allTypes.find((t) => t.value === type);

    if (t) {
      return t.label;
    } else return type;
  };

  static getDetailedListingType(listing: Listing) {
    const type = ListingsHelper.getListingPropertyType(
      listing.details.propertyType
    );
    const style = listing.details.style;

    let detailedType = type;

    if (style) {
      detailedType += `, ${style}`;
    }

    return detailedType;
  }

  static getAppliedFilters(value?: any) {
    const filters = value ? value : store.getState().filters.value;

    const appliedFilters: string[] = [];

    const type = filters.type;
    appliedFilters.push(`For ${type.toLowerCase()}`);

    const selectedPropertyTypes = filters.propertyType;

    if (selectedPropertyTypes?.length > 0) {
      const firstType = ListingsHelper.getListingPropertyType(
        selectedPropertyTypes[0]
      );
      if (selectedPropertyTypes.length === 1) {
        appliedFilters.push(firstType);
      } else {
        appliedFilters.push(
          `${firstType} +${selectedPropertyTypes.length - 1}`
        );
      }
    }

    const minPrice = filters.minPrice;
    const maxPrice = filters.maxPrice === 999999999 ? null : filters.maxPrice;

    if (minPrice || maxPrice) {
      if (minPrice && maxPrice) {
        appliedFilters.push(
          `${minPrice ? `$${millify(+minPrice)}` : ""} - ${
            maxPrice ? `$${millify(+maxPrice)}` : ""
          }`
        );
      } else if (minPrice) {
        appliedFilters.push(`${minPrice ? `$${millify(+minPrice)}` : ""}+`);
      } else if (maxPrice) {
        appliedFilters.push(`${maxPrice ? `$${millify(+maxPrice)}` : ""}+`);
      }
    }

    const minBeds = filters.minBeds;

    if (minBeds) {
      appliedFilters.push(`${minBeds}+ beds`);
    }

    const minBaths = filters.minBaths;

    if (minBaths) {
      appliedFilters.push(`${minBaths}+ baths`);
    }

    const minParking = filters.minParkingSpaces;

    if (minParking) {
      appliedFilters.push(`${minParking}+ parking`);
    }

    return appliedFilters;
  }

  static displayData(listing: Listing) {
    const isAuthenticated = AuthenticationService.isAuthenticated();
    if (isAuthenticated) {
      return true;
    } else {
      return listing.status === ListingStatus.Active;
    }
  }
}
