import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import styles from "./map.module.scss";
import {
  renderMapClusters,
  renderSingleMarker,
} from "./components/Clusters/clusters.render.js";
import { googleMapStyles } from "./google-map.styles";
import Marker from "./components/Marker/marker.component";
import { ReactComponent as LocationPin } from "assets/svg/location-pin.svg";
import { ReactComponent as SchoolPin } from "assets/svg/schoolpin.svg";
import { getCurrentLocation } from "./map-utils";
import Button from "../../../Form/Button/button.component";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import {
  selectMapFavourites,
  selectActiveListing,
  selectDetailsListing,
  selectDrawMode,
  selectDrawingBoundary,
} from "redux/map/map.selectors";
import {
  toggleDrawMode,
  toggleFavourites,
  updateActiveListing,
  updateBoundaries,
  updateDetailsListing,
  updateDrawingBoundary,
} from "redux/map/map.actions";
import withFilters from "HOC/withFilters/with-filters";
import {
  IonButton,
  IonIcon,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { selectIsPreConstruction } from "redux/filters/filters.selectors";
import MapButtonsBottom from "../MapButtons/MapButtonsBottom/map-buttons-bottom.component";
import MapButtonsTop from "../MapButtons/MapButtonsTop/map-buttons-top.component";
import { selectIsSchoolsActive } from "redux/schools/schools.selectors";
import SchoolSheet from "components/shared/Map/SchoolSheet/school-sheet.component";
import ListingListItem from "components/shared/Listings/ListingListItem/listing-list-item.component";
import { selectLocationsValue } from "redux/locations/locations.selectors";
import { ListingsFiltersService } from "services/listingsFiltersService";

const MapContainer = ({
  isApp = false,
  pageRef,
  loading,
  clusters,
  selectedCluster,
  onSelectCluster,
  selectedListings,
  onSelectListings,
  onDeselectClusterAndListings,
  filters,
  extraFilters,
  updateSelectedLocation,
  mapLocations,
  updateBoundaryFilter,
  schools,
  selectedSchool,
  onSelectSchool,
  toggledSchools,
  onToggleSchool,
  onClearSchoolsBoundaries,
  view,
  setView,
  handleModals,
  onMapReady,
}) => {
  const dispatch = useDispatch();
  const router = useIonRouter();
  const [mapType, setMapType] = useState("roadmap");
  const favouritesOnly = useSelector(selectMapFavourites);
  const activeListing = useSelector(selectActiveListing);
  const detailsListing = useSelector(selectDetailsListing);
  const isDrawModeEnabled = useSelector(selectDrawMode);
  const drawingBoundary = useSelector(selectDrawingBoundary);
  const isPreConstruction = useSelector(selectIsPreConstruction);
  const showSchools = useSelector(selectIsSchoolsActive);
  const locations = useSelector(selectLocationsValue);
  const containerRef = useRef();
  const mapRef = useRef();
  const mapsRef = useRef();
  const drawingManagerRef = useRef();
  const drawingRef = useRef([]);
  const polygonsRef = useRef([]);
  const selectedSchoolsRef = useRef([]);
  const schoolPolygonRef = useRef([]);
  const selectedSchoolPolygonRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(14);
  const [center, setCenter] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [points, setPoints] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isCentered, setIsCentered] = useState(false);

  const currentLocationHandler = useCallback(async () => {
    const location = await getCurrentLocation();
    if (location) {
      setCurrentLocation(location);
      mapRef.current.setCenter({
        lat: location.latitude,
        lng: location.longitude,
      });
      mapRef.current.setZoom(14);
    }
  }, []);

  const mapTypeHandler = () => {
    mapType === "roadmap" ? setMapType("satellite") : setMapType("roadmap");
  };

  const onMapReadyHandler = async (map, maps) => {
    mapRef.current = map;
    mapsRef.current = maps;
    map.setMapTypeId(mapType);
    drawingManagerRef.current = new maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
    });
    drawingManagerRef.current.setMap(map);
    onMapReady(mapRef.current, mapsRef.current);
    navigateToDetailListing();
    if (!detailsListing && localStorage.getItem("location") === "active") {
      try {
        await currentLocationHandler();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const navigateToDetailListing = () => {
    if (mapRef.current && detailsListing) {
      mapRef.current.setCenter({
        lat: +detailsListing.map.latitude,
        lng: +detailsListing.map.longitude,
      });
      const currentZoom = mapRef.current.getZoom();
      mapRef.current.setZoom(currentZoom === 16 ? 17 : 16);
    }
  };

  useIonViewDidEnter(() => {
    navigateToDetailListing();
  }, [detailsListing, mapRef.current]);

  useIonViewDidLeave(() => {
    dispatch(updateDetailsListing(null));
  });

  const onChangeHandler = ({ zoom: newZoom, bounds: newBounds }) => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      setCenter({ lat: center.lat(), lng: center.lng() });
      if (currentLocation) {
        if (
          currentLocation.latitude === center.lat() &&
          currentLocation.longitude === center.lng()
        ) {
          setIsCentered(true);
        } else {
          setIsCentered(false);
        }
      }
    }

    setBounds(newBounds);
    setZoom(newZoom);
    if (!isApp && newZoom !== zoom) {
      onDeselectClusterAndListings();
    }

    if (
      !drawingRef.current.length &&
      !toggledSchools.length &&
      !isDrawModeEnabled
    ) {
      updateBoundaryFilter({
        map: [
          [
            [newBounds.sw.lng, newBounds.ne.lat],
            [newBounds.ne.lng, newBounds.ne.lat],
            [newBounds.ne.lng, newBounds.sw.lat],
            [newBounds.sw.lng, newBounds.sw.lat],
            [newBounds.sw.lng, newBounds.ne.lat],
          ],
        ],
      });
    }

    dispatch(
      updateBoundaries([
        [
          [newBounds.sw.lng, newBounds.ne.lat],
          [newBounds.ne.lng, newBounds.ne.lat],
          [newBounds.ne.lng, newBounds.sw.lat],
          [newBounds.sw.lng, newBounds.sw.lat],
          [newBounds.sw.lng, newBounds.ne.lat],
        ],
      ])
    );
  };

  const drawCompleteHandler = useCallback(
    (poly) => {
      if (drawingRef.current.length) {
        drawingRef.current.forEach((p) => p.polygon.setMap(null));
        drawingRef.current.push({ polygon: poly });
      } else {
        drawingRef.current.push({ polygon: poly });
      }

      drawingManagerRef.current.setDrawingMode(null);
      const bounds = [];
      const paths = [];
      const vertices = poly.getPath();

      if (vertices) {
        for (let i = 0; i < vertices.getLength(); i++) {
          const xy = vertices.getAt(i);
          bounds.push([xy.lng(), xy.lat()]);
          paths.push({ lat: xy.lat(), lng: xy.lng() });
        }
        updateBoundaryFilter({ map: [bounds] });
        dispatch(updateDrawingBoundary([bounds]));
      }
      enable();
    },
    [dispatch, updateBoundaryFilter]
  );

  const drawFreeHandHandler = useCallback(() => {
    const maps = mapsRef.current;
    const map = mapRef.current;
    let poly = new maps.Polyline({
      map: map,
      clickable: false,
      strokeColor: mapType === "satellite" ? "#dbdbdb" : "#6e6e6e",
      strokeWeight: 2,
      zIndex: 10,
    });

    const mouseMove = maps.event.addListener(map, "mousemove", (e) => {
      poly.getPath().push(e.latLng);
    });

    const touchMove = maps.event.addListener(map, "touchmove", (e) => {
      poly.getPath().push(e.latLng);
    });

    maps.event.addListenerOnce(map, "mouseup", () => {
      maps.event.removeListener(mouseMove);
      const path = poly.getPath();

      poly.setMap(null);
      poly = new maps.Polygon({
        map: map,
        paths: path,
        clickable: false,
        strokeColor: mapType === "satellite" ? "#dbdbdb" : "#6e6e6e",
        strokeWeight: 2,
        fillColor: mapType === "satellite" ? "#6e6e6e" : "#dbdbdb",
        fillOpacity: 0.6,
        zIndex: 10,
      });
      drawCompleteHandler(poly);
    });

    maps.event.addListenerOnce(map, "touchend", () => {
      maps.event.removeListener(touchMove);
      const path = poly.getPath();
      poly.setMap(null);
      poly = new maps.Polygon({
        map: map,
        paths: path,
        clickable: false,
        strokeColor: mapType === "satellite" ? "#dbdbdb" : "#6e6e6e",
        strokeWeight: 2,
        fillColor: mapType === "satellite" ? "#6e6e6e" : "#dbdbdb",
        fillOpacity: 0.6,
        zIndex: 10,
      });
      drawCompleteHandler(poly);
    });
  }, [drawCompleteHandler, mapType]);
  //   await hapticsImpact();

  //   if (toggledSchools.length) {
  //     const foundSchool = toggledSchools.find(
  //       (s) => s.id === selectedSchool.id
  //     );

  //     if (foundSchool) {
  //       dispatch(removeSchoolBoundary(selectedSchool.id));
  //       if (schoolsFilterListings.schools.length === 1) {
  //         dispatch(toggleFilterListings());
  //       }
  //     } else {
  //       if (isDrawModeEnabled) {
  //         dispatch(toggleDrawMode());
  //       }
  //       dispatch(addSchoolBoundary(selectedSchool));
  //     }
  //   } else {
  //     if (isDrawModeEnabled) {
  //       dispatch(toggleDrawMode());
  //     }
  //     dispatch(toggleFilterListings(selectedSchool));
  //   }
  //   onSelectSchool(null);
  // };

  // Functions

  const cancelFavouritesHandler = () => {
    dispatch(toggleFavourites(false));
  };

  const disable = () => {
    mapRef.current &&
      mapRef.current.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: false,
        gestureHandling: "none",
        disableDefaultUI: true,
        clickableIcons: false,
      });
  };

  const enable = () => {
    mapRef.current.setOptions({
      draggable: true,
      zoomControl: false,
      scrollwheel: true,
      disableDoubleClickZoom: false,
      gestureHandling: "auto",
      disableDefaultUI: false,
      clickableIcons: false,
    });
  };

  const calculateCardLocation = (type) => {
    const cardWith = type === "listing" ? 350 : 320;
    const cardHeight = type === "listing" ? 200 : 350;
    const position = {
      top: undefined,
      bottom: undefined,
      left: undefined,
      right: undefined,
      transform: undefined,
    };
    try {
      const mapWidth = mapRef.current.getDiv().offsetWidth;
      const mapHeight = mapRef.current.getDiv().offsetHeight;
      const overlay = new mapsRef.current.OverlayView();
      overlay.draw = function () {};
      overlay.setMap(mapRef.current);
      const { x, y } = overlay
        .getProjection()
        .fromLatLngToContainerPixel(
          new mapsRef.current.LatLng(
            type === "listing"
              ? selectedListings[0]?.map.latitude
              : selectedSchool.latitude,
            type === "listing"
              ? selectedListings[0]?.map.longitude
              : selectedSchool.longitude
          )
        );
      if (
        x > cardWith / 2 &&
        mapWidth - x > cardWith / 2 &&
        mapHeight - y > cardHeight
      ) {
        position.top = "4rem";
        position.right = "50%";
        position.transform = "translateX(50%)";
      } else if (
        x > cardWith / 2 &&
        mapWidth - x > cardWith / 2 &&
        y > cardHeight
      ) {
        position.bottom = "2rem";
        position.right = "50%";
        position.transform = "translateX(50%)";
      } else if (
        x > cardWith / 2 &&
        mapHeight - y > cardHeight / 2 &&
        y > cardHeight / 2
      ) {
        position.right = "2rem";
        position.top = "50%";
        position.transform = "translateY(-50%)";
      } else if (y > cardHeight / 2 && mapHeight - y > cardHeight) {
        position.left = "5rem";
        position.top = "50%";
        position.transform = "translateY(-50%)";
      } else if (y > cardHeight / 2) {
        position.left = "5rem";
        position.top = "75%";
        position.transform = "translateY(-50%)";
      } else if (mapWidth - x > cardWith) {
        position.left = "5rem";
      } else {
        position.right = "2rem";
      }
    } catch (err) {
      console.log(err);
    }

    return position;
  };

  const renderCards = () => {
    return (
      <div
        className={styles.cards}
        style={calculateCardLocation("listing")}
        onMouseOver={() => {
          disable();
        }}
        onMouseLeave={() => enable()}
        onTouchStart={() => disable()}
        onTouchEnd={() => enable()}>
        <div className={styles.listings}>
          {selectedListings.map((listing, index) => (
            <ListingListItem
              listing={listing}
              onClick={() => router.push(`/listings/${listing.mlsNumber}`)}
              key={index}
            />
          ))}
        </div>
      </div>
    );
  };

  const handleSchoolClick = (e, school) => {
    if (!isApp) {
      const map = mapRef.current;
      const mapBounds = containerRef.current.getBoundingClientRect();
      const mapXStart = mapBounds.left;
      const mapXEnd = mapBounds.right;
      const mapYStart = mapBounds.top;
      const mapYEnd = mapBounds.bottom;

      map.panTo({
        lat: school.latitude - 0.01,
        lng: school.longitude,
      });
      if (
        e.clientX + 150 > mapXEnd ||
        e.clientX < mapXStart + 200 ||
        e.clientY + 150 > mapYEnd
      ) {
        map.panTo({
          lat: school.latitude - 0.01,
          lng: school.longitude,
        });
      }
    }

    onDeselectClusterAndListings();
    onSelectSchool(school);
  };

  const renderSchoolCard = () => {
    return (
      <div
        className={styles.schoolCard}
        style={calculateCardLocation("school")}>
        <SchoolSheet
          school={selectedSchool}
          toggled={
            toggledSchools.find((school) => school.id === selectedSchool.id)
              ? true
              : false
          }
          onToggleBoundary={() => onToggleSchool(selectedSchool)}
          disabled={toggledSchools.length >= 10}
        />
      </div>
    );
  };

  useEffect(() => {
    if (mapRef.current) mapRef.current.setMapTypeId(mapType);
  }, [mapType]);

  // useEffect(() => {
  //   if (mapRef.current) {
  //     if (filters.neighborhood && filters.neighborhood.length) {
  //       const selectedNeighborhoods =
  //         ListingsFiltersService.getNeighborhoodsLocationValues();

  //       const existingDrawings = drawingRef.current;
  //       console.log("existingDrawings", existingDrawings);

  //       for (const neighborhood of selectedNeighborhoods) {
  //         if (
  //           !existingDrawings.find(
  //             (d) => d.type === "neighborhood" && d.id === neighborhood.name
  //           )
  //         ) {
  //           console.log("here");
  //           const points = neighborhood.coordinates[0];

  //           const bounds = new mapsRef.current.LatLngBounds();
  //           const shapeCoords = points.map((loc) => {
  //             return { lat: loc[1], lng: loc[0] };
  //           });

  //           shapeCoords.push(shapeCoords[0]);

  //           for (let i = 0; i < shapeCoords.length; i++) {
  //             bounds.extend(shapeCoords[i]);
  //           }
  //           const polygon = new mapsRef.current.Polygon({
  //             map: mapRef.current,
  //             paths: shapeCoords,
  //             clickable: false,
  //             strokeColor: "#dbdbdb",
  //             strokeWeight: 2,
  //             fillColor: "#6e6e6e",
  //             fillOpacity: 0.5,
  //             zIndex: 12,
  //             visible: true,
  //           });
  //           drawingRef.current.push({
  //             polygon,
  //             id: neighborhood.name,
  //             type: "neighborhood",
  //           });
  //         }

  //         existingDrawings
  //           .filter(
  //             (d) =>
  //               d.type === "neighborhood" &&
  //               !selectedNeighborhoods.find((n) => n.name === d.id)
  //           )
  //           .forEach((d) => {
  //             d.polygon.setMap(null);
  //           });
  //       }
  //     }
  //   }
  // }, [filters.area, filters.city, filters.neighborhood, locations]);

  const showSchoolBoundary = useCallback(() => {
    if (schoolPolygonRef.current) {
      schoolPolygonRef.current.forEach((p) => p.polygon.setMap(null));
      schoolPolygonRef.current = [];
    }
    const maps = mapsRef.current;
    const map = mapRef.current;

    if (maps && toggledSchools.length) {
      toggledSchools.forEach((school) => {
        const boundary = JSON.parse(JSON.parse(school.boundaryArray)).Points;

        let points = [];
        for (let i = 0; i < boundary.length; i += 2) {
          points.push(boundary.slice(i, i + 2));
        }

        const bounds = new maps.LatLngBounds();
        const shapeCoords = points.map((loc) => {
          return { lat: loc[0], lng: loc[1] };
        });

        for (let i = 0; i < shapeCoords.length; i++) {
          bounds.extend(shapeCoords[i]);
        }

        const polygon = new maps.Polygon({
          map: map,
          paths: shapeCoords,
          clickable: false,
          strokeColor: school.strokeColor,
          strokeWeight: 2,
          fillColor: school.fillColor,
          fillOpacity: 0.6,
          zIndex: 10,
        });
        maps.event.addListener(polygon, "mouseover", function () {
          this.setOptions({
            fillOpacity: "0.8",
            zIndex: 11,
          });
          const pin = document.getElementById(school.id);
          const foundSchool = selectedSchoolsRef.current?.schools?.find(
            (s) => s.id === school.id
          );
          if (pin && foundSchool) {
            pin.style.fill = school.hoverPinFillColor;
            pin.style.stroke = school.hoverPinStrokeColor;
          }
        });

        maps.event.addListener(polygon, "mouseout", function () {
          this.setOptions({
            fillOpacity: "0.6",
            zIndex: 10,
          });
          const pin = document.getElementById(school.id);

          const foundSchool = selectedSchoolsRef.current?.schools?.find(
            (s) => s.id === school.id
          );

          if (pin && foundSchool) {
            pin.style.fill = school.fillColor;
            pin.style.stroke = school.strokeColor;
          }
        });

        schoolPolygonRef.current.push({
          polygon,
          name: school.name,
          id: school.id,
        });
      });
    } else if (schoolPolygonRef.current) {
      schoolPolygonRef.current.forEach((p) => p.polygon.setMap(null));
      schoolPolygonRef.current = [];
    }
  }, [toggledSchools]);

  useEffect(() => {
    const maps = mapsRef.current;
    const map = mapRef.current;
    if (maps && selectedSchool) {
      if (selectedSchoolPolygonRef.current) {
        selectedSchoolPolygonRef.current.polygon.setMap(null);
        selectedSchoolPolygonRef.current = null;
      }
      try {
        const boundary = JSON.parse(
          JSON.parse(selectedSchool.boundaryArray)
        )?.Points;

        let points = [];
        for (let i = 0; i < boundary.length; i += 2) {
          points.push(boundary.slice(i, i + 2));
        }

        const bounds = new maps.LatLngBounds();
        const shapeCoords = points.map((loc) => {
          return { lat: loc[0], lng: loc[1] };
        });

        for (let i = 0; i < shapeCoords.length; i++) {
          bounds.extend(shapeCoords[i]);
        }

        const polygon = new maps.Polygon({
          map: map,
          paths: shapeCoords,
          clickable: false,
          strokeColor: toggledSchools.find((s) => s.id === selectedSchool.id)
            ? "#c24100"
            : "#dff7f0",
          strokeWeight: 2,
          fillColor: toggledSchools.find((s) => s.id === selectedSchool.id)
            ? "#ffeee5"
            : "#1f7a60",
          fillOpacity: toggledSchools.find((s) => s.id === selectedSchool.id)
            ? 1
            : 0.6,
          zIndex: 12,
        });

        selectedSchoolPolygonRef.current = {
          polygon,
          name: selectedSchool.name,
          id: selectedSchool.id,
        };
      } catch (err) {
        console.log(err);
      }
    } else {
      if (selectedSchoolPolygonRef.current) {
        selectedSchoolPolygonRef.current.polygon.setMap(null);
        selectedSchoolPolygonRef.current = null;
      }
    }
  }, [toggledSchools, selectedSchool]);

  useIonViewDidEnter(() => {
    if (mapRef.current) {
      if (mapRef.current.zoom === 10 && filters.area)
        mapRef.current.setZoom(11);
    }
  }, [filters.area]);

  const enableDrawing = useCallback(() => {
    const mouseListener = () => {
      drawFreeHandHandler();
    };
    const touchListener = () => {
      drawFreeHandHandler();
    };
    const maps = mapsRef.current;
    const map = mapRef.current;
    const drawingManager = drawingManagerRef.current;
    disable();
    if (maps && map && drawingManager) {
      maps.event.addDomListenerOnce(map, "mousedown", mouseListener);
      maps.event.addDomListenerOnce(map, "touchstart", touchListener);
      drawingManager.setDrawingMode(maps.drawing.OverlayType.POLYLINE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableDrawing = useCallback(() => {
    const maps = mapsRef.current;
    const map = mapRef.current;
    const drawingManager = drawingManagerRef.current;
    if (maps && map && drawingManager) {
      maps.event.clearListeners(map, "mousedown");
      maps.event.clearListeners(map, "touchstart");
      enable();
      drawingManager.setDrawingMode(null);
      if (drawingRef.current.length) {
        drawingRef.current.forEach((p) => p.polygon.setMap(null));
        drawingRef.current = [];
        const bounds = map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const mapBounds = [
          [
            [sw.lng(), ne.lat()],
            [ne.lng(), ne.lat()],
            [ne.lng(), sw.lat()],
            [sw.lng(), sw.lat()],
            [sw.lng(), ne.lat()],
          ],
        ];
        updateBoundaryFilter({map: mapBounds});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDrawModeEnabled) {
      enableDrawing();
    } else {
      disableDrawing();
    }
  }, [disableDrawing, enableDrawing, isDrawModeEnabled]);

  useEffect(() => {
    if (toggledSchools.length) {
      try {
        const map = [];
        toggledSchools.forEach((s) => {
          if (!s.boundaryArray || s.boundaryArray === "[]") return;
          const boundary = JSON.parse(JSON.parse(s.boundaryArray)).Points;
          let points = [];
          for (let i = 0; i < boundary.length; i += 2) {
            points.push(boundary.slice(i, i + 2).reverse());
          }
          map.push([...points]);
          updateBoundaryFilter({ map });
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      if (bounds)
        updateBoundaryFilter({
          map: [
            [
              [bounds.sw.lng, bounds.ne.lat],
              [bounds.ne.lng, bounds.ne.lat],
              [bounds.ne.lng, bounds.sw.lat],
              [bounds.sw.lng, bounds.sw.lat],
              [bounds.sw.lng, bounds.ne.lat],
            ],
          ],
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggledSchools]);

  useEffect(() => {
    if (!showSchools && bounds) {
      updateBoundaryFilter({
        map: [
          [
            [bounds.sw.lng, bounds.ne.lat],
            [bounds.ne.lng, bounds.ne.lat],
            [bounds.ne.lng, bounds.sw.lat],
            [bounds.sw.lng, bounds.sw.lat],
            [bounds.sw.lng, bounds.ne.lat],
          ],
        ],
      });
    }

    if (!showSchools) {
      if (schoolPolygonRef.current) {
        schoolPolygonRef.current.forEach((p) => p.polygon.setMap(null));
        schoolPolygonRef.current = [];
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSchools]);

  useEffect(() => {
    if (!selectedSchool) {
      if (schoolPolygonRef.current && !toggledSchools.length)
        schoolPolygonRef.current.forEach((p) => p.polygon.setMap(null));
    } else {
      showSchoolBoundary();
    }
  }, [selectedSchool, showSchoolBoundary, toggledSchools]);

  useEffect(() => {
    const maps = mapsRef.current;
    const map = mapRef.current;
    if (favouritesOnly && maps && map) {
      const bounds = new maps.LatLngBounds();
      const shapeCoords = points.map((loc) => {
        return {
          lat: loc.geometry.coordinates[1],
          lng: loc.geometry.coordinates[0],
        };
      });
      for (let i = 0; i < shapeCoords.length; i++) {
        bounds.extend(shapeCoords[i]);
      }
      map.fitBounds(bounds);
    }
  }, [favouritesOnly, points]);

  const calculateSchoolClass = (s) => {
    if (!selectedSchool) return "schoolPinDeactive";
    if (selectedSchool.id === s.id) {
      if (toggledSchools.find((school) => school.id === s.id)) {
        return "schoolPinSelected";
      } else {
        return "schoolPinActive";
      }
    }
    return "schoolPinDeactive";
  };
  return (
    <div className={`${styles.map}`} ref={containerRef}>
      {favouritesOnly && (
        <span className={`${styles.btnContainer} ${styles.favouritesBtn}`}>
          <Button
            type="gray"
            title="showing favourites only"
            onClick={cancelFavouritesHandler}
            style={{ width: "25rem", top: isApp ? "8rem" : "1.5rem" }}>
            <CloseIcon />
          </Button>
        </span>
      )}

      {drawingBoundary && (
        <IonButton
          className={`${styles.removeOutlineButton} aecorn-button dark`}
          onClick={() => dispatch(toggleDrawMode())}
          size={isApp ? "small" : "default"}>
          Remove outline
          <IonIcon slot="end" icon={closeOutline} />
        </IonButton>
      )}

      {showSchools && toggledSchools.length > 0 && (
        <IonButton
          className={`${styles.removeOutlineButton} aecorn-button dark`}
          onClick={() => onClearSchoolsBoundaries()}
          size={isApp ? "small" : "default"}>
          Remove outline
          <IonIcon slot="end" icon={closeOutline} />
        </IonButton>
      )}

      <>
        <MapButtonsTop
          isApp={isApp}
          pageRef={pageRef}
          isDrawingDisabled={toggledSchools.length || selectedSchool}
          mapType={mapType}
          view={view}
          setView={setView}
          onChangeMapTyoe={mapTypeHandler}
        />
        <MapButtonsBottom
          isApp={isApp}
          pageRef={pageRef}
          isCentered={isCentered}
          mapType={mapType}
          onLocate={() => currentLocationHandler(false)}
        />
      </>

      {isApp && noResults && !loading && (
        <div className={styles.noResults}>
          <p>No results found. Try zooming out or changing your filters.</p>
          <IonIcon
            className={styles.close}
            icon={closeOutline}
            onClick={() => setNoResults(false)}
          />
        </div>
      )}

      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
          libraries: ["drawing"].join(","),
        }}
        resetBoundsOnResize={true}
        options={{
          fullscreenControl: false,
          panControl: false,
          zoomControl: false,
          minZoom: 8,
          maxZoom: 20,
          clickableIcons: false,
          styles: googleMapStyles,
          keyboardShortcuts: false,
        }}
        zoom={zoom}
        defaultZoom={10}
        defaultCenter={{
          lat: 43.642567,
          lng: -79.387054,
        }}
        center={center && { lat: center.lat, lng: center.lng }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => onMapReadyHandler(map, maps)}
        onChange={onChangeHandler}
        onClick={({ event }) => {
          if (!event.target.className) {
            onDeselectClusterAndListings();
          }
          if (!event.target.className) dispatch(updateActiveListing(null));
          if (
            typeof event.target.className !== "object" &&
            selectedSchool &&
            !event.target.className.includes("toggle") &&
            !event.target.className.includes("map-school")
          ) {
            onSelectSchool(null);
          }
        }}>
        {detailsListing &&
          renderSingleMarker(
            detailsListing,
            mapRef.current,
            selectedListings,
            onSelectListings,
            () => onSelectSchool(null)
          )}
        {currentLocation && (
          <Marker
            lat={currentLocation.latitude}
            lng={currentLocation.longitude}>
            <LocationPin
              className={`${styles.locationPin} ${
                mapType === "satellite" ? styles.lightLocationPin : ""
              }`}
            />
          </Marker>
        )}
        {showSchools &&
          zoom > 10 &&
          schools &&
          schools.slice(0, zoom <= 12 ? 50 : zoom <= 14 ? 35 : 25).map((s) => (
            <Marker
              key={s.id + Math.floor(Math.random() + (1000 - 1))}
              lat={s.latitude}
              lng={s.longitude}>
              <SchoolPin
                id={s.id}
                onClick={(e) => handleSchoolClick(e, s)}
                className={`${styles[calculateSchoolClass(s)]} ${
                  styles.schoolPin
                } ${isApp && styles.schoolAnimatedPin}`}
                style={{
                  fill: toggledSchools.find((school) => school.id === s.id)
                    ?.fillColor,
                  stroke:
                    toggledSchools.find((school) => school.id === s.id) &&
                    "white",
                }}
              />
            </Marker>
          ))}

        {selectedListings.length && !isApp && (
          <Marker
            zIndex={100000}
            lat={selectedListings[0].map.latitude}
            lng={selectedListings[0].map.longitude}>
            {renderCards()}
          </Marker>
        )}
        {selectedSchool && !isApp && (
          <Marker
            lat={selectedSchool.latitude}
            lng={selectedSchool.longitude}
            zIndex={10000}>
            {renderSchoolCard()}
          </Marker>
        )}

        {renderMapClusters({
          clusters,
          map: mapRef.current,
          activeListing,
          onSelectCluster,
          onSelectListings,
          selectedCluster,
          selectedListings,
          detailsListing,
          isSatellite: mapType === "satellite",
          setSchool: () => onSelectSchool(null),
          isApp,
          isPre: isPreConstruction,
        })}
      </GoogleMapReact>
    </div>
  );
};

export default withFilters(MapContainer);
