import { User } from "API";
import { createSelector } from "reselect";

const selectClient = (state: {
  client: {
    currentClient: User | null;
    error: string | null;
    loading: { action: string | null; key: string | null };
    isAdmin: boolean;
    isAgent: boolean;
  };
}) => state.client;

export const selectCurrentClient = createSelector(
  [selectClient],
  (client) => client.currentClient
);

export const selectCurrentClientId = createSelector([selectClient], (client) =>
  client.currentClient ? client.currentClient.id : null
);

export const selectClientError = createSelector(
  [selectClient],
  (client) => client.error
);

export const selectClientLoading = createSelector(
  [selectClient],
  (client) => client.loading
);
