import { API } from "aws-amplify";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { store } from "../redux/store";
import {
  createFavourite,
  deleteFavourite,
  updateFavourite,
} from "../graphql/mutations";
import { Listing } from "../models/listings/listing.model";
import {
  GetFavouriteByMlsNumberQuery,
  CreateFavouriteMutation,
  CreateFavouriteMutationVariables,
  Favourite,
  DeleteFavouriteMutation,
  UpdateFavouriteMutation,
  ListFavouritesQuery,
  UpdateFavouriteInput,
  OnCreateFavouriteSubscription,
  OnDeleteFavouriteSubscription,
  OnUpdateFavouriteSubscription,
} from "../API";
import { getFavouriteByMlsNumber, listFavourites } from "../graphql/queries";
import { onCreateFavourite as onNewFavorite, onDeleteFavourite as onRemoveFavorite, onUpdateFavourite as onModifyFavorite } from "graphql/subscriptions";

const getFavorites = async () => {
  let nextToken: string | null | undefined;

  const favorites: Favourite[] = [];

  do {
    const response = await API.graphql<GraphQLQuery<ListFavouritesQuery>>({
      query: listFavourites,
      variables: { nextToken },
    });

    if (response.data?.listFavourites) {
      favorites.push(...response.data.listFavourites.items as Favourite[]);
      nextToken = response.data.listFavourites.nextToken;
    }
  } while (nextToken);

  return favorites;
}

const createFavorite = async (listing: Listing): Promise<Favourite> => {
  let repliersFavoriteId;

  if (listing.status === "A") {
    const repliersClientId: number =
      store.getState().client.currentClient.repliersID;

    const body = {
      mlsNumber: listing.mlsNumber,
      clientId: repliersClientId,
    };

    const { data } = await API.post("ettieREST", "/favorites", {
      body,
    });

    repliersFavoriteId = data;
  }

  const variables: CreateFavouriteMutationVariables = {
    input: {
      listing: JSON.stringify(listing),
      mlsNumber: listing.mlsNumber,
      notification: false,
      repliersID: repliersFavoriteId,
    },
  };

  const res = await API.graphql<GraphQLQuery<CreateFavouriteMutation>>({
    query: createFavourite,
    variables,
  });
  return res.data?.createFavourite as Favourite;
};

const updateFavoriteListing = async (listing: Listing) => {
  const mlsNumber = listing.mlsNumber;
  const favorite = await getFavoriteByMlsNumber(mlsNumber);
  if (favorite) {
    const variables: UpdateFavouriteInput = {
      id: favorite.id,
      listing: JSON.stringify(listing),
      notification: listing.status === "U" ? false : favorite.notification,
    };

    const response = await API.graphql<GraphQLQuery<UpdateFavouriteMutation>>({
      query: updateFavourite,
      variables: { input: variables },
    });

    return response.data?.updateFavourite as Favourite;
  }
};

const removeFavorite = async (favorite: Favourite) => {
  if (favorite.repliersID) {
    await API.del("ettieREST", "/favorites", {
      body: {
        id: favorite.repliersID,
      },
    });
  }

  await API.graphql<GraphQLQuery<DeleteFavouriteMutation>>({
    query: deleteFavourite,
    variables: { input: { id: favorite.id } },
  });
};


const getFavoriteByMlsNumber = async (mlsNumber: string) => {
  const res = await API.graphql<GraphQLQuery<GetFavouriteByMlsNumberQuery>>({
    query: getFavouriteByMlsNumber,
    variables: { mlsNumber },
  });
  return res.data?.getFavouriteByMlsNumber?.items[0] as Favourite;
};

const toggleFavoriteNotification = async (favorite: Favourite) => {
  const res = await API.graphql<GraphQLQuery<UpdateFavouriteMutation>>({
    query: updateFavourite,
    variables: {
      input: {
        id: favorite.id,
        notification: !favorite.notification,
      },
    },
  });

  return res.data?.updateFavourite as Favourite;
};

const onCreateFavorite = async (callback: (data: Favourite) => void) => {
  const res = API.graphql<GraphQLSubscription<OnCreateFavouriteSubscription>>({
    query: onNewFavorite,
  });

  const subscription = res.subscribe(async (data) => {
    const favorite = data.value.data?.onCreateFavourite;
    if (favorite) {
      callback(favorite);
    }
  });

  return subscription;
}

const onUpdateFavorite = async (callback: (data: Favourite) => void) => {
  const res = API.graphql<GraphQLSubscription<OnUpdateFavouriteSubscription>>({
    query: onModifyFavorite,
  });

  const subscription = res.subscribe(async (data) => {
    const favorite = data.value.data?.onUpdateFavourite;
    if (favorite) {
      callback(favorite);
    }
  });

  return subscription;
}

const onDeleteFavorite = async (callback: (data: Favourite) => void) => {
  const res = API.graphql<GraphQLSubscription<OnDeleteFavouriteSubscription>>({
    query: onRemoveFavorite,
  });

  const subscription = res.subscribe(async (data) => {
    const favorite = data.value.data?.onDeleteFavourite;
    if (favorite) {
      callback(favorite);
    }
  });

  return subscription;
}


export const FavoritesService = {
  getFavorites,
  createFavorite,
  updateFavoriteListing,
  removeFavorite,
  getFavoriteByMlsNumber,
  toggleFavoriteNotification,
  onCreateFavorite,
  onUpdateFavorite,
  onDeleteFavorite,
};