import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import styles from "./map-searchbar.module.scss";
import { searchOutline } from "ionicons/icons";

const MapSearchBar = ({
  onOpenSearchModal,
} : {
  onOpenSearchModal: () => void;
}) => {

  return (
    <div className={styles.container}>
      <IonItem lines="none" onClick={onOpenSearchModal}>
        <IonIcon slot="start" icon={searchOutline} />
        <IonLabel>Search by location or MLS#</IonLabel>
      </IonItem>
    </div>
  );
};

export default MapSearchBar;
