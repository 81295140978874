import { IonButton, IonIcon } from "@ionic/react";
import { DeviceService } from "services/deviceInfoService";
import {
  CapacitorCalendar,
  PluginPermission,
} from "@ebarooni/capacitor-calendar";
import { calendarOutline } from "ionicons/icons";

const AddToCalendarButton = ({
  iconOnly = false,
  event,
}: {
  iconOnly?: boolean;
  event: {
    title: string;
    startDate: number;
    endDate: number;
    location?: string;
    notes?: string;
  };
}) => {
  const isNative = DeviceService.isApp();

  const convertEpochToICalFormat = (epoch: number) => {
    const date = new Date(epoch);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
  };

  const addToCalendar = async () => {
    if (isNative) {
      const permission = await CapacitorCalendar.requestPermission({
        alias: PluginPermission.WRITE_CALENDAR,
      });

      if (permission.result === "granted") {
        CapacitorCalendar.createEventWithPrompt({ ...event, isAllDay: false });
      }
    } else {
      const startDate = convertEpochToICalFormat(event.startDate);
      const endDate = convertEpochToICalFormat(event.endDate);

      const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
METHOD:PUBLISH
BEGIN:VTIMEZONE
TZID:America/Toronto
X-LIC-LOCATION:America/Toronto
BEGIN:DAYLIGHT
TZNAME:EDT
END:DAYLIGHT
BEGIN:STANDARD
TZNAME:EST
DTSTART:${startDate}
RRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
UID:${event.title}
DTSTAMP:${startDate}
DTSTART:${startDate}
DTEND:${endDate}
SUMMARY:${event.notes}
DESCRIPTION:${event.title}
LOCATION:${event.location}
SEQUENCE:0
STATUS:CONFIRMED
END:VEVENT
END:VCALENDAR`;

      const blob = new Blob([icsContent], {
        type: "text/calendar;charset=utf-8",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "event.ics";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <IonButton className="aecorn-button clear-dark" onClick={addToCalendar} size={iconOnly ? "small" : "default"}>
      {iconOnly ? <IonIcon icon={calendarOutline} slot="icon-only" /> : "Add to calendar"}
    </IonButton>
  );
};

export default AddToCalendarButton;
