import { IonButton, IonDatetime, IonIcon } from "@ionic/react";
import styles from "../modals.module.scss";
import contentStyles from "./tour-creation-modal.module.scss";
import Modal from "components/ui/modal/modal.component";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Checkmark from "assets/svg/icons/checkmark.svg";
import { closeOutline } from "ionicons/icons";

const TourCreationModal = ({
  title,
  isOpen,
  onDismiss,
  onNavigate,
}: {
  title: string;
  isOpen: boolean;
  onDismiss: () => void;
  onNavigate: () => void;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Modal
      canDismiss={false}
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      breakpoints={isMobile ? [0, 1] : undefined}
      initialBreakpoint={isMobile ? 1 : undefined}>
      <div className={styles.sheetContent}>
        <div className={styles.modalHeader}>
          <MediaQuery minWidth={768}>
            <IonIcon icon={closeOutline} onClick={onDismiss} />
          </MediaQuery>
        </div>
        <div className={contentStyles.container}>
          <IonIcon icon={Checkmark} />
          <div className={contentStyles.title}>
            {title} has been successfully created.
          </div>
          <div className={contentStyles.description}>
            You can edit and view the tour by clicking on view button on tour
            card.
          </div>
          <div className={contentStyles.actions}>
            <IonButton className="aecorn-button" onClick={onNavigate}>
              Go to the tour
            </IonButton>
            <IonButton
              className="aecorn-button clear border"
              onClick={onDismiss}>
              Done
            </IonButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TourCreationModal;
