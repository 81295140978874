import { IonPage } from "@ionic/react";
import { useSelector } from "react-redux";
import {
  selectIsAgent,
  selectIsAuthenticated,
} from "redux/user/user.selectors";
import { Redirect } from "react-router";
import AgentTour from "../AgentTour/agent-tour.page";
import ClientTour from "../ClientTour/client-tour.page";

const TourDetails = ({tourId} : {tourId: string}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAgent = useSelector(selectIsAgent);

  return (
    <>
      {!isAuthenticated && <Redirect to="/" />}
      <IonPage>{isAgent ? <AgentTour tourId={tourId} /> : <ClientTour tourId={tourId} />}</IonPage>
    </>
  );
};

export default TourDetails;