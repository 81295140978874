import { Listing } from "models/listings/listing.model";
import styles from "./listing-information.module.scss";
import { IonSegment, IonSegmentButton } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import ListingEssentials from "./segments/ListingEssentials/listing-essentials.component";
import ListingDetails from "./segments/ListingDetails/listing-details.componenet";
import ListingRoomsDetails from "./segments/ListingRoomsDetails/listing-rooms-details.component";
import ListingHistory from "./segments/ListingHistory/listing-history.component";
import ListingSchools from "./segments/ListingSchools/listing-schools.componenet";
import { School } from "API";
import { SchoolsService } from "services/schoolsService";
import ListingMarketData from "./segments/ListingMarketData/listing-market-data.component";
import { ListingService } from "services/listingService";
import { ListingStatistics } from "models/listings/listing-statistics.model";
import ListingCalculators from "./segments/ListingCalculators/listing-calculators.component";
import { selectIsAuthenticated } from "redux/user/user.selectors";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import { useSelector } from "react-redux";

const CONTENT_MAX_HEIGHT = 512;

type Segment =
  | "essentials"
  | "details"
  | "rooms"
  | "history"
  | "schools"
  | "market"
  | "calculators";

const ListingInformation = ({ listing, estimatedPrice }: { listing: Listing, estimatedPrice: number | null }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const restrictedAccess = listing.status === ListingStatus.Unavailable && !isAuthenticated;
  
  const initialRender = useRef(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const segmentRef = useRef<HTMLIonSegmentElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [isContentExpanded, setIsContentExpanded] = useState<boolean>(true);
  const [selectedSegment, setSelectedSegment] = useState<Segment>("essentials");
  const [schoolsLoading, setSchoolsLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<School[]>([]);
  const [marketDataLoading, setMarketDataLoading] = useState<boolean>(true);
  const [marketData, setMarketData] = useState<ListingStatistics | null>(null);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        setSchoolsLoading(true);
        const schools = await SchoolsService.findListingNearbySchools(listing);
        setSchools(schools || []);
      } catch (error) {
        console.error(error);
      } finally {
        setSchoolsLoading(false);
      }
    };

    fetchSchools();
  }, []);

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        setMarketDataLoading(true);
        const marketData = await ListingService.getListingStatistics(listing);
        setMarketData(marketData);
      } catch (error) {
        console.error(error);
      } finally {
        setMarketDataLoading(false);
      }
    };

    fetchMarketData();
  }, []);

  const checkOverflow = () => {
    if (contentRef.current) {
      if (contentRef.current.scrollHeight > CONTENT_MAX_HEIGHT) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkOverflow);

    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    checkOverflow();
    containerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });

    // if (selectedSegment === "calculators") {
    //   setIsOverflowing(false);
    //   setIsContentExpanded(true);
    // } else {
    //   setIsContentExpanded(false);
    // }
  }, [selectedSegment]);

  const handleSegmentScroll = () => {
    const scrollLeft = segmentRef.current?.scrollLeft || 0;

    const firstIonSegmentButton = segmentRef.current?.querySelector(
      "ion-segment-button"
    ) as HTMLElement;

    const firstIonSegmentButtonWidth = firstIonSegmentButton.clientWidth;

    if (scrollLeft > firstIonSegmentButtonWidth) {
      segmentRef.current?.classList.add(styles.noMargin);
    } else if (scrollLeft === 0) {
      segmentRef.current?.classList.remove(styles.noMargin);
    }
  };

  return (
    <div ref={containerRef} className={styles.container}>
      <span className={styles.title}>Property Information</span>
      <IonSegment
        ref={segmentRef}
        value={selectedSegment}
        onIonChange={(event) =>
          setSelectedSegment(event.detail.value as Segment)
        }
        mode="ios"
        onScroll={handleSegmentScroll}
        scrollable>
        <IonSegmentButton value="essentials">Essential</IonSegmentButton>
        <IonSegmentButton value="details">Details</IonSegmentButton>
        <IonSegmentButton value="rooms">Rooms details</IonSegmentButton>
        <IonSegmentButton value="history">Listing history</IonSegmentButton>
        <IonSegmentButton value="schools">Schools</IonSegmentButton>
        <IonSegmentButton value="market">Market data</IonSegmentButton>
        <IonSegmentButton value="calculators">Calculators</IonSegmentButton>
      </IonSegment>
      <div
        ref={contentRef}
        className={`${styles.segmentContent} ${
          isContentExpanded && styles.expanded
        }`}>
        {selectedSegment === "essentials" && (
          <ListingEssentials listing={listing} restricted={restrictedAccess} />
        )}
        {selectedSegment === "details" && <ListingDetails listing={listing} estimatedPrice={estimatedPrice} restricted={restrictedAccess} />}
        {selectedSegment === "rooms" && (
          <ListingRoomsDetails listing={listing} />
        )}
        {selectedSegment === "history" && <ListingHistory listing={listing} />}
        {selectedSegment === "schools" && (
          <ListingSchools
            loading={schoolsLoading}
            listing={listing}
            schools={schools}
          />
        )}
        {selectedSegment === "market" && (
          <ListingMarketData
            listing={listing}
            data={marketData}
            loading={marketDataLoading}
          />
        )}
        {selectedSegment === "calculators" && (
          <ListingCalculators listing={listing} />
        )}
        {/* {isOverflowing && (
          <>
            <div className={styles.fade}></div>
            <div
              className={styles.showMoreButton}
              onClick={() => setIsContentExpanded((expanded) => !expanded)}>
              <IonIcon
                icon={isContentExpanded ? chevronUpOutline : chevronDownOutline}
              />
              <span>Show {isContentExpanded ? "less" : "more"}</span>
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};

export default ListingInformation;