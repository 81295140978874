import { Auth } from "aws-amplify";
import { store } from "redux/store";
import UserActionTypes from "redux/user/user.types";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";

const getToken = async () => {
  const user = await getCognitoUser();
  return user.signInUserSession.accessToken.jwtToken;
};

const getCognitoUser = async () => {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  return user;
};

const isAuthenticated = () => {
  const user = store.getState().user.currentUser;
  if (user) {
    return true;
  } else {
    return false;
  }
};

const register = async ({
  username,
  password,
  given_name,
  family_name,
  phone_number,
  subscribed,
}: {
  username: string;
  password: string;
  given_name: string;
  family_name: string;
  phone_number?: string;
  subscribed: boolean;
}) => {
  return await Auth.signUp({
    username,
    password,
    attributes: {
      given_name,
      family_name,
      phone_number,
      "custom:subscribed": subscribed ? "1" : "0",
    },
  });
};

const confirmRegistration = async (username: string, code: string) => {
  return await Auth.confirmSignUp(username, code);
};

const verifyPhoneNumber = async () => {
  try {
    await Auth.verifyCurrentUserAttribute("phone_number");
  } catch(err) {
    console.error(err);
  }
};

const login = async (username: string, password: string) => {
  const user = await Auth.signIn({ username, password });
  return user;
};

const federatedLogin = async (provider: CognitoHostedUIIdentityProvider) => {
  Auth.federatedSignIn({ provider});
  // return await Auth.currentAuthenticatedUser();
};

const logout = async () => {
  await Auth.currentCredentials();
  await Auth.signOut();
  await Auth.currentCredentials();
  store.dispatch({ type: UserActionTypes.SIGN_OUT });
};

const updatePassword = async (newPassword: string, oldPassword: string) => {
  const user = await getCognitoUser();
  return await Auth.changePassword(user, oldPassword, newPassword);
};

const resetPassword = async (username: string) => {
  const result = await Auth.forgotPassword(username);
  return result;
};

const confirmPassword = async (
  username: string,
  code: string,
  newPassword: string
) => {
  const result = await Auth.forgotPasswordSubmit(username, code, newPassword);
  return result;
};

const resendConfirmationCode = async (username: string) => {
  const result = await Auth.resendSignUp(username);
  return result;
};

const isCurrentUserAgent = async (): Promise<boolean> => {
  try {
    const user = await getCognitoUser();
    return user.signInUserSession.accessToken.payload[
      "cognito:groups"
    ].includes("Agents");
  } catch (error) {
    return false;
  }
};

const updateUserAttributes = async (attributes: { [key: string]: string }) => {
  const user = await getCognitoUser();
  await Auth.updateUserAttributes(user, attributes);
  return await getCognitoUser();
};

const updateUserAttribute = async (attribute: string, value: string) => {
  const user = await getCognitoUser();
  await Auth.updateUserAttributes(user, {
    [attribute]: value,
  });
  return await getCognitoUser();
};

const deleteAccount = async () => {
  await Auth.deleteUser();
};

export const AuthenticationService = {
  getToken,
  getCognitoUser,
  isAuthenticated,
  register,
  confirmRegistration,
  verifyPhoneNumber,
  login,
  federatedLogin,
  logout,
  updatePassword,
  resetPassword,
  confirmPassword,
  resendConfirmationCode,
  isCurrentUserAgent,
  updateUserAttributes,
  updateUserAttribute,
  deleteAccount,
};
