import { TourItem } from "API";
import styles from "./tour-stop-time.module.scss";
import moment from "moment";
import {
  IonIcon,
  IonLabel,
  useIonToast,
} from "@ionic/react";
import EditIcon from "assets/svg/edit.svg";
import { useState } from "react";
import MediaQuery from "react-responsive";
import { TourItemService } from "services/tourItemService";
import TourTimeModal from "components/shared/Modals/TourTimeModal/tour-time-modal.component";

const TourStopTime = ({ tourItem, hidden }: { tourItem: TourItem, hidden: boolean }) => {
  const [presentToast] = useIonToast();
  const [isStartTimeModalOpen, setIsStartTimeModalOpen] = useState(false);
  const [isEndTimeModalOpen, setIsEndTimeModalOpen] = useState(false);
  const tempStartTime = { current: tourItem.startTime };
  const tempEndTime = { current: tourItem.endTime };

  const handleSaveStartTime = async () => {
    try {
      setIsStartTimeModalOpen(false);
      if (
        moment(tempStartTime.current, "HH:mm").isAfter(
          moment(tourItem.endTime, "HH:mm")
        )
      ) {
        presentToast({
          message: "Start time cannot be after end time",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
        return;
      }
      const updatedTourItem = await TourItemService.updateTourItem({
        id: tourItem.id,
        startTime: tempStartTime.current,
      });
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while saving the start time",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const handleSaveEndTime = async () => {
    try {
      setIsEndTimeModalOpen(false);
      if (
        moment(tourItem.startTime, "HH:mm").isAfter(
          moment(tempEndTime.current, "HH:mm")
        )
      ) {
        presentToast({
          message: "End time cannot be before start time",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
        return;
      }
      const updatedTourItem = await TourItemService.updateTourItem({
        id: tourItem.id,
        endTime: tempEndTime.current,
      });
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while saving the end time",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  return (
    <div className={styles.container} style={{display: hidden ? "none" : "block"}}>
      <TourTimeModal
      title="Start time"
      isOpen={isStartTimeModalOpen}
      onDismiss={() => setIsStartTimeModalOpen(false)}
      selectedTime={tourItem.startTime || ""}
      onUpdateTime={(time) => (tempStartTime.current = time)}
      onSave={handleSaveStartTime}
      />
      <TourTimeModal
      title="End time"
      isOpen={isEndTimeModalOpen}
      onDismiss={() => setIsEndTimeModalOpen(false)}
      selectedTime={tourItem.endTime || ""}
      onUpdateTime={(time) => (tempEndTime.current = time)}
      onSave={handleSaveEndTime}
      />
      <MediaQuery minWidth={768}>
        <IonLabel className={styles.label}>Time</IonLabel>
      </MediaQuery>
      <div className={styles.row}>
        <div className={styles.card}>
          <div>
            Start time:{" "}
            {tourItem.startTime
              ? moment(tourItem.startTime, "HH:mm").format("hh:mm A")
              : ""}
          </div>
          <IonIcon
            icon={EditIcon}
            onClick={() => setIsStartTimeModalOpen(true)}
          />
        </div>
        <div className={styles.card}>
          <div>
            End time:{" "}
            {tourItem.endTime
              ? moment(tourItem.endTime, "HH:mm").format("hh:mm A")
              : ""}
          </div>
          <IonIcon
            icon={EditIcon}
            onClick={() => setIsEndTimeModalOpen(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default TourStopTime;
