import { TourItem } from "API";
import styles from "./tour-stop-info.module.scss";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonTextarea,
  useIonToast,
} from "@ionic/react";
import EditIcon from "assets/svg/edit.svg";
import MediaQuery from "react-responsive";
import { useRef, useState } from "react";
import { TourItemService } from "services/tourItemService";
import Modal from "components/ui/modal/modal.component";
import { closeOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { selectIsAgent } from "redux/user/user.selectors";

const TourStopInfo = ({
  tourItem,
  hidden,
  isClient,
}: {
  tourItem: TourItem;
  hidden: boolean;
  isClient?: boolean;
}) => {
  const isUserAgent = useSelector(selectIsAgent);

  const [presentToast] = useIonToast();
  const [isShowingNoteModalOpen, setIsShowingNoteModalOpen] = useState(false);
  const [isPrivateNoteModalOpen, setIsPrivateNoteModalOpen] = useState(false);
  const [isEntryInfoModalOpen, setIsEntryInfoModalOpen] = useState(false);

  const tempShowingNote = useRef<string>(tourItem.showingNote || "");
  const tempPrivateNote = useRef<string>(tourItem.privateNote || "");
  const tempEntryInfo = useRef<string>(tourItem.entryInfo || "");

  const handleSaveShowingNote = async () => {
    try {
      setIsShowingNoteModalOpen(false);
      await TourItemService.updateTourItem({
        id: tourItem.id,
        showingNote: tempShowingNote.current,
      });
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while saving the start time",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const handleSavePrivateNote = async () => {
    try {
      setIsPrivateNoteModalOpen(false);

      await TourItemService.updateTourItem({
        id: tourItem.id,
        privateNote: tempPrivateNote.current,
      });
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while saving the start time",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const handleSaveEntryInfo = async () => {
    try {
      setIsEntryInfoModalOpen(false);

      await TourItemService.updateTourItem({
        id: tourItem.id,
        entryInfo: tempEntryInfo.current,
      });
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while saving the start time",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const ShowingNoteModalContent = () => (
    <div className={styles.sheetContent}>
      <div className={styles.modalHeader}>
        Showing notes
        <MediaQuery minWidth={768}>
          <IonIcon
            icon={closeOutline}
            onClick={() => setIsShowingNoteModalOpen(false)}
          />
        </MediaQuery>
      </div>
      <IonTextarea
        className="aecorn-input dark"
        value={tempShowingNote.current}
        onIonChange={(e) => (tempShowingNote.current = e.detail.value!)}
      />
      <IonButton className="aecorn-button" onClick={handleSaveShowingNote}>
        Save changes
      </IonButton>
    </div>
  );

  const PrivateNoteModalContent = () => (
    <div className={styles.sheetContent}>
      <div className={styles.modalHeader}>
        Private notes
        <MediaQuery minWidth={768}>
          <IonIcon
            icon={closeOutline}
            onClick={() => setIsPrivateNoteModalOpen(false)}
          />
        </MediaQuery>
      </div>
      <IonTextarea
        className="aecorn-input dark"
        value={tempPrivateNote.current}
        onIonChange={(e) => (tempPrivateNote.current = e.detail.value!)}
      />
      <IonButton className="aecorn-button" onClick={handleSavePrivateNote}>
        Save changes
      </IonButton>
    </div>
  );

  const EntryInfoModalContent = () => (
    <div className={styles.sheetContent}>
      <div className={styles.modalHeader}>
        Lockbox and entry info
        <MediaQuery minWidth={768}>
          <IonIcon
            icon={closeOutline}
            onClick={() => setIsEntryInfoModalOpen(false)}
          />
        </MediaQuery>
      </div>
      <IonTextarea
        className="aecorn-input dark"
        value={tempEntryInfo.current}
        onIonChange={(e) => (tempEntryInfo.current = e.detail.value!)}
      />
      <IonButton className="aecorn-button" onClick={handleSaveEntryInfo}>
        Save changes
      </IonButton>
    </div>
  );

  return (
    <div
      className={`${styles.container} ${!isClient && styles.agentContainer}`}
      style={{ display: hidden ? "none" : "flex" }}>
      {!isClient && (
        <>
          <MediaQuery minWidth={768}>
            <Modal
              isOpen={isShowingNoteModalOpen}
              onDidDismiss={() => setIsShowingNoteModalOpen(false)}>
              <ShowingNoteModalContent />
            </Modal>
            <Modal
              isOpen={isPrivateNoteModalOpen}
              onDidDismiss={() => setIsPrivateNoteModalOpen(false)}>
              <PrivateNoteModalContent />
            </Modal>
            <Modal
              isOpen={isEntryInfoModalOpen}
              onDidDismiss={() => setIsEntryInfoModalOpen(false)}>
              <EntryInfoModalContent />
            </Modal>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <Modal
              isOpen={isShowingNoteModalOpen}
              onDidDismiss={() => setIsShowingNoteModalOpen(false)}
              breakpoints={[0, 1]}
              initialBreakpoint={1}>
              <ShowingNoteModalContent />
            </Modal>
            <Modal
              isOpen={isPrivateNoteModalOpen}
              onDidDismiss={() => setIsPrivateNoteModalOpen(false)}
              breakpoints={[0, 1]}
              initialBreakpoint={1}>
              <PrivateNoteModalContent />
            </Modal>
            <Modal
              isOpen={isEntryInfoModalOpen}
              onDidDismiss={() => setIsEntryInfoModalOpen(false)}
              breakpoints={[0, 1]}
              initialBreakpoint={1}>
              <EntryInfoModalContent />
            </Modal>
          </MediaQuery>
        </>
      )}
      {isUserAgent ? (
        <div className={styles.cards}>
          <section>
            <IonLabel className={styles.label}>For client</IonLabel>
            <div className={styles.card}>
              <div>
                Showing notes:
                <br />
                {tourItem.showingNote}
              </div>
              <IonIcon
                icon={EditIcon}
                onClick={() => setIsShowingNoteModalOpen(true)}
              />
            </div>
          </section>
          <section>
            <IonLabel className={styles.label}>For agent</IonLabel>
            <div className={styles.card}>
              <div>
                Private notes:
                <br />
                {tourItem.privateNote}
              </div>
              <IonIcon
                icon={EditIcon}
                onClick={() => setIsPrivateNoteModalOpen(true)}
              />
            </div>
            <div className={styles.card}>
              <div>
                Lockbox and entry info:
                <br />
                {tourItem.entryInfo}
              </div>
              <IonIcon
                icon={EditIcon}
                onClick={() => setIsEntryInfoModalOpen(true)}
              />
            </div>
          </section>
        </div>
      ) : (
        <div className={styles.clientNotes}>
          <div>{tourItem.showingNote}</div>
        </div>
      )}
    </div>
  );
};

export default TourStopInfo;
