import { createSelector } from "reselect";
import { store } from "../store";

const selectMap = (state) => state.map;

export const selectMapClusters = createSelector([selectMap], (map) => map.clusters);

export const selectMapListings = createSelector([selectMap], (map) => {
  const t = store.getState().filters.value.type;

  if (
    t &&
    t?.toLowerCase() === "pre-construction" &&
    map.listings &&
    !map.listings[0]
  ) {
    return [];
  }
  if (
    t?.toLowerCase() !== "pre-construction" &&
    map.listings &&
    !map.listings[0]
  ) {
    return [];
  }
  return map.listings || [];
});

export const selectMapSelectedListings = createSelector([selectMap], (map) => map.selectedListings);

export const selectMapSelectedCluster = createSelector([selectMap], (map) => map.selectedCluster);

export const selectMapLoading = createSelector(
  [selectMap],
  (map) => map.loading
);

export const selectMapError = createSelector([selectMap], (map) => map.error);

export const selectMapSelectedLocation = createSelector(
  [selectMap],
  (map) => map.selectedLocation
);

export const selectMapRef = createSelector([selectMap], (map) => map.ref);

export const selectGoogleMapsRef = createSelector([selectMap], (map) => map.googleMapsRef);

export const selectMapLocations = createSelector(
  [selectMap],
  (map) => map.locations
);

export const selectMapBoundaries = createSelector(
  [selectMap],
  (map) => map.boundary
);

export const selectMapFavourites = createSelector(
  [selectMap],
  (map) => map.favouritesOnly
);

export const selectActiveListing = createSelector(
  [selectMap],
  (map) => map.activeListing
);

export const selectDetailsListing = createSelector(
  [selectMap],
  (map) => map.detailsListing
);

export const selectDrawingBoundary = createSelector(
  [selectMap],
  (map) => map.drawingBoundary
);

export const selectDrawMode = createSelector([selectMap], (map) => map.drawMode);