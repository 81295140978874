import { useMediaQuery } from "react-responsive";
import { Redirect, Route } from "react-router";
import React from "react/jsx-runtime";

interface PublicRouteProps {
  component: React.ComponentType<any>;
  exact?: boolean;
  path: string;
  tab?: string;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  component: Component,
  path,
  tab,
  ...rest
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      <Route
        path={path}
        {...rest}
        render={(props) =>
          tab && isMobile ? (
            <Redirect
              to={{
                pathname: `/tabs/${tab}`,
                search: location.search,
              }}
            />
          ) : (
            <Component {...props} {...props.match.params} />
          )
        }></Route>
    </>
  );
};

export default PublicRoute;
