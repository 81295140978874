/* eslint-disable eqeqeq */
import moment from "moment";
import { LaunchNavigator } from "@ionic-native/launch-navigator";
import { getPlatforms } from "@ionic/react";
import _ from "lodash";
import millify from "millify";
import { allTypes } from "../app/pages/Filters/data/filters.data";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Clipboard } from "@ionic-native/clipboard";
import classifyPoint from "robust-point-in-polygon";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { store } from "../redux/store";
import {PushNotifications} from '@capacitor/push-notifications';
import { updateClient } from "../amplify/graphql.utils";
import { App } from "@capacitor/app";

export const numberWithCommas = (number, addCurrencySign) => {
  const n = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .split(".")[0];

  return addCurrencySign ? `$${n}` : n;
};

export const getGreetingTime = () => {
  const time = moment().hour();
  if (time >= 12 && time <= 17) return "Good afternoon";
  else if (time >= 17) return "Good evening";
  else return "Good morning";
};

export const buildAddress = (
  address,
  accurate = false,
  isNavigation = false
) => {
  let fullAddress = "";
  if (address.unitNumber && !accurate && !isNavigation)
    fullAddress += `${address.unitNumber} - `;
  if (address.streetNumber) fullAddress += `${address.streetNumber} `;
  if (address.streetName) fullAddress += `${address.streetName} `;
  if (address.streetSuffix) fullAddress += `${address.streetSuffix} `;
  if (address.streetDirection) fullAddress += `${address.streetDirection} `;
  if (accurate && address.zip) fullAddress += `${address.zip}`;

  return fullAddress.trim();
};

export const buildPropertyInfo = (property, type, style) => {
  property =
    property === "CondoProperty"
      ? "Condo"
      : property === "ResidentialProperty"
      ? "Freehold"
      : property === "CommercialProperty" && "Commercial property";
  return property + ", " + type + (style !== null ? ", " + style : "");
};

export const buildParkingInfo = (garage, parking) => {
  if (
    (garage === "" || garage === null || parseInt(garage) === 0) &&
    (parking === "" || parking === null || parseInt(parking) === 0)
  ) {
    return "0 parking";
  } else if (parseInt(garage) !== 0) {
    return String(parseInt(garage)) + " garage";
  } else if (
    (garage === "" || garage === null || parseInt(garage) === 0) &&
    parseInt(parking) !== 0
  ) {
    return String(parseInt(parking)) + " parking";
  }
};
export const getHighlightedText = (text, highlight, styles) => {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  const spans = parts
    .filter((part) => part.length)
    .map((part, i) => {
      return (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? {
                  whiteSpace: "pre",
                  fontWeight: "bold",
                  margin: 0,
                  ...styles,
                }
              : { whiteSpace: "pre", margin: 0, ...styles }
          }
        >
          {part}
        </span>
      );
    });

  return spans;
};

export const getHighlightedLength = (text, highlight) => {
  let match;
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  parts.forEach((p) => {
    if (p.toLowerCase() === highlight.toLowerCase()) match = p;
  });

  return match ? match.length : 0;
};

export const getImageOrFallback = (path, fallback) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = path;
    img.onload = () => resolve(path);
    img.onerror = () => resolve(fallback);
  });
};

export const isApp = () => {
  const APP_PLATFORMS = [
    "ios",
    "android",
    "capacitor",
    "tablet",
    "phablet",
    "mobile",
    "mobileweb",
  ];
  const platforms = getPlatforms();
  const presents = _.intersectionWith(APP_PLATFORMS, platforms, _.isEqual);

  if (platforms.includes("capacitor")) {return true;}
  if (!presents.length) {return false;}
  if (window.innerWidth > 960) {return false;}
  return presents.length > 0;
};

export const isCapacitor = () => {
  const platforms = getPlatforms();
  return platforms.includes("capacitor");
};

export const isAndroid = () => {
  const platforms = getPlatforms();
  return platforms.includes("android");
};

export const formatPrice = (price) => {
  return `$${numberWithCommas(price)}`;
};

export const renderPrice = (currency, soldPrice, rates, listPrice) => {
  return currency
    ? soldPrice > 0
      ? `${renderCurrencySign(currency, false)}${millify(
          (+soldPrice / rates.CAD) * rates[currency]
        )}`
      : `${renderCurrencySign(currency, false)}${millify(
          (+listPrice / rates.CAD) * rates[currency]
        )}`
    : soldPrice > 0
    ? `$${numberWithCommas(soldPrice)}`
    : `$${numberWithCommas(listPrice)}`;
};

export const renderEstimate = (currency, estimate, rates) => {
  return currency
    ? `${renderCurrencySign(currency, false)}${millify(
        (+estimate / rates.CAD) * rates[currency]
      )}`
    : `$${numberWithCommas(estimate)}`;
};

export const getPricesPercentageDiff = (price1, price2) => {
  return parseFloat(((price2 - price1) / price1) * 100).toFixed(1);
};

export const getTagTitle = (lastStatus, type) => {
  switch (lastStatus) {
    case "New":
      return type.toLowerCase();
    case "Sus":
      return "suspended";
    case "Ter":
      return "terminated";
    case "Dft":
      return "deal fell through";
    case "Pc":
      return type.toLowerCase();
    case "Ext":
      return "extended";
    case "Exp":
      return "expired";
    case "Sc":
      return "sold conditional";
    case "Lc":
      return "leased conditional";
    case "Sld":
      return "sold";
    case "Lsd":
      return "leased";
    default:
      return type.toLowerCase();
  }
};

export const showOnMarket = (lastStatus) => {
  if (["Sld", "Lsd", "Lc", "Sc", "Dft"].includes(lastStatus)) return true;
  return false;
};

export const getPropertyType = (type) => {
  const t = allTypes.find((t) => t.value === type);

  if (t) {
    return t.name;
  } else return type;
};

export const diffDate = (status, listDate, soldDate, isActiveComparables) => {
  if (isActiveComparables || !status) status = "A";
  if (!isActiveComparables || !status) status = "U";

  const now = new Date();
  const date = status === "A" ? new Date(listDate) : new Date(soldDate);
  const diff = Math.floor(
    (Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) -
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) /
      (1000 * 60 * 60 * 24)
  );
  let onMarket;
  if (status === "U") {
    onMarket = moment(new Date(soldDate)).diff(new Date(listDate), "days");
  }

  let label = diff === 0 ? "0" : diff > 1 ? `${diff} days` : `${diff} day`;
  if (onMarket >= 0) {
    onMarket = `${onMarket}d on market`;
  }

  // const l = moment(status === "A" ? listDate : soldDate).fromNow(true);
  const l = Math.round(
    moment.duration(moment().startOf("day") - moment(listDate)).asDays()
  );
  // const m =
  //   status === "U" &&
  //   Math.round(moment.duration(moment(listDate) - moment(soldDate)).asDays());

  const m = status === "U" && moment(soldDate).diff(moment(listDate), "days");
  const soldDateVal =
    status === "U" && moment(new Date()).diff(soldDate, "days");

  const res = {
    soldDate: moment(soldDate).format("D MMM YYYY"),
    soldDateNumber: !soldDateVal
      ? "today"
      : `${soldDateVal} ${soldDateVal > 1 ? "days" : "day"}`,
    listedValue: !l ? "today" : l > 1 ? `${l} days` : `${l} day`,
    soldValue: !m ? "today" : m > 1 ? `${m} days` : `${m} day`,
    listedNumber: !l ? null : l,
    soldNumber: !m ? null : m,
    label: !l
      ? status === "A"
        ? "listed today"
        : "listed today"
      : l > 1
      ? l > 999
        ? `listed ${l}d ago`
        : `listed ${l} days ago`
      : "listed yesterday",
    onMarket: !m
      ? status === "U"
        ? "less than a day on market"
        : null
      : m > 1
      ? m > 999
        ? `${m}d on market`
        : `${m} days on market`
      : "1 day on market",
  };

  return res;
};

export const hideTabs = () => {
  const tabsEl = document.querySelector("ion-tab-bar");
  if (tabsEl) {
    tabsEl.hidden = true;
  }
};

export const showTabs = () => {
  const tabsEl = document.querySelector("ion-tab-bar");
  if (tabsEl) {
    tabsEl.hidden = false;
  }
};

export const capitalize = (text) => {
  if (!text || typeof text !== "string") return;
  return text.charAt(0).toUpperCase() + text.substring(1);
};

export const handleNavigation = (address) => {
  window.open(
    `https://google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${address}`,
    "_blank"
  );
};

export const handleAppNavigation = ({
  map: { latitude, longitude },
  address,
  byAddress = false,
}) => {
  const platforms = getPlatforms();
  if (platforms.find((p) => p === "capacitor")) {
    let options;
    LaunchNavigator.appSelection.userPrompted.get((prompted) => {
      if (!prompted) {
        LaunchNavigator.appSelection.userChoice.exists((exists) => {
          if (exists) {
            LaunchNavigator.appSelection.userChoice.get((app) => {
              options = { app };
            });
            LaunchNavigator.appSelection.userPrompted.set();
          } else {
            LaunchNavigator.navigate(
              byAddress
                ? buildAddress(address, false, true)
                : [latitude, longitude],
              options
            );
            LaunchNavigator.appSelection.userChoice.set(
              LaunchNavigator.APP.USER_SELECT
            );
          }
        });
      } else {
        LaunchNavigator.isAppAvailable(
          LaunchNavigator.APP.GOOGLE_MAPS,
          (isAvailable) => {
            if (isAvailable) {
              options = { app: LaunchNavigator.APP.GOOGLE_MAPS };
              LaunchNavigator.navigate(
                byAddress
                  ? buildAddress(address, false, true)
                  : [latitude, longitude],
                options
              );
            } else {
              LaunchNavigator.navigate(
                byAddress
                  ? buildAddress(address, false, true)
                  : [latitude, longitude],
                options
              );
              LaunchNavigator.appSelection.userChoice.set(
                LaunchNavigator.APP.USER_SELECT
              );
            }
          }
        );
      }
    });
  } else {
    window.open(
      `https://google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${
        byAddress
          ? buildAddress(address, false, true)
          : latitude + "," + longitude
      }&avoid=tolls|highways&dir_action=navigate`,
      "_blank"
    );
  }
};

// export const handleAppNavigation = ({ map: { latitude, longitude } }) => {
//   const platforms = getPlatforms();
//   const options = { app: LaunchNavigator.APP.USER_SELECT };

//   if (platforms.find((p) => p === "capacitor")) {
//     LaunchNavigator.navigate([latitude, longitude], options);
//   } else {
//     window.open(
//       `https://google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${latitude},${longitude}`,
//       "_blank"
//     );
//   }
// };

export const handleAppAddressNavigation = (address) => {
  const platforms = getPlatforms();

  if (platforms.find((p) => p === "capacitor")) {
    LaunchNavigator.navigate(address);
  } else {
    window.open(
      `https://google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${address}`,
      "_blank"
    );
  }
};

export const hapticsImpact = async (level = ImpactStyle.Light) => {
  try {
    await Haptics.impact(level);
  } catch (err) {
    console.log(err);
  }
};

export const copyToClipboard = async (text) => {
  try {
    await Clipboard.copy(text);
    return true;
  } catch (err) {
    try {
      navigator.clipboard.writeText(text);
      return true;
    } catch (err) {
      return false;
    }
  }
};

export const isBeforeOrAfter = (date) => {
  if (!date) return;
  const diff = moment().diff(date, "days");

  return diff > 0 ? -1 : diff === 0 ? 0 : 1;
};

export const getMainClient = (data) => {
  const mainClient = data.items.find((u) => u.role === "client");

  if (mainClient) {
    return {
      client: mainClient,
      name: `${mainClient.givenName} ${mainClient.familyName}`,
    };
  } else {
    return { name: "---" };
  }
};

function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export const getBoundingCoords = (pLatitude, pLongitude, pDistanceInMeters) => {
  let latRadian = toRad(pLatitude);

  let degLatKm = 110.574235;
  let degLongKm = 110.572833 * Math.cos(latRadian);
  let deltaLat = pDistanceInMeters / 1000.0 / degLatKm;
  let deltaLong = pDistanceInMeters / 1000.0 / degLongKm;

  let topLat = parseFloat(pLatitude + deltaLat);
  let bottomLat = parseFloat(pLatitude - deltaLat);
  let leftLng = parseFloat(pLongitude - deltaLong);
  let rightLng = parseFloat(pLongitude + deltaLong);

  let northWestCoords = { lng: leftLng, lat: topLat };
  let northEastCoords = { lng: rightLng, lat: topLat };
  let southWestCoords = { lng: leftLng, lat: bottomLat };
  let southEastCoords = { lng: rightLng, lat: bottomLat };

  return {
    topLeft: northWestCoords,
    topRight: northEastCoords,
    bottomRight: southEastCoords,
    bottomLeft: southWestCoords,
  };
};

export const calcCrow = (lat1, lon1, lat2, lon2) => {
  let R = 6371; // km
  let dLat = toRad(lat2 - lat1);
  let dLon = toRad(lon2 - lon1);
  let radLat1 = toRad(lat1);
  let radLat2 = toRad(lat2);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(radLat1) *
      Math.cos(radLat2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d;
};

export const isInsidePolygon = (point, poly) => {
  const res = classifyPoint(poly, point);
  return res === -1 ? true : res === 0 ? true : false;
};

export const handleLinkClick = (link) => {
  if (isApp()) {
    InAppBrowser.create(link, "_blank", {
      hideurlbar: true,
      hidenavigationbutton: true,
      toolbar: "yes",
      toolbarcolor: "#110d1b",
      closebuttoncolor: "#f4f4f7",
      closebuttoncaption: "Back",
      toolbarposition: "top",
    });
  } else {
    window.open(link, "_blank");
  }
};

export const calculatePriceRange = (val, type, isSafe) => {
  if (["sale", "pre-construction"].includes(type)) {
    if (val <= 20) return val * 10000; //200,000
    if (val > 20 && val <= 30) return 20 * 10000 + (val - 20) * 20000; //400,000
    if (val > 30 && val <= 50)
      return 20 * 10000 + 10 * 20000 + (val - 30) * 30000; // 1,000,000
    if (val > 50 && val <= 60)
      return 20 * 10000 + 10 * 20000 + 20 * 30000 + (val - 50) * 50000; // 1,500,000
    if (val > 60 && val <= 70)
      return (
        20 * 10000 + 10 * 20000 + 20 * 30000 + 10 * 50000 + (val - 60) * 100000
      ); // 2,500,000
    if (val > 70 && val <= 75)
      return (
        20 * 10000 +
        10 * 20000 +
        20 * 30000 +
        10 * 50000 +
        10 * 100000 +
        (val - 70) * 150000
      ); // 3,250,000
    if (val > 75 && val <= 80)
      return (
        20 * 10000 +
        10 * 20000 +
        20 * 30000 +
        10 * 50000 +
        10 * 100000 +
        5 * 150000 +
        (val - 75) * 200000
      ); // 4,250,000
    if (val > 80 && val <= 85)
      return (
        20 * 10000 +
        10 * 20000 +
        20 * 30000 +
        10 * 50000 +
        10 * 100000 +
        5 * 150000 +
        5 * 200000 +
        (val - 80) * 250000
      ); // 5,500,000
    if (val > 85 && val < 100)
      return (
        20 * 10000 +
        10 * 20000 +
        20 * 30000 +
        10 * 50000 +
        10 * 100000 +
        5 * 150000 +
        5 * 200000 +
        5 * 250000 +
        (val - 85) * 300000
      ); // 10,000,000
    if (val === 100) return isSafe ? 10000000 : "max";
  } else {
    if (val <= 20) return val * 10;
    if (val > 20 && val <= 30) return 20 * 10 + (val - 20) * 20;
    if (val > 30 && val <= 50) return 20 * 10 + 10 * 20 + (val - 30) * 30;
    if (val > 50 && val <= 60)
      return 20 * 10 + 10 * 20 + 20 * 30 + (val - 50) * 50;
    if (val > 60 && val <= 70)
      return 20 * 10 + 10 * 20 + 20 * 30 + 10 * 50 + (val - 60) * 100;
    if (val > 70 && val <= 75)
      return (
        20 * 10 + 10 * 20 + 20 * 30 + 10 * 50 + 10 * 100 + (val - 70) * 150
      );
    if (val > 75 && val <= 80)
      return (
        20 * 10 +
        10 * 20 +
        20 * 30 +
        10 * 50 +
        10 * 100 +
        5 * 150 +
        (val - 75) * 200
      );
    if (val > 80 && val <= 85)
      return (
        20 * 10 +
        10 * 20 +
        20 * 30 +
        10 * 50 +
        10 * 100 +
        5 * 150 +
        5 * 200 +
        (val - 80) * 250
      );
    if (val > 85 && val < 100)
      return (
        20 * 10 +
        10 * 20 +
        20 * 30 +
        10 * 50 +
        10 * 100 +
        5 * 150 +
        5 * 200 +
        5 * 250 +
        (val - 85) * 300
      );
    if (val === 100) return isSafe ? 10000 : "max";
  }
};

export const reversePriceRange = (val, type) => {
  if (["sale", "pre-construction"].includes(type)) {
    if (val === "max") return 100;
    if (val <= 200000) return val / 10000;
    if (val <= 400000) return 20 + (val - 200000) / 20000;
    if (val <= 1000000) return 30 + (val - 400000) / 30000;
    if (val <= 1500000) return 50 + (val - 1000000) / 50000;
    if (val <= 2500000) return 60 + (val - 1500000) / 100000;
    if (val <= 3250000) return 70 + (val - 2500000) / 150000;
    if (val <= 4250000) return 75 + (val - 3250000) / 200000;
    if (val <= 5000000) return 80 + (val - 4250000) / 250000;
    if (val < 10000000) return 85 + (val - 5000000) / 300000;
    if (val === 10000000) return 100;
  } else {
    if (val === "max") return 100;
    if (val <= 200) return val / 10;
    if (val <= 400) return 20 + (val - 200) / 20;
    if (val <= 1000) return 30 + (val - 400) / 30;
    if (val <= 1500) return 50 + (val - 1000) / 50;
    if (val <= 2500) return 60 + (val - 15000) / 1000;
    if (val <= 3250) return 70 + (val - 2500) / 150;
    if (val <= 4250) return 75 + (val - 3250) / 200;
    if (val <= 5000) return 80 + (val - 4250) / 250;
    if (val < 10000) return 85 + (val - 5000) / 300;
    if (val === 10000) return 100;
  }
};

export const getDeviceOS = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) return "wp";

  if (/android/i.test(userAgent)) return "android";
  if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) return "ios";

  return "na";
};

export const calculateCashback = (price, type) => {
  const offer = store.getState().ui.ettieOffer;
  if (offer) {
    if (type === "sale") {
      const total = offer.salePercentage * +price - offer.sale;
      return total > 100 ? numberWithCommas(total) : 100;
    } else if (type === "precon") {
      const total = offer.salePercentage * +price;
      return numberWithCommas(total);
    } else {
      const total = offer.leasePercentage * (+price / 2) - offer.lease;
      return total > 100 ? numberWithCommas(total) : 100;
    }
  }
};

export const getPrice = (price, type, currency, rates, isPre) => {
  
  const priceToShow = currency
    ? ["Sale", "Sold", "Delisted"].includes(type)
      ? (price / rates.CAD) * rates[currency] >= 1000000
        ? millify((price / rates.CAD) * rates[currency], {
            precision: 1,
          })
        : millify((price / rates.CAD) * rates[currency], {
            precision: 0,
          })
      : millify((price / rates.CAD) * rates[currency], {
          precision: 1,
        })
    : ["Sale", "Sold", "Delisted"].includes(type)
    ? price >= 1000000
      ? millify(price, {
          precision: 1,
        })
      : millify(price, {
          precision: 0,
        })
    : millify(price, {
        precision: 1,
      });

  if (isPre) {
    return priceToShow === "1000K" ? "1M+" : `${priceToShow}+`;
  } else {
    return priceToShow === "1000K" ? "1M" : priceToShow;
  }
};

export const renderCurrencySign = (currency, useSpan = true) => {
  if (!currency) return "$";
  if (!useSpan) {
    switch (currency.toLowerCase()) {
      case "cad":
        return "$";
      case "usd":
        return "USD ";
      case "aud":
        return "AUD ";
      case "cny":
        return "CNY ";
      case "eur":
        return String.fromCharCode(8364);
      case "gbp":
        return String.fromCharCode(163);
      case "inr":
        return String.fromCharCode(8377);
      case "jpy":
        return "JPY";
      case "irr":
        return "IRR";
      case "mxn":
        return String.fromCharCode(8369);
      default:
        return "$";
    }
  } else {
    switch (currency.toLowerCase()) {
      case "cad":
        return <span>$</span>;
      case "usd":
        return <span>USD </span>;
      case "aud":
        return <span>AUD </span>;
      case "cny":
        return <span>CNY </span>;
      case "eur":
        return <span>{String.fromCharCode(8364)}</span>;
      case "gbp":
        return <span>{String.fromCharCode(163)}</span>;
      case "inr":
        return <span>{String.fromCharCode(8377)}</span>;
      case "jpy":
        return <span>JPY </span>;
      case "irr":
        return <span>IRR </span>;
      case "mxn":
        return <span>{String.fromCharCode(8369)}</span>;
      default:
        return <span>$</span>;
    }
  }
};

export const getLastStatusLabel = (lastStatus) => {
  switch (lastStatus) {
    case "New":
      return "Listed";
    case "Sus":
      return "Suspended";
    case "Ter":
      return "Terminated";
    case "Ext":
      return "Extended";
    case "Exp":
      return "Expired";
    case "Sc":
      return "Sold conditional";
    case "Lc":
      return "Leased conditional";
    case "Sld":
      return "Sold";
    case "Lsd":
      return "Leased";
    default:
      return "Listed";
  }
};

export const isActiveTag = (lastStatus) =>
  ["New", "Dft", "Ext", "Pc"].includes(lastStatus) ? "active" : "inactive";

export const PriceDataFormater = (number) => {
  if (number >= 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number >= 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number >= 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
};