import Marker from "../Marker/marker.component";
import styles from "./clusters.module.scss";
import { getPrice } from "../../../../../../utils/functions";
import { ListingsHelper } from "utils/ListingsHelper";

export const renderDetailsMarker = (
  listing,
  client,
  isPre,
  isApp,
  setSchool,
  rates,
  containerRef,
  selectLocation
) => {
  let currency;
  if (client && rates) {
    if (client.currency !== "cad") currency = client.currency.toUpperCase();
  }

  const container = containerRef.current;

  const point = isPre
    ? {
        type: "Pre",
        properties: {
          cluster: false,
          item: listing,
          listingId: listing.id,
          listingPrice: listing.startPrice,
          phase: listing.phase,
          category: "Sale",
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(listing.longitude),
            parseFloat(listing.latitude),
          ],
        },
      }
    : {
        type: "Feature",
        properties: {
          cluster: false,
          item: listing,
          listingId: listing.mlsNumber,
          listingPrice:
            listing.status === "U" &&
            ["Sld", "Lsd"].includes(listing.lastStatus)
              ? listing.soldPrice
              : listing.listPrice,
          status: listing.status,
          category: listing.type,
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(listing.map.longitude),
            parseFloat(listing.map.latitude),
          ],
        },
      };

  return (
    <Marker
      key={listing.mlsNumber}
      lat={+point.geometry.coordinates[1]}
      lng={+point.geometry.coordinates[0]}>
      <div
        className={`${styles.detailsMarker} ${isApp && styles.animatedMarker} ${
          getPrice(
            +point.properties.listingPrice,
            point.type,
            currency,
            rates,
            isPre
          ).length > 5 && styles[isPre ? "preWideMarker" : "wideMarker"]
        } `}
        onClick={(e) => {
          setSchool(null);

          if (!isApp) {
            const mapBounds = container.getBoundingClientRect();
            const mapXStart = mapBounds.left;
            const mapXEnd = mapBounds.right;
            const mapYStart = mapBounds.top;
            const mapYEnd = mapBounds.bottom;

            // if (
            //   e.clientX + 150 > mapXEnd ||
            //   e.clientX < mapXStart + 200 ||
            //   e.clientY + 150 > mapYEnd
            // ) {
            //   map.panTo({
            //     lat: latitude,
            //     lng: longitude,
            //   });
            // }
          }

          selectLocation({
            id: undefined,
            points: [point],
            coords: { lat: +listing.map.latitude, lng: +listing.map.longitude },
          });
        }}>
        <span className={styles.price}>
          {getPrice(
            +point.properties.listingPrice,
            point.type,
            currency,
            rates,
            isPre
          )}
        </span>
        <span className={styles.chevron} />
      </div>
    </Marker>
  );
};

export const renderMapClusters = ({
  clusters,
  onSelectCluster,
  onSelectListings,
  selectedCluster,
  selectedListings,
  detailsListing,
  map,
  setSchool,
  activeListing,
  isPre = false,
  isApp = false,
  isSatellite = false,
}) => {
  if (!clusters) {
    return;
  }
  const totalPoints = clusters.reduce((acc, cluster) => {
    return acc + cluster.count;
  }, 0);

  return clusters.map((cluster, index) => {
    if (cluster.count > 1) {
      let isHovered = false;
      let isActive = false;

      const isClusterSelected =
        selectedCluster &&
        selectedCluster.location.latitude === cluster.location.latitude &&
        selectedCluster.location.longitude === cluster.location.longitude;

      let width = 5 + (cluster.count / totalPoints) * 400;
      let height = 5 + (cluster.count / totalPoints) * 400;

      width = width > 50 ? 50 : width;
      height = height > 50 ? 50 : height;

      return (
        <Marker
          key={index}
          lat={cluster.location.latitude}
          lng={cluster.location.longitude}
          zIndex={cluster.count}>
          <div
            className={`${
              styles[isPre ? "preClusterMarker" : "clusterMarker"]
            } ${isApp && styles.animatedClusterMarker} ${
              isSatellite
                ? styles[isPre ? "preClusterMarkerLight" : "clusterMarkerLight"]
                : ""
            } ${
              isClusterSelected || isHovered
                ? isSatellite
                  ? styles[
                      isPre ? "preSelectedClusterLight" : "selectedClusterLight"
                    ]
                  : styles[isPre ? "preSelectedCluster" : "selectedCluster"]
                : ""
            } ${isHovered && styles.hovered} ${
              isActive && styles[isPre ? "preActiveCluster" : "activeCluster"]
            }`}
            style={{
              width,
              height,
            }}
            onClick={() => {
              setSchool(null);
              const currentZoom = map.getZoom();
              if (cluster.count <= 25 || currentZoom >= 18) {
                onSelectCluster(cluster);
              } else {
                const { top_left, bottom_right } = cluster.bounds;

                const bounds = new window.google.maps.LatLngBounds(
                  new window.google.maps.LatLng(
                    top_left.latitude,
                    top_left.longitude
                  ),
                  new window.google.maps.LatLng(
                    bottom_right.latitude,
                    bottom_right.longitude
                  )
                );
                map.setCenter(bounds.getCenter());
                map.fitBounds(bounds);

                const zoomAfterFit = map.getZoom();
                const zoomIncrement =
                  currentZoom <= 10 ? 3 : currentZoom <= 15 ? 2 : 1;
                const targetZoom = Math.min(currentZoom + zoomIncrement, 20);

                if (zoomAfterFit < targetZoom) {
                  map.setZoom(targetZoom);
                } else if (zoomAfterFit > 20) {
                  map.setZoom(20);
                }
              }
            }}>
            {cluster.count}
          </div>
        </Marker>
      );
    } else {
      if (
        detailsListing &&
        detailsListing.mlsNumber === cluster.listing.mlsNumber
      ) {
        return;
      }
      const displayData = ListingsHelper.displayData(cluster.listing);
      const price = ListingsHelper.getMapListingFormattedPrice(cluster.listing);
      let isHovered = false;
      let isActive = false;

      if (activeListing && cluster.listing.mlsNumber === activeListing.mls) {
        isActive = true;
      }
      if (activeListing && cluster.listing.mlsNumber === activeListing) {
        isHovered = true;
      }

      const isListingSelected = selectedListings.find(
        (listing) => listing.mlsNumber === cluster.listing.mlsNumber
      );

      return (
        <Marker
          key={cluster.listing.mlsNumber}
          lat={cluster.listing.map.latitude}
          lng={cluster.listing.map.longitude}>
          <div
            className={`${styles[isPre ? "preMarker" : "marker"]} ${
              isApp && styles.animatedMarker
            } ${
              isSatellite && !isActive
                ? styles[isPre ? "preMarkerLight" : "markerLight"]
                : ""
            } ${
              isListingSelected || isHovered
                ? isSatellite
                  ? styles[
                      isPre ? "preSelectedMarkerLight" : "selectedMarkerLight"
                    ]
                  : styles[isPre ? "preSelecterMarker" : "selectedMarker"]
                : ""
            } ${isHovered && styles.hovered} ${
              price.length > 5 && styles[isPre ? "preWideMarker" : "wideMarker"]
            } ${
              isActive && styles[isPre ? "preActiveMarker" : "activeMarker"]
            }`}
            onClick={() => {
              setSchool(null);
              onSelectListings([cluster.listing]);
            }}>
            <span className={styles.price}>{displayData ? price : "--"}</span>
            <span className={styles.chevron} />
          </div>
        </Marker>
      );
    }
  });
};

export const renderSingleMarker = (
  listing,
  map,
  selectedListings,
  onSelectListings,
  setSchool
) => {
  const displayData = ListingsHelper.displayData(listing);

  return (
    <Marker
      zIndex={10000}
      key={listing.mlsNumber}
      lat={+listing.map.latitude}
      lng={+listing.map.longitude}>
      <div
        className={`${styles.marker} ${styles.detailsMarker} ${
          selectedListings.find(
            (l) => l.mlsNumber === listing.mlsNumber
          ) && styles.selectedDetailsMarker
        }`}
        onClick={() => {
          setSchool(null);
          onSelectListings([listing]);
        }}>
        <span className={styles.price}>
          {displayData
            ? ListingsHelper.getMapListingFormattedPrice(listing)
            : "--"}
        </span>
        <span className={styles.chevron} />
      </div>
    </Marker>
  );
};
