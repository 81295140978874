import {
  IonHeader,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonTitle,
  IonLabel,
  IonModal,
} from "@ionic/react";
import styles from "./header.module.scss";
import { chevronBackOutline, notificationsOutline } from "ionicons/icons";
import { useState } from "react";
import Profile from "../Profile/profile.component";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { selectCurrentClient } from "../../../redux/client/client.selectors";
import { selectSelectedTab } from "../../../redux/ui/ui.selectors";

const AppHeader = ({
  title,
  isProfilePage = false,
  isTourPage = false,
  hasRightButton = true,
  hasBackButton = true,
  backUrl,
  backHref = undefined,
  isModal = false,
  isTourDetails = false,
  isFilters = false,
  onReset,
  onClick,
  customBack,
  ...rest
}) => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const user = useSelector(selectCurrentUser);
  const client = useSelector(selectCurrentClient);
  const selectedTab = useSelector(selectSelectedTab);

  const handleAlerts = () => {
    setShowProfileModal(true);
  };

  return (
    <IonHeader className={styles.appHeader} {...rest}>
      <IonModal
        isOpen={showProfileModal}
        onDidDismiss={() => setShowProfileModal(false)}
        swipeToClose={true}>
        <Profile
          setShowModal={setShowProfileModal}
          alertsOnly={true}
          user={user}
          client={client}
        />
      </IonModal>
      <IonToolbar className={styles.toolbar}>
        {!isModal && hasBackButton && (
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={backHref ?? `/`}
              className={styles.btn}
              slot="start"
              text="Back"
              icon={chevronBackOutline}
            />
          </IonButtons>
        )}

        {backUrl && (
          <IonButtons slot="start">
            <IonButton
              className={styles.manageAlerts}
              routerDirection="back"
              routerLink={backUrl}>
              <IonIcon icon={chevronBackOutline} />
              <IonLabel>Back</IonLabel>
            </IonButton>
          </IonButtons>
        )}
        {customBack && (
          <IonButtons slot="start">
            <IonButton className={styles.manageAlerts} onClick={customBack}>
              <IonIcon icon={chevronBackOutline} />
              <IonLabel>Back</IonLabel>
            </IonButton>
          </IonButtons>
        )}

        {isModal && isFilters && (
          <IonButtons slot="start" onClick={onClick}>
            <IonButton className={styles.manageAlerts}>
              <IonIcon icon={chevronBackOutline} />
              <IonLabel>Back</IonLabel>
            </IonButton>
          </IonButtons>
        )}

        {hasRightButton &&
          (isTourPage ? (
            <IonButtons slot="end" onClick={onClick}>
              <IonButton className={styles.manageAlerts}>
                <span>New tour</span>
              </IonButton>
            </IonButtons>
          ) : isModal && !isFilters ? (
            <IonButtons slot="end" onClick={onClick}>
              <IonButton className={styles.manageAlerts}>
                <span>Close</span>
              </IonButton>
            </IonButtons>
          ) : isTourDetails ? (
            <IonButtons slot="end" onClick={onClick}>
              <IonButton className={styles.manageAlerts}>
                <span>Close</span>
              </IonButton>
            </IonButtons>
          ) : isFilters ? (
            <IonButtons slot="end" onClick={onReset}>
              <IonButton className={styles.manageAlerts}>
                <span>Reset</span>
              </IonButton>
            </IonButtons>
          ) : (
            <IonButtons
              slot="end"
              className={isProfilePage && styles.hidden}
              onClick={handleAlerts}>
              <IonButton
                className={styles.manageAlerts}
                disabled={isProfilePage}>
                <IonIcon icon={notificationsOutline} />
                <span>Alerts</span>
              </IonButton>
            </IonButtons>
          ))}

        <IonTitle className={styles.title}>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default AppHeader;
