import {
  IonButton,
  IonChip,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPopover,
  IonRange,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonModal,
} from "@ionic/react";
import styles from "./search-filters.module.scss";
import { ListingsClass } from "enums/Listings/ListingClass.enum";
import {
  chevronDownOutline,
  chevronUpOutline,
  gridOutline,
  mapOutline,
  optionsOutline,
  searchOutline,
} from "ionicons/icons";
import { useSelector } from "react-redux";
import { selectFiltersValue } from "redux/filters/filters.selectors";
import { ListingsHelper } from "utils/ListingsHelper";
import { useEffect, useRef, useState } from "react";
import ListingsFiltersModal from "components/shared/Modals/ListingsFiltersModal/listings-filters-modal.component";
import { ListingsFiltersService } from "services/listingsFiltersService";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import { ListingType } from "enums/Listings/ListingType.enum";

const SearchFilters = ({
  view,
  updateView,
  searchTerm,
  onSearch,
}: {
  view: "map" | "list";
  updateView: (view: "map" | "list") => void;
  searchTerm: string;
  onSearch: (term: string) => void;
}) => {
  const filters = useSelector(selectFiltersValue);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const priceFilterPopoverRef = useRef<HTMLIonPopoverElement>(null);
  const bedsFilterPopoverRef = useRef<HTMLIonPopoverElement>(null);
  const propertyTypeFilterPopoverRef = useRef<HTMLIonPopoverElement>(null);

  useEffect(() => {
    ListingsFiltersService.updateStatus(ListingStatus.Active);
  }, [filters.type]);

  const handleUpdateListingsClass = (e: CustomEvent) => {
    ListingsFiltersService.updateClass(e.detail.value);
  };

  const handlePriceRangeChange = (e: CustomEvent) => {
    ListingsFiltersService.updatePriceRange(
      e.detail.value.lower,
      e.detail.value.upper
    );
  };

  const handleMinPriceChange = (e: CustomEvent) => {
    ListingsFiltersService.updateMinPrice(e.detail.value);
  };

  const handleMaxPriceChange = (e: CustomEvent) => {
    ListingsFiltersService.updateMaxPrice(e.detail.value);
  };

  const handleBedsRangeChange = (e: CustomEvent) => {
    ListingsFiltersService.updateMinBeds(e.detail.value);
  };

  const handleLastStatusChange = (e: CustomEvent) => {
    const value = e.detail.value as "sale" | "sold" | "delisted";
    ListingsFiltersService.updateLastStatus(value);
  };

  const handleUpdateDate = (e: CustomEvent) => {
    const value = e.detail.value as number;
    ListingsFiltersService.updateDate(value);
  };

  const handlePropertyTypeChange = (type: string | null) => {
    ListingsFiltersService.updatePropertyType(type);
  };

  return (
    <div className={styles.container}>
      <IonModal
        className="aecorn-modal"
        isOpen={showFiltersModal}
        onDidDismiss={() => setShowFiltersModal(false)}>
        <ListingsFiltersModal dismiss={() => setShowFiltersModal(false)} />
      </IonModal>
      <div className={styles.filter}>
        <IonItem lines="none" className="aecorn-search-input">
          <IonInput
            value={searchTerm}
            debounce={500}
            onIonInput={(e) => onSearch(e.detail.value!)}
            clearInput
            placeholder="Search by location or MLS#"
            className="aecorn-search-input">
            <IonIcon slot="start" icon={searchOutline} />
          </IonInput>
        </IonItem>
      </div>
      <div className={styles.filter}>
        <IonItem lines="none" className="aecorn-select">
          <IonSelect
            aria-label="Select purpose"
            placeholder="Select purpose"
            interface="popover"
            justify="space-between"
            toggleIcon={chevronDownOutline}
            expandedIcon={chevronUpOutline}
            multiple
            interfaceOptions={{ cssClass: "aecorn-select-popover" }}
            value={filters.class}
            onIonChange={handleUpdateListingsClass}>
            {Object.entries(ListingsClass).map(([key, value]) => (
              <IonSelectOption key={key} value={value}>
                {key}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </div>
      <div className={styles.filter}>
        <IonItem
          id="price-popover"
          button
          lines="none"
          className="aecorn-select">
          <IonLabel>{ListingsHelper.getListingPriceLabel()}</IonLabel>
          <IonIcon slot="end" icon={chevronDownOutline} />
        </IonItem>
        <IonPopover
          ref={priceFilterPopoverRef}
          trigger="price-popover"
          triggerAction="click"
          showBackdrop={false}
          size="cover">
          <IonContent className="ion-padding">
            <div className={styles.popoverContent}>
              <div className={styles.item}>
                <IonRange
                  value={{
                    lower: ListingsHelper.convertPriceToPercentage(
                      filters.minPrice,
                      filters.type,
                      "lower"
                    ),
                    upper: ListingsHelper.convertPriceToPercentage(
                      filters.maxPrice,
                      filters.type,
                      "upper"
                    ),
                  }}
                  onIonChange={handlePriceRangeChange}
                  dualKnobs></IonRange>
              </div>
              <div className={styles.inputs}>
                <IonInput
                  min={0}
                  value={filters.minPrice}
                  onIonChange={handleMinPriceChange}
                  type="number"
                  className="aecorn-input">
                  <div slot="start">{filters.minPrice ? "$" : "Any"}</div>
                  <div className="aecorn-input-label" slot="label">
                    Min
                  </div>
                </IonInput>
                <IonInput
                  min={0}
                  value={filters.maxPrice}
                  onIonChange={handleMaxPriceChange}
                  type="number"
                  className="aecorn-input">
                  <div slot="start">{filters.maxPrice ? "$" : "Any"}</div>
                  <div className="aecorn-input-label" slot="label">
                    Max
                  </div>
                </IonInput>
              </div>
              <IonButton
                onClick={() => priceFilterPopoverRef.current?.dismiss()}
                shape="round"
                className="aecorn-button">
                Apply
              </IonButton>
            </div>
          </IonContent>
        </IonPopover>
      </div>
      <div className={styles.filter}>
        <IonItem
          id="beds-popover"
          button
          lines="none"
          className="aecorn-select">
          <IonLabel>{ListingsHelper.getListingNumberOfBedsLabel()}</IonLabel>
          <IonIcon slot="end" icon={chevronDownOutline} />
        </IonItem>
        <IonPopover
          ref={bedsFilterPopoverRef}
          trigger="beds-popover"
          triggerAction="click"
          showBackdrop={false}
          size="cover">
          <IonContent className="ion-padding">
            <div className={styles.popoverContent}>
              <div className={styles.item}>
                <IonLabel className={styles.label}>
                  {filters.minBeds ? `${filters.minBeds}+` : "Any"}
                </IonLabel>
                <IonRange
                  min={0}
                  max={5}
                  snaps
                  value={filters.minBeds}
                  onIonChange={handleBedsRangeChange}></IonRange>
              </div>
              <IonButton
                onClick={() => bedsFilterPopoverRef.current?.dismiss()}
                shape="round"
                className="aecorn-button">
                Apply
              </IonButton>
            </div>
          </IonContent>
        </IonPopover>
      </div>
      <div className={styles.filter}>
        <IonSegment
          mode="ios"
          className="aecorn-segment"
          onIonChange={handleLastStatusChange}
          value={filters.transactionStatus}>
          <IonSegmentButton value="sale">
            <IonLabel>
              {filters.type === ListingType.Sale ? "Sale" : "Lease"}
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="sold">
            <IonLabel>
              {filters.type === ListingType.Sale ? "Sold" : "Leased"}
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="delisted">
            <IonLabel>De-listed</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>
      <IonItem lines="none" className="aecorn-select">
        <IonSelect
          aria-label={
            filters.transactionStatus === "sale"
              ? "Listing date"
              : filters.transactionStatus === "sold"
              ? filters.type === ListingType.Sale
                ? "Sold date"
                : "Leased date"
              : "De-list date"
          }
          placeholder={
            filters.transactionStatus === "sale"
              ? "Listing date"
              : filters.transactionStatus === "sold"
              ? filters.type === ListingType.Sale
                ? "Sold date"
                : "Leased date"
              : "De-list date"
          }
          interface="popover"
          justify="space-between"
          toggleIcon={chevronDownOutline}
          expandedIcon={chevronUpOutline}
          interfaceOptions={{ cssClass: "aecorn-select-popover" }}
          value={filters.date}
          onIonChange={handleUpdateDate}>
          {ListingsHelper.getListingDateFilterOptions().map((option, index) => (
            <IonSelectOption key={index} value={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      <IonItem id="types-popover" lines="none" button className="aecorn-select">
        <IonLabel>{ListingsHelper.getListingPropertyTypeLabel()}</IonLabel>
        <IonIcon slot="end" icon={chevronDownOutline} />
      </IonItem>
      <IonPopover
        ref={propertyTypeFilterPopoverRef}
        trigger="types-popover"
        triggerAction="click"
        showBackdrop={false}
        size="cover">
        <div className={styles.popoverContent}>
          <div className={styles.chips}>
            <IonChip
              onClick={() => handlePropertyTypeChange(null)}
              className={`aecorn-chip ${
                !filters.propertyType.length && "active"
              }`}>
              Any
            </IonChip>
            {ListingsHelper.getListingPropertyTypesByClass().map(
              (option, index) => (
                <IonChip
                  key={index}
                  onClick={() => handlePropertyTypeChange(option.value)}
                  className={`aecorn-chip ${
                    filters.propertyType.includes(option.value) && "active"
                  }`}>
                  {option.label}
                </IonChip>
              )
            )}
          </div>
          <IonButton
            onClick={() => propertyTypeFilterPopoverRef.current?.dismiss()}
            shape="round"
            className="aecorn-button">
            Apply
          </IonButton>
        </div>
      </IonPopover>
      <div className={styles.filter}>
        <IonButton
          onClick={() => setShowFiltersModal(true)}
          fill="clear"
          className="aecorn-button clear">
          <IonIcon slot="start" icon={optionsOutline} />
          More filters
          {ListingsFiltersService.hasActiveFilters() && (
            <span className={styles.activeFilters} />
          )}
        </IonButton>
        <div className={styles.viewButtons}>
          <div
            onClick={() => updateView("map")}
            className={`${styles.viewButton} ${
              view === "map" && styles.activeViewButton
            }`}>
            <IonIcon icon={mapOutline} />
          </div>
          <div
            onClick={() => updateView("list")}
            className={`${styles.viewButton} ${
              view === "list" && styles.activeViewButton
            }`}>
            <IonIcon icon={gridOutline} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFilters;
