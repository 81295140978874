import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLoading,
  IonRadio,
  IonRadioGroup,
  IonTextarea,
  useIonToast,
} from "@ionic/react";
import styles from "./listing-request-modal.module.scss";
import modalStyles from "../modals.module.scss";
import { checkmarkCircle, closeOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "redux/client/client.selectors";
import { Listing } from "models/listings/listing.model";
import { useState } from "react";
import { ListingsHelper } from "utils/ListingsHelper";
import TourAvailability from "components/shared/Tours/TourAvailability/tour-availability.component";
import { useForm } from "react-hook-form";
import { ActivityService } from "services/activityService";

const ListingRequestModal = ({
  dismiss,
  listing,
}: {
  dismiss: () => void;
  listing: Listing;
}) => {
  const client = useSelector(selectCurrentClient);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [guestEmail, setGuestEmail] = useState("");
  const [guests, setGuests] = useState<string[]>([]);
  const [clientAvailability, setClientAvailability] = useState<string[]>([]);
  const [message, setMessage] = useState(
    `I'd like to book an appointment to view ${ListingsHelper.getListingAddress(
      listing.address
    )} (${listing.mlsNumber}).`
  );

  const [presentToast] = useIonToast();

  const {
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm<{
    name: string;
    email: string;
    phone: string | null;
  }>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      name: client?.name,
      email: client?.email,
      phone: client?.phone ? client.phone.replace("+1", "") : null,
    },
  });

  const nameFormControl = register("name", {
    required: {
      value: true,
      message: "Name is required",
    },
  });

  const emailFormControl = register("email", {
    required: {
      value: true,
      message: "Email is required",
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: "Invalid email address",
    },
  });

  const phoneFormControl = register("phone", {
    pattern: {
      value: /^\d{10}$/,
      message: "Invalid phone number",
    },
  });

  const [requestType, setRequestType] = useState<"tour" | "information">(
    "tour"
  );

  const getSubtitle = () => {
    switch (step) {
      case 1:
        return "Please complete this form to contact the agent or schedule a viewing appointment.";
      case 2:
        return "Select your available times. You can always edit them in your tour panel or when requesting your next showing.";
      default:
        return "Request a viewing";
    }
  };

  const onAddGuest = () => {
    if (guestEmail && !guests.includes(guestEmail.trim())) {
      setGuests([...guests, guestEmail.trim()]);
      setGuestEmail("");
    }
  };

  const isEmailValid = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onProceed = async () => {
    if (step === 1) {
      if (requestType === "tour") {
        setStep(2);
      } else {
        await submitRequest();
      }
    } else {
      await submitRequest();
    }
  };

  const submitRequest = async () => {
    try {
      setLoading(true);
      const phone = getValues("phone") ? `+1${getValues("phone")}` : undefined;
      if (requestType === "information") {
        await ActivityService.createListingInformationRequest({
          mlsNumber: listing.mlsNumber,
          name: getValues("name"),
          email: getValues("email"),
          phone: phone === "" ? undefined : phone,
          message,
        });
      } else {
        await ActivityService.createTourRequest({
          mlsNumber: listing.mlsNumber,
          name: getValues("name"),
          email: getValues("email"),
          phone: phone === "" ? undefined : phone,
          guests,
          message,
          availability: clientAvailability,
        });
      }
      setStep(3);
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while submitting your request.",
        duration: 5000,
        cssClass: "aecorn-error-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {step !== 3 && (
        <div className={modalStyles.header}>
          <IonIcon icon={closeOutline} onClick={dismiss} />
          <div>Submit request</div>
        </div>
      )}
      <IonContent>
        <IonLoading isOpen={loading} message="Loading..." />
        <div className={styles.content}>
          {step === 3 && (
            <div className={styles.success}>
              <IonIcon icon={checkmarkCircle} />
              <span>Request submitted successfully</span>
              <ul>
                <li>An agent will get in touch within 24 hours.</li>
                {requestType === "tour" && (
                  <>
                    <li>
                      After the tour has been created, you will receive both
                      emails and notifications.
                    </li>
                    {client && (
                      <li>
                        You can track updates on your tour panel. You also
                        receive notifications on updates.
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          )}
          {step !== 3 && <p>{getSubtitle()}</p>}
          {step === 1 && (
            <div className={styles.form}>
              <label>Contact information</label>
              <IonInput
                className={`aecorn-input dark ${
                  errors.name?.message && "ion-invalid ion-touched"
                }`}
                {...nameFormControl}
                placeholder="Name*"
                required
                autocapitalize="words"
                onIonInput={nameFormControl.onChange}
                disabled={client ? true : false}
              />
              <IonInput
                className={`aecorn-input dark ${
                  errors.email?.message && "ion-invalid ion-touched"
                }`}
                placeholder="Email*"
                required
                {...emailFormControl}
                onIonInput={emailFormControl.onChange}
                type="email"
                disabled={client ? true : false}
              />
              <IonInput
                className={`aecorn-input dark ${
                  errors.phone?.message && "ion-invalid ion-touched"
                }`}
                placeholder="Phone"
                required
                {...phoneFormControl}
                onIonInput={phoneFormControl.onChange}
                type="tel"
                disabled={client && client.phone ? true : false}
              >
                <span slot="start">+1</span>
              </IonInput>
            </div>
          )}
          {step === 1 && (
            <div className={styles.form}>
              <label>I want to request:</label>
              <IonRadioGroup
                className="aecorn-radio"
                value={requestType}
                onIonChange={(e) => setRequestType(e.detail.value)}>
                <IonItem lines="none">
                  <IonRadio value="tour">Showing</IonRadio>
                </IonItem>
                <IonItem lines="none">
                  <IonRadio value="information">Information</IonRadio>
                </IonItem>
              </IonRadioGroup>
            </div>
          )}
          {step === 2 && (
            <TourAvailability
              onAvailabilityUpdate={(availability: string[]) =>
                setClientAvailability(availability)
              }
            />
          )}
          {step === 2 && (
            <div className={styles.form}>
              <label>Request a guest:</label>
              <div className={styles.input}>
                <IonInput
                  className="aecorn-input dark"
                  placeholder="Enter guest's email"
                  value={guestEmail}
                  onIonInput={(e) => setGuestEmail(e.detail.value ?? "")}
                />
                <IonButton
                  size="small"
                  shape="round"
                  className="aecorn-button"
                  disabled={
                    !guestEmail ||
                    !isEmailValid(guestEmail) ||
                    guests.includes(guestEmail.trim())
                  }
                  onClick={onAddGuest}>
                  Add
                </IonButton>
              </div>
              {guests.map((guest) => (
                <div key={guest} className={styles.guest}>
                  {guest}
                  <IonIcon
                    icon={closeOutline}
                    onClick={() => setGuests(guests.filter((g) => g !== guest))}
                  />
                </div>
              ))}
            </div>
          )}
          {step === 2 && (
            <div className={styles.form}>
              <label>Message</label>
              <IonTextarea
                className="aecorn-input dark"
                placeholder="Message"
                value={message}
                onIonChange={(e) => setMessage(e.detail.value ?? "")}
              />
            </div>
          )}
          <span className={styles.note}>
            By submitting this form, I understand AECORN will share my
            information with registered real estate professionals.
          </span>
        </div>
      </IonContent>
      <div className={modalStyles.footer}>
        <IonButton
          onClick={() => {
            if (step === 1 || step === 3) {
              dismiss();
            } else {
              setStep(step - 1);
            }
          }}
          size="large"
          shape="round"
          className="aecorn-button border clear">
          {step === 1 ? "Cancel" : step === 2 ? "Back" : "Close"}
        </IonButton>
        {step !== 3 && (
          <IonButton
            size="large"
            shape="round"
            className="aecorn-button"
            disabled={!isValid}
            onClick={onProceed}>
            Proceed
          </IonButton>
        )}
      </div>
    </>
  );
};

export default ListingRequestModal;
