const TourActionTypes = {
  FETCH_TOURS_START: "FETCH_TOURS_START",
  FETCH_TOURS_SUCCESS: "FETCH_TOURS_SUCCESS",
  FETCH_TOURS_FAILURE: "FETCH_TOURS_FAILURE",
  ADD_TOUR_START: "ADD_TOUR_START",
  ADD_TOUR_SUCCESS: "ADD_TOUR_SUCCESS",
  ADD_TOUR_FAILURE: "ADD_TOUR_FAILURE",
  UPDATE_TOUR_START: "UPDATE_TOUR_START",
  UPDATE_TOUR_SUCCESS: "UPDATE_TOUR_SUCCESS",
  UPDATE_TOUR_FAILURE: "UPDATE_TOUR_FAILURE",
  DELETE_TOUR_START: "DELETE_TOUR_START",
  DELETE_TOUR_SUCCESS: "DELETE_TOUR_SUCCESS",
  DELETE_TOUR_FAILURE: "DELETE_TOUR_FAILURE",
  ADD_CLIENTS_START: "ADD_CLIENTS_START",
  ADD_CLIENTS_SUCCESS: "ADD_CLIENTS_SUCCESS",
  ADD_CLIENTS_FAILURE: "ADD_CLIENTS_FAILURE",
  CLEAR_CURRENT_TOUR: "CLEAR_CURRENT_TOUR",
  UPDATE_TOUR_REQUEST: "UPDATE_TOUR_REQUEST",
};

export default TourActionTypes;
