export const getUserPublicProfile = /* GraphQL */ `
  query GetUserPublicProfile($id: ID!) {
    getUser(id: $id) {
      givenName
      familyName
      name
      displayName
      email
      phone
      profilePicture
    }
  }
`;

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    repliersID
    givenName
    familyName
    name
    displayName
    email
    phone
    profilePicture
    role
    agent {
      userId
      website
      licenseNumber
      areas
      availability
      id
      createdAt
      updatedAt
      brokerageAgentsId
      __typename
    }
    client {
      userId
      agentId
      agent {
        user {
          name
          givenName
          familyName
          displayName
          email
          phone
          profilePicture
        }
      }
      availability
      id
      createdAt
      updatedAt
      agentClientsId
      __typename
    }
    subscribed
    currency
    measurement
    notifications {
      userId
      schedule
      type
      id
      createdAt
      updatedAt
      __typename
    }
    deviceToken
    profile {
      userId
      buyingSelling
      leasingRenting
      justLooking
      preConstruction
      isRealtor
      id
      createdAt
      updatedAt
      __typename
    }
    requestedTour
    tours {
      nextToken
      __typename
    }
    toursNote
    deactivated
    id
    createdAt
    updatedAt
    userAgentId
    userClientId
    userNotificationsId
    userProfileId
    __typename
  }
}
`;


export const getUsersByEmail = /* GraphQL */ `query GetUserByEmail(
  $email: AWSEmail!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      repliersID
      givenName
      familyName
      name
      displayName
      email
      phone
      profilePicture
      role
      subscribed
      currency
      measurement
      deviceToken
      requestedTour
      toursNote
      deactivated
      id
      createdAt
      updatedAt
      userAgentId
      userClientId
      userNotificationsId
      userProfileId
      __typename
    }
    nextToken
    __typename
  }
}
`;

export const listAgentUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id 
      givenName 
      familyName 
      displayName 
      name
      email 
      profilePicture 
      role
      __typename
    }
    nextToken
    __typename
  }
}
`;