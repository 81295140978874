import { School } from "API";
import styles from "./school-sheet.module.scss";
import { SchoolsService } from "services/schoolsService";
import { IonRouterLink, IonToggle } from "@ionic/react";

const SchoolSheet = ({
  school,
  toggled,
  onToggleBoundary,
  disabled,
}: {
  school: School;
  toggled: boolean;
  disabled: boolean;
  onToggleBoundary: (boundary: any) => void;
}) => {
  const grades = SchoolsService.getSchoolGradeRange(school);
  const lastYearScores = SchoolsService.getSchoolLastYearScores(school);
  const boundary = SchoolsService.getSchoolBoundary(school);

  const renderLanguages = () => {
    if (school.isEnglish && school.isFrenchImmersion) {
      return "English/French";
    }
    if (school.isEnglish) {
      return "English";
    }
    if (school.isFrenchImmersion) {
      return "French";
    }
  };

  const renderSchoolType = () => {
    if (school.isPublic && school.isCatholic) {
      return "Public/Catholic";
    }
    if (school.isPublic) {
      return "Public";
    }
    if (school.isCatholic) {
      return "Catholic";
    }
  };

  const renderAECORNScore = () => {
    if (!school.ettieOverallAvg) {
      return "N/A";
    } else {
      return (school.ettieOverallAvg / 10).toFixed(1);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{school.name}</h4>
        <div className={styles.info}>
          <span>
            {grades.from}-{grades.to}
          </span>
          <span>{renderLanguages()}</span>
          <span>{renderSchoolType()}</span>
        </div>
      </div>
      <div className={styles.rating}>
        <div className={styles.rate}>
          <span>{renderAECORNScore()}</span>
          {school.ettieOverallAvg && <span>Out of 10</span>}
        </div>
        <div className={styles.ratingDetails}>
          <span className={styles.ratingTitle}>EQAO GTA School Ranking</span>
          {lastYearScores?.map((score, index) => (
            <div key={index} className={styles.yearRate}>
              <span>
                {score.yearMark} {score.grade}
              </span>
              <span>{score.rank}</span>
            </div>
          ))}
        </div>
      </div>
      {boundary && (
        <div className={styles.boundary}>
          <div className={styles.boundaryTitle}>
            <span>Restrict listings</span>{" "}
            <IonToggle
              checked={toggled}
              disabled={disabled}
              onIonChange={() => onToggleBoundary(school)}
              className="aecorn-toggle"
            />
          </div>
          <p>
            Select to restrict listings to this school’s boundaries. Select up
            to 10 schools at a time.
          </p>
        </div>
      )}
      <div className={styles.footer}>
        Confirm boundaries before purchasing. Information supplied by{" "}
        <IonRouterLink routerLink="/school-terms">EQAO</IonRouterLink>.
      </div>
    </div>
  );
};

export default SchoolSheet;
