import { IonPage } from "@ionic/react";
import { useSelector } from "react-redux";
import {
  selectIsAgent,
  selectIsAuthenticated,
} from "redux/user/user.selectors";
import AgentTours from "../AgentTours/agent-tours.page";
import ClientTours from "../ClientTours/client-tours.page";
import { Redirect } from "react-router";

const Tours = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAgent = useSelector(selectIsAgent);

  return (
    <>
      {!isAuthenticated && <Redirect to="/" />}
      <IonPage>{isAgent ? <AgentTours /> : <ClientTours />}</IonPage>
    </>
  );
};

export default Tours;