import {
  IonButton,
  IonChip,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRange,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import styles from "./listings-filters-modal.module.scss";
import modalStyles from "../modals.module.scss";
import {
  chevronDownOutline,
  chevronUpOutline,
  closeOutline,
} from "ionicons/icons";
import { useSelector } from "react-redux";
import { selectFiltersValue } from "redux/filters/filters.selectors";
import { ListingsHelper } from "utils/ListingsHelper";
import { ListingsFiltersService } from "services/listingsFiltersService";
import { useState } from "react";
import { ListingType } from "enums/Listings/ListingType.enum";
import { DeviceService } from "services/deviceInfoService";
import RegionsFilter, {
  Area,
} from "./components/RegionsFilter/regions-filter.component";
import { selectLocationsValue } from "redux/locations/locations.selectors";

interface ListingsFiltersModalProps {
  dismiss: () => void;
}

const ListingsFiltersModal = (props: ListingsFiltersModalProps) => {
  const isNative = DeviceService.isApp();

  const filters = useSelector(selectFiltersValue);
  const locations: Area[] = useSelector(selectLocationsValue);

  const [keyword, setKeyword] = useState("");

  const handleTypeChange = (e: CustomEvent) => {
    ListingsFiltersService.updateType(e.detail.value);
  };

  const handleLastStatusChange = (e: CustomEvent) => {
    const value = e.detail.value as "sale" | "sold" | "delisted";
    ListingsFiltersService.updateLastStatus(value);
  };

  const handlePropertyTypeChange = (type: string | null) => {
    ListingsFiltersService.updatePropertyType(type);
  };

  const handleUpdateDate = (e: CustomEvent) => {
    const value = e.detail.value as number;
    ListingsFiltersService.updateDate(value);
  };

  const handlePriceRangeChange = (e: CustomEvent) => {
    ListingsFiltersService.updatePriceRange(
      e.detail.value.lower,
      e.detail.value.upper
    );
  };

  const handleSqftRangeChange = (e: CustomEvent) => {
    ListingsFiltersService.updateSqftRange(
      e.detail.value.lower,
      e.detail.value.upper
    );
  };

  const handleMinPriceChange = (e: CustomEvent) => {
    ListingsFiltersService.updateMinPrice(e.detail.value);
  };

  const handleMaxPriceChange = (e: CustomEvent) => {
    ListingsFiltersService.updateMaxPrice(e.detail.value);
  };

  const handleBedsRangeChange = (e: CustomEvent) => {
    ListingsFiltersService.updateMinBeds(e.detail.value);
  };

  const handleBathsRangeChange = (e: CustomEvent) => {
    ListingsFiltersService.updateMinBaths(e.detail.value);
  };

  const handleMinParkingSpacesChange = (e: CustomEvent) => {
    ListingsFiltersService.updateMinParkingSpaces(e.detail.value);
  };

  const handleMaxMaintenanceFeeChange = (e: CustomEvent) => {
    ListingsFiltersService.updateMaxMaintenanceFee(e.detail.value);
  };

  const handleUpdateKeywordTerm = (e: CustomEvent) => {
    setKeyword(e.detail.value);
  };

  const handleAddKeyword = () => {
    ListingsFiltersService.addKeyword(keyword);
    setKeyword("");
  };

  const handleRemoveKeyword = (keyword: string) => {
    ListingsFiltersService.removeKeyword(keyword);
  };

  return (
    <>
      <div className={modalStyles.header}>
        <IonIcon icon={closeOutline} onClick={props.dismiss} />
        <div>Filters</div>
      </div>
      <IonContent>
        <div className={styles.filters}>
          <div className={styles.filter}>
            <IonLabel className={styles.label}>Type</IonLabel>
            <IonSegment
              mode="ios"
              className="aecorn-segment"
              onIonChange={handleTypeChange}
              value={filters.type}>
              <IonSegmentButton value={ListingType.Sale}>
                <IonLabel>Sale</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={ListingType.Lease}>
                <IonLabel>Lease</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
          <div className={styles.filter}>
            <IonLabel className={styles.label}>Status</IonLabel>
            <IonSegment
              mode="ios"
              className="aecorn-segment"
              onIonChange={handleLastStatusChange}
              value={filters.transactionStatus}>
              <IonSegmentButton value="sale">
                <IonLabel>
                  {filters.type === ListingType.Sale ? "Sale" : "Lease"}
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="sold">
                <IonLabel>
                  {filters.type === ListingType.Sale ? "Sold" : "Leased"}
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="delisted">
                <IonLabel>De-listed</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
          <div className={styles.filter}>
            <IonItem lines="none" className="aecorn-select">
              <IonSelect
                aria-label={
                  filters.transactionStatus === "sale"
                    ? "Listing date"
                    : filters.transactionStatus === "sold"
                    ? filters.type === ListingType.Sale
                      ? "Sold date"
                      : "Leased date"
                    : "De-list date"
                }
                placeholder={
                  filters.transactionStatus === "sale"
                    ? "Listing date"
                    : filters.transactionStatus === "sold"
                    ? filters.type === ListingType.Sale
                      ? "Sold date"
                      : "Leased date"
                    : "De-list date"
                }
                interface={isNative ? "alert" : "popover"}
                interfaceOptions={{
                  cssClass: isNative ? "aecorn-alert" : "aecorn-select-popover",
                }}
                justify="space-between"
                toggleIcon={chevronDownOutline}
                expandedIcon={chevronUpOutline}
                value={filters.date}
                onIonChange={handleUpdateDate}>
                {ListingsHelper.getListingDateFilterOptions().map(
                  (option, index) => (
                    <IonSelectOption key={index} value={option.value}>
                      {option.label}
                    </IonSelectOption>
                  )
                )}
              </IonSelect>
            </IonItem>
          </div>
          <div className={styles.filter}>
            <IonLabel className={styles.label}>Property Type</IonLabel>
            <div className={styles.chips}>
              <IonChip
                onClick={() => handlePropertyTypeChange(null)}
                className={`aecorn-chip ${
                  !filters.propertyType.length && "active"
                }`}>
                Any
              </IonChip>
              {ListingsHelper.getListingPropertyTypesByClass().map(
                (option, index) => (
                  <IonChip
                    key={index}
                    onClick={() => handlePropertyTypeChange(option.value)}
                    className={`aecorn-chip ${
                      filters.propertyType.includes(option.value) && "active"
                    }`}>
                    {option.label}
                  </IonChip>
                )
              )}
            </div>
          </div>
          <div className={styles.filter}>
            <IonLabel className={styles.label}>Price</IonLabel>
            <div className={styles.item}>
              <IonRange
                value={{
                  lower: ListingsHelper.convertPriceToPercentage(
                    filters.minPrice,
                    filters.type,
                    "lower"
                  ),
                  upper: ListingsHelper.convertPriceToPercentage(
                    filters.maxPrice,
                    filters.type,
                    "upper"
                  ),
                }}
                onIonChange={handlePriceRangeChange}
                dualKnobs></IonRange>
            </div>
            <div className={styles.inputs}>
              <IonInput
                min={0}
                value={filters.minPrice}
                onIonChange={handleMinPriceChange}
                type="number"
                className="aecorn-input">
                <div slot="start">{filters.minPrice ? "$" : "Any"}</div>
                <div className="aecorn-input-label" slot="label">
                  Min
                </div>
              </IonInput>
              <IonInput
                min={0}
                value={filters.maxPrice}
                onIonChange={handleMaxPriceChange}
                type="number"
                className="aecorn-input">
                <div slot="start">{filters.maxPrice ? "$" : "Any"}</div>
                <div className="aecorn-input-label" slot="label">
                  Max
                </div>
              </IonInput>
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.item}>
              <div className={styles.labelContainer}>
                <IonLabel className={styles.label}>Beds</IonLabel>
                <IonLabel className={styles.label}>
                  {filters.minBeds ? `${filters.minBeds}+` : "Any"}
                </IonLabel>
              </div>
              <IonRange
                min={0}
                max={5}
                value={filters.minBeds}
                onIonChange={handleBedsRangeChange}></IonRange>
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.item}>
              <div className={styles.labelContainer}>
                <IonLabel className={styles.label}>Bathrooms</IonLabel>
                <IonLabel className={styles.label}>
                  {filters.minBaths ? `${filters.minBaths}+` : "Any"}
                </IonLabel>
              </div>
              <IonRange
                min={0}
                max={5}
                value={filters.minBaths}
                onIonChange={handleBathsRangeChange}></IonRange>
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.item}>
              <div className={styles.labelContainer}>
                <IonLabel className={styles.label}>Parkings</IonLabel>
                <IonLabel className={styles.label}>
                  {filters.minParkingSpaces
                    ? `${filters.minParkingSpaces}+`
                    : "Any"}
                </IonLabel>
              </div>
              <IonRange
                min={0}
                max={5}
                value={filters.minParkingSpaces}
                onIonChange={handleMinParkingSpacesChange}></IonRange>
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.labelContainer}>
              <IonLabel className={styles.label}>Square Footage</IonLabel>
              <IonLabel className={styles.label}>
                {filters.minSqft && filters.maxSqft
                  ? `${filters.minSqft} - ${filters.maxSqft} sqft`
                  : filters.minSqft && !filters.maxSqft
                  ? `${filters.minSqft}+ sqft`
                  : filters.maxSqft && !filters.minSqft
                  ? `${filters.maxSqft}- sqft`
                  : "Any"}
              </IonLabel>
            </div>
            <div className={styles.item}>
              <IonRange
                value={{
                  lower: ListingsHelper.convertSqftToPercentage(
                    filters.minSqft,
                    "lower"
                  ),
                  upper: ListingsHelper.convertSqftToPercentage(
                    filters.maxSqft,
                    "upper"
                  ),
                }}
                onIonChange={handleSqftRangeChange}
                dualKnobs></IonRange>
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.labelContainer}>
              <IonLabel className={styles.label}>Max Maintenance Fee</IonLabel>
              <IonLabel className={styles.label}>
                {filters.maxMaintenanceFee
                  ? `$${filters.maxMaintenanceFee}`
                  : "Any"}
              </IonLabel>
            </div>
            <div className={styles.item}>
              <IonRange
                min={0}
                max={5000}
                value={filters.maxMaintenanceFee}
                onIonChange={handleMaxMaintenanceFeeChange}></IonRange>
            </div>
          </div>
          {locations && locations.length > 0 && (
            <div className={styles.filter}>
              <RegionsFilter />
            </div>
          )}
          <div className={styles.filter}>
            <IonLabel className={styles.label}>Keywords</IonLabel>
            <div className={styles.chips}>
              {filters.keywords.map((keyword: string, index: number) => (
                <IonChip key={index} className="aecorn-chip active">
                  <IonIcon
                    onClick={() => handleRemoveKeyword(keyword)}
                    icon={closeOutline}
                  />
                  {keyword}
                </IonChip>
              ))}
            </div>
            <div className={styles.inputs}>
              <IonInput
                placeholder="Enter a keyword"
                value={keyword}
                onIonInput={handleUpdateKeywordTerm}
                className="aecorn-input"></IonInput>
              <IonButton
                className="aecorn-button"
                disabled={keyword.length === 0}
                onClick={handleAddKeyword}>
                Add
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
      <div className={modalStyles.footer}>
        <IonButton
          onClick={() => props.dismiss()}
          size="large"
          shape="round"
          className="aecorn-button">
          Search
        </IonButton>
        <IonButton
          size="large"
          shape="round"
          className="aecorn-button clear"
          onClick={() => ListingsFiltersService.reset()}>
          Reset
        </IonButton>
      </div>
    </>
  );
};

export default ListingsFiltersModal;
