import { Link } from "react-router-dom";
import styles from "./footer.module.scss";

const Footer = ({color} : {color?: string}) => {
  return (
    <div className={styles.container} style={{backgroundColor: color ? color : "#141414"}}>
      <div>
        <span>Questions, comments or suggestions?</span>
        <span>We would love to hear from you, and</span>
        <span>welcome your feedback.</span>
        <span>
          Email us at <Link to={"mailto:info@aecorn.ca"}>info@aecorn.ca</Link>
        </span>
      </div>
      <div>
        <span>Operated by</span>
        <span>AECORN Realty Inc. Brokerage</span>
        <span>625 Cochrane Dr, Unit 801</span>
        <span>Markham, ON L3R 9R9</span>
      </div>
    </div>
  );
};

export default Footer;
