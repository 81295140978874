import styles from "./tour-stop-overview.module.scss";
import { Listing } from "models/listings/listing.model";
import GoogleMapReact from "google-map-react";
import { ReactComponent as LocationPin } from "assets/svg/location-pin.svg";
import Marker from "components/shared/Map/Map/components/Marker/marker.component";
import { googleMapStyles } from "components/shared/Map/Map/google-map.styles";
import { ListingsHelper } from "utils/ListingsHelper";
import { useSelector } from "react-redux";
import {
  selectCurrentUsername,
  selectIsAgent,
} from "redux/user/user.selectors";
import TourReview from "components/shared/Tours/TourReview/tour-review.component";
import { Review, Tour, TourItem } from "API";
import { useMediaQuery } from "react-responsive";
import { IonButton } from "@ionic/react";
import { useState } from "react";
import { isNil } from "lodash";

const TourStopOverview = ({
  tour,
  tourItem,
  listing,
  hidden,
  isClient,
}: {
  tour: Tour;
  tourItem: TourItem;
  listing: Listing;
  hidden: boolean;
  isClient?: boolean;
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const currentUserId = useSelector(selectCurrentUsername);
  const isUserAgent = useSelector(selectIsAgent);

  const [userReview, setUserReview] = useState<Review | undefined>(tourItem.reviews?.items.find(
    (r) => r?.userId === currentUserId
  ) as Review);

  const bedrooms =
    +listing.details.numBedrooms +
    (listing.details.numBedroomsPlus ? +listing.details.numBedroomsPlus : 0);
  const bathrooms =
    +listing.details.numBathrooms +
    (listing.details.numBathroomsPlus ? +listing.details.numBathroomsPlus : 0);
  const parkings =
    +listing.details.numParkingSpaces +
    (listing.details.numGarageSpaces ? +listing.details.numGarageSpaces : 0);
  return (
    <div
      style={{ display: hidden ? "none" : "flex" }}
      className={`${styles.container} ${!isClient && styles.agentContainer}`}>
      <div className={styles.stopInfo}>
        <div className={styles.map}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
            }}
            defaultCenter={{
              lat: +listing.map.latitude,
              lng: +listing.map.longitude,
            }}
            options={{
              disableDefaultUI: true,
              zoomControl: false,
              draggable: false,
              scrollwheel: false,
              panControl: false,
              styles: googleMapStyles,
              keyboardShortcuts: false,
            }}
            defaultZoom={15}>
            <Marker
              zIndex={1}
              lat={+listing.map.latitude}
              lng={+listing.map.longitude}>
              <LocationPin className={styles.pin} />
            </Marker>
          </GoogleMapReact>
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <div className={styles.label}>Address:</div>
            <div className={styles.value}>
              {ListingsHelper.getListingAddress(listing.address)}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>MLS #:</div>
            <div className={styles.value}>{listing.mlsNumber}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.label}>Beds:</div>
              <div className={styles.value}>{bedrooms}</div>
            </div>
            <div className={styles.column}>
              <div className={styles.label}>Baths:</div>
              <div className={styles.value}>{bathrooms}</div>
            </div>
            <div className={styles.column}>
              <div className={styles.label}>Parking:</div>
              <div className={styles.value}>{parkings}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Price:</div>
            <div className={styles.value}>
              {ListingsHelper.getFormattedPrice(+listing.listPrice)}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Property type:</div>
            <div className={styles.value}>
              {ListingsHelper.getListingPropertyType(
                listing.details.propertyType
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Listing brokerage:</div>
            <div className={styles.value}>
              {listing.office.brokerageName || "-"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Listing agent:</div>
            <div className={styles.value}>
              {listing.agents.length ? listing.agents.join(", ") : "-"}
            </div>
          </div>
          <IonButton className="aecorn-button primary" routerLink={`/listings/${listing.mlsNumber}`}>
            View Listing Details
          </IonButton>
        </div>
        {isUserAgent && !isMobile && (<div className={styles.review}>
          <div className={styles.title}>Client and guests reviews</div>
          <div className={styles.reviewItems}>
            {tourItem.reviews?.items.filter(review => !isNil(review)).map((review, index) => {
              return <TourReview key={index} tour={tour} tourItemId={tourItem.id} review={review!} />
            })}
          </div>
        </div>)}
      </div>
      {!isUserAgent && isMobile && (
        <div className={styles.review}>
          <TourReview
            tour={tour}
            tourItemId={tourItem.id}
            review={userReview ?? undefined}
            setUserReview={setUserReview}
          />
        </div>
      )}
      {isUserAgent && isMobile && (<div className={styles.review}>
        <div className={styles.title}>Client and guests reviews</div>
        <div className={styles.reviewItems}>
          {tourItem.reviews?.items.filter(review => !isNil(review)).map((review, index) => {
            return <TourReview key={index} tour={tour} tourItemId={tourItem.id} review={review!} />
          })}
        </div>
      </div>)}
    </div>
  );
};

export default TourStopOverview;
