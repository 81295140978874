import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  useIonModal,
  useIonRouter,
} from "@ionic/react";
import styles from "./home.module.scss";
import {
  callOutline,
  globeOutline,
  logoInstagram,
  mailOutline,
  searchOutline,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import AuthenticationModal from "components/shared/Modals/AuthenticationModal/authentication-modal.component";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as GooglePlayButton } from "assets/svg/buttons/GooglePlayButton.svg";
import { ReactComponent as AppStoreButton } from "assets/svg/buttons/AppStoreButton.svg";
import { Capacitor } from "@capacitor/core";
import "swiper/css";
import QuickSearches from "components/web/QuickSearches/quick-searches.component";
import ListingSearchModal from "components/app/modals/ListingSearchModal/listing-search-modal.component";
import { Location } from "models/locations/locations.model";
import { Listing } from "models/listings/listing.model";
import MediaQuery from "react-responsive";
import { DeviceService } from "services/deviceInfoService";
import Header from "components/web/Header/header.component";
import { ListingService } from "services/listingService";
import ListingCard from "components/shared/Listings/ListingCard/listing-card.component";
import Service1Icon from "assets/svg/homepage/service1.svg";
import Service2Icon from "assets/svg/homepage/service2.svg";
import Service3Icon from "assets/svg/homepage/service3.svg";
import DiscoverAppImage from "assets/illustrations/discover-app.png";
import TourModuleFeature from "assets/illustrations/tour-feature.png";
import PreconstructionFeature from "assets/illustrations/preconstruction-feature.png";
import SchoolDataFeature from "assets/illustrations/schools-feature.png";
import FooterLogo from "assets/svg/logo/logo-white.png";
import { Link } from "react-router-dom";
import { storeUrls } from "utils/constants";

const Home = () => {
  const router = useIonRouter();
  const pageRef = useRef<HTMLDivElement | undefined>();
  const isApp = Capacitor.isNativePlatform();
  const isAndroid = DeviceService.isAndroid();
  const isIOS = DeviceService.isIOS();
  const [authMode, setAuthMode] = useState<"login" | "register" | null>(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [AECORNListings, setAECORNListings] = useState<Listing[]>([]);

  useEffect(() => {
    const fetchAECORNListings = async () => {
      try {
        const listings = await ListingService.getAECORNListings();
        setAECORNListings(listings);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAECORNListings();
  }, []);

  const [presentAuthenticationModal, dismissAuthenticationModal] = useIonModal(
    AuthenticationModal,
    {
      onDismiss: () => {
        setAuthMode(null);
        dismissAuthenticationModal();
      },
      mode: authMode,
    }
  );

  const handleSearchLocationSelect = (location: Location) => {
    setShowSearchModal(false);
    if (location.location) {
      router.push(
        `/listings?lat=${location.location.lat}&lng=${location.location.lng}`
      );
    }
  };

  const handleSearchListingSelect = (listing: Listing) => {
    setShowSearchModal(false);
    router.push(`/listings/${listing.mlsNumber}`);
  };

  return (
    <IonPage ref={pageRef}>
      <MediaQuery minWidth={768}>
        <IonHeader mode="ios">
          <Header />
        </IonHeader>
      </MediaQuery>
      <IonContent fullscreen>
        <IonModal
          className="aecorn-modal"
          isOpen={showSearchModal}
          onDidDismiss={() => setShowSearchModal(false)}
          presentingElement={pageRef.current}>
          <ListingSearchModal
            dismiss={() => setShowSearchModal(false)}
            onSelectLocation={handleSearchLocationSelect}
            onSelectListing={handleSearchListingSelect}
          />
        </IonModal>
        <div className={styles.container}>
          <section className={styles.top}>
            <div className={styles.search}>
              <div className={styles.title}>
                Find Your Dream Home with <span>AECORN</span>
              </div>
              <div
                className={styles.input}
                onClick={() => setShowSearchModal(true)}>
                <span>Search by location or MLS#</span>
                <IonIcon icon={searchOutline} />
              </div>
            </div>
          </section>
          <div className={styles.middle}>
            <section className={styles.listings}>
              <div className={styles.title}>Latest AECORN Listings</div>
              <div className={styles.items}>
                <div className={styles.swiper}>
                  <Swiper
                    spaceBetween={12}
                    breakpoints={{
                      0: { slidesPerView: 1.1 },
                      768: { slidesPerView: "auto" },
                    }}>
                    {AECORNListings.map((listing, index) => (
                      <SwiperSlide key={index} className={styles.swiperSlide}>
                        <ListingCard
                          listing={listing}
                          style={{
                            boxShadow:
                              "0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(0, 0, 0, 0.06), 0px 3px 5px 0px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </section>
            <section className={styles.about}>
              <div className={styles.title}>About AECORN</div>
              <div className={styles.text}>
                AECORN is a full-service real estate brokerage that can guide
                you through the entire process- door to door. That's why we've
                developed a search platform with specialized features available
                exclusively through AECORN Realty.
              </div>
              <IonButton
                size="large"
                className="aecorn-button"
                routerLink="/about">
                Learn about AECORN realty brokerage
              </IonButton>
            </section>
            <section className={styles.services}>
              <div className={styles.title}>Find your path with AECORN</div>
              <div className={styles.cards}>
                <div className={styles.card}>
                  <IonIcon icon={Service1Icon} />
                  <span className={styles.title}>Buying and selling</span>
                  <span className={styles.text}>
                    We make property searching easy, whether you’re browsing or
                    ready to make the sale.
                  </span>
                  <IonButton
                    size="large"
                    className="aecorn-button clear border"
                    routerLink="/listings?type=Sale">
                    Hunt for homes
                  </IonButton>
                </div>
                <div className={styles.card}>
                  <IonIcon icon={Service2Icon} />
                  <span className={styles.title}>Leasing</span>
                  <span className={styles.text}>
                    Not looking to buy? No problem! Search, save and tour all
                    leasing opportunties with ease!
                  </span>
                  <IonButton
                    size="large"
                    className="aecorn-button clear border"
                    routerLink="/listings?type=Lease">
                    Find your lease
                  </IonButton>
                </div>
                <div className={styles.card}>
                  <IonIcon icon={Service3Icon} />
                  <span className={styles.title}>Pre-construction</span>
                  <span className={styles.text}>
                    Looking for a great investment? Check out AECORN’s exclusive
                    Pre-construction program!
                  </span>
                  <IonButton
                    size="large"
                    className="aecorn-button clear border"
                    disabled
                    routerLink="/listings?type=Pre-construction">
                    Coming soon
                  </IonButton>
                </div>
              </div>
            </section>
            {!isApp && (
              <section className={styles.app}>
                <div className={styles.title}>Discover AECORN App</div>
                <div className={styles.content}>
                  <img src={DiscoverAppImage} alt="Discover AECORN App" className={styles.featureImage} />
                  <div className={styles.info}>
                    <div className={styles.subtitle}>
                      Showing details, directions and tools all updated live.
                    </div>
                    <div className={styles.text}>
                      The AECORN app offers all the features you know and love,
                      like detailed searches, filters, favorites, saved
                      searches, custom reports and make-an-offer. But the AECORN
                      app also includes exclusives that you wont find anywhere
                      else.
                    </div>
                    <div className={styles.buttons}>
                      {(isAndroid || (!isAndroid && !isIOS)) && (
                        <GooglePlayButton onClick={() => window.open(storeUrls.android, "_blank")} />
                      )}
                      {(isIOS || (!isAndroid && !isIOS)) && <AppStoreButton onClick={() => window.open(storeUrls.apple, "_blank")} />}
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section className={styles.feature}>
              <div className={styles.content}>
                <div className={styles.subtitle}>Tour module</div>
                <div className={styles.text}>
                  It’s time to move beyond just property-hunting. This feature
                  allows agents and clients to work together seamlessly to book
                  showings and set up a customized tour schedule — complete with
                  tour notes, meetup details, contact information and navigation
                  tools.
                </div>
              </div>
              <img alt="Tour module" src={TourModuleFeature} className={styles.featureImage} />
            </section>
            <section className={`${styles.feature} ${styles.reversed}`}>
              <div className={styles.content}>
                <div className={styles.tag}>Coming soon</div>
                <div className={styles.subtitle}>
                  Pre-construction properties
                </div>
                <div className={styles.text}>
                  When you’re looking for pre-construction, quality matters.
                  AECORN's pre-con specialists hand-select the leading projects
                  on the market so that you know you're picking from the best of
                  the best.
                </div>
              </div>
              <img
                alt="Pre-construction properties"
                src={PreconstructionFeature}
                className={styles.featureImage}
              />
            </section>
            <section className={styles.feature}>
              <div className={styles.content}>
                <div className={styles.subtitle}>School data</div>
                <div className={styles.text}>
                  Not looking to buy? No problem! Search, save and tour all
                  leasing opportunties with ease!
                </div>
              </div>
              <img alt="School data" src={SchoolDataFeature} className={styles.featureImage} />
            </section>
          </div>
          <section className={styles.bottom}>
            <QuickSearches />
          </section>
          <footer>
            <div>
              <img src={FooterLogo} alt="AECORN Realty" />
              <p>
                AECORN is a full-service real estate brokerage that can guide
                you through the entire process door to door.
              </p>
              <div className={styles.social}>
                Follow us:{" "}
                <span className={styles.icons}>
                  <IonIcon
                    icon={logoInstagram}
                    onClick={() =>
                      window.open("https://instagram.com/aecorn.ca", "_blank")
                    }
                  />
                </span>
              </div>
            </div>
            <div>
              <span className={styles.title}>
                Questions, comments or suggestions?
              </span>
              <p>We would love to hear from you, and welcome your feedback.</p>
              <div className={styles.contacts}>
                <span>
                  <IonIcon icon={mailOutline} />{" "}
                  <a href="mailto:info@aecorn.ca">info@aecorn.ca</a>
                </span>
                <span>
                  <IonIcon icon={mailOutline} />{" "}
                  <a href="mailto:info@aecorn.ca">support@aecorn.ca</a>
                </span>
                <span>
                  <IonIcon icon={callOutline} />{" "}
                  <a href="tel:+14168038444">+1 416 803 8444</a>
                </span>
              </div>
            </div>
            <div>
              <span className={styles.title}>Operated by</span>
              <p>
                AECORN Realty Inc. Brokerage <br />
                120 East Beaver Creek Rd, Suite 200, Richmond Hill, ON. L4B 4V1
              </p>
              <div className={styles.contacts}>
                <span>
                  <IonIcon icon={globeOutline} />{" "}
                  <a href="https://aecorn.ca">www.aecorn.ca</a>
                </span>
              </div>
            </div>
            {!isApp && (
              <div className={styles.app}>
                <span className={styles.title}>Download AECORN App</span>
                <div className={styles.buttons}>
                  {(isAndroid || (!isAndroid && !isIOS)) && (
                    <GooglePlayButton onClick={() => window.open(storeUrls.android, "_blank")} />
                  )}
                  {(isIOS || (!isAndroid && !isIOS)) && <AppStoreButton onClick={() => window.open(storeUrls.apple, "_blank")} />}
                </div>
              </div>
            )}
            <div className={styles.legal}>
              <span className={styles.links}>
                <Link to="/terms">Terms and conditions</Link>
                <Link to="/privacy">Privacy policy</Link>
              </span>
              <span className={styles.copy}>
                &copy; {new Date().getFullYear()} AECORN Realty Inc. All rights
                reserved.
              </span>
            </div>
          </footer>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
