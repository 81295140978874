import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
  IonThumbnail,
} from "@ionic/react";
import styles from "./listing-search-modal.module.scss";
import { searchOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { Listing } from "models/listings/listing.model";
import { Location, Locations } from "models/locations/locations.model";
import LocationSearchResult from "components/shared/Listings/LocationSearchResult/location-search-result.component";
import ListingListItem from "components/shared/Listings/ListingListItem/listing-list-item.component";
import { debounce } from "lodash";
import TelescopeIcon from "assets/svg/icons/telescope.svg";
import { GeneralConstants } from "utils/constants";
import { API } from "aws-amplify";

export const ListingSearchModal = ({
  onSelectLocation,
  onSelectListing,
  dismiss,
}: {
  onSelectLocation: (location: Location) => void;
  onSelectListing: (listing: Listing) => void;
  dismiss: () => void;
}) => {
  const [history, setHistory] = useState<
    { type: "city" | "neighborhood" | "address"; value: Location }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [listings, setListings] = useState<Listing[]>([]);
  const [locations, setLocations] = useState<Locations>({
    cities: [],
    neighborhoods: [],
  });
  const [googleAddresses, setGoogleAddresses] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const inputRef = useRef<HTMLIonInputElement>(null);
  const currentRequest = useRef<Promise<any> | null>(null);

  const onSelectListingResult = (listing: Listing) => {
    onSelectListing(listing);
    dismiss();
  };

  const onSelectSearchResult = (
    location: Location,
    type: "city" | "neighborhood" | "address"
  ) => {
    if (location.placeId) {
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      service.getDetails(
        {
          placeId: location.placeId,
          fields: ["geometry"],
        },
        (place, status) => {
          if (
            place?.geometry?.location &&
            status === window.google.maps.places.PlacesServiceStatus.OK
          ) {
            const locationWithCoordinates = {
              ...location,
              location: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              },
            };
            addToHistory(locationWithCoordinates, type);
            onSelectLocation(locationWithCoordinates);
          }
        }
      );
    } else {
      addToHistory(location, type);
      onSelectLocation(location);
    }
    dismiss();
  };

  const addToHistory = (location: Location, type: "city" | "neighborhood" | "address") => {
    if (!history.find(h => h.value.name === location.name)) {
      const updatedHistory = [...history, { type, value: location }];
      localStorage.setItem(
        GeneralConstants.SEARCH_HISTORY_KEY,
        JSON.stringify(updatedHistory)
      );
      setHistory(updatedHistory);
    }
  };

  const onClearHistory = () => {
    localStorage.removeItem(GeneralConstants.SEARCH_HISTORY_KEY);
    setHistory([]);
  }

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm) {
        if (currentRequest.current) {
          API.cancel(currentRequest.current, "Cancelled");
          currentRequest.current = null;
        }

        try {
          setLoading(true);

          if (searchTerm.length < 3) {
            setListings([]);
            setLocations({ cities: [], neighborhoods: [] });
          } else {
            const requestPromise = API.get(
              GeneralConstants.REST_API_NAME,
              "/listings/search",
              {
                queryStringParameters: { term: searchTerm, activeListingsOnly: false },
              }
            );
            currentRequest.current = requestPromise;
            const response = await requestPromise;
            setListings(response.data.listings);
            setLocations(response.data.locations);
            setLoading(false);
          }
        } catch (error: any) {
          if (error.name !== 'CanceledError') {
            console.error(error);
            setLoading(false);
          }
        }
      } else {
        setListings([]);
        setLocations({ cities: [], neighborhoods: [] });
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  useEffect(() => {
    if (!window.google) return;

    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    const fetchPredictions = () => {
      if (searchTerm.length > 3) {
        autocompleteService.getPlacePredictions(
          {
            input: searchTerm,
            componentRestrictions: { country: "ca" },
            types: ["address"],
          },
          async (results, status) => {
            if (
              results &&
              status === window.google.maps.places.PlacesServiceStatus.OK
            ) {
              setGoogleAddresses(results);
            } else {
              setGoogleAddresses([]);
            }
          }
        );
      } else {
        setGoogleAddresses([]);
      }
    };

    debounce(fetchPredictions, 500)();
  }, [searchTerm]);

  useEffect(() => {
    const searchHistory = localStorage.getItem(
      GeneralConstants.SEARCH_HISTORY_KEY
    );
    if (searchHistory) {
      setHistory(JSON.parse(searchHistory));
    }
  }, []);

  return (
    <>
      <IonHeader mode="ios" className={styles.header}>
        <IonItem lines="none">
          <IonInput
            ref={inputRef}
            value={searchTerm}
            onIonInput={(e) => setSearchTerm(e.detail.value!)}
            clearInput
            autoFocus={true}
            autocomplete="street-address"
            placeholder="Search by location or MLS#">
            <IonIcon slot="end" icon={searchOutline} />
          </IonInput>
        </IonItem>
      </IonHeader>
      <IonContent>
        <div className={styles.container}>
          {searchTerm.length > 0 ? (
            loading ? (
              <IonList>
                {Array.from({ length: 10 }).map((_, i) => (
                  <IonItem key={i} lines="none">
                    <IonThumbnail slot="start">
                      <IonSkeletonText animated />
                    </IonThumbnail>
                    <IonLabel>
                      <h3>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "80%" }}></IonSkeletonText>
                      </h3>
                      <p>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "60%" }}></IonSkeletonText>
                      </p>
                      <p>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "30%" }}></IonSkeletonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
            ) : listings.length > 0 ||
              locations.cities.length > 0 ||
              locations.neighborhoods.length > 0 ||
              googleAddresses.length > 0 ? (
              <div className={styles.listings}>
                {(locations.cities.length > 0 ||
                  locations.neighborhoods.length > 0) && (
                    <div className={styles.title}>Locations</div>
                  )}
                {locations.cities.map((location, index) => (
                  <div key={index} className={styles.locationResult}>
                    <LocationSearchResult
                      term={searchTerm}
                      location={location}
                      type="city"
                      onClick={(loc) => onSelectSearchResult(loc, "city")}
                    />
                  </div>
                ))}
                {locations.neighborhoods.map((location, index) => (
                  <div key={index} className={styles.locationResult}>
                    <LocationSearchResult
                      term={searchTerm}
                      location={location}
                      type="neighborhood"
                      onClick={(loc) =>
                        onSelectSearchResult(loc, "neighborhood")
                      }
                    />
                  </div>
                ))}
                {googleAddresses.map((address, index) => (
                  <div key={index} className={styles.locationResult}>
                    <LocationSearchResult
                      term={searchTerm}
                      location={{
                        name: address.description,
                        placeId: address.place_id,
                      }}
                      type="address"
                      onClick={(loc) => onSelectSearchResult(loc, "address")}
                    />
                  </div>
                ))}
                {listings.length > 0 && (
                  <div className={styles.title}>Listings</div>
                )}
                {listings.map((listing, i) => (
                  <div key={i} className={styles.listingResult}>
                    <ListingListItem
                      listing={listing}
                      onClick={() => onSelectListingResult(listing)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.noListings}>
                <IonIcon icon={TelescopeIcon} />
                <div className={styles.title}>No matching result</div>
                <p>
                  Try typing the location, finding the property on map, or use
                  filters to get desired results.
                </p>
              </div>
            )
          ) : history.length > 0 ? (
            <div className={styles.listings}>
              <div className={styles.title}>
                <span>Search history</span>
                <IonButton size="small" className="aecorn-button clear-dark" onClick={onClearHistory}>Clear</IonButton>
              </div>
              {history.map((item, i) => (
                <div key={i} className={styles.locationResult}>
                  <LocationSearchResult
                    term={item.value.name}
                    location={item.value}
                    type={item.type}
                    onClick={(loc) => onSelectSearchResult(loc, item.type)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </IonContent>
    </>
  );
};

export default ListingSearchModal;
