import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonLoading,
  IonRow,
  useIonToast,
} from "@ionic/react";
import styles from "./tour-add-showing-modal.module.scss";
import modalStyles from "../modals.module.scss";
import { Showing, Tour, TourClientRequest, TourItem, TourItemStatus } from "API";
import {
  addOutline,
  chevronBackOutline,
  closeOutline,
  removeOutline,
  searchOutline,
} from "ionicons/icons";
import favouriteIcon from "assets/svg/star-light.svg";
import MediaQuery from "react-responsive";
import { TourService } from "services/tourService";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Listing } from "models/listings/listing.model";
import { ListingService } from "services/listingService";
import { FavoritesService } from "services/favoritesService";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import { ListingsHelper } from "utils/ListingsHelper";
import TourStatusPill from "components/shared/TourStatusPill/tour-status-pill.component";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import Modal from "components/ui/modal/modal.component";
import { TourStop } from "models/tours/tour-stop.model";
import { API } from "aws-amplify";
import { GeneralConstants } from "utils/constants";

const TourAddShowingModal = ({
  tour,
  stops,
  tourItems,
  dismiss,
}: {
  tour: Tour;
  stops: TourStop[];
  tourItems: TourItem[];
  dismiss: () => void;
}) => {
  const [presentToast] = useIonToast();
  const [showModal, setShowModal] = useState(false);
  const client = TourService.getTourClient(tour);
  const [selectedTypes, setSelectedTypes] = useState<
    ("bookedShowings" | "favorites" | "requests")[]
  >(["bookedShowings", "requests"]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchListings, setSearchListings] = useState<Listing[]>([]);
  const [favoriteListings, setFavoriteListings] = useState<Listing[]>([]);
  const [requests, setRequests] = useState<
    { request: TourClientRequest; listing: Listing }[]
  >([]);
  const [bookedShowings, setBookedShowings] = useState<
    { showing: Showing; listing: Listing }[]
  >([]);
  const [addedShowings, setAddedShowings] = useState<Listing[]>([]);
  const currentRequest = useRef<Promise<any> | null>(null);

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const updateSelectedType = (
    type: "bookedShowings" | "favorites" | "requests"
  ) => {
    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((t) => t !== type));
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  const handleAddShowing = async (listing: Listing) => {
    if (addedShowings.find((l) => l.mlsNumber === listing.mlsNumber)) {
      presentToast({
        message: "You have already added this MLS # to the tour.",
        duration: 3000,
        position: "top",
        cssClass: "aecorn-warning-toast",
      });
      return;
    } else if (
      stops.find((stop) => stop.listing.mlsNumber === listing.mlsNumber)
    ) {
      presentToast({
        message: "This MLS # is already in the tour.",
        duration: 3000,
        position: "top",
        cssClass: "aecorn-warning-toast",
      });
      return;
    } else {
      setAddedShowings([...addedShowings, listing]);
    }
  };

  const handleRemoveShowing = (listing: Listing) => {
    const filteredListings = addedShowings.filter(
      (l) => l.mlsNumber !== listing.mlsNumber
    );
    setAddedShowings(filteredListings);
    if (filteredListings.length === 0) {
      setShowModal(false);
    }
  };

  const onSubmit = async () => {
    try {
      setSubmitLoading(true);
      const latestOrder = tourItems ? tourItems.reduce(
        (acc, item) => ((item?.order || 0) > acc ? item?.order || 0 : acc),
        0
      ) : 0;

      const promises = addedShowings.map((listing, index) => {
        const bookedShowing = bookedShowings.find(
          (l) => l.listing.mlsNumber === listing.mlsNumber
        );

        const addShowingPromise = TourService.addShowingToTour(tour, {
          tourId: tour.id!,
          mlsNumber: listing.mlsNumber,
          order: latestOrder + index + 1,
          status: bookedShowing
            ? TourItemStatus.confirmed
            : TourItemStatus.requested,
          startTime: bookedShowing?.showing.startTime,
          endTime: bookedShowing?.showing.endTime,
          privateNote: bookedShowing?.showing.instructions,
          entryInfo: bookedShowing?.showing.entryInfo,
        });


        if (bookedShowing) {
          addShowingPromise.then(() => {
            TourService.removeBookedShowing(bookedShowing.showing.id);
            setBookedShowings((prev) =>
              prev.filter((l) => l.showing.id !== bookedShowing.showing.id)
            );
          });
        }

        return addShowingPromise;
      });

      await Promise.all(promises);


      presentToast({
        message: "Showings added to the tour successfully.",
        duration: 3000,
        position: "top",
        cssClass: "aecorn-success-toast",
      });

      dismiss();
    } catch (error) {
      console.error(error);
      presentToast({
        message: "Failed to add showings to the tour.",
        duration: 3000,
        position: "top",
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    const fetchListings = async () => {
      try {
        setLoading(true);

        const favoritesPromise = async () => {
          const favorites = await FavoritesService.getFavorites();
          const promises = favorites.map((fav) =>
            ListingService.getListingByMlsNumber(fav.mlsNumber)
          );
          return Promise.all(promises);
        };

        const bookedPromise = async () => {
          if (tour.date) {
            const booked = await TourService.getBookedShowingsByDate(tour.date);
            const promises = booked.map(async (showing) => {
              const listing = await ListingService.getListingByMlsNumber(
                showing.mlsNumber!
              );
              return { showing, listing };
            });
            return Promise.all(promises);
          } else {
            return [];
          }
        };

        const result = await Promise.all([favoritesPromise(), bookedPromise()]);

        setFavoriteListings(
          (result[0] as Listing[]).filter(
            (listing) => listing.status === ListingStatus.Active
          )
        );
        setBookedShowings(
          result[1] as { showing: Showing; listing: Listing }[]
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchListings();
  }, []);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm) {

        try {
          if (currentRequest.current) {
            API.cancel(currentRequest.current, "Cancelled");
            currentRequest.current = null;
          }

          if (searchTerm.length < 3) {
            setSearchListings([]);
            return;
          } else {
            const requestPromise = API.get(
              GeneralConstants.REST_API_NAME,
              "/listings/search",
              {
                queryStringParameters: { term: searchTerm, activeListingsOnly: true },
              }
            );

            currentRequest.current = requestPromise;

            const response = await requestPromise;
            const listings = response.data.listings as Listing[];
            setSearchListings(listings);
          }

        } catch (error: any) {
          if (error.name !== 'CanceledError') {
            console.error(error);
          }
        }
      } else {
        setSearchListings([]);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  const BookedShowing = ({
    showing,
    listing,
  }: {
    showing: Showing;
    listing: Listing;
  }) => {
    return (
      <div className={styles.listing}>
        <div className={styles.info}>
          <span className={styles.address}>
            {ListingsHelper.getListingAddress(listing.address)}{" "}
            <span className={styles.type}>(For {listing.type})</span>
          </span>
          <span className={styles.datetime}>
            {moment(showing.date).format("DD MMM")}-{showing.startTime}-
            {showing.endTime}
          </span>
        </div>
        <div className={styles.actions}>
          <TourStatusPill status={TourItemStatus.confirmed} />
          <ListingButton listing={listing} />
        </div>
      </div>
    );
  };

  const FavoriteListing = ({ listing }: { listing: Listing }) => {
    return (
      <div className={styles.listing}>
        <div className={styles.info}>
          <span className={styles.address}>
            {ListingsHelper.getListingAddress(listing.address)}{" "}
            <span className={styles.type}>(For {listing.type})</span>
          </span>
        </div>
        <div className={styles.actions}>
          <IonIcon
            className={`${styles.icon} ${styles.favoriteIcon}`}
            icon={favouriteIcon}
          />
          <ListingButton listing={listing} />
        </div>
      </div>
    );
  };

  const SearchListing = ({ listing }: { listing: Listing }) => {
    return (
      <div className={styles.listing}>
        <div className={styles.info}>
          <span className={styles.address}>
            {ListingsHelper.getListingAddress(listing.address)}{" "}
            <span className={styles.type}>(For {listing.type})</span>
          </span>
        </div>
        <div className={styles.actions}>
          <IonIcon
            className={`${styles.icon} ${styles.searchIcon}`}
            icon={searchOutline}
          />
          <ListingButton listing={listing} />
        </div>
      </div>
    );
  };

  const ListingButton = ({ listing }: { listing: Listing }) => {
    return (
      <IonButton
        className="aecorn-button"
        size="small"
        onClick={() =>
          !addedShowings.find((l) => l.mlsNumber === listing.mlsNumber)
            ? handleAddShowing(listing)
            : handleRemoveShowing(listing)
        }>
        <IonIcon
          slot="icon-only"
          icon={
            addedShowings.find((l) => l.mlsNumber === listing.mlsNumber)
              ? removeOutline
              : addOutline
          }
        />
      </IonButton>
    );
  };

  return (
    <>
      <MediaQuery minWidth={768}>
        <div className={`${modalStyles.header} ${modalStyles.tour} ${modalStyles.safePadding}`}>
          <IonIcon icon={closeOutline} onClick={dismiss} />
          <div>Add showings to {tour.title}</div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <IonHeader mode="ios" className={modalStyles.toursHeader}>
          <IonButton className="aecorn-button clear-dark" onClick={dismiss}>
            Back to {tour.title}
            <IonIcon slot="start" icon={chevronBackOutline} />
          </IonButton>
          <div className={modalStyles.title}>
            Add showings to <span>{tour.title}</span>
          </div>
          <div className={styles.tourInfo}>
            <span>{client?.displayName || ""}</span>
            <span>
              {tour.date && `${moment(tour.date).format("d MMMM")}, `}
              {`${tour.startTime
                ? moment(tour.startTime, "HH:mm").format("hh:mm A")
                : ""
                } - ${tour.endTime
                  ? moment(tour.endTime, "HH:mm").format("hh:mm A")
                  : ""
                }`}
            </span>
          </div>
        </IonHeader>
      </MediaQuery>
      <IonContent className={styles.content}>
        <IonLoading isOpen={submitLoading} message="Please wait..." />
        <Modal
          isOpen={showModal}
          onDidDismiss={() => setShowModal(false)}
          initialBreakpoint={1}
          breakpoints={[0, 1]}>
          <div className={styles.sheetContent}>
            <div className={styles.title}>Added showings list</div>
            <div className={styles.listings}>
              <span>
                {addedShowings.length === 1
                  ? `You are adding ${ListingsHelper.getListingAddress(
                    addedShowings[0].address
                  )} to ${tour.title}.`
                  : `You are adding the following listings to ${tour.title}`}
              </span>
              <div className={styles.list}>
                {addedShowings.map((listing, index) => (
                  <IonItem key={index} lines="none">
                    <div className={styles.listing}>
                      <span>Listing MLS #: {listing.mlsNumber}</span>
                      <IonIcon
                        icon={closeOutline}
                        onClick={() => handleRemoveShowing(listing)}
                      />
                    </div>
                  </IonItem>
                ))}
              </div>
              <IonButton
                className="aecorn-button"
                size="large"
                expand="block"
                onClick={onSubmit}>
                Approve
              </IonButton>
            </div>
          </div>
        </Modal>
        <MediaQuery minWidth={768}>
          <div className={styles.webContainer}>
            <div className={styles.leftSection}>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none" className="aecorn-search-input">
                      <IonInput
                        value={searchTerm}
                        debounce={500}
                        onIonInput={(e) => onSearch(e.detail.value!)}
                        clearInput
                        placeholder="Search by location or MLS#"
                        className="aecorn-search-input">
                        <IonIcon slot="start" icon={searchOutline} />
                      </IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className={styles.types}>
                      <IonCheckbox
                        checked={selectedTypes.includes("bookedShowings")}
                        onIonChange={() => updateSelectedType("bookedShowings")}
                        className="aecorn-checkbox"
                        labelPlacement="end">
                        Booked
                      </IonCheckbox>
                      {/* <IonCheckbox
                        checked={selectedTypes.includes("requests")}
                        onIonChange={() => updateSelectedType("requests")}
                        className="aecorn-checkbox"
                        labelPlacement="end">
                        Requests
                      </IonCheckbox> */}
                      <IonCheckbox
                        checked={selectedTypes.includes("favorites")}
                        onIonChange={() => updateSelectedType("favorites")}
                        className="aecorn-checkbox"
                        labelPlacement="end">
                        Favorites
                      </IonCheckbox>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {!loading &&
                !searchListings.length &&
                (!selectedTypes.includes("favorites") ||
                  !favoriteListings.length) &&
                (!selectedTypes.includes("bookedShowings") ||
                  !bookedShowings.length) && (
                  <div className={styles.noResults}>
                    <IonIcon icon={searchOutline} />
                    <span>No results yet</span>
                  </div>
                )}
              {loading ? (
                <LoadingScreen />
              ) : (
                <IonList>
                  {selectedTypes.includes("bookedShowings") &&
                    bookedShowings.map(({ showing, listing }) => (
                      <IonItem key={showing.id} lines="none">
                        <BookedShowing showing={showing} listing={listing} />
                      </IonItem>
                    ))}
                  {selectedTypes.includes("favorites") &&
                    favoriteListings.map((listing, index) => (
                      <IonItem
                        key={`favorite-${listing.mlsNumber}-${index}`}
                        lines="none">
                        <FavoriteListing listing={listing} />
                      </IonItem>
                    ))}
                  {searchListings.map((listing, index) => (
                    <IonItem
                      key={`search-${listing.mlsNumber}-${index}`}
                      lines="none">
                      <SearchListing listing={listing} />
                    </IonItem>
                  ))}
                </IonList>
              )}
            </div>
            <div className={styles.rightSection}>
              <IonButton
                className={`aecorn-button ${styles.totalButton}`}
                expand="block"
                size="large">
                <span>Added showings list</span>
                <span className={styles.total}>{addedShowings.length}</span>
              </IonButton>
              <IonList className={styles.list}>
                {addedShowings.map((listing, index) => (
                  <IonItem key={index} lines="none">
                    <div className={styles.listing}>
                      <span>Listing MLS #: {listing.mlsNumber}</span>
                      <IonIcon
                        icon={closeOutline}
                        onClick={() => handleRemoveShowing(listing)}
                      />
                    </div>
                  </IonItem>
                ))}
              </IonList>
              {addedShowings.length > 0 && (
                <IonButton
                  className="aecorn-button"
                  expand="block"
                  size="large"
                  onClick={onSubmit}>
                  Approve
                </IonButton>
              )}
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <div className={styles.leftSection}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonItem lines="none" className="aecorn-search-input">
                    <IonInput
                      value={searchTerm}
                      debounce={500}
                      onIonInput={(e) => onSearch(e.detail.value!)}
                      clearInput
                      placeholder="Search by location or MLS#"
                      className="aecorn-search-input">
                      <IonIcon slot="start" icon={searchOutline} />
                    </IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div className={styles.types}>
                    <IonCheckbox
                      checked={selectedTypes.includes("bookedShowings")}
                      onIonChange={() => updateSelectedType("bookedShowings")}
                      className="aecorn-checkbox"
                      labelPlacement="end">
                      Booked
                    </IonCheckbox>
                    {/* <IonCheckbox
                      checked={selectedTypes.includes("requests")}
                      onIonChange={() => updateSelectedType("requests")}
                      className="aecorn-checkbox"
                      labelPlacement="end">
                      Requests
                    </IonCheckbox> */}
                    <IonCheckbox
                      checked={selectedTypes.includes("favorites")}
                      onIonChange={() => updateSelectedType("favorites")}
                      className="aecorn-checkbox"
                      labelPlacement="end">
                      Favorites
                    </IonCheckbox>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            {!loading &&
              !searchListings.length &&
              (!selectedTypes.includes("favorites") ||
                !favoriteListings.length) &&
              (!selectedTypes.includes("bookedShowings") ||
                !bookedShowings.length) && (
                <div className={styles.noResults}>
                  <IonIcon icon={searchOutline} />
                  <span>No results yet</span>
                </div>
              )}
            {loading ? (
              <LoadingScreen />
            ) : (
              <IonList>
                {selectedTypes.includes("bookedShowings") &&
                  bookedShowings.map(({ showing, listing }) => (
                    <IonItem key={showing.id} lines="none">
                      <BookedShowing showing={showing} listing={listing} />
                    </IonItem>
                  ))}
                {selectedTypes.includes("favorites") &&
                  favoriteListings.map((listing, index) => (
                    <IonItem
                      key={`favorite-${listing.mlsNumber}-${index}`}
                      lines="none">
                      <FavoriteListing listing={listing} />
                    </IonItem>
                  ))}
                {searchListings.map((listing, index) => (
                  <IonItem
                    key={`search-${listing.mlsNumber}-${index}`}
                    lines="none">
                    <SearchListing listing={listing} />
                  </IonItem>
                ))}
              </IonList>
            )}
          </div>
        </MediaQuery>
      </IonContent>
      <MediaQuery maxWidth={767}>
        {addedShowings.length > 0 && (
          <IonFooter className={styles.footer}>
            <IonButton
              className={`aecorn-button ${styles.totalButton}`}
              expand="block"
              size="large"
              onClick={() => setShowModal(true)}>
              <span>Added showings list</span>
              <span className={styles.total}>{addedShowings.length}</span>
            </IonButton>
          </IonFooter>
        )}
      </MediaQuery>
    </>
  );
};

export default TourAddShowingModal;
