import { IonIcon, useIonModal } from "@ionic/react";
import styles from "./restricted-access-card.module.scss";
import LockIcon from "assets/svg/lock.svg";
import AuthenticationModal from "../Modals/AuthenticationModal/authentication-modal.component";
import { useState } from "react";

const RestrictedAccessCard = ({
  size = "medium",
}: {
  size?: "medium" | "small";
}) => {
  const [authMode, setAuthMode] = useState<"login" | "register" | null>(null);
  const [presentAuthenticationModal, dismiss] = useIonModal(
    AuthenticationModal,
    {
      onDismiss: () => {
        setAuthMode(null);
        dismiss();
      },
      mode: authMode,
    }
  );

  const openAuthenticationModal = (mode: "login" | "register") => {
    setAuthMode(mode);
    presentAuthenticationModal({
      cssClass: "auth-modal",
      onDidDismiss: () => setAuthMode(null),
    });
  };

  return (
    <div className={`${styles.container} ${size === "small" && styles.small}`}>
      <IonIcon icon={LockIcon} />
      <p>
        Please{" "}
        <span onClick={() => openAuthenticationModal("register")}>
          register
        </span>{" "}
        or <span onClick={() => openAuthenticationModal("login")}>sign in</span>{" "}
        to view this information.
      </p>
    </div>
  );
};

export default RestrictedAccessCard;
