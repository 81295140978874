import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import styles from "./agent-tour.module.scss";
import MediaQuery from "react-responsive";
import Header from "components/web/Header/header.component";
import { useEffect, useRef, useState } from "react";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import { TourService } from "services/tourService";
import { ShareStatus, Tour, TourItem, TourItemStatus, TourStatus } from "API";
import { useHistory } from "react-router";
import { navigateOutline, shareSocialOutline } from "ionicons/icons";
import TourStatusPill from "components/shared/TourStatusPill/tour-status-pill.component";
import { Listing } from "models/listings/listing.model";
import { ListingService } from "services/listingService";
import AgentTourStatusAndListings from "./segments/AgentTourStatusAndListings/agent-tour-status-and-listings.component";
import { TourStop } from "models/tours/tour-stop.model";
import AgentTourInfo from "./segments/AgentTourInfo/agent-tour-info.component";
import AgentTourAttendees from "./segments/AgentTourAttendees/agent-tour-attendees.component";
import { TourItemService } from "services/tourItemService";
import { useDispatch, useSelector } from "react-redux";
import { markNotification } from "redux/ui/ui.actions";

enum TourTabs {
  StatusAndListings = 1,
  Info = 2,
  RequestsHistory = 3,
  Attendees = 4,
}

const AgentTour = ({ tourId }: { tourId: string }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [presentToast] = useIonToast();
  const [loading, setLoading] = useState(true);
  const [listingsLoading, setListingsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<TourTabs>(
    TourTabs.StatusAndListings
  );
  const [tour, setTour] = useState<Tour>();
  const [tourItems, setTourItems] = useState<TourItem[]>([]);
  const [stops, setStops] = useState<TourStop[]>([]);
  const segmentRef = useRef<HTMLIonSegmentElement>(null);
  const subscriptions = useRef<any[]>([]);

  const fetchTour = async () => {
    try {
      setLoading(true);
      const tour = await TourService.getTourById(tourId);
      if (tour) {
        setTour(tour);
        await TourService.markTourAsSeen(tour);
        dispatch(markNotification(tour.id));
      } else {
        history.replace("/tours");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchTourItems = async () => {
    try {
      setListingsLoading(true);
      const tourItems = await TourItemService.getTourItemsByTourId(tourId);
      setTourItems(tourItems);
      const tourStops = await Promise.all(
        tourItems
          .filter((item) => item)
          .map(async (item) => ({
            id: item!.id,
            listing: await ListingService.getListingByMlsNumber(
              item!.mlsNumber
            ),
            order: item?.order,
            status: item?.status,
            startTime: item?.startTime,
            endTime: item?.endTime,
          }))
      );

      setStops(tourStops.sort((s1, s2) => s1.order! - s2.order!));
    } catch (e) {
      console.error(e);
    } finally {
      setListingsLoading(false);
    }
  };

  const navigateTourStops = async () => {
    TourService.navigateTourStops(tour!, stops);
  };

  const handleSegmentScroll = () => {
    const scrollLeft = segmentRef.current?.scrollLeft || 0;

    const firstIonSegmentButton = segmentRef.current?.querySelector(
      "ion-segment-button"
    ) as HTMLElement;

    const firstIonSegmentButtonWidth = firstIonSegmentButton.clientWidth;

    if (scrollLeft > firstIonSegmentButtonWidth) {
      segmentRef.current?.classList.add(styles.noMargin);
    } else if (scrollLeft === 0) {
      segmentRef.current?.classList.remove(styles.noMargin);
    }
  };

  const reorderStops = (stops: TourStop[]) => {
    const previousStops = [...stops];
    try {
      setStops(
        stops
          .map((stop, index) => ({ ...stop, order: index + 1 }))
          .sort((s1, s2) => s1.order! - s2.order!)
      );
      const updatedTourStops = stops.map((stop, index) => ({
        id: stop.id,
        order: index + 1,
      }));

      TourService.reorderTourStops(updatedTourStops);
      presentToast({
        message: "Stops reordered successfully",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
    } catch (e) {
      console.error(e);
      presentToast({
        message: "Failed to reorder stops",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
      setStops(previousStops);
    }
  };

  const updateStatus = async (status: TourStatus) => {
    try {
      if (status === TourStatus.cancelled) {
        await TourService.cancelTour(tour!.id);
      }
      const updatedTour = await TourService.updateTour({
        id: tour!.id,
        status,
      });
      setTour(updatedTour);

      presentToast({
        message: "Tour status updated successfully",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
    } catch (e) {
      console.error(e);
      presentToast({
        message: "Failed to update tour status",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const onShare = async () => {
    if (!tour) return;
    try {
      setSubmitLoading(true);
      await TourService.shareTour(tour.id);
      setTour({ ...tour, shared: ShareStatus.shared });
      presentToast({
        message: "Tour shared with client successfully",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
    } catch (e) {
      console.error(e);
      presentToast({
        message: "Failed to share tour with client",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    fetchTour();
  }, [tourId]);

  useIonViewWillEnter(() => {
    fetchTourItems();
  });

  useEffect(() => {
    if (tour) {
      const tourChangeSubscription = TourService.onTourUpdate(
        tour.id,
        (data) => {
          if (data) {
            setTour(data);
          }
        }
      );

      const newTourItemSubscription = TourItemService.onTourItemCreateByTourId(tour.id, () => {
        fetchTourItems();
      });

      const tourPendingClientsSubscription =
        TourService.onTourPendingClientsUpdate(tour.id, (tour) => {
          if (tour) {
            setTour(tour);
          }
        });

      subscriptions.current.push(
        tourChangeSubscription,
        tourPendingClientsSubscription,
        newTourItemSubscription
      );
    }

    return () => {
      subscriptions.current.forEach((sub) => sub.unsubscribe());
    };
  }, [tour]);

  const TopSection = () => (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton
            className="aecorn-back-button"
            text="Back to My Tours"
            defaultHref="/tabs/tours"
          />
        </IonButtons>
        <MediaQuery maxWidth={767}>
          {tour?.shared === ShareStatus.unshared && (
            <IonButtons slot="end">
              <IonButton
                disabled={!tour?.clients?.length}
                onClick={onShare}
                className="aecorn-button border clear">
                Share with client{" "}
                <IonIcon icon={shareSocialOutline} slot="end" />
              </IonButton>
            </IonButtons>
          )}
        </MediaQuery>
      </IonToolbar>
      <div className={styles.summary}>
        <span className={styles.title}>
          <span>{tour?.title}</span>
          {tour?.status && <TourStatusPill status={tour.status} />}
        </span>
        <MediaQuery maxWidth={767}>
          <span className={styles.shareText}>
            {tour?.shared === ShareStatus.unshared && "Not shared with client"}
          </span>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <div className={styles.topButtons}>
            <span className={styles.shareText}>
              {tour?.shared === ShareStatus.unshared &&
                "Not shared with client"}
            </span>
            {tour?.shared === ShareStatus.unshared && (
              <IonButton
                disabled={!tour?.clients?.length}
                onClick={onShare}
                size="small"
                className="aecorn-button border clear">
                Share with client{" "}
                <IonIcon icon={shareSocialOutline} slot="end" />
              </IonButton>
            )}
            <IonButton
              size="small"
              className="aecorn-button border clear"
              disabled={!stops.length}
              onClick={navigateTourStops}>
              Navigate Tour
              <IonIcon icon={navigateOutline} slot="start" />
            </IonButton>
          </div>
        </MediaQuery>
      </div>
      <IonSegment
        ref={segmentRef}
        className={`${styles.segment} aecorn-segment dark`}
        mode="ios"
        scrollable
        value={activeTab}
        onIonChange={(ev) => setActiveTab(ev.detail.value as TourTabs)}
        onScroll={handleSegmentScroll}>
        <IonSegmentButton value={TourTabs.StatusAndListings}>
          Status and Listings
        </IonSegmentButton>
        <IonSegmentButton value={TourTabs.Info}>Info</IonSegmentButton>
        <IonSegmentButton value={TourTabs.RequestsHistory}>
          Requests History
        </IonSegmentButton>
        <IonSegmentButton value={TourTabs.Attendees}>
          Attendees
        </IonSegmentButton>
      </IonSegment>
    </>
  );

  return (
    <>
      <IonLoading isOpen={submitLoading} message="Loading..." />
      <IonHeader mode="ios" className={styles.header}>
        <MediaQuery minWidth={768}>
          <Header />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <TopSection />
        </MediaQuery>
      </IonHeader>
      <IonContent className={styles.content}>
        <MediaQuery minWidth={768}>
          <TopSection />
        </MediaQuery>
        {loading ? <LoadingScreen /> : <></>}
        {!loading && tour && (
          <>
            {activeTab === TourTabs.StatusAndListings && (
              <AgentTourStatusAndListings
                tour={tour}
                tourItems={tourItems}
                stops={stops}
                loading={listingsLoading}
                reorderStops={reorderStops}
                updateStatus={updateStatus}
              />
            )}
            {activeTab === TourTabs.Info && <AgentTourInfo tour={tour} />}
            {activeTab === TourTabs.Attendees && (
              <AgentTourAttendees tour={tour} />
            )}
          </>
        )}
      </IonContent>
    </>
  );
};

export default AgentTour;
