import { School } from "API";
import styles from "./listing-schools.module.scss";
import { Listing } from "models/listings/listing.model";
import {
  IonItem,
  IonRouterLink,
  IonSelect,
  IonSelectOption,
  IonSpinner,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { calcCrow, isInsidePolygon } from "utils/functions";
import { DeviceService } from "services/deviceInfoService";

const ListingSchools = ({
  loading,
  listing,
  schools,
}: {
  loading: boolean;
  listing: Listing;
  schools: School[];
}) => {
  const isNative = DeviceService.isApp();
  const [schoolType, setSchoolType] = useState<
    "Elementary" | "Middle" | "High"
  >("Elementary");
  const [mostSuitableSchool, setMostSuitableSchool] = useState<School | null>(
    null
  );

  const getSelectedTypeSchools = () => {
    switch (schoolType) {
      case "Elementary":
        return schools.filter((school) => school.isElementary);
      case "Middle":
        return schools.filter((school) => school.isMiddle);
      case "High":
        return schools.filter((school) => school.isHigh);
      default:
        return [];
    }
  };

  const getSchoolsDetails = () => {
    const selectedTypeSchools = getSelectedTypeSchools();

    const schoolsWithDetails = selectedTypeSchools.map((school) => {
      const programs = [];

      if (school.isFrenchImmersion) {
        programs.push("French Immersion");
      }

      if (school.isExtendedFrench) {
        programs.push("Extended French");
      }

      if (school.isIB) {
        programs.push("International Baccalaureate");
      }

      if (school.isAP) {
        programs.push("Advanced Placement");
      }

      if (school.isGifted) {
        programs.push("Gifted");
      }

      if (school.isArts) {
        programs.push("Arts");
      }

      if (school.isSport) {
        programs.push("Sports");
      }

      return {
        school: school,
        withinBoundary: checkListingIsWithinSchoolBoundary(school),
        distance: calcCrow(
          school.latitude,
          school.longitude,
          listing.map.latitude,
          listing.map.longitude
        ),
        programs,
        rating: school.ettieOverallAvg
          ? +(school.ettieOverallAvg / 10).toFixed(1)
          : 0,
      };
    });

    return schoolsWithDetails;
  };

  const getSortedSchools = () => {
    const schoolsWithDetails = getSchoolsDetails();

    const sortedSchools = schoolsWithDetails
      .sort((a, b) => a.distance - b.distance)
      .sort((a, b) => b.rating - a.rating)
      .sort((a, b) => b.withinBoundary - a.withinBoundary);

    return sortedSchools;
  };

  const checkListingIsWithinSchoolBoundary = (school: School) => {
    if (!school.boundaryArray) {
      return -1;
    }

    try {
      const boundary = JSON.parse(JSON.parse(school.boundaryArray)).Points;

      let points = [];
      for (let i = 0; i < boundary.length; i += 2) {
        points.push(boundary.slice(i, i + 2));
      }
      const isInside = isInsidePolygon(
        [parseFloat(listing.map.latitude), parseFloat(listing.map.longitude)],
        points
      );

      return isInside ? 1 : 0;
    } catch (err) {
      return -1;
    }
  };

  useEffect(() => {
    if (schools.length > 0) {
      const sortedSchools = getSortedSchools();
      setMostSuitableSchool(sortedSchools[0].school);
    }
  }, [schoolType]);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <IonSpinner name="crescent" />
        </div>
      ) : (
        <div className={styles.schoolsContainer}>
          <div className={styles.header}>
            <IonItem className="aecorn-select border" lines="none">
              <IonSelect
                value={schoolType}
                interface={isNative ? "alert" : "popover"}
                interfaceOptions={{
                  cssClass: isNative ? "aecorn-alert" : "aecorn-select-popover",
                }}
                justify="space-between"
                toggleIcon={chevronDownOutline}
                expandedIcon={chevronUpOutline}
                onIonChange={(e) => setSchoolType(e.detail.value)}>
                <IonSelectOption value="Elementary">
                  Elementary schools
                </IonSelectOption>
                <IonSelectOption value="Middle">Middle schools</IonSelectOption>
                <IonSelectOption value="High">High schools</IonSelectOption>
              </IonSelect>
            </IonItem>
            {mostSuitableSchool && (
              <p>
                Residents likely attend{" "}
                <IonRouterLink routerLink={`/schools/${mostSuitableSchool.id}`}>
                  {mostSuitableSchool.name}
                </IonRouterLink>
                . Please confirm the school for this property before making a
                purchasing decision.
              </p>
            )}
          </div>
          {getSortedSchools().filter(school => school.withinBoundary === 1).length === 0 && (
            <div className="aecorn-listing-detail-tab">
              No schools available
            </div>
          )}
          {getSortedSchools().filter(school => school.withinBoundary === 1).map((item, index) => (
            <div key={index} className="aecorn-listing-detail-tab vertical">
              <div className={styles.name}>
                <IonRouterLink routerLink={`/schools/${item.school.id}`}>
                  {item.school.name}
                </IonRouterLink>
              </div>
              <div className={styles.grades}>
                {item.school.gradeFrom === -1 ? "JK" : item.school.gradeFrom} -{" "}
                {item.school.gradeEnd}{" "}
                {item.distance && `/ ${item.distance.toFixed(2)} km`}
              </div>
              <div className={styles.programs}>
                <span>Special Programs</span>
                <span>
                  {item.programs.length > 0 ? item.programs.join(", ") : "N/A"}
                </span>
              </div>
              <div className={styles.rating}>
                {item.rating ? (
                  <>
                    <span>{item.rating}</span> out of 10
                  </>
                ) : (
                  "N/A"
                )}
              </div>
              <div className={styles.boundary}>
                {item.withinBoundary === 1
                  ? "Within boundary"
                  : item.withinBoundary === 0
                    ? "Outside boundary"
                    : "Boundary not available"}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListingSchools;
