import { useMediaQuery } from "react-responsive";
import { Redirect, Route } from "react-router";
import React from "react/jsx-runtime";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  exact?: boolean;
  path: string;
  isAuthenticated: boolean;
  isAuthorized?: boolean;
  tab?: string;
}

const PrivateRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  isAuthenticated,
  isAuthorized = true,
  path,
  tab,
  ...rest
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      <Route
        path={path}
        {...rest}
        render={(props) =>
          isAuthenticated && isAuthorized ? (
            tab && isMobile ? (
              <Redirect to={`/tabs/${tab}`} />
            ) : (
              <Component {...props} {...props.match.params} />
            )
          ) : (
            <Redirect to="/" />
          )
        }></Route>
    </>
  );
};

export default PrivateRoute;
