import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import styles from "./tour-stop.module.scss";
import { useEffect, useRef, useState } from "react";
import { Review, TourItem } from "API";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Header from "components/web/Header/header.component";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import { Redirect, useHistory } from "react-router";
import { TourItemService } from "services/tourItemService";
import TourStatusPill from "components/shared/TourStatusPill/tour-status-pill.component";
import { useSelector } from "react-redux";
import {
  selectCurrentUsername,
  selectIsAgent,
  selectIsAuthenticated,
} from "redux/user/user.selectors";
import {
  calendarNumberOutline,
  chevronDownOutline,
  chevronUpOutline,
  navigateOutline,
  timeOutline,
} from "ionicons/icons";
import { Listing } from "models/listings/listing.model";
import { ListingService } from "services/listingService";
import { HelperFunctions } from "utils/HelperFunctions";
import { ListingsHelper } from "utils/ListingsHelper";
import moment from "moment";
import { DeviceService } from "services/deviceInfoService";
import { TourItemStatus } from "models";
import { capitalize } from "lodash";
import TourStopOverview from "./segments/TourStopOverview/tour-stop-overview.component";
import TourStopTime from "./segments/TourStopTime/tour-stop-time.component";
import TourStopUploads from "./segments/TourStopUploads/tour-stop-uploads.component";
import TourStopInfo from "./segments/TourStopInfo/tour-stop-info.component";
import TourReview from "components/shared/Tours/TourReview/tour-review.component";

enum TourItemTabs {
  Overview = 1,
  Time = 2,
  Uploads = 3,
  Info = 4,
}

const TourStop = ({
  tourId,
  tourItemId,
}: {
  tourId: string;
  tourItemId: string;
}) => {
  const history = useHistory();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userId = useSelector(selectCurrentUsername);
  const isUserAgent = useSelector(selectIsAgent);
  const isNative = DeviceService.isApp();
  const isMobile = useMediaQuery({ maxWidth: 1239 });
  const segmentRef = useRef<HTMLIonSegmentElement>(null);
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tourItem, setTourItem] = useState<TourItem>();
  const [listing, setListing] = useState<Listing>();
  const [userReview, setUserReview] = useState<Review>();
  const [activeTab, setActiveTab] = useState<TourItemTabs>(
    TourItemTabs.Overview
  );
  const subscription = useRef<ZenObservable.Subscription>();

  const navigate = () => {
    if (!listing) {
      return;
    }

    HelperFunctions.navigateByAddress(
      ListingsHelper.getListingAddress(listing.address)
    );
  };

  const handleSegmentScroll = () => {
    const scrollLeft = segmentRef.current?.scrollLeft || 0;

    const firstIonSegmentButton = segmentRef.current?.querySelector(
      "ion-segment-button"
    ) as HTMLElement;

    const firstIonSegmentButtonWidth = firstIonSegmentButton.clientWidth;

    if (scrollLeft > firstIonSegmentButtonWidth) {
      segmentRef.current?.classList.add(styles.noMargin);
    } else if (scrollLeft === 0) {
      segmentRef.current?.classList.remove(styles.noMargin);
    }
  };

  const onRemove = () => {
    presentAlert({
      header: "Remove from tour",
      message: `Are you sure you want to remove this listing from the tour?`,
      cssClass: "aecorn-alert",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "aecorn-alert-button-cancel",
        },
        {
          text: "Remove",
          handler: async () => {
            setSubmitLoading(true);
            try {
              await TourItemService.deleteTourItem(tourItem!);
              presentToast({
                message: "Listing removed from tour",
                duration: 2000,
                cssClass: "aecorn-success-toast",
              });
              history.replace(`/tours/details/${tourId}`);
            } catch (error) {
              console.error(error);
              presentToast({
                message: "Failed to remove listing from tour",
                duration: 2000,
                cssClass: "aecorn-error-toast",
              });
            } finally {
              setSubmitLoading(false);
            }
          },
          cssClass: "aecorn-alert-button-danger",
        },
      ],
    });
  };

  const handleUpdateStatus = async (e: CustomEvent) => {
    const newStatus = e.detail.value;
    setSubmitLoading(true);
    try {
      const updatedTourItem = await TourItemService.updateTourItem({
        id: tourItemId,
        status: newStatus,
      });
      setTourItem({ ...tourItem!, status: updatedTourItem?.status });
      presentToast({
        message: "Showing status updated",
        duration: 2000,
        cssClass: "aecorn-success-toast",
      });
    } catch (error) {
      console.error(error);
      presentToast({
        message: "Failed to update showing status",
        duration: 2000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    const fetchTourItem = async () => {
      try {
        const tourItem = await TourItemService.getTourItemById(tourItemId);

        if (!tourItem || tourItem.tourId !== tourId) {
          history.replace(`/tours/${tourId}`);
        }

        const listing = await ListingService.getListingByMlsNumber(
          tourItem.mlsNumber
        );

        if (listing) {
          setListing(listing);
        } else {
          history.replace(`/tours/${tourId}`);
        }

        subscription.current = TourItemService.onTourItemUpdateById(
          tourItem.id,
          (data) => {
            setTourItem(data);
          }
        );

        const review = tourItem.reviews?.items.find(
          (r) => r?.userId === userId
        );
        if (review) {
          setUserReview(review);
        }

        setTourItem(tourItem);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTourItem();

    return () => {
      subscription.current?.unsubscribe();
    };
  }, [tourId, tourItemId]);

  const TopSection = () => {
    if (!tourItem || !listing) {
      return <></>;
    } else {
      return (
        <>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                className="aecorn-back-button"
                text={`Back to ${tourItem.tour?.title}`}
                defaultHref={`/tours/details/${tourId}`}
              />
            </IonButtons>
          </IonToolbar>
          <div className={styles.summary}>
            <span className={styles.title}>
              <span>
                {tourItem.tour?.title} - Stop {tourItem.order}
              </span>
              {tourItem.status && <TourStatusPill status={tourItem.status} />}
              {tourItem.clientRequested && (
                <MediaQuery minWidth={1240}>
                  <span className={styles.clientRequest}>Client request</span>
                </MediaQuery>
              )}
            </span>
            {tourItem.clientRequested && (
              <MediaQuery maxWidth={1239}>
                <span className={styles.clientRequest}>Client request</span>
              </MediaQuery>
            )}
            <MediaQuery minWidth={1240}>
              <div className={styles.topButtons}>
                {!isUserAgent && tourItem.tour && (
                  <div className={styles.review}>
                    <TourReview
                      tour={tourItem.tour}
                      tourItemId={tourItemId}
                      review={userReview}
                      setUserReview={setUserReview}
                    />
                  </div>
                )}
                {isUserAgent && (
                  <IonButton
                    size="small"
                    className="aecorn-button border danger"
                    onClick={onRemove}>
                    Remove from tour
                  </IonButton>
                )}
                <IonButton
                  size="small"
                  className="aecorn-button border clear"
                  disabled={!listing}
                  onClick={navigate}>
                  Navigate Stop
                  <IonIcon icon={navigateOutline} slot="start" />
                </IonButton>
              </div>
            </MediaQuery>
          </div>
          <div className={styles.timeAddress}>
            <MediaQuery maxWidth={1239}>
              <div className={styles.left}>
                <span>{ListingsHelper.getListingAddress(listing.address)}</span>
                <span>{`${listing.address.zip}, ${listing.address.city}`}</span>
              </div>
              <div className={styles.right}>
                <span>
                  {tourItem.tour?.date &&
                    moment(tourItem.tour.date).format("ddd, MMMM DD")}
                </span>
                <span>
                  {tourItem.startTime &&
                    moment(tourItem.startTime, "hh:mm").format("h:mm A")}
                  {tourItem.startTime && tourItem.endTime && "-"}{" "}
                  {tourItem.endTime &&
                    moment(tourItem.endTime, "hh:mm").format("h:mm A")}
                </span>
              </div>
            </MediaQuery>
            <MediaQuery minWidth={1240}>
              <div className={styles.left}>
                <div
                  className={`${styles.address} ${
                    !isUserAgent && styles.clientAddress
                  }`}>
                  <span>
                    {ListingsHelper.getListingAddress(listing.address)}
                  </span>
                  <span>{`${listing.address.zip}, ${listing.address.city}`}</span>
                </div>
                <div
                  className={`${styles.time} ${
                    !isUserAgent && styles.clientTime
                  }`}>
                  {isUserAgent ? (
                    <>
                      {tourItem.tour?.date &&
                        moment(tourItem.tour.date).format("ddd, MMMM DD")}
                      {tourItem.tour?.date && " - "}
                      {tourItem.startTime &&
                        moment(tourItem.startTime, "hh:mm").format("h:mm A")}
                      {tourItem.startTime && tourItem.endTime && "-"}
                      {tourItem.endTime &&
                        moment(tourItem.endTime, "hh:mm").format("h:mm A")}
                    </>
                  ) : (
                    <>
                      {tourItem.tour?.date && (
                        <>
                          <IonIcon icon={calendarNumberOutline} />{" "}
                          {moment(tourItem.tour.date).format("ddd, MMMM DD")}
                        </>
                      )}
                      {tourItem.tour?.startTime && tourItem.tour?.endTime && (
                        <>
                          <IonIcon icon={timeOutline} />{" "}
                          {moment(tourItem.tour.startTime, "hh:mm").format(
                            "h:mm A"
                          )}
                          {" - "}
                          {moment(tourItem.tour.endTime, "hh:mm").format(
                            "h:mm A"
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </MediaQuery>
          </div>
          {isUserAgent && (
            <div className={styles.status}>
              <IonLabel>Showing Status</IonLabel>
              <IonItem className="aecorn-select dark" lines="none">
                <IonSelect
                  value={tourItem.status}
                  interface={isNative ? "alert" : "popover"}
                  interfaceOptions={{
                    cssClass: isNative
                      ? "aecorn-alert"
                      : "aecorn-select-popover",
                  }}
                  justify="space-between"
                  toggleIcon={chevronDownOutline}
                  expandedIcon={chevronUpOutline}
                  onIonChange={handleUpdateStatus}>
                  {Object.values(TourItemStatus).map((status) => (
                    <IonSelectOption key={status} value={status}>
                      {capitalize(status)}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </div>
          )}
          <MediaQuery maxWidth={1239}>
            <div className={styles.bottomButtons}>
              {tourItem.primaryAgent === userId && (
                <IonButton
                  onClick={onRemove}
                  size="small"
                  className="aecorn-button border danger">
                  Remove from tour
                </IonButton>
              )}
              <IonButton
                size="small"
                className="aecorn-button border clear"
                disabled={!listing}
                onClick={navigate}>
                Navigate Stop
                <IonIcon icon={navigateOutline} slot="start" />
              </IonButton>
            </div>
          </MediaQuery>
          {(isUserAgent || isMobile) && (
            <IonSegment
              ref={segmentRef}
              className={`${styles.segment} aecorn-segment dark`}
              mode="ios"
              scrollable
              value={activeTab}
              onIonChange={(ev) =>
                setActiveTab(ev.detail.value as TourItemTabs)
              }
              onScroll={handleSegmentScroll}>
              <IonSegmentButton value={TourItemTabs.Overview}>
                Overview
              </IonSegmentButton>
              {isUserAgent && (
                <IonSegmentButton value={TourItemTabs.Time}>
                  Time
                </IonSegmentButton>
              )}
              <IonSegmentButton value={TourItemTabs.Uploads}>
                Notes
              </IonSegmentButton>
              <IonSegmentButton value={TourItemTabs.Info}>
                Info
              </IonSegmentButton>
            </IonSegment>
          )}
        </>
      );
    }
  };

  return (
    <IonPage>
      {!isAuthenticated && <Redirect to="/" />}
      <IonLoading isOpen={submitLoading} message="Loading..." />
      <IonHeader mode="ios" className={styles.header}>
        <MediaQuery minWidth={1240}>
          <Header />
        </MediaQuery>
        <MediaQuery maxWidth={1239}>
          <TopSection />
        </MediaQuery>
      </IonHeader>
      <IonContent
        className={styles.content}
        scrollY={activeTab !== TourItemTabs.Uploads || !isMobile}>
        <MediaQuery minWidth={1240}>
          <TopSection />
        </MediaQuery>
        {loading ? <LoadingScreen /> : <></>}
        {!loading && tourItem && listing && (
          <>
            {isUserAgent || isMobile ? (
              <>
                <TourStopOverview
                  tour={tourItem.tour!}
                  tourItem={tourItem}
                  listing={listing}
                  hidden={activeTab !== TourItemTabs.Overview}
                />

                <TourStopTime
                  tourItem={tourItem}
                  hidden={activeTab !== TourItemTabs.Time}
                />

                <TourStopUploads
                  tourItem={tourItem}
                  hidden={activeTab !== TourItemTabs.Uploads}
                />

                <TourStopInfo
                  tourItem={tourItem}
                  hidden={activeTab !== TourItemTabs.Info}
                />
              </>
            ) : (
              <div className={styles.clientContainer}>
                <section>
                  <IonLabel className={styles.sectionTitle}>Overview</IonLabel>
                  <TourStopOverview
                    tour={tourItem.tour!}
                    tourItem={tourItem}
                    listing={listing}
                    hidden={false}
                    isClient={true}
                  />
                </section>

                <section>
                  <IonLabel className={styles.sectionTitle}>Notes</IonLabel>
                  <TourStopUploads
                    tourItem={tourItem}
                    hidden={false}
                    isClient={true}
                  />
                </section>
                <section>
                  <IonLabel className={styles.sectionTitle}>Info</IonLabel>
                  <TourStopInfo
                    tourItem={tourItem}
                    hidden={false}
                    isClient={true}
                  />
                </section>
              </div>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default TourStop;
