import { IonContent, IonIcon } from "@ionic/react";
import styles from "./authentication-modal.module.scss";
import LogoWithText from "components/shared/LogoWithText/logo-with-text.component";
import googleIcon from "assets/svg/google.svg";
import appleIcon from "assets/svg/apple.svg";
import SignInForm from "./forms/SignInForm/sign-in-form.component";
import { AuthenticationService } from "services/authenticationService";
import { useRef, useState } from "react";
import { closeOutline, warningOutline } from "ionicons/icons";
import ForgotPasswordForm from "./forms/ForgotPasswordForm/forgot-password-form.component";
import SignUpForm from "./forms/SignUpForm/sign-up-form.component";
import SignUpConfirmationForm from "./forms/SignUpConfirmationForm/sign-up-confirmation-form.component";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";

const AuthenticationModal = ({
  mode = "login",
  onDismiss,
}: {
  mode?: "login" | "register" | "forgotPassword";
  onDismiss: () => void;
}) => {
  const contentRef = useRef<HTMLIonContentElement>(null);
  const [form, setForm] = useState<
    "login" | "register" | "forgotPassword" | "registerConfirmation"
  >(mode);
  const [error, setError] = useState<string | null>(null);
  const [tempUserData, setTempUserData] = useState<{
    email: string;
    password: string;
    phone_number?: string;
  } | null>(null);

  const handleError = (error: string | null) => {
    setError(error);
    if (error) {
      contentRef.current?.scrollToTop();
    }
  };

  const handleSignUpSuccess = (userInfo: {
    email: string;
    password: string;
    phone_number?: string;
  }) => {
    setForm("registerConfirmation");
    setTempUserData(userInfo);
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.logo}>
          <LogoWithText size="auto" />
        </div>
        <IonIcon icon={closeOutline} onClick={onDismiss} />
      </div>
      <IonContent ref={contentRef} className={styles.content}>
        <div className={styles.container}>
          {error && (
            <div className={styles.error}>
              <IonIcon icon={warningOutline} />
              {error}
              <IonIcon icon={closeOutline} onClick={() => setError(null)} />
            </div>
          )}
          <span className={styles.title}>
            {form === "login"
              ? "Sign in"
              : form === "register"
              ? "Sign up"
              : form === "forgotPassword"
              ? "Forgot your password?"
              : "Email verification"}
          </span>
          {form !== "forgotPassword" && form !== "registerConfirmation" && (
            <>
              <div className={styles.socialButtons}>
                <div
                  className={styles.socialButton}
                  onClick={() =>
                    AuthenticationService.federatedLogin(CognitoHostedUIIdentityProvider.Google)
                  }>
                  <IonIcon icon={googleIcon} />
                  <span>Sign in with Google</span>
                </div>
                <div
                  className={styles.socialButton}
                  onClick={() => AuthenticationService.federatedLogin(CognitoHostedUIIdentityProvider.Apple)}>
                  <IonIcon icon={appleIcon} />
                  <span>Sign in with Apple</span>
                </div>
              </div>
              <div className={styles.separator}>
                <span>Or sign in with your email</span>
              </div>
            </>
          )}
          {form === "login" ? (
            <SignInForm
              onSuccess={onDismiss}
              onSwitch={(
                newMode: "register" | "forgotPassword" | "registerConfirmation",
                userInfo?: {
                  email: string;
                  password: string;
                }
              ) => {
                if (newMode === "registerConfirmation" && userInfo) {
                  setForm(newMode);
                  setTempUserData(userInfo);
                } else {
                  setForm(newMode);
                }
              }}
              onError={handleError}
            />
          ) : form === "forgotPassword" ? (
            <ForgotPasswordForm onDismiss={onDismiss} onError={handleError} />
          ) : form === "register" ? (
            <SignUpForm
              onSuccess={handleSignUpSuccess}
              onSwitch={() => setForm("login")}
              onError={handleError}
            />
          ) : (
            <SignUpConfirmationForm
              email={tempUserData?.email || ""}
              password={tempUserData?.password || ""}
              phoneNumber={tempUserData?.phone_number || ""}
              onDismiss={onDismiss}
              onError={handleError}
            />
          )}
        </div>
      </IonContent>
    </>
  );
};

export default AuthenticationModal;
