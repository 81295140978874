import UserActionTypes from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  guest: null,
  isClient: false,
  isAgent: false,
  isAdmin: false,
  state: null,
  error: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.user,
        isClient: 
          action.payload.user.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("Clients"),
        isAgent:
          action.payload.user.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("Agents"),
        isAdmin:
          action.payload.user.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("Admins"),
        state: "signedIn",
        error: null,
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        isClient: false,
        isAgent: false,
        state: "signedOut",
        error: null,
      };
    case UserActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        currentUser: null,
        isClient: false,
        isAgent: false,
        state: "signedUp",
        error: null,
      };

    case UserActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        currentUser: null,
        isClient: false,
        isAgent: false,
        error: action.payload,
      };
    case UserActionTypes.GET_GUEST_SUCCESS:
      return {
        ...state,
        guest: action.payload,
        error: null,
      };
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
    case UserActionTypes.CONFIRM_SIGN_UP_FAILURE:
    case UserActionTypes.GET_GUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UserActionTypes.UPDATE_USER:
      return {
        ...state,
        currentUser: action.payload,
        isAgent:
          action.payload.user.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("Agents"),
        isAdmin:
          action.payload.user.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("Admins"),
        state: "signedIn",
        error: null,
      };
      case UserActionTypes.SIGN_OUT:
      return {
        ...state,
        currentUser: null,
        isClient: false,
        isAgent: false,
        state: "signedOut",
        error: null,
      };
    default:
      return state;
  }
};

export default userReducer;
