import { useState } from "react";
import styles from "../../authentication-modal.module.scss";
import { useForm } from "react-hook-form";
import {
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonNote,
  IonSpinner,
  useIonToast,
} from "@ionic/react";
import { AuthenticationService } from "services/authenticationService";
import { closeOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";

const ForgotPasswordForm = ({
  onDismiss,
  onError,
}: {
  onDismiss: () => void;
  onError: (message: string | null) => void;
}) => {
  const [step, setStep] = useState<"email" | "code">("email");
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [presentToast] = useIonToast();

  const {
    register: registerEmailForm,
    handleSubmit: handleEmailFormSubmit,
    clearErrors,
    formState: { errors: emailFormErrors, isValid: isEmailFormValid },
    getValues: getEmailFormValues,
  } = useForm<{
    email: string;
  }>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    register: registerCodeForm,
    handleSubmit: handleCodeFormSubmit,
    formState: { errors: codeFormErrors, isValid: isCodeFormValid },
    getValues: getCodeFormValues,
  } = useForm<{
    code: string;
    newPassword: string;
    newPasswordConfirm: string;
  }>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const emailFormControl = registerEmailForm("email", {
    required: {
      value: true,
      message: "Email is required",
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: "Invalid email address",
    },
  });

  const codeFormControl = registerCodeForm("code", {
    required: {
      value: true,
      message: "Code is required",
    },
  });

  const newPasswordFormControl = registerCodeForm("newPassword", {
    required: {
      value: true,
      message: "New password is required",
    },
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters long",
    },
    validate: {
      hasUppercase: (value) =>
        /[A-Z]/.test(value) ||
        "Password must contain at least one uppercase letter",
      hasLowercase: (value) =>
        /[a-z]/.test(value) ||
        "Password must contain at least one lowercase letter",
      hasNumber: (value) =>
        /\d/.test(value) || "Password must contain at least one number",
      hasSpecialCharacter: (value) =>
        /[^A-Za-z0-9]/.test(value) ||
        "Password must contain at least one special character",
    },
  });

  const newPasswordConfirmFormControl = registerCodeForm("newPasswordConfirm", {
    required: {
      value: true,
      message: "Password confirmation is required",
    },
    validate: (value) =>
      value === getCodeFormValues().newPassword || "Passwords do not match",
  });

  const onSubmitEmail = async (data: { email: string }) => {
    try {
      clearErrors();
      onError(null);
      setLoading(true);
      await AuthenticationService.resetPassword(data.email);
      presentToast({
        message: "Code has been sent successfully to your email.",
        duration: 5000,
        cssClass: "aecorn-success-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
      setStep("code");
    } catch (error: any) {
      onError(
        error.code === "UserNotFoundException"
          ? "Email not found"
          : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const onSubmitCode = async (data: { code: string; newPassword: string }) => {
    try {
      onError(null);
      setLoading(true);
      await AuthenticationService.confirmPassword(
        getEmailFormValues().email,
        data.code,
        data.newPassword
      );
      presentToast({
        message:
          "Password has been changed successfully. You will be signed in a moment!",
        duration: 5000,
        cssClass: "aecorn-success-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
      await AuthenticationService.login(
        getEmailFormValues().email,
        data.newPassword
      );
      onDismiss();
    } catch (error: any) {
      onError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      const email = getEmailFormValues().email;
      await AuthenticationService.resetPassword(email);
      presentToast({
        message: "A new code has been sent to your email",
        duration: 5000,
        cssClass: "aecorn-success-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    } catch (error: any) {
      onError(error.message);
    }
  };

  return (
    <div>
      <p>
        {step === "email"
          ? "Enter the email associated with your AECORN account and we will send you instructions."
          : "We've sent a verification code to your email - enter the code below. Then set a new password for your account."}
      </p>
      {step === "email" ? (
        <form
          onSubmit={handleEmailFormSubmit(onSubmitEmail)}
          className={styles.form}>
          <div className={styles.formItem}>
            <IonLabel>Email address*</IonLabel>
            <IonInput
              className={`aecorn-input dark ${emailFormErrors.email?.message && "ion-invalid ion-touched"
                }`}
              required
              placeholder="Enter your email address"
              {...emailFormControl}
              onIonInput={emailFormControl.onChange}
              type="email"
            />
            {emailFormErrors.email && (
              <IonNote>{emailFormErrors.email.message}</IonNote>
            )}
          </div>
          <IonButton
            className="aecorn-button primary"
            expand="block"
            type="submit"
            disabled={!isEmailFormValid || loading}>
            {loading ? <IonSpinner name="crescent" /> : "Submit"}
          </IonButton>
        </form>
      ) : (
        <form
          onSubmit={handleCodeFormSubmit(onSubmitCode)}
          className={styles.form}>
          <div className={styles.formItem}>
            <IonLabel>Verification code*</IonLabel>
            <IonInput
              className={`aecorn-input dark ${codeFormErrors.code?.message && "ion-invalid ion-touched"
                }`}
              required
              type="number"
              placeholder="Enter the code"
              {...codeFormControl}
              onIonInput={codeFormControl.onChange}
            />
            {codeFormErrors.code && (
              <IonNote>{codeFormErrors.code.message}</IonNote>
            )}
          </div>
          <div className={styles.formItem}>
            <IonLabel>New password*</IonLabel>
            <IonInput
              className={`aecorn-input dark ${codeFormErrors.newPassword?.message && "ion-invalid ion-touched"
                }`}
              required
              placeholder="Enter your new password"
              {...newPasswordFormControl}
              onIonInput={newPasswordFormControl.onChange}
              type={isPasswordVisible ? "text" : "password"}>
              <IonIcon
                icon={isPasswordVisible ? eyeOffOutline : eyeOutline}
                slot="end"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            </IonInput>
            {codeFormErrors.newPassword && (
              <IonNote>{codeFormErrors.newPassword.message}</IonNote>
            )}
          </div>
          <div className={styles.formItem}>
            <IonLabel>Confirm new password*</IonLabel>
            <IonInput
              className={`aecorn-input dark ${codeFormErrors.newPasswordConfirm?.message &&
                "ion-invalid ion-touched"
                }`}
              required
              placeholder="Confirm your new password"
              {...newPasswordConfirmFormControl}
              onIonInput={newPasswordConfirmFormControl.onChange}
              type={isPasswordVisible ? "text" : "password"}>
              <IonIcon
                icon={isPasswordVisible ? eyeOffOutline : eyeOutline}
                slot="end"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              />
            </IonInput>
            {codeFormErrors.newPasswordConfirm && (
              <IonNote>{codeFormErrors.newPasswordConfirm.message}</IonNote>
            )}
          </div>
          <IonButton
            className="aecorn-button primary"
            expand="block"
            type="submit"
            disabled={!isCodeFormValid || loading}>
            {loading ? <IonSpinner name="crescent" /> : "Submit"}
          </IonButton>
          <span className={styles.resendCode} onClick={handleResendCode}>
            Resend code
          </span>
        </form>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
