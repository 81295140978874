import { IonAvatar } from "@ionic/react";
import styles from "./tour-attendee.module.scss";
import { TourRole, UserTour } from "API";
import DefaultProfile from "assets/svg/Avatar.svg";
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";

const AttendeeRoleMap = {
    [TourRole.client]: "Client",
    [TourRole.primaryagent]: "Lead agent",
    [TourRole.showingagent]: "Showing agent",
    [TourRole.guest]: "Guest",
}

const TourAttendee = ({ attendee }: { attendee: UserTour }) => {
  const [profilePicture, setProfilePicture] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getUserProfilePicture = async () => {
      try {
        const url = await Storage.get(attendee.user!.profilePicture!);
        setProfilePicture(url);
      } catch (e) {
        console.error(e);
      }
    };

    if (attendee.user?.profilePicture) {
      getUserProfilePicture();
    }
  }, [attendee]);

  return (
    <div className={styles.attendee}>
      <IonAvatar>
        <img src={profilePicture ?? DefaultProfile} alt={attendee.user?.displayName ?? "attendee"} />
      </IonAvatar>
      <div className={styles.info}>
        <span>{attendee.user?.displayName || "Unknown attendee"}</span>
        <span>{AttendeeRoleMap[attendee.role]}</span>
      </div>
    </div>
  );
};

export default TourAttendee;
