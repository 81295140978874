import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import {
  SortOption,
  SortOptionValue,
} from "types/shared/SortOption.type";
import styles from "./sort-button.module.scss";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from "@ionic/react";
import { chevronDown } from "ionicons/icons";

const SortButton = ({
  items,
  value,
  order,
  onSelect,
  onChangeOrder,
}: {
  items: SortOption[];
  value: string | number;
  order: "asc" | "desc";
  onSelect: (value: SortOptionValue) => void;
  onChangeOrder: () => void;
}) => {
  return (
    <div className={styles.container}>
      <SortIcon
        onClick={onChangeOrder}
        className={order === "desc" ? styles.icon : styles.reversedIcon}
      />
      <span id="select-popover" className={styles.select}>
        Sort by {items.find((item) => item.value === value)?.label}
        <IonIcon icon={chevronDown} />
      </span>
      <IonPopover
        trigger="select-popover"
        triggerAction="click"
        dismissOnSelect
        showBackdrop={false}>
        <IonContent>
          <IonList className={styles.options}>
            {items.map((item, i) => (
              <IonItem button lines="none" onClick={() => onSelect(item.value)} key={i}>
                <IonLabel>{item.label}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonPopover>
    </div>
  );
};

export default SortButton;
