import LogoIconOnly from "components/Logo/LogoIconOnly";
import styles from "./loading-screen.module.scss";
import { useEffect, useState } from "react";
import { IonButton } from "@ionic/react";

const LoadingScreen = ({
  reloadFunction,
  timeout = 10000,
}: {
  reloadFunction?: () => void;
  timeout?: number;
}) => {
  const [showReloadButton, setShowReloadButton] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (reloadFunction) {
      timer = setTimeout(() => {
        setShowReloadButton(true);
      }, timeout);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.loadingContainer}>
        <div className={styles.logoContainer}>
          <LogoIconOnly />
          <div className={styles.spinner}></div>
        </div>
        {showReloadButton && (
          <>
          <span>Loading seems to be taking longer than usual.</span>
          <IonButton className="aecorn-button border clear" onClick={reloadFunction}>
            Reload
          </IonButton>
          </>
        )}
      </div>
    </div>
  );
};

export default LoadingScreen;
