import { getPlatforms } from "@ionic/react";
import { intersectionWith, isEqual } from "lodash";

const isApp = () => {
  const APP_PLATFORMS = [
    "ios",
    "android",
    "capacitor",
    "tablet",
    "phablet",
    "mobile",
    "mobileweb",
  ];
  const platforms = getPlatforms();
  const presents = intersectionWith(APP_PLATFORMS, platforms, isEqual);

  if (platforms.includes("capacitor")) {
    return true;
  }
  if (!presents.length) {
    return false;
  }
  if (window.innerWidth > 762) {
    return false;
  }
  return presents.length > 0;
};

const isCapacitor = () => {
  const platforms = getPlatforms();
  return platforms.includes("capacitor");
};

const isAndroid = () => {
  const platforms = getPlatforms();
  return platforms.includes("android");
};

const isIOS = () => {
  const platforms = getPlatforms();
  return platforms.includes("ios");
}

const getDeviceToken = async () => {
  return localStorage.getItem("deviceToken");
};

export const DeviceService = {
  isApp,
  isCapacitor,
  isAndroid,
  isIOS,
  getDeviceToken
};
