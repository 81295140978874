import { API } from "aws-amplify";
import { GeneralConstants } from "../utils/constants";
import { Listing } from "../models/listings/listing.model";
import { Locations } from "models/locations/locations.model";
import { ListingStatistics } from "models/listings/listing-statistics.model";

const getListingByMlsNumber = async (mlsNumber: string) => {
  const response = await API.get(
    GeneralConstants.REST_API_NAME,
    `/listings/${mlsNumber}`,
    {}
  );
  return response.data as Listing;
};

const searchListingsAndLocations = async (
  term: string,
  activeListingsOnly = false
) => {
  const response = await API.get(
    GeneralConstants.REST_API_NAME,
    "/listings/search",
    {
      queryStringParameters: { term, activeListingsOnly },
    }
  );

  const listings = response.data.listings as Listing[];
  const locations = response.data.locations as Locations;

  return { listings, locations };
};

const getSimilarListingsByMlsNumber = async (
  mlsNumber: string
): Promise<Listing[]> => {
  const response = await API.get(
    GeneralConstants.REST_API_NAME,
    `/listings/${mlsNumber}/similar`,
    {}
  );

  return response.data;
};

const getAECORNListings = async (): Promise<Listing[]> => {
  const response = await API.get(GeneralConstants.REST_API_NAME, "/listings/aecorn", {});

  return response.data;
};

const getListingEstimateByMlsNumber = async (mlsNumber: string): Promise<number> => {
  const response = await API.get(
    GeneralConstants.REST_API_NAME,
    `/estimate/${mlsNumber}`,
    {}
  );

  return response;
};

const getListingStatistics = async (
  listing: Listing
): Promise<ListingStatistics> => {
  const response = await API.post(
    GeneralConstants.REST_API_NAME,
    `/listings/statistics`,
    {
      body: {
        area: listing.address.area,
        neighborhood: listing.address.neighborhood,
        propertyType: listing.details.propertyType,
      },
    }
  );
  return response.data;
};

export const ListingService = {
  getListingByMlsNumber,
  searchListingsAndLocations,
  getSimilarListingsByMlsNumber,
  getAECORNListings,
  getListingStatistics,
  getListingEstimateByMlsNumber,
};
