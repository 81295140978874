import { AuthenticationService } from "services/authenticationService";
import styles from "./listing.module.scss";
import { useParams } from "react-router";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
  useIonModal,
  useIonRouter,
} from "@ionic/react";
import Header from "components/web/Header/header.component";
import ListingGallery from "components/shared/ListingDetails/ListingGallery/listing-gallery.component";
import { useCallback, useEffect, useRef, useState } from "react";
import { Listing as ListingModel } from "models/listings/listing.model";
import { ListingService } from "services/listingService";
import ListingSummary from "components/shared/ListingDetails/ListingSummary/listing-summary.component";
import ListingInformation from "components/shared/ListingDetails/ListingInformation/listing-information.component";
import ListingComparables from "components/shared/ListingDetails/ListingComparables/listing-comparables.component";
import ListingReport from "components/shared/Listings/ListingReport/listing-report.component";
import Footer from "components/web/Footer/footer.component";
import { chevronBack, shareSocialOutline } from "ionicons/icons";
import StarIcon from "assets/svg/star-dark.svg";
import BellIcon from "assets/svg/notifications.svg";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import ErrorScreen from "components/shared/ErrorScreen/error-screen.component";
import { AxiosError } from "axios";
import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { ListingsHelper } from "utils/ListingsHelper";
import { FavoritesService } from "services/favoritesService";
import { Favourite } from "API";
import { ReportsService } from "services/reportsService";
import ListingRequestModal from "components/shared/Modals/ListingRequestModal/listing-request-modal.components";
import MediaQuery, { useMediaQuery } from "react-responsive";
import AuthenticationModal from "components/shared/Modals/AuthenticationModal/authentication-modal.component";
import ListingOfferModal from "components/shared/Modals/ListingOfferModal/listing-offer-modal.components";
import { useSelector } from "react-redux";
import { selectIsAgent } from "redux/user/user.selectors";
import ListingShareModal from "components/shared/Modals/ListingShareModal/listing-share-modal.component";
import Modal from "components/ui/modal/modal.component";

const Listing = () => {
  const { mlsNumber }: { mlsNumber: string } = useParams();
  const pageRef = useRef<HTMLDivElement | undefined>();
  const router = useIonRouter();
  const isAuthenticated = AuthenticationService.isAuthenticated();
  const isAgent = useSelector(selectIsAgent);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [listing, setListing] = useState<ListingModel | null>(null);
  const [favourite, setFavourite] = useState<Favourite | null>(null);
  const [estimatedPrice, setEstimatedPrice] = useState<number | null>(null);
  const [similarListings, setSimilarListings] = useState<ListingModel[]>([]);
  const [reportAvailable, setReportAvailable] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [appHeaderOpacity, setAppHeaderOpacity] = useState(0);
  const [appHeaderTransform, setAppHeaderTransform] =
    useState("translateY(-100%)");

  const [presentRequestModal, dismissRequestModal] = useIonModal(
    ListingRequestModal,
    {
      dismiss: () => dismissRequestModal(),
      listing,
    }
  );

  const [presentOfferModal, dismissOfferModal] = useIonModal(
    ListingOfferModal,
    {
      dismiss: () => dismissOfferModal(),
      listing,
    }
  );

  const [presentAuthenticationModal, dismiss] = useIonModal(
    AuthenticationModal,
    {
      onDismiss: () => {
        dismiss();
      },
      mode: "login",
    }
  );

  const loadData = useCallback(async () => {
    const fetchListing = async () => {
      try {
        setLoading(true);
        const listingItem = await ListingService.getListingByMlsNumber(
          mlsNumber
        );
        setListing(listingItem);
      } catch (error: any) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSimilarListings = async () => {
      try {
        const listings = await ListingService.getSimilarListingsByMlsNumber(
          mlsNumber
        );
        setSimilarListings(listings || []);
      } catch (error: any) {
        console.error(error);
      }
    };

    const fetchEstimatedPrice = async () => {
      try {
        const price = await ListingService.getListingEstimateByMlsNumber(
          mlsNumber
        );
        setEstimatedPrice(price);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchFavourite = async () => {
      if (!isAuthenticated) {
        return;
      }
      try {
        const favouriteItem = await FavoritesService.getFavoriteByMlsNumber(
          mlsNumber
        );
        setFavourite(favouriteItem);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchReportAvailability = async () => {
      try {
        const response = await ReportsService.checkReportAvailability(
          mlsNumber
        );
        setReportAvailable(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchListing();
    fetchSimilarListings();
    fetchEstimatedPrice();
    fetchFavourite();
    fetchReportAvailability();
  }, [mlsNumber]);

  useEffect(() => {
    loadData();
  }, [mlsNumber]);

  const handleContentScroll = (event: CustomEvent) => {
    const fadePoint = 50;
    const maxScroll = 100;
    const scrollTop = event.detail.scrollTop;

    if (scrollTop < fadePoint) {
      setAppHeaderOpacity(0);
      setAppHeaderTransform("translateY(-100%)");
    } else if (scrollTop >= fadePoint && scrollTop <= maxScroll) {
      const opacityValue = (scrollTop - fadePoint) / (maxScroll - fadePoint);
      setAppHeaderOpacity(opacityValue);
      setAppHeaderTransform(`translateY(${(1 - opacityValue) * -100}%)`);
    } else {
      setAppHeaderOpacity(1);
      setAppHeaderTransform("translateY(0%)");
    }
  };

  const handleShare = async () => {
    if (Capacitor.isNativePlatform()) {
      const canShare = await Share.canShare();

      if (canShare) {
        await Share.share({
          title: ListingsHelper.getListingAddress(listing!.address),
          text: `Check out this listing on AECORN: ${ListingsHelper.getListingAddress(listing?.address)} for $${listing?.listPrice}`,
          url: `https://www.aecorn.ca/listings/${listing!.mlsNumber}`,
          dialogTitle: "Share listing",
        });
      }
    } else {
      setShowShareModal(true);
    }
  };

  const handleFavourite = async (e: any) => {
    e.stopPropagation();
    if (favoriteLoading || notificationLoading) {
      return;
    }
    if (!isAuthenticated) {
      presentAuthenticationModal({
        cssClass: "auth-modal",
      });
      return;
    }
    try {
      setFavoriteLoading(true);
      if (favourite) {
        await FavoritesService.removeFavorite(favourite);
        setFavourite(null);
      } else {
        const newFavourite = await FavoritesService.createFavorite(listing!);
        setFavourite(newFavourite);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFavoriteLoading(false);
    }
  };

  const handleNotification = async (e: any) => {
    e.stopPropagation();
    if (favoriteLoading || notificationLoading) {
      return;
    }
    if (!isAuthenticated) {
      presentAuthenticationModal({
        cssClass: "auth-modal",
      });
      return;
    }
    try {
      setNotificationLoading(true);
      if (favourite) {
        const updatedFavourite =
          await FavoritesService.toggleFavoriteNotification(favourite);
        setFavourite(updatedFavourite);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setNotificationLoading(false);
    }
  };

  const handleNavigateBack = () => {
    if (router.canGoBack()) {
      router.goBack();
    } else {
      router.push("/tabs/listings");
    }
  };

  return (
    <IonPage ref={pageRef}>
      <header
        className={styles.appHeader}
        style={{
          opacity: appHeaderOpacity,
          transform: appHeaderTransform,
        }}>
        <div className={styles.backButton} onClick={handleNavigateBack}>
          <IonIcon icon={chevronBack} />
        </div>
        {listing && (
          <div className={styles.buttons}>
            <div className={styles.button} onClick={handleShare}>
              <IonIcon icon={shareSocialOutline} />
            </div>

            <div className={styles.button} onClick={handleFavourite}>
              <IonIcon
                className={
                  favoriteLoading ? styles.loading : favourite ? styles.enabledFavourite : styles.disabledFavourite
                }
                icon={StarIcon}
              />
            </div>
            {listing.status === ListingStatus.Active && (
              <div className={styles.button} onClick={handleNotification}>
                <IonIcon
                  className={
                    notificationLoading ? styles.loading :
                      favourite
                        ? favourite.notification
                          ? styles.enabledNotification
                          : styles.disabledNotification
                        : styles.disabled
                  }
                  icon={BellIcon}
                />
              </div>
            )}
          </div>
        )}
      </header>
      <Modal
        isOpen={showShareModal}
        onDidDismiss={() => setShowShareModal(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <ListingShareModal
          listing={listing!}
          onDismiss={() => setShowShareModal(false)}
        />
      </Modal>
      <MediaQuery minWidth={768}>
        <IonHeader mode="ios">
          <Header />
        </IonHeader>
      </MediaQuery>
      <IonContent
        onIonScroll={handleContentScroll}
        scrollEvents
        className={styles.content}>
        <div className={styles.container}>
          <div className={styles.listingContainer}>
            {loading && <LoadingScreen reloadFunction={loadData} />}
            {!loading && error && (
              <ErrorScreen
                errorCode={error.response?.status || 400}
                onSignIn={loadData}
              />
            )}
            {!loading && listing && (
              <>
                <ListingGallery
                  listing={listing}
                  favourite={favourite}
                  onUpdateFavourite={handleFavourite}
                  onUpdateNotification={handleNotification}
                  loading={favoriteLoading}
                  onShare={handleShare}
                />
                <div className={styles.section}>
                  <ListingSummary
                    listing={listing}
                    estimatedPrice={estimatedPrice}
                    onOpenRequestModal={() => presentRequestModal({ cssClass: "aecorn-modal", presentingElement: pageRef.current })}
                    onOpenOfferModal={() => presentOfferModal({ cssClass: "aecorn-modal", presentingElement: pageRef.current })}
                  />
                </div>
                <div className={styles.section}>
                  <ListingInformation
                    listing={listing}
                    estimatedPrice={estimatedPrice}
                  />
                </div>
                {reportAvailable && (
                  <div className={styles.section}>
                    <ListingReport listing={listing} />
                  </div>
                )}
                <div className={styles.section}>
                  <ListingComparables
                    sold={listing.comparables}
                    active={similarListings}
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.webFooter}>
            <Footer />
          </div>
        </div>
      </IonContent>
      {!loading && !error && !isAgent && (
        <IonFooter className={styles.appFooter}>
          <IonToolbar>
            <IonButton
              className="aecorn-button"
              expand="block"
              onClick={() => presentRequestModal({
                cssClass: "aecorn-modal",
                presentingElement: pageRef.current
              })}>
              Contact or request viewing
            </IonButton>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Listing;
