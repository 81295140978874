import styles from "./skinny-footer.module.scss";

const SkinnyFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.container}>
      <div className={styles.links}>
        <a href="/terms">Terms of Use</a>
        <span>|</span>
        <a href="/privacy">Privacy Policy</a>
      </div>
      <div className={styles.copyRight}>
        © {currentYear} AECORN. All rights reserved.
      </div>
      <div className={styles.appVersion}>
        App Version: {process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
};

export default SkinnyFooter;