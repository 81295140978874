import { API } from "aws-amplify";
import {
  CreateTourInput,
  CreateTourMutation,
  CreateUserTourInput,
  CreateUserTourMutation,
  ListToursQuery,
  ShareStatus,
  TourStatus,
  Tour,
  TourRole,
  UpdateTourInput,
  UpdateUserTourInput,
  UpdateUserTourMutation,
  User,
  CreateTourItemInput,
  GetShowingsByDateQuery,
  ModelTourFilterInput,
  TourItemStatus,
  OnUpdateTourByTourIdSubscription,
  CreateTourPendingClientMutation,
  OnUpdateTourPendingClientByTourIdSubscription,
  GetTourPendingClientByEmailQuery,
  TourPendingClient,
  DeleteShowingMutation,
} from "../API";
import {
  createTour as createTourMutation,
  createUserTour as createUserTourMutation,
  createTourPendingClient as createTourPendingClientMutation,
  deleteTourPendingClient,
  deleteUserTour,
  deleteShowing,
} from "../graphql/mutations";
import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { UserService } from "./userService";
import { GeneralConstants } from "../utils/constants";
import {
  getClientTour,
  getAgentTour,
  listTours,
  getTourPendingClientByEmail,
  updateUserTour,
} from "./queries/tours.queries";
import { updateTour as updateTourMutation } from "./mutations/tours.mutations";
import { GetTourQuery, UpdateTourMutation } from "./types/tours.types";
import { AuthenticationService } from "./authenticationService";
import { TourItemService } from "./tourItemService";
import { getShowingsByDate} from "../graphql/queries";
import { BookedShowing } from "../models/listings/BookedShowing.model";
import moment from "moment";
import { getCurrentLocation } from "components/shared/Map/Map/map-utils";
import { Listing } from "models/listings/listing.model";
import { ListingsHelper } from "utils/ListingsHelper";
import {
  onUpdateTourByTourId,
  onUpdateTourPendingClientByTourId,
} from "graphql/subscriptions";

const getActiveTour = async () => {
  const currentDate = moment().format("YYYY-MM-DD");

  const filter = {
    date: { eq: currentDate },
    status: { eq: TourStatus.ready },
    shared: { eq: ShareStatus.shared },
    startTime: {
      between: [
        moment().subtract(2, "hours").format("HH:mm"),
        moment().add(2, "hours").format("HH:mm"),
      ],
    },
  };

  const response = await API.graphql<GraphQLQuery<ListToursQuery>>({
    query: listTours,
    variables: {
      filter,
    },
  });

  return response.data?.listTours?.items?.[0];
};

const getTourById = async (id: string) => {
  const isCurrentUserAgent = await AuthenticationService.isCurrentUserAgent();
  const response = await API.graphql<GraphQLQuery<GetTourQuery>>({
    query: isCurrentUserAgent ? getAgentTour : getClientTour,
    variables: { id },
  });
  return response.data?.getTour;
};

const getTours = async (
  filter?: ModelTourFilterInput,
  nextToken?: string | null
) => {
  const response = await API.graphql<GraphQLQuery<ListToursQuery>>({
    query: listTours,
    variables: { ...(nextToken && { nextToken }), ...(filter && { filter }) },
  });

  return {
    tours: (response.data?.listTours?.items as Tour[]) || [],
    nextToken: response.data?.listTours?.nextToken,
  };
};

const getAgentTours = async (
  filter?: ModelTourFilterInput,
  nextToken?: string | null
) => {
  return await getTours(filter, nextToken);
};

const getClientTours = async (
  filter?: ModelTourFilterInput,
  nextToken?: string | null
) => {
  const toursFilter = { ...filter, shared: { eq: ShareStatus.shared } };
  return await getTours(toursFilter, nextToken);
};

const getClientAvailableTours = async (clientId: string) => {
  const filter = {
    and: [
      { clients: { contains: clientId } },
      {
        or: [
          { status: { eq: TourStatus.ready } },
          { status: { eq: TourStatus.pending } },
        ],
      },
    ],
  };

  const result = await getTours(filter);

  return result.tours.filter((tour) =>
    tour.users?.items.find(
      (user) => user?.userId === clientId && user.role === TourRole.client
    )
  );
};

const createTour = async (
  tour: CreateTourInput,
  leadAgent: User,
  showingAgents: User[],
  client: { email: string; user?: User },
  guests?: { email: string; user?: User }[]
) => {
  const currentUser = await UserService.getCurrentUser();
  const clientsIds: string[] = [];
  if (client.user) {
    clientsIds.push(client.user.id);
  }
  const guestsIds =
    guests?.filter((guest) => guest.user).map((guest) => guest.user!.id) || [];
  const clients = [...clientsIds, ...guestsIds];

  const input: CreateTourInput = {
    ...tour,
    creator: currentUser?.id,
    primaryAgent: leadAgent.id,
    showingAgents: showingAgents.map((agent) => agent.id) || [],
    clients,
    status: TourStatus.pending,
    shared: ShareStatus.unshared,
  };

  const response = await API.graphql<GraphQLQuery<CreateTourMutation>>({
    query: createTourMutation,
    variables: { input },
  });

  const createdTour = response.data?.createTour;

  if (createdTour) {
    const guestsUsers =
      guests?.filter((guest) => guest.user).map((guest) => guest.user!) || [];

    await createUserTours(
      createdTour.id,
      leadAgent,
      showingAgents,
      client.user,
      guestsUsers
    );

    const pendingClients = [];

    if (!client.user) {
      pendingClients.push({ email: client.email, role: TourRole.client });
    }

    guests?.forEach((guest) => {
      if (!guest.user) {
        pendingClients.push({ email: guest.email, role: TourRole.guest });
      }
    });

    const promises = [];

    if (pendingClients.length > 0) {
      promises.push(createTourPendingClient(currentUser.id, pendingClients[0], createdTour.id, createdTour.title || ""));
    }
  }

  return createdTour;
};

const updateTour = async (tour: UpdateTourInput) => {
  await API.graphql<GraphQLQuery<UpdateTourMutation>>({
    query: updateTourMutation,
    variables: { input: tour },
  });

  const updatedTour = await getTourById(tour.id);
  return updatedTour;
};

const archiveTour = async (id: string) => {
  return await updateTour({ id, status: TourStatus.archived });
};

const cancelTour = async (id: string, notifyAttendees = false) => {
  const a = true;
  if (a) {
    try {
      const authToken = await AuthenticationService.getToken();

      await API.post(GeneralConstants.REST_API_NAME, `/tours/${id}/cancel`, {
        body: {
          authtoken: authToken,
        },
      });
    } catch (error) {
      throw new Error("Failed to notify attendees");
    }
  }

  return await updateTour({ id, status: TourStatus.cancelled });
};

const markTourAsSeen = async (tour: Tour) => {
  const currentUserId = await UserService.getCurrentUserId();
  const userTour = tour.users?.items?.find(
    (userTour) => userTour?.userId === currentUserId
  );

  if (userTour) {
    await API.graphql<GraphQLQuery<UpdateUserTourMutation>>({
      query: updateUserTour,
      variables: { input: { id: userTour.id, seen: true } },
    });
  }
};

const acceptTourInvitation = async (tourId: string, invitationId: string) => {
  const authToken = await AuthenticationService.getToken();

  await API.post(GeneralConstants.REST_API_NAME, `/tours/${tourId}/invitation/${invitationId}`, {
    body: {
      authtoken: authToken,
      accept: true,
    },
  });
};

const rejectTourInvitation = async (tourId: string, invitationId: string) => {
  const authToken = await AuthenticationService.getToken();

  await API.post(GeneralConstants.REST_API_NAME, `/tours/${tourId}/invitation/${invitationId}`, {
    body: {
      authtoken: authToken,
      accept: false,
    },
  });
}

const updateTourUsers = async (
  tourId: string,
  users: { id: string; role: TourRole }[]
) => {
  const currentUserId = await UserService.getCurrentUserId();
  const tour = await getTourById(tourId);

  // Iterate through existing users and remove any that are not in the new list and add any that are not in the existing list and update any that have a different role
  const existingUsers = tour?.users?.items || [];

  const usersToRemove = existingUsers.filter(
    (existingUser) =>
      !users.find((user) => user.id === existingUser?.userId) ||
      !users.find(
        (user) =>
          user.id === existingUser?.userId && user.role === existingUser?.role
      )
  );
  const usersToAdd = users.filter(
    (user) =>
      !existingUsers.find((existingUser) => existingUser?.userId === user.id) ||
      !existingUsers.find(
        (existingUser) =>
          existingUser?.userId === user.id && existingUser?.role === user.role
      )
  );

  // Remove users
  await Promise.all(
    usersToRemove.map((user) => {
      if (user?.id) {
        return removeUserTourById(user.id);
      }
      return Promise.resolve();
    })
  );

  // Add users
  await Promise.all(
    usersToAdd.map((user) => {
      return createUserTour({
        creator: currentUserId,
        userId: user.id,
        tourId,
        role: user.role,
        seen: false,
      });
    })
  );

  // Update tour agents and clients
  const updatedTour = await updateTour({
    id: tourId,
    primaryAgent: users.find((user) => user.role === TourRole.primaryagent)?.id,
    showingAgents: users
      .filter((user) => user.role === TourRole.showingagent)
      .map((user) => user.id),
    clients: users
      .filter(
        (user) => user.role === TourRole.client || user.role === TourRole.guest
      )
      .map((user) => user.id),
  });

  // Update tour items
  const tourItems = tour?.tourItems?.items || [];
  await Promise.all(
    tourItems.map((item) => {
      if (item) {
        return TourItemService.updateTourItem({
          id: item.id,
          primaryAgent: updatedTour?.primaryAgent,
          showingAgents: updatedTour?.showingAgents,
          clients: updatedTour?.clients,
        });
      }
    })
  );

  if (tour?.shared === ShareStatus.shared) {
    const authToken = await AuthenticationService.getToken();
    await API.post(
      GeneralConstants.REST_API_NAME,
      `/tours/${tourId}/notify-new-clients`,
      {
        body: {
          who: usersToAdd.map((user) => ({id: user.id, role: user.role})),
          authtoken: authToken,
        },
      }
    );
  }

  return await getTourById(tourId);
};

const updateTourPendingClients = async (
  tourId: string,
  clients: { email: string; role: TourRole.client | TourRole.guest }[]
) => {
  const currentUser = await UserService.getCurrentUser();
  const tour = await getTourById(tourId);

  const existingPendingClients = tour?.pendingClients?.items || [];
  const clientsToRemove = existingPendingClients.filter(
    (client) => !clients.find((newClient) => newClient.email === client.email)
  );

  const clientsToAdd = clients.filter(
    (client) =>
      !existingPendingClients.find(
        (existingClient) => existingClient.email === client.email
      )
  );

  // Remove clients
  await Promise.all(
    clientsToRemove.map((client) => {
      if (client.id) {
        return removeTourPendingClientById(client.id);
      }
      return Promise.resolve();
    })
  );

  // Add clients
  await Promise.all(
    clientsToAdd.map((client) => {
      return createTourPendingClient(currentUser.id, client, tourId, tour?.title || "");
    })
  );

  if (tour?.shared === ShareStatus.shared) {
    const authToken = await AuthenticationService.getToken();
    await API.post(
      GeneralConstants.REST_API_NAME,
      `/tours/${tourId}/notify-new-clients`,
      {
        body: {
          who: clientsToAdd.map((client) => ({id: client.email, role: client.role})),
          pending: true,
          authtoken: authToken,
        },
      }
    );
  }
};

const reorderTourStops = async (stops: { id: string; order: number }[]) => {
  await Promise.all(
    stops.map((stop) =>
      TourItemService.updateTourItem({
        id: stop.id,
        order: stop.order,
      })
    )
  );
};

const getToursUpdates = async () => {
  const currentUserId = await UserService.getCurrentUserId();
  const isUserAgent = await AuthenticationService.isCurrentUserAgent();
  const userTours = isUserAgent
    ? await getAgentTours()
    : await getClientTours();

  const updates = userTours.tours.filter((tour) =>
    tour?.users?.items.some(
      (user) => user?.userId === currentUserId && !user?.seen
    )
  );

  return updates;
};

const addShowingToTour = async (tour: Tour, showing: CreateTourItemInput) => {
  return await TourItemService.createTourItem({
    ...showing,
    tourId: tour.id,
    primaryAgent: tour.primaryAgent,
    showingAgents: tour.showingAgents,
    clients: tour.clients,
  });
};

const shareTour = async (tourId: string) => {
  const tour = await getTourById(tourId);
  if (tour) {
    const clients = tour.users.items
      .filter(
        (user) =>
          user &&
          (user.role === TourRole.client || user.role === TourRole.guest)
      )
      .map((user) => user!.user);

    if (clients.length === 0) {
      throw new Error("Tour has no clients to share with");
    }

    const authToken = await AuthenticationService.getToken();

    await API.post(GeneralConstants.REST_API_NAME, `/tours/${tourId}/share`, {
      body: {
        authtoken: authToken,
      },
    });

    const response = await API.graphql<GraphQLQuery<UpdateTourMutation>>({
      query: updateTourMutation,
      variables: { input: { id: tourId, shared: ShareStatus.shared } },
    });

    return response.data?.updateTour;
  } else {
    throw new Error("Tour not found");
  }
};

// Utility functions
const getTourPrimaryAgent = (tour: Tour) => {
  return tour.users?.items.find((user) => user?.role === TourRole.primaryagent)
    ?.user;
};

const getTourShowingAgents = (tour: Tour) => {
  return (
    tour.users?.items
      .filter((user) => user?.role === TourRole.showingagent)
      .map((user) => user?.user) || []
  );
};

const getTourClient = (tour: Tour) => {
  return tour.users?.items.find((user) => user?.role === TourRole.client)?.user;
};

const getTourGuests = (tour: Tour) => {
  return (
    tour.users?.items
      .filter((user) => user?.role === TourRole.guest)
      .map((user) => user?.user) || []
  );
};

const getTourPendingClients = (tour: Tour) => {
  return tour.pendingClients?.items || [];
};

const getTourUserByUserId = (tour: Tour, userId: string) => {
  return tour.users?.items.find((user) => user?.userId === userId);
};

const getTourUsers = (tour: Tour) => {
  const tourUsers = tour.users?.items || [];

  const TourRoleMap = {
    [TourRole.primaryagent]: "Lead Agent",
    [TourRole.showingagent]: "Showing Agent",
    [TourRole.client]: "Client",
    [TourRole.guest]: "Guest",
  };

  return tourUsers
    .map((user) => ({
      ...user?.user,
      role: user?.role,
      title: user?.role ? TourRoleMap[user.role] : "Unknown",
    }))
    .sort((a, b) => {
      if (a.role === TourRole.primaryagent) {
        return -1;
      } else if (b.role === TourRole.primaryagent) {
        return 1;
      }

      if (a.role === TourRole.showingagent) {
        return -1;
      } else if (b.role === TourRole.showingagent) {
        return 1;
      }

      if (a.role === TourRole.client) {
        return -1;
      } else if (b.role === TourRole.client) {
        return 1;
      }
      return 0;
    });
};

const addGuestsToTour = async (tour: Tour, guests: string[]) => {
  const currentUserId = await UserService.getCurrentUserId();
  const users = await Promise.all(
    guests.map(async (email) => {
      const user = await UserService.getUserByEmail(email);
      return {
        id: user?.id,
        email,
      };
    })
  );

  const currentClients = tour.clients || [];
  for (const user of users) {
    if (user.id) {
      await createUserTour({
        creator: currentUserId,
        userId: user.id,
        tourId: tour.id,
        role: TourRole.guest,
        seen: false,
      });
      currentClients.push(user.id);
    } else {
      await createTourPendingClient(
        currentUserId,
        { email: user.email, role: TourRole.guest },
        tour.id,
        tour.title || ""
      );
    }
  }

  await updateTour({
    id: tour.id,
    clients: currentClients,
  });

  const tourItems = tour?.tourItems?.items || [];
  await Promise.all(
    tourItems.map((item) => {
      if (item) {
        return TourItemService.updateTourItem({
          id: item.id,
          clients: currentClients,
        });
      }
    })
  );
};

const getPendingTours = async () => {
  const user = await UserService.getCurrentUser();
  if (user) {
    return await getPendingToursByEmail(user.email) as TourPendingClient[];
  } else {
    return [];
  }
}

const getPendingToursByEmail = async (email: string) => {
  const response = await API.graphql<GraphQLQuery<GetTourPendingClientByEmailQuery>>({
    query: getTourPendingClientByEmail,
    variables: {
      email,
    },
  });

  return response.data?.getTourPendingClientByEmail?.items || [] as TourPendingClient[];
};

const navigateTourStops = async (
  tour: Tour,
  stops: {
    listing: Listing;
    order?: number | null;
    status?: string | null;
  }[]
) => {
  const currentLocation = await getCurrentLocation();
  let locations = "";
  if (currentLocation)
    locations += `${currentLocation.latitude},${currentLocation.longitude}/`;

  if (tour.meetupLocation) locations += `${tour.meetupLocation}/`;
  stops.forEach((stop) => {
    if (
      stop.status &&
      ![
        TourItemStatus.skipped,
        TourItemStatus.cancelled,
        TourItemStatus.completed,
        TourItemStatus.rejected,
      ].includes(stop.status as TourItemStatus)
    ) {
      locations += `${ListingsHelper.getListingAddress(
        stop.listing.address,
        true
      )}/`;
    }
  });

  window.open(
    `https://www.google.com/maps/dir/${locations.slice(
      0,
      locations.length
    )}&dirflg=d,t`,
    "_blank"
  );
};

// #region Subscriptions
const onTourUpdate = (
  tourId: string,
  callback: (update: Tour | undefined) => void
) => {
  const result = API.graphql<
    GraphQLSubscription<OnUpdateTourByTourIdSubscription>
  >({
    query: onUpdateTourByTourId,
    variables: { id: tourId },
  });

  const subscription = result.subscribe(async () => {
    const tour = await getTourById(tourId);
    callback(tour);
  });

  return subscription;
};

const onTourPendingClientsUpdate = (
  tourId: string,
  callback: (update: Tour | undefined) => void
) => {
  const result = API.graphql<
    GraphQLSubscription<OnUpdateTourPendingClientByTourIdSubscription>
  >({
    query: onUpdateTourPendingClientByTourId,
    variables: { tourId },
  });

  const subscription = result.subscribe(async () => {
    const tour = await getTourById(tourId);
    callback(tour);
  });

  return subscription;
};
// #endregion

// Private functions

const createUserTours = async (
  tourId: string,
  leadAgent: User,
  showingAgents: User[],
  client?: User,
  guests?: User[]
) => {
  const currentUserId = await UserService.getCurrentUserId();

  const userTourPromises = [
    createUserTour({
      creator: currentUserId,
      userId: leadAgent.id,
      tourId,
      role: TourRole.primaryagent,
      seen: currentUserId === leadAgent.id,
    }),
    ...showingAgents.map((agent) =>
      createUserTour({
        creator: currentUserId,
        userId: agent.id,
        tourId,
        role: TourRole.showingagent,
        seen: agent.id === currentUserId,
      })
    ),
    ...(client
      ? [
          createUserTour({
            creator: currentUserId,
            userId: client.id,
            tourId,
            role: TourRole.client,
            seen: false,
          }),
        ]
      : []),
  ];

  if (guests) {
    userTourPromises.push(
      ...guests.map((guest) =>
        createUserTour({
          creator: currentUserId,
          userId: guest.id,
          tourId,
          role: TourRole.guest,
          seen: false,
        })
      )
    );
  }

  return Promise.all(userTourPromises);
};

const createUserTour = async (userTour: CreateUserTourInput) => {
  const response = await API.graphql<GraphQLQuery<CreateUserTourMutation>>({
    query: createUserTourMutation,
    variables: { input: userTour },
  });
  return response.data?.createUserTour;
};

const createTourPendingClient = async (
  creator: string,
  client: { email: string; role: TourRole },
  tourId: string,
  tourTitle: string
) => {
  const response = await API.graphql<
    GraphQLQuery<CreateTourPendingClientMutation>
  >({
    query: createTourPendingClientMutation,
    variables: {
      input: {
        creator,
        tourId,
        tourTitle,
        email: client.email,
        role: client.role,
      },
    },
  });
  return response.data?.createTourPendingClient;
};

const updateTourUser = async (userTour: UpdateUserTourInput) => {
  const response = await API.graphql<GraphQLQuery<UpdateUserTourMutation>>({
    query: updateUserTour,
    variables: { input: userTour },
  });
  return response.data?.updateUserTour;
};

const removeUserTourById = async (id: string) => {
  await API.graphql({ query: deleteUserTour, variables: { input: { id } } });
};

const removeTourPendingClientById = async (id: string) => {
  await API.graphql({
    query: deleteTourPendingClient,
    variables: { input: { id } },
  });
};

const getBookedShowingsByDate = async (date: string) => {
  const response = await API.graphql<GraphQLQuery<GetShowingsByDateQuery>>({
    query: getShowingsByDate,
    variables: { date },
  });

  const showings: BookedShowing[] = <BookedShowing[]>(
    (response.data?.getShowingsByDate?.items || [])
  );

  return showings;
};

const removeBookedShowing = async (id: string) => {
  await API.graphql<DeleteShowingMutation>({
    query: deleteShowing,
    variables: { input: { id } },
  });
}

const isCurrentUserTourPrimaryAgent = async (tour: Tour) => {
  const currentUserId = await UserService.getCurrentUserId();
  return tour.primaryAgent === currentUserId;
};

export const TourService = {
  getActiveTour,
  getTourById,
  getAgentTours,
  getClientTours,
  createTour,
  updateTour,
  updateTourUsers,
  updateTourPendingClients,
  reorderTourStops,
  getTourUserByUserId,
  getTourUsers,
  getTourPrimaryAgent,
  getTourShowingAgents,
  getTourClient,
  getTourGuests,
  getTourPendingClients,
  getToursUpdates,
  archiveTour,
  cancelTour,
  markTourAsSeen,
  shareTour,
  addShowingToTour,
  addGuestsToTour,
  getBookedShowingsByDate,
  removeBookedShowing,
  isCurrentUserTourPrimaryAgent,
  navigateTourStops,
  onTourUpdate,
  onTourPendingClientsUpdate,
  getClientAvailableTours,
  getPendingTours,
  getPendingToursByEmail,
  acceptTourInvitation,
  rejectTourInvitation,
};
