import {
  IonBackButton,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonTextarea,
  IonToolbar,
  isPlatform,
  useIonToast,
  useIonViewWillLeave,
} from "@ionic/react";
import styles from "./new-tour.module.scss";
import { useEffect, useState } from "react";
import {
  addOutline,
  chevronDownOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import MediaQuery from "react-responsive";
import { User } from "API";
import TourLeadAgentModal from "components/shared/Modals/TourLeadAgentModal/tour-lead-agent-modal.component";
import { UserService } from "services/userService";
import TourShowingAgentsModal from "components/shared/Modals/TourShowingAgentsModal/tour-showing-agents-modal.component";
import TourClientModal from "components/shared/Modals/TourClientModal/tour-client-modal.component";
import TourGuestsModal from "components/shared/Modals/TourGuestsModal/tour-guests-modal.component";
import TourAvailabilityModal from "components/shared/Modals/TourAvailabilityModal/tour-availability-modal.component";
import {
  groupSlotsByDay,
  renderSlotText,
} from "components/shared/Tours/TourAvailability/tour-availability.component";
import moment from "moment";
import TourDateModal from "components/shared/Modals/TourDateModal/tour-date-modal.component";
import TourTimeModal from "components/shared/Modals/TourTimeModal/tour-time-modal.component";
import { TourService } from "services/tourService";
import TourCreationModal from "components/shared/Modals/TourCreationModal/tour-creation-modal.component";
import { Redirect, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAgent } from "redux/user/user.selectors";
import { selectTourRequest } from "redux/tour/tour.selectors";
import { Listing } from "models/listings/listing.model";
import { ListingsHelper } from "utils/ListingsHelper";
import { updateTourRequest } from "redux/tour/tour.actions";

const NewTour = () => {
  const isApp = isPlatform("hybrid");
  const history = useHistory();
  const dispatch = useDispatch();
  const isAgent = useSelector(selectIsAgent);

  const tourRequest:
    | {
        listing: Listing;
        userId?: string;
        email: string;
        guests: string[];
        availability: string[];
      }
    | undefined = useSelector(selectTourRequest);

  const [step, setStep] = useState(1);
  const [createdTour, setCreatedTour] = useState<any>();

  const [presentToast] = useIonToast();
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [leadAgent, setLeadAgent] = useState<User>();
  const [showingAgents, setShowingAgents] = useState<User[]>([]);
  const [client, setClient] = useState<{ email: string; user?: User }>();
  const [guests, setGuests] = useState<{ email: string; user?: User }[]>([]);
  const [guestsLoading, setGuestsLoading] = useState(false);
  const [privateNotes, setPrivateNotes] = useState("");
  const [availability, setAvailability] = useState<string[]>([]);
  const [date, setDate] = useState<string>();
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();

  const [isLeadAgentModalOpen, setIsLeadAgentModalOpen] = useState(false);
  const [isShowingAgentsModalOpen, setIsShowingAgentsModalOpen] =
    useState(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isGuestsModalOpen, setIsGuestsModalOpen] = useState(false);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isStartTimeModalOpen, setIsStartTimeModalOpen] = useState(false);
  const [isEndTimeModalOpen, setIsEndTimeModalOpen] = useState(false);

  const [isTourCreatedModalOpen, setIsTourCreatedModalOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<User[]>([]);

  const [tempPrimaryAgent, setTempPrimaryAgent] = useState<
    User | undefined | null
  >();
  const [tempShowingAgents, setTempShowingAgents] = useState<User[]>([]);
  const [tempClient, setTempClient] = useState<{
    email: string;
    user?: User;
  } | null>();
  const [tempGuests, setTempGuests] = useState<
    { email: string; user?: User }[]
  >([]);
  const [tempDate, setTempDate] = useState<string>();
  const [tempStartTime, setTempStartTime] = useState<string>("");
  const [tempEndTime, setTempEndTime] = useState<string>("");

  useEffect(() => {
    const mapTourRequest = async () => {
      if (tourRequest) {
        try {
          setLoading(true);
          const title = `Tour for ${ListingsHelper.getListingAddress(
            tourRequest.listing.address
          )}`;
          setTitle(title);
          setAvailability(tourRequest.availability);
          if (tourRequest.userId) {
            UserService.getUserById(tourRequest.userId).then((result) => {
              if (result) {
                setClient({
                  email: result.email,
                  user: result as User,
                });
              }
            });
          } else {
            setClient({ email: tourRequest.email });
          }

          for (const guestEmail of tourRequest.guests) {
            const user = await UserService.getUserByEmail(guestEmail);
            setGuests((guests) => [
              ...guests,
              { email: guestEmail, user: undefined },
            ]);
          }
        } catch (err) {
          console.log(err);
          presentToast({
            message: "Failed to load tour request",
            duration: 3000,
            cssClass: "aecorn-error-toast",
          });
        } finally {
          setLoading(false);
        }
      }
    };
    mapTourRequest();
  }, [tourRequest]);

  useIonViewWillLeave(() => {
    if (tourRequest) {
      dispatch(updateTourRequest(undefined));
    }
  });

  const onClose = () => {
    setIsTourCreatedModalOpen(false);
    history.replace(isApp ? "/tabs/tours" : "/tours");
  };

  const onNavigate = () => {
    setIsTourCreatedModalOpen(false);
    history.replace(`/tours/details/${createdTour.id}`);
  };

  const onOpenLeadAgentModal = () => {
    setTempPrimaryAgent(leadAgent);
    setIsLeadAgentModalOpen(true);
  };

  const onOpenShowingAgentsModal = () => {
    setTempShowingAgents(showingAgents);
    setIsShowingAgentsModalOpen(true);
  };

  const onOpenClientModal = () => {
    setTempClient(client);
    setIsClientModalOpen(true);
  };

  const onOpenGuestsModal = () => {
    setTempGuests(guests);
    setIsGuestsModalOpen(true);
  };

  const onOpenDateModal = () => {
    setTempDate(date);
    setIsDateModalOpen(true);
  };

  const searchForAgents = async (searchTerm: string) => {
    try {
      if (!searchTerm) {
        setSearchResults([]);
        return;
      }
      const results = await UserService.getAgentUsers(searchTerm);
      setSearchResults(results as User[]);
    } catch (err) {
      console.log(err);
    }
  };

  const searchForClients = async (searchTerm: string) => {
    try {
      if (!searchTerm) {
        setSearchResults([]);
        return;
      }
      const results = await UserService.getClientUsers(searchTerm);
      setSearchResults(results as User[]);
    } catch (err) {
      console.log(err);
    }
  };

  const searchForGuests = async (
    searchTerm: string,
    newTempGuests: { email: string; user?: User }[]
  ) => {
    try {
      setGuestsLoading(true);
      if (!searchTerm) {
        setSearchResults([]);
        return;
      }
      const results = await UserService.getClientUsers(searchTerm);
      setTempGuests(
        newTempGuests.map((g) => ({
          ...g,
          user: g.user
            ? g.user
            : results.find((r) => r!.email === g.email) || undefined,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setGuestsLoading(false);
    }
  };

  const onAddGuestEmail = async () => {
    const email = searchTerm.trim();
    const newTempGuests = [...tempGuests, { email, user: undefined }];
    setSearchTerm("");
    await searchForGuests(email, newTempGuests);
  };

  const resetSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
  };

  const onRemoveDay = (day: string) => {
    const updatedSlots = availability.filter((slot) => !slot.startsWith(day));
    setAvailability(updatedSlots);
  };

  const onSaveTime = () => {
    if (isStartTimeModalOpen && tempStartTime) {
      if (endTime && moment(tempStartTime).isAfter(moment(endTime, "HH:mm"))) {
        presentToast({
          message: "Start time cannot be after end time",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
        return;
      }
      setStartTime(moment(tempStartTime).format("HH:mm"));
      setIsStartTimeModalOpen(false);
    }
    if (isEndTimeModalOpen && tempEndTime) {
      if (
        startTime &&
        moment(tempEndTime).isBefore(moment(startTime, "HH:mm"))
      ) {
        presentToast({
          message: "End time cannot be before start time",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
        return;
      }
      setEndTime(moment(tempEndTime).format("HH:mm"));
      setIsEndTimeModalOpen(false);
    }
  };

  const onContinue = async () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else {
      try {
        setLoading(true);
        const createdTour = await TourService.createTour(
          {
            title,
            date: moment(date).format("YYYY-MM-DD"),
            startTime,
            endTime,
            privateNote: privateNotes,
            availability: JSON.stringify(availability),
          },
          leadAgent!,
          showingAgents,
          client!,
          guests
        );
        if (createdTour) {
          setCreatedTour(createdTour);
          setIsTourCreatedModalOpen(true);
        }
      } catch (err) {
        console.log(err);
        presentToast({
          message: "Failed to create tour",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const Breadcrumbs = () => {
    return (
      <IonBreadcrumbs mode="ios" className="aecorn-breadcrumbs">
        <IonBreadcrumb active={step === 1} onClick={() => setStep(1)}>
          Private info
          <IonIcon slot="separator" icon={chevronForwardOutline}></IonIcon>
        </IonBreadcrumb>
        <IonBreadcrumb active={step === 2} onClick={() => setStep(2)}>
          Attendees
          <IonIcon slot="separator" icon={chevronForwardOutline}></IonIcon>
        </IonBreadcrumb>
        <IonBreadcrumb active={step === 3} onClick={() => setStep(3)}>
          Date & Time
        </IonBreadcrumb>
      </IonBreadcrumbs>
    );
  };

  return (
    <IonPage>
      {!isAgent && <Redirect to="/" />}
      <IonLoading isOpen={loading} message="Loading..." />
      <IonHeader mode="ios" className={styles.header}>
        <IonToolbar>
          <div className={styles.title}>Create a new tour</div>
          <IonButtons slot="end">
            <IonBackButton
              className="aecorn-back-button"
              text="Discard and exit"
              defaultHref={isApp ? "/tabs/tours" : "/tours"}
              type="button"
              icon=""
            />
          </IonButtons>
        </IonToolbar>
        <div className={styles.text}>
          First create a tour, you can add and edit showings later.
        </div>
        <MediaQuery maxWidth={767}>
          <Breadcrumbs />
        </MediaQuery>
      </IonHeader>
      <IonContent className={styles.content}>
        <TourCreationModal
          title={title}
          isOpen={isTourCreatedModalOpen}
          onDismiss={onClose}
          onNavigate={onNavigate}
        />
        <TourLeadAgentModal
          isOpen={isLeadAgentModalOpen}
          onDismiss={() => {
            setIsLeadAgentModalOpen(false);
            resetSearch();
            setTempPrimaryAgent(null);
          }}
          searchTerm={searchTerm}
          onSearchTermChange={(term: string) => {
            setSearchTerm(term);
            searchForAgents(term);
          }}
          searchResults={searchResults}
          selectedAgent={tempPrimaryAgent}
          onUpdateAgent={setTempPrimaryAgent}
          onSave={() => {
            setLeadAgent(tempPrimaryAgent!);
            setIsLeadAgentModalOpen(false);
            resetSearch();
          }}
        />
        <TourShowingAgentsModal
          isOpen={isShowingAgentsModalOpen}
          onDismiss={() => {
            setIsShowingAgentsModalOpen(false);
            resetSearch();
            setTempShowingAgents([]);
          }}
          searchTerm={searchTerm}
          onSearchTermChange={(term: string) => {
            setSearchTerm(term);
            searchForAgents(term);
          }}
          searchResults={searchResults}
          selectedAgents={tempShowingAgents}
          onUpdateAgents={setTempShowingAgents}
          onSave={() => {
            setShowingAgents(tempShowingAgents);
            setIsShowingAgentsModalOpen(false);
            resetSearch();
          }}
        />
        <TourClientModal
          isOpen={isClientModalOpen}
          onDismiss={() => {
            setIsClientModalOpen(false);
            resetSearch();
            setTempClient(null);
          }}
          searchTerm={searchTerm}
          onSearchTermChange={(term: string) => {
            setSearchTerm(term);
            searchForClients(term);
          }}
          searchResults={searchResults}
          selectedClient={tempClient}
          onUpdateClient={setTempClient}
          onSave={() => {
            setClient(tempClient!);
            setIsClientModalOpen(false);
            resetSearch();
          }}
        />
        <TourGuestsModal
          isOpen={isGuestsModalOpen}
          onDismiss={() => {
            setIsGuestsModalOpen(false);
            resetSearch();
            setTempGuests([]);
          }}
          searchTerm={searchTerm}
          onSearchTermChange={(term: string) => {
            setSearchTerm(term);
            searchForAgents(term);
          }}
          searchResults={searchResults}
          selectedGuests={tempGuests}
          onUpdateGuests={setTempGuests}
          onAddEmail={onAddGuestEmail}
          loading={guestsLoading}
          client={client?.user}
          primaryAgent={leadAgent}
          showingAgents={showingAgents}
          onSave={() => {
            setGuests(tempGuests);
            setIsGuestsModalOpen(false);
            resetSearch();
          }}
        />
        <TourAvailabilityModal
          isOpen={isAvailabilityModalOpen}
          onDismiss={() => setIsAvailabilityModalOpen(false)}
          availability={availability}
          onSave={(availability: string[]) => {
            setAvailability(availability);
            setIsAvailabilityModalOpen(false);
          }}
        />
        <TourDateModal
          isOpen={isDateModalOpen}
          onDismiss={() => setIsDateModalOpen(false)}
          selectedDate={tempDate || ""}
          onUpdateDate={(date: string) => setTempDate(date)}
          onSave={() => {
            setDate(tempDate);
            setIsDateModalOpen(false);
          }}
        />
        <TourTimeModal
          title={isStartTimeModalOpen ? "Select start time" : "Select end time"}
          isOpen={isStartTimeModalOpen || isEndTimeModalOpen}
          onDismiss={() => {
            setIsStartTimeModalOpen(false);
            setIsEndTimeModalOpen(false);
          }}
          selectedTime={isStartTimeModalOpen ? tempStartTime : tempEndTime}
          onUpdateTime={(time: string) => {
            if (isStartTimeModalOpen) {
              setTempStartTime(time);
            } else {
              setTempEndTime(time);
            }
          }}
          onSave={onSaveTime}
        />
        <div className={styles.container}>
          <MediaQuery minWidth={769}>
            <Breadcrumbs />
          </MediaQuery>
          <div
            className={styles.step}
            style={{ display: step !== 1 ? "none" : "flex" }}>
            <div className={styles.item}>
              <IonLabel className={styles.label}>Tour title</IonLabel>
              <IonInput
                required
                value={title}
                autocapitalize="words"
                className="aecorn-input dark"
                placeholder="Enter a title for the tour"
                onIonChange={(e) => setTitle(e.detail.value!)}></IonInput>
            </div>
            <div className={styles.item}>
              <IonLabel className={styles.label}>Lead agent</IonLabel>
              <IonItem
                lines="none"
                className="aecorn-select dark"
                button
                detailIcon={chevronDownOutline}
                onClick={onOpenLeadAgentModal}>
                {leadAgent ? leadAgent.displayName : "Select lead agent"}
              </IonItem>
            </div>
            <div className={styles.item}>
              <IonLabel className={styles.label}>
                Private agent tour notes
              </IonLabel>
              <IonTextarea
                required
                className="aecorn-input dark"
                placeholder="Enter a title for the tour"
                onIonChange={(e) =>
                  setPrivateNotes(e.detail.value!)
                }></IonTextarea>
            </div>
          </div>
          <div
            className={styles.step}
            style={{ display: step !== 2 ? "none" : "flex" }}>
            <div className={styles.item}>
              <IonLabel className={styles.label}>Showing agents</IonLabel>
              <IonItem
                lines="none"
                className="aecorn-select dark"
                button
                detailIcon={chevronDownOutline}
                onClick={onOpenShowingAgentsModal}>
                {showingAgents.length > 0
                  ? showingAgents.map((agent) => agent.displayName).join(", ")
                  : "Select showing agents"}
              </IonItem>
            </div>
            <div className={styles.item}>
              <IonLabel className={styles.label}>Client</IonLabel>
              <IonItem
                lines="none"
                className="aecorn-select dark"
                button
                detailIcon={chevronDownOutline}
                onClick={onOpenClientModal}>
                {client
                  ? client.user
                    ? client.user.displayName
                    : client.email
                  : "Select client"}
              </IonItem>
            </div>
            <div className={styles.item}>
              <IonLabel className={styles.label}>Guests</IonLabel>
              <IonItem
                lines="none"
                className="aecorn-select dark"
                button
                detailIcon={chevronDownOutline}
                onClick={onOpenGuestsModal}>
                {guests.length > 0
                  ? guests
                      .map((guest) =>
                        guest.user ? guest.user.displayName : guest.email
                      )
                      .join(", ")
                  : "Select guests"}
              </IonItem>
            </div>
          </div>
          <div
            className={styles.step}
            style={{ display: step !== 3 ? "none" : "flex" }}>
            <div className={styles.item}>
              <IonLabel className={styles.label}>
                <span>Available timeframes</span>
                <IonButton
                  className="aecorn-button shadow"
                  onClick={() => setIsAvailabilityModalOpen(true)}>
                  <IonIcon icon={addOutline} slot="icon-only" />
                </IonButton>
              </IonLabel>
              {availability.length > 0 &&
                Object.entries(groupSlotsByDay(availability)).map(
                  ([day, slots]) => (
                    <div key={day} className={styles.slot}>
                      <span>{renderSlotText(day, slots, false)}</span>
                      <IonButton
                        className="aecorn-button clear-dark"
                        onClick={() => onRemoveDay(day)}>
                        remove
                      </IonButton>
                    </div>
                  )
                )}
            </div>
            <div className={styles.item}>
              <IonLabel className={styles.label}>Date</IonLabel>
              <IonItem
                lines="none"
                className="aecorn-select dark"
                button
                detailIcon={chevronDownOutline}
                onClick={onOpenDateModal}>
                {date ? moment(date).format("MMM DD YYYY") : "Select date"}
              </IonItem>
            </div>
            <div className={styles.item}>
              <IonLabel className={styles.label}>Time</IonLabel>
              <div className={styles.row}>
                <div className={styles.subItem}>
                  <IonLabel className={styles.subLabel}>Start</IonLabel>
                  <IonItem
                    lines="none"
                    className="aecorn-select dark"
                    button
                    detailIcon={chevronDownOutline}
                    onClick={() => setIsStartTimeModalOpen(true)}>
                    {startTime
                      ? moment(startTime, "HH:mm").format("hh:mm A")
                      : "Select time"}
                  </IonItem>
                </div>
                <div className={styles.subItem}>
                  <IonLabel className={styles.subLabel}>End</IonLabel>
                  <IonItem
                    lines="none"
                    className="aecorn-select dark"
                    button
                    detailIcon={chevronDownOutline}
                    onClick={() => setIsEndTimeModalOpen(true)}>
                    {endTime
                      ? moment(endTime, "HH:mm").format("hh:mm A")
                      : "Select time"}
                  </IonItem>
                </div>
              </div>
            </div>
          </div>
          <MediaQuery minWidth={769}>
            <div className={styles.buttons}>
              {step > 1 && (
                <IonButton
                  className="aecorn-button clear border"
                  onClick={() => setStep(step - 1)}>
                  Back
                </IonButton>
              )}
              <IonButton
                className="aecorn-button"
                onClick={onContinue}
                disabled={
                  step === 3
                    ? !title || !leadAgent || !showingAgents.length || !client
                    : false
                }>
                {step === 3 ? "Create tour" : "Continue"}
              </IonButton>
            </div>
          </MediaQuery>
        </div>
      </IonContent>
      <MediaQuery maxWidth={767}>
        <IonFooter className={styles.footer}>
          <div className={styles.buttons}>
            {step > 1 && (
              <IonButton
                className="aecorn-button clear border"
                onClick={() => setStep(step - 1)}>
                Back
              </IonButton>
            )}
            <IonButton
              className="aecorn-button"
              onClick={onContinue}
              disabled={
                step === 3
                  ? !title || !leadAgent || !showingAgents.length || !client
                  : false
              }>
              {step === 3 ? "Create tour" : "Continue"}
            </IonButton>
          </div>
        </IonFooter>
      </MediaQuery>
    </IonPage>
  );
};

export default NewTour;
