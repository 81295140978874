import { IonBackButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import styles from './contact-us.module.scss';
import MediaQuery from 'react-responsive';
import Header from 'components/web/Header/header.component';

const ContactUs = () => {
    return <IonPage>
        <IonHeader mode="ios" className={styles.header}>
            <MediaQuery minWidth={768}>
                <Header />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <IonToolbar>
                    <div className={styles.title}>Contact Us</div>
                    <IonButtons slot="end">
                        <IonBackButton
                            className="aecorn-back-button"
                            text="Back"
                            defaultHref={"/"}
                            type="button"
                            icon=""
                        />
                    </IonButtons>
                </IonToolbar>
            </MediaQuery>
        </IonHeader>
        <IonContent className={styles.content}>
            <IonCard>
                <IonCardContent>
                    <div className={styles.contactUs}>
                        <h1>Contact Us</h1>
                        <p>For any queries, please contact us at:</p>
                        <p>Email: <a href="mailto:info@aecorn.ca">
                            info@aecorn.ca
                        </a>
                        </p>
                        <p>Phone: <a href="tel:+14168038444">+1 416 803 8444</a></p>
                        <p>Address: 120 East Beaver Creek Rd, Suite 200, Richmond Hill, ON L4B 4V1</p>
                    </div>
                    <div className={styles.contactUs}>
                        <p>For any technical issues, please contact us at:</p>
                        <p>Email: <a href="mailto:support@aecorn.ca">
                            support@aecorn.ca
                        </a>
                        </p>
                    </div>
                </IonCardContent>
            </IonCard>
        </IonContent>
    </IonPage>
}

export default ContactUs;