import { Favourite } from "API";
import styles from "./favorites.module.scss";
import { Listing } from "models/listings/listing.model";
import ListingListItem from "components/shared/Listings/ListingListItem/listing-list-item.component";
import { IonIcon, useIonRouter } from "@ionic/react";
import { useMediaQuery } from "react-responsive";
import ListingCard from "components/shared/Listings/ListingCard/listing-card.component";
import FavoriteIcon from "assets/svg/star-light.svg";

const Favorites = ({
  loading,
  favorites,
}: {
  loading: boolean;
  favorites: { item: Favourite; listing: Listing }[];
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const router = useIonRouter();

  const onClick = (listing: Listing) => {
    router.push(`/listings/${listing.mlsNumber}`);
  };

  return (
    <div className={styles.container}>
      {!loading && favorites.length === 0 && (
        <div className={styles.noFavorites}>
          <IonIcon icon={FavoriteIcon} />
          No favorites yet
        </div>
      )}
      {favorites.map((fav, index) =>
        isMobile ? (
          <ListingListItem
            key={index}
            listing={fav.listing}
            onClick={onClick}
          />
        ) : (
          <ListingCard key={index} listing={fav.listing} />
        )
      )}
    </div>
  );
};

export default Favorites;
