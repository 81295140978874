import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
  IonThumbnail,
} from "@ionic/react";
import styles from "./listings-sheet.module.scss";
import { Listing } from "models/listings/listing.model";
import ListingListItem from "components/shared/Listings/ListingListItem/listing-list-item.component";
import { MutableRefObject } from "react";

const ListingsSheet = ({
  modalRef,
  listings,
  loading,
  count,
  navigate,
}: {
  modalRef: MutableRefObject<HTMLIonModalElement | null>;
  listings: Listing[];
  loading: boolean;
  count: number | undefined;
  navigate: (mlsNumber: string) => void;
}) => {
  const handleClick = (listing: Listing) => {
    navigate(listing.mlsNumber);
  };

  const generateSkeleton = () => {
    return Array.from({ length: count || 1 }).map((_, i) => (
      <IonItem key={i} lines="none">
        <IonThumbnail style={{ height: "120px", width: "100px" }} slot="start">
          <IonSkeletonText animated />
        </IonThumbnail>
        <IonLabel>
          <h3>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}></IonSkeletonText>
          </h3>
          <p>
            <IonSkeletonText
              animated={true}
              style={{ width: "80%" }}></IonSkeletonText>
          </p>
          <p>
            <IonSkeletonText
              animated={true}
              style={{ width: "60%" }}></IonSkeletonText>
          </p>
          <p>
            <IonSkeletonText
              animated={true}
              style={{ width: "80%" }}></IonSkeletonText>
          </p>
          <p>
            <IonSkeletonText
              animated={true}
              style={{ width: "50%" }}></IonSkeletonText>
          </p>
        </IonLabel>
      </IonItem>
    ));
  };

  return count ? (
    count > 1 ? (
      <IonContent className={styles.multiContainer} scrollX={false}>
        <IonList>
          {loading
            ? generateSkeleton()
            : listings.map((listing, index) => (
                <ListingListItem key={index} listing={listing} onClick={handleClick} />
              ))}
        </IonList>
      </IonContent>
    ) : (
      <div className={styles.singleContainer}>
        {loading ? (
          generateSkeleton()
        ) : (
          <ListingListItem listing={listings[0]} onClick={handleClick} />
        )}
      </div>
    )
  ) : (
    <div className={styles.singleContainer}></div>
  );
};

export default ListingsSheet;
