import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import styles from "./activity.module.scss";
import { useEffect, useState } from "react";
import {
  ActivityType,
  Activity as APIActivity,
  Tour,
  TourItemStatus,
} from "API";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import { ActivityService } from "services/activityService";
import { useHistory } from "react-router";
import MediaQuery from "react-responsive";
import Header from "components/web/Header/header.component";
import { Listing } from "models/listings/listing.model";
import { ListingService } from "services/listingService";
import { TourService } from "services/tourService";
import { ListingsHelper } from "utils/ListingsHelper";
import {
  groupSlotsByDay,
  renderSlotText,
} from "components/shared/Tours/TourAvailability/tour-availability.component";
import { callOutline, mailOutline } from "ionicons/icons";
import TourIcon from "assets/svg/tours.svg";
import LocationIcon from "assets/svg/filledLocation.svg";
import moment from "moment";
import AddListingToExistingTourModal from "components/shared/Modals/AddListingToExistingTourModal/add-listing-to-existing-tour-modal.component";
import { useDispatch } from "react-redux";
import { updateTourRequest } from "redux/tour/tour.actions";
import { UserService } from "services/userService";

const Activity = ({ activityId }: { activityId: string }) => {
  const history = useHistory();
  const router = useIonRouter();
  const dispatch = useDispatch();
  const [presentToast] = useIonToast();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activity, setActivity] = useState<APIActivity>();
  const [activityContent, setActivityContent] = useState<any>();
  const [listing, setListing] = useState<Listing>();
  const [toursListings, setToursListings] = useState<Listing[]>([]);
  const [userTours, setUserTours] = useState<Tour[]>([]);
  const [selectedExistingTour, setSelectedExistingTour] = useState<Tour>();

  const onDownloadMLSReport = async () => {
    const url = activityContent.url;
    if (url) {
      window.open(url, "_blank");
    }
  };

  const onAddToNewTour = () => {
    dispatch(
      updateTourRequest({
        listing,
        userId: activity?.sender,
        email: activityContent.email,
        phone: activityContent.phone,
        availability: activityContent.availability,
        guests: activityContent.guests,
      })
    );

    router.push("/tours/new");
  };

  const onAddToExistingTour = async () => {
    if (selectedExistingTour && listing) {
      try {
        const latestOrder =
          selectedExistingTour.tourItems?.items.reduce(
            (acc, item) => ((item?.order || 0) > acc ? item?.order || 0 : acc),
            0
          ) || 0;

        setSubmitLoading(true);
        await TourService.addShowingToTour(selectedExistingTour, {
          tourId: selectedExistingTour.id,
          mlsNumber: listing.mlsNumber,
          order: latestOrder + 1,
          status: TourItemStatus.requested,
        });

        if (activityContent.guests && activityContent.guests.length > 0) {
          const guestsToAdd = [];
          for (const email of activityContent.guests) {
            const tourUsers = selectedExistingTour.users?.items || [];
            if (!tourUsers.find((user) => user?.user?.email === email)) {
              guestsToAdd.push(email);
            }
          }
          await TourService.addGuestsToTour(selectedExistingTour, guestsToAdd);
        }

        presentToast({
          message: "Listing added to tour",
          duration: 3000,
          cssClass: "aecorn-success-toast",
        });
        setSelectedExistingTour(undefined);
        if (router.canGoBack()) {
          router.goBack();
        } else {
          history.replace("/activities");
        }
      } catch (error) {
        console.error(error);
        presentToast({
          message: "Failed to add listing to tour",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchActivity = async () => {
      let requesterUserId: string | undefined;
      try {
        setLoading(true);
        const loadedActivity = await ActivityService.getActivityById(
          activityId
        );

        const activityContent = JSON.parse(loadedActivity.content!);

        setActivityContent(activityContent);
        if (activityContent.mlsNumber) {
          const loadedListing = await ListingService.getListingByMlsNumber(
            activityContent.mlsNumber
          );
          setListing(loadedListing);
        }
        if (loadedActivity.sender) {
          requesterUserId = loadedActivity.sender;
        } else if (activityContent.email) {
          const email = activityContent.email;
          const user = await UserService.getUserByEmail(email);
          if (user) {
            requesterUserId = user.id;
          }
        }

        if (requesterUserId) {
          const tours = await TourService.getClientAvailableTours(
            requesterUserId
          );
          setUserTours(tours);

          const toursListingsPromises: Promise<Listing>[] = [];

          tours.forEach((tour) => {
            tour.tourItems?.items.forEach((item) => {
              if (item) {
                toursListingsPromises.push(
                  ListingService.getListingByMlsNumber(item.mlsNumber)
                );
              }
            });
          });

          const toursListings = await Promise.all(toursListingsPromises);
          setToursListings(toursListings);
        }
        setActivity(loadedActivity);
        await ActivityService.markActivityAsRead(activityId);
      } catch (err) {
        history.replace("/activities");
      } finally {
        setLoading(false);
      }
    };
    fetchActivity();
  }, [activityId]);

  return (
    <IonPage>
      <IonLoading message="Loading..." isOpen={submitLoading} />
      <IonHeader mode="ios" className={styles.header}>
        <MediaQuery minWidth={768}>
          <Header />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <div className={styles.topSection}>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton
                  className="aecorn-back-button"
                  text="Back to activity center"
                  defaultHref="/tabs/activities"
                />
              </IonButtons>
            </IonToolbar>
            <div className={styles.title}>
              {listing ? `#${listing.mlsNumber}` : "Activity"}
            </div>
          </div>
        </MediaQuery>
      </IonHeader>
      <IonContent className={styles.content}>
        <MediaQuery minWidth={768}>
          <div className={styles.topbar}>
            <IonBackButton
              className="aecorn-back-button"
              text="Back to activity center"
              defaultHref="/activities"
            />
            <div className={styles.title}>
              {listing ? `#${listing.mlsNumber}` : "Activity"}
            </div>
          </div>
        </MediaQuery>
        {loading ? (
          <LoadingScreen />
        ) : (
          <div className={styles.container}>
            {listing && (
              <div className={styles.listingSummary}>
                <div className={styles.address}>
                  <span>
                    {ListingsHelper.getListingAddress(listing?.address)}
                  </span>
                  <span>
                    {listing?.address.zip}, {listing?.address.city}
                  </span>
                </div>
                <IonButton
                  className="aecorn-button clear-dark"
                  routerLink={`/listings/${listing?.mlsNumber}`}>
                  View Listing
                </IonButton>
              </div>
            )}
            {(activity?.type === ActivityType.tourRequest ||
              activity?.type === ActivityType.listingInformation || activity?.type === ActivityType.offer) && (
              <div className={styles.requesterInfo}>
                <div>
                  <span>{activityContent.name}</span>
                  <span>{activityContent.email}</span>
                  <span>{activityContent.phone}</span>
                </div>
                {activity?.type === ActivityType.tourRequest && (
                  <div>
                    {activityContent.availability.length
                      ? Object.entries(
                          groupSlotsByDay(activityContent.availability)
                        ).map(([day, slots]) => (
                          <div key={day} className={styles.slot}>
                            {renderSlotText(day, slots, false)}
                          </div>
                        ))
                      : "Any time"}
                  </div>
                )}
                {activity?.type === ActivityType.offer && (
                  <div>
                    <span>Offer: ${activityContent.offer}</span>
                    <span>Preferred date: {activityContent.date ? moment(activityContent.date).format("DD MMM") : "N/A"}</span>
                  </div>
                )}
              </div>
            )}
            {(activity?.type === ActivityType.tourShare ||
              activity?.type === ActivityType.tourCancel) && (
              <div className={styles.tourText}>
                {`Tour "${activityContent.tourName}" has been ${
                  activity?.type === ActivityType.tourShare
                    ? "shared with you."
                    : "cancelled."
                }`}
              </div>
            )}
            <div className={styles.buttons}>
              {(activity?.type === ActivityType.tourShare ||
                activity?.type === ActivityType.tourCancel) && (
                <IonButton
                  className={`aecorn-button ${
                    activity?.type === ActivityType.tourCancel && "clear border"
                  }`}
                  routerLink={`/tours/details/${activityContent.tourId}`}>
                  View Tour
                </IonButton>
              )}
              {activity?.type === ActivityType.mlsReport && (
                <IonButton
                  className="aecorn-button"
                  onClick={onDownloadMLSReport}>
                  Download MLS Report
                </IonButton>
              )}
              {activity?.type === ActivityType.tourRequest && (
                <IonButton className="aecorn-button" onClick={onAddToNewTour}>
                  Add to a new tour
                </IonButton>
              )}
              {(activity?.type === ActivityType.listingInformation || activity?.type === ActivityType.offer) && (
                <>
                  <IonButton
                    className="aecorn-button"
                    href={`mailto:${activityContent.email}`}>
                    <IonIcon slot="start" icon={mailOutline} />
                    Email
                  </IonButton>
                  {activityContent.phone && (
                    <IonButton
                      className="aecorn-button"
                      href={`tel:${activityContent.phone}`}>
                      <IonIcon slot="start" icon={callOutline} />
                      Call
                    </IonButton>
                  )}
                </>
              )}
            </div>
            {selectedExistingTour && (
              <AddListingToExistingTourModal
                isOpen={selectedExistingTour !== undefined}
                onDismiss={() => setSelectedExistingTour(undefined)}
                tour={selectedExistingTour!}
                listingToAdd={listing!}
                listings={toursListings.filter((l) =>
                  selectedExistingTour?.tourItems?.items.find(
                    (i) => i?.mlsNumber === l.mlsNumber
                  )
                )}
                onSave={onAddToExistingTour}
              />
            )}
            {activity?.type === ActivityType.tourRequest &&
              userTours.length > 0 && (
                <div className={styles.tours}>
                  <div className={styles.note}>
                    Or, add to one of the client's available tours
                  </div>
                  {userTours.map((tour, index) => (
                    <div className={styles.tour} key={index}>
                      <div className={styles.tourHeader}>
                        <span>
                          <IonIcon icon={TourIcon} />
                          <span>{tour.title}</span>
                        </span>
                        <span className={styles.date}>
                          {tour.date && moment(tour.date).format("ddd, DD MMM")}
                          {tour.startTime &&
                            tour.endTime &&
                            `, ${moment(tour.startTime, "HH:mm").format(
                              "HH:mm A"
                            )}-${moment(tour.endTime, "HH:mm").format(
                              "HH:mm A"
                            )}`}
                        </span>
                      </div>
                      {tour.tourItems?.items &&
                        tour.tourItems.items.length > 0 && (
                          <div className={styles.stops}>
                            <div className={styles.title}>Stops:</div>
                            {tour.tourItems?.items
                              .sort((a, b) => (a?.order || 0) - (b?.order || 0))
                              .map((stop, index) => {
                                const listing = toursListings.find(
                                  (l) => l.mlsNumber === stop?.mlsNumber
                                );
                                if (!listing || !stop) return null;
                                return (
                                  <div key={index} className={styles.stop}>
                                    <div className={styles.address}>
                                      <span>
                                        <span className={styles.order}>
                                          <IonIcon icon={LocationIcon} />
                                          <span>{stop.order}</span>
                                        </span>
                                        <span>
                                          {ListingsHelper.getListingAddress(
                                            listing.address
                                          )}
                                        </span>
                                      </span>
                                      <span>
                                        {listing.address.zip},{" "}
                                        {listing.address.city}
                                      </span>
                                    </div>
                                    <div className={styles.time}>
                                      {stop.startTime &&
                                        stop.endTime &&
                                        `${moment(
                                          stop.startTime,
                                          "HH:mm"
                                        ).format("HH:mm A")}-${moment(
                                          stop.endTime,
                                          "HH:mm"
                                        ).format("HH:mm A")}`}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      <IonButton
                        className="aecorn-button clear border"
                        disabled={
                          tour.tourItems?.items.find(
                            (item) => item?.mlsNumber === listing?.mlsNumber
                          ) !== undefined
                        }
                        onClick={() => setSelectedExistingTour(tour)}>
                        {tour.tourItems?.items.find(
                          (item) => item?.mlsNumber === listing?.mlsNumber
                        )
                          ? "Already added"
                          : "Add to this tour"}
                      </IonButton>
                    </div>
                  ))}
                </div>
              )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Activity;
