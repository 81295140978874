import { IonButton, IonIcon } from "@ionic/react";
import styles from "../modals.module.scss";
import Modal from "components/ui/modal/modal.component";
import MediaQuery, { useMediaQuery } from "react-responsive";
import TourAvailability from "components/shared/Tours/TourAvailability/tour-availability.component";
import { useEffect, useRef } from "react";
import { closeOutline } from "ionicons/icons";

const TourAvailabilityModal = ({
  isOpen,
  onDismiss,
  availability,
  onSave,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  availability: string[];
  onSave: (availability: string[]) => void;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const tempAvailability = useRef<string[]>(availability);

  useEffect(() => {
    tempAvailability.current = availability;
  }, [availability]);

  return (
    <Modal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      breakpoints={isMobile ? [0, 1] : undefined}
      initialBreakpoint={isMobile ? 1 : undefined}>
      <div className={styles.sheetContent}>
        <div className={styles.modalHeader}>Available timeframes
          <MediaQuery minWidth={768}>
            <IonIcon icon={closeOutline} onClick={onDismiss} />
          </MediaQuery>
        </div>
        <TourAvailability
          availability={tempAvailability.current}
          onAvailabilityUpdate={(update) => {
            tempAvailability.current = update;
          }}
        />
        <IonButton
          className="aecorn-button"
          onClick={() => onSave(tempAvailability.current)}>
          Save changes
        </IonButton>
      </div>
    </Modal>
  );
};

export default TourAvailabilityModal;
