import styles from "./school-details.module.scss";
import School from "../../../app/pages/SchoolDetails/school-details.component";
import { IonCard, IonCardContent, IonContent, IonPage } from "@ionic/react";

const SchoolDetails = (props: any) => {
  return (
    <IonPage>
      <IonContent>
        <div className={styles.schoolDetails}>
          <IonCard>
            <IonCardContent>
              <School isApp={false} {...props} />
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default SchoolDetails;
