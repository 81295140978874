import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  CreateTourItemMediaMutation,
  Note,
  OnCreateTourItemMediaSubscription,
  OnDeleteTourItemMediaSubscription,
  Photo,
  Recording,
  Tour,
  TourItem,
  TourItemMedia,
  TourItemMediaType,
  UpdateNoteMutation,
} from "../API";
import { Photo as CapacitorPhoto } from "@capacitor/camera";
import {
  createNote,
  updateNote as updateNoteMutation,
  deleteNote as deleteNoteMutation,
  deletePhoto as deletePhotoMutation,
  deleteRecording as deleteRecordingMutation,
  createPhoto,
  createRecording,
  createTourItemMedia,
  deleteTourItemMedia,
} from "../graphql/mutations";
import { StorageService } from "./storageService";
import { GeneralConstants } from "../utils/constants";
import {
  onCreateTourItemMedia,
  onDeleteTourItemMedia,
} from "graphql/subscriptions";
import { TourItemService } from "./tourItemService";

const buildFileObject = (
  fileName: string,
  key: string,
  additionalProperties?: { [key: string]: string }
) => {
  return {
    ...additionalProperties,
    path: key,
    file: {
      bucket: GeneralConstants.S3_BUCKET_NAME,
      region: GeneralConstants.S3_BUCKET_REGION,
      key: "public/" + fileName,
    },
  };
};

const addNote = async (tourItemId: string, content: string) => {
  const response = await API.graphql<GraphQLQuery<CreateTourItemMediaMutation>>(
    {
      query: createTourItemMedia,
      variables: {
        input: { tourItemId, content, type: TourItemMediaType.text },
      },
    }
  );

  return response.data?.createTourItemMedia;
};

const addPhoto = async (
  tourItemId: string,
  file: CapacitorPhoto,
  caption?: string
) => {
  const { key, fileName } = await StorageService.addPhoto(file);
  const image = buildFileObject(fileName, key, { tourItemId });

  const response = await API.graphql<GraphQLQuery<CreateTourItemMediaMutation>>(
    {
      query: createTourItemMedia,
      variables: {
        input: { ...image, content: caption, type: TourItemMediaType.photo },
      },
    }
  );
  return response.data?.createTourItemMedia as TourItemMedia;
};

const addRecording = async (tourItemId: string, data: string) => {
  const { key, fileName } = await StorageService.addRecording(data);
  const recording = buildFileObject(fileName, key, { tourItemId });

  const response = await API.graphql<GraphQLQuery<CreateTourItemMediaMutation>>(
    {
      query: createTourItemMedia,
      variables: {
        input: {
          ...recording,
          type: TourItemMediaType.recording,
        },
      },
    }
  );

  return response.data?.createTourItemMedia as TourItemMedia;
};

const updateNote = async (noteId: string, content: string) => {
  const response = await API.graphql<GraphQLQuery<UpdateNoteMutation>>({
    query: updateNoteMutation,
    variables: { input: { id: noteId, content } },
  });
  return response.data?.updateNote;
};

const deleteAllMedia = async (tourItem: TourItem) => {
  const promises: Promise<void>[] = [];

  tourItem.media?.items.forEach((media) => {
    if (media) {
      promises.push(deleteMedia(media));
    }
  });

  await Promise.all(promises);
};

const deleteMedia = async (media: TourItemMedia) => {
  if (media.path) {
    await StorageService.deleteItem(media.path);
  }
  await API.graphql({
    query: deleteTourItemMedia,
    variables: { input: { id: media.id } },
  });
};

const deleteMediaByTourItemIdAndMediaId = async (tourItemId: string, mediaId: string) => {
  const tourItem = await TourItemService.getTourItemById(tourItemId);
  const media = tourItem.media?.items.find(m => m?.id === mediaId);

  if (media) {
    await deleteMedia(media);
  }
}

const deleteNote = async (note: Note) => {
  await API.graphql({
    query: deleteNoteMutation,
    variables: { input: { id: note.id } },
  });
};

const deletePhoto = async (photo: Photo) => {
  if (photo.path) {
    await StorageService.deleteItem(photo.path);
  }
  await API.graphql({
    query: deletePhotoMutation,
    variables: { input: { id: photo.id } },
  });
};

const deleteRecording = async (recording: Recording) => {
  if (recording.path) {
    await StorageService.deleteItem(recording.path);
  }
  API.graphql({
    query: deleteRecordingMutation,
    variables: { input: { id: recording.id } },
  });
};

const getMediaOwnerDetailsByTour = async (tour: Tour, media: TourItemMedia) => {
  const ownerUserId = media.userId;
  const owner = tour.users?.items?.find((user) => user?.userId === ownerUserId);
  return owner;
};

// #region Subscriptions

const onCreateTourItemMediaByTourItemId = (
  tourItemId: string,
  callback: (data: any) => void
) => {
  const result = API.graphql<
    GraphQLSubscription<OnCreateTourItemMediaSubscription>
  >({
    query: onCreateTourItemMedia,
    variables: {
      filter: {
        tourItemId: { eq: tourItemId },
      },
    },
  });

  const subscription = result.subscribe(async (data) => {
    const media = data.value.data?.onCreateTourItemMedia;

    if (media) {
      callback(media);
    }
  });

  return subscription;
};

const onDeleteTourItemMediaByTourItemId = (
  tourItemId: string,
  callback: (data: any) => void
) => {
  const result = API.graphql<
    GraphQLSubscription<OnDeleteTourItemMediaSubscription>
  >({
    query: onDeleteTourItemMedia,
    variables: {
      filter: {
        tourItemId: { eq: tourItemId },
      },
    },
  });

  const subscription = result.subscribe(async (data) => {
    const media = data.value.data?.onDeleteTourItemMedia;

    if (media) {
      callback(media);
    }
  });

  return subscription;
};

// #endregion

export const TourItemMediaService = {
  addNote,
  updateNote,
  addPhoto,
  addRecording,
  deleteMedia,
  deleteMediaByTourItemIdAndMediaId,
  deleteAllMedia,
  deleteNote,
  deletePhoto,
  deleteRecording,
  getMediaOwnerDetailsByTour,
  onCreateTourItemMediaByTourItemId,
  onDeleteTourItemMediaByTourItemId,
};
