import { IonSpinner } from "@ionic/react";
import { Listing } from "models/listings/listing.model";
import styles from "./listing-list-item.module.scss";
import { useState } from "react";
import ListingStatusTag from "../ListingStatusTag/listing-status-tag.component";
import ListingFavouriteIcons from "../ListingFavouriteIcons/listing-favourite-icons.component";
import { ListingsHelper } from "utils/ListingsHelper";
import { AuthenticationService } from "services/authenticationService";
import RestrictedAccessCard from "components/shared/RestrictedAccessCard/restricted-access-card.component";
import DEFAULT_IMAGE from "assets/img/Image not found.png";

const ListingListItem = ({
  listing,
  onClick,
}: {
  listing: Listing;
  onClick: (listing: Listing) => void;
}) => {
  const isAuthenticated = AuthenticationService.isAuthenticated();
  const displayData = ListingsHelper.displayData(listing);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const image = ListingsHelper.getListingThumbnail(listing);
  const listingPrice = ListingsHelper.getListingFormattedPrice(listing);
  const openHouses = ListingsHelper.getListingOpenHouses(listing);
  const days = ListingsHelper.getListingFormattedDate(listing);

  const renderRooms = () => {
    const bedrooms =
      +listing.details.numBedrooms +
      (listing.details.numBedroomsPlus ? +listing.details.numBedroomsPlus : 0);
    const bathrooms =
      +listing.details.numBathrooms +
      (listing.details.numBathroomsPlus
        ? +listing.details.numBathroomsPlus
        : 0);
    const parkings =
      +listing.details.numParkingSpaces +
      (listing.details.numGarageSpaces ? +listing.details.numGarageSpaces : 0);

    return (
      <>
        <span>{bedrooms} Bed</span>
        <span>{bathrooms} Bath</span>
        <span>{parkings} Parking</span>
      </>
    );
  };

  const handleClick = () => {
    if (!displayData && !isAuthenticated) {
      return;
    }
    onClick(listing);
  };

  return (
    <div onClick={handleClick} className={styles.container}>
      {!displayData && !isAuthenticated && (
        <div className={styles.restrictedAccess}>
          <RestrictedAccessCard size="small" />
        </div>
      )}
      {openHouses.length > 0 && (
        <div className={styles.openHouses}>
          <span className={styles.title}>OPEN HOUSE: </span>
          <span>
            {openHouses.map((openHouse, index) => (
              <span key={index}>{`${openHouse.date} ${openHouse.time} `}</span>
            ))}
          </span>
        </div>
      )}
      <div className={styles.card}>
      <div
        className={`${styles.image} ${!displayData && styles.scatteredData}`}>
        <img
          src={image}
          defaultValue={DEFAULT_IMAGE}
          onLoad={() => setIsImageLoaded(true)}
          onError={(e) => {
            e.currentTarget.src = DEFAULT_IMAGE;
            setIsImageLoaded(true);
          }}
        />
        {!isImageLoaded && <IonSpinner name="crescent" />}
      </div>
      <div
        className={`${styles.details} ${!displayData && styles.scatteredData}`}>
        <div className={styles.icons}>
          {isAuthenticated && <ListingFavouriteIcons listing={listing} />}
        </div>
        <div className={styles.price}>{listingPrice}</div>
        <div className={styles.address}>
          {ListingsHelper.getListingAddress(listing.address)}
        </div>
        <div className={styles.type}>
          {ListingsHelper.getListingPropertyType(listing.details.propertyType)}
        </div>
        <div className={styles.rooms}>{renderRooms()}</div>
        <div className={styles.tag}>
          <ListingStatusTag listing={listing} />
          {days > 0 ? <span>{days} day</span> : <span>Today</span>}
        </div>
        <div className={styles.mlsNumber}>
          MLS# {displayData ? listing.mlsNumber : "123456789"}
        </div>
      </div>
      </div>
    </div>
  );
};

export default ListingListItem;
