import { SchoolLevel } from "enums/Schools/SchoolLevel.enum";
import { SchoolProgram } from "enums/Schools/SchoolProgram.enum";
import { SchoolType } from "enums/Schools/SchoolType.enum";
import { SchoolsActionTypes } from "redux/schools/schools.types";
import { store } from "redux/store";

const reset = () => {
  store.dispatch({
    type: SchoolsActionTypes.RESET_SCHOOLS_FILTERS,
  });
};

const toggle = () => {
  store.dispatch({
    type: SchoolsActionTypes.TOGGLE_SCHOOLS,
  });
};

const updateLevelFilter = (level: SchoolLevel | null) => {
  if (!level) {
    store.dispatch({
      type: SchoolsActionTypes.UPDATE_SCHOOLS_LEVELS,
      payload: [],
    });
    return;
  }

  const levels = store.getState().schools.filters.levels;

  const updatedLevels = levels.includes(level)
    ? levels.filter((l: SchoolLevel) => l !== level)
    : [...levels, level];

  store.dispatch({
    type: SchoolsActionTypes.UPDATE_SCHOOLS_LEVELS,
    payload: updatedLevels,
  });
};

const updateTypeFilter = (type: SchoolType | null) => {
  if (!type) {
    store.dispatch({
      type: SchoolsActionTypes.UPDATE_SCHOOLS_TYPES,
      payload: [],
    });
    return;
  }

  const types = store.getState().schools.filters.types;

  const updatedTypes = types.includes(type)
    ? types.filter((t: SchoolType) => t !== type)
    : [...types, type];

  store.dispatch({
    type: SchoolsActionTypes.UPDATE_SCHOOLS_TYPES,
    payload: updatedTypes,
  });
};

const updateProgramFilter = (program: SchoolProgram | null) => {
  if (!program) {
    store.dispatch({
      type: SchoolsActionTypes.UPDATE_SCHOOLS_PROGRAMS,
      payload: [],
    });
    return;
  }

  const programs = store.getState().schools.filters.programs;

  const updatedPrograms = programs.includes(program)
    ? programs.filter((p: SchoolProgram) => p !== program)
    : [...programs, program];

  store.dispatch({
    type: SchoolsActionTypes.UPDATE_SCHOOLS_PROGRAMS,
    payload: updatedPrograms,
  });
}

const updateRateFilter = (rate: number) => {
  store.dispatch({
    type: SchoolsActionTypes.UPDATE_SCHOOLS_RATE,
    payload: rate,
  });
};

const updateShowUnrated = (showUnrated: boolean) => {
  store.dispatch({
    type: SchoolsActionTypes.UPDATE_SCHOOLS_SHOW_UNRATED,
    payload: showUnrated,
  });
};

export const SchoolsFiltersService = {
  reset,
  toggle,
  updateLevelFilter,
  updateTypeFilter,
  updateProgramFilter,
  updateRateFilter,
  updateShowUnrated,
};
