import { uniqBy } from "lodash";
import { SchoolsActionTypes } from "./schools.types";
import { SchoolLevel } from "enums/Schools/SchoolLevel.enum";
import { SchoolType } from "enums/Schools/SchoolType.enum";
import { SchoolProgram } from "enums/Schools/SchoolProgram.enum";

const schoolColor = {
  strokeColor: "#dff7f0",
  fillColor: "#1f7a60",
  hoverStrokeColor: "#c24100",
  hoverFillColor: "#ffeee5",
  hoverPinStrokeColor: "white",
  hoverPinFillColor: "#c24100",
};

const INITIAL_STATE = {
  active: false,
  selected: null,
  filterListings: { active: false, schools: [] },
  filters: {
    levels: [],
    types: [],
    programs: [],
    elementary: false,
    middle: false,
    high: false,
    public: false,
    catholic: false,
    private: false,
    frenchImmersion: false,
    ib: false,
    ap: false,
    gifted: false,
    sports: false,
    arts: false,
    rate: null,
    showUnrated: false,
    showListingsWithin: false,
  },
};

const schoolsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SchoolsActionTypes.TOGGLE_SCHOOLS:
      return { ...INITIAL_STATE, active: !state.active };
    case SchoolsActionTypes.UPDATE_SELECTED_SCHOOL:
      return { ...state, selected: action.payload };
    case SchoolsActionTypes.TOGGLE_FILTER_LISTINGS:
      if (action.payload) {
        return {
          ...state,
          filterListings: {
            active: true,
            schools: [
              {
                ...action.payload,
                ...schoolColor,
              },
            ],
          },
        };
      }
      return {
        ...state,
        filterListings: { active: false, schools: [] },
      };
    case SchoolsActionTypes.ADD_SCHOOL_BOUNDARY: {
      const allSchools = [
        ...state.filterListings.schools,
        {
          ...action.payload,
          ...schoolColor,
        },
      ];
      const uniqueSchools = uniqBy(allSchools, "id");

      return {
        ...state,
        filterListings: {
          active: true,
          schools: uniqueSchools,
        },
      };
    }

    case SchoolsActionTypes.REMOVE_SCHOOL_BOUNDARY:
      return {
        ...state,
        filterListings: {
          active: true,
          schools: state.filterListings.schools.filter(
            (s) => s.id !== action.payload
          ),
        },
      };
    case SchoolsActionTypes.UPDATE_SCHOOLS_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case SchoolsActionTypes.UPDATE_SCHOOLS_LEVELS:
      return {
        ...state,
        filters: {
          ...state.filters,
          levels: action.payload,
          elementary: action.payload.includes(SchoolLevel.Elementary),
          middle: action.payload.includes(SchoolLevel.Middle),
          high: action.payload.includes(SchoolLevel.High),
        },
      };
    case SchoolsActionTypes.UPDATE_SCHOOLS_TYPES:
      return {
        ...state,
        filters: {
          ...state.filters,
          types: action.payload,
          public: action.payload.includes(SchoolType.Public),
          catholic: action.payload.includes(SchoolType.Catholic),
          private: action.payload.includes(SchoolType.Private),
        },
      };
    case SchoolsActionTypes.UPDATE_SCHOOLS_PROGRAMS:
      return {
        ...state,
        filters: {
          ...state.filters,
          programs: action.payload,
          frenchImmersion: action.payload.includes(SchoolProgram.French_Immersion),
          ib: action.payload.includes(SchoolProgram.International_Baccalaureate),
          ap: action.payload.includes(SchoolProgram.Advanced_Placement),
          gifted: action.payload.includes(SchoolProgram.Gifted),
          sports: action.payload.includes(SchoolProgram.Sports),
          arts: action.payload.includes(SchoolProgram.Arts),
        },
      };
    case SchoolsActionTypes.UPDATE_SCHOOLS_RATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          rate: action.payload,
        },
      };
    case SchoolsActionTypes.UPDATE_SCHOOLS_SHOW_UNRATED:
      return {
        ...state,
        filters: {
          ...state.filters,
          showUnrated: action.payload,
        },
      };
    case SchoolsActionTypes.RESET_SCHOOLS_FILTERS:
      return {
        ...state,
        filters: {
          levels: [],
          types: [],
          programs: [],
          elementary: false,
          middle: false,
          high: false,
          public: false,
          catholic: false,
          private: false,
          frenchImmersion: false,
          ib: false,
          ap: false,
          gifted: false,
          sports: false,
          arts: false,
          rate: null,
          showUnrated: false,
          showListingsWithin: false,
        },
      };
    default:
      return state;
  }
};

export default schoolsReducer;
