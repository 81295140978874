import { Listing } from "models/listings/listing.model";
import styles from "./listing-gallery.module.scss";
import { ListingsHelper } from "utils/ListingsHelper";
import { useEffect, useRef, useState } from "react";
import { IonIcon, IonModal, IonSpinner, useIonRouter } from "@ionic/react";
import {
  chevronBack,
  chevronForward,
  closeCircle,
  scanOutline,
  shareSocialOutline,
} from "ionicons/icons";
import StarIcon from "assets/svg/star-light.svg";
import BellIcon from "assets/svg/notifications.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import { Favourite } from "API";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import DEFAULT_IMAGE from "assets/img/Image not found.png";

const ListingGallery = ({
  listing,
  favourite,
  onUpdateFavourite,
  onUpdateNotification,
  loading,
  onShare,
}: {
  listing: Listing;
  favourite: Favourite | null;
  onUpdateFavourite: (e: any) => Promise<void>;
  onUpdateNotification: (e: any) => Promise<void>;
  loading: boolean;
  onShare: () => Promise<void>;
}) => {
  const router = useIonRouter();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const selectedImageRef = useRef<HTMLDivElement>(null);
  const thumbnailsRef = useRef<HTMLDivElement>(null);
  const [fullScreenEnabled, setFullScreenEnabled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const previousIndexRef = useRef(0);
  const [loadedImages, setLoadedImages] = useState(
    Array(listing.images.length).fill(false)
  );

  useEffect(() => {
    if (!loadedImages[currentIndex]) {
      const newLoadedImages = [...loadedImages];
      newLoadedImages[currentIndex] = true;
      setLoadedImages(newLoadedImages);
    }

    if (thumbnailsRef.current && currentIndex !== previousIndexRef.current) {
      const activeThumbnail = thumbnailsRef.current.children[
        currentIndex
      ] as HTMLElement;
      activeThumbnail.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
      previousIndexRef.current = currentIndex;
    }
  }, [currentIndex, loadedImages]);

  const previous = () => {
    if (currentIndex === 0) {
      setCurrentIndex(listing.images.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const next = () => {
    if (currentIndex === listing.images.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleNavigateBack = () => {
    if (router.canGoBack()) {
      router.goBack();
    } else {
      history.replace("/tabs/listings");
    }
  };

  const handleUpdateFavourite = async (e: any) => {
    if (loading) {
      return;
    }
    await onUpdateFavourite(e);
  };

  const handleUpdateNotification = async (e: any) => {
    if (loading) {
      return;
    }
    await onUpdateNotification(e);
  };

  const Buttons = () => (
    <div className={styles.buttons}>
      <div className={styles.button} onClick={onShare}>
        <IonIcon icon={shareSocialOutline} />
      </div>

      <div className={styles.button} onClick={handleUpdateFavourite}>
        {loading ? <IonSpinner color="light" name="circles" /> : <IonIcon
          className={
            favourite ? styles.enabledFavourite : styles.disabledFavourite
          }
          icon={StarIcon}
        />}
      </div>

      {listing.status === ListingStatus.Active && (
        <div className={styles.button} onClick={handleUpdateNotification}>
          {loading ? <IonSpinner color="light" name="circles" /> : <IonIcon
            className={
              favourite
                ? favourite.notification
                  ? styles.enabledNotification
                  : styles.disabledNotification
                : styles.disabled
            }
            icon={BellIcon}
          />}
        </div>
      )}
      <div className={styles.button}>
        <IonIcon
          icon={scanOutline}
          onClick={() => setFullScreenEnabled(true)}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <IonModal
        className={styles.galleryModal}
        isOpen={fullScreenEnabled}
        onDidDismiss={() => setFullScreenEnabled(false)}>
        <div className={styles.fullScreenGallery}>
          <IonIcon
            icon={closeCircle}
            onClick={() => setFullScreenEnabled(false)}
          />
          <Swiper
            className={styles.modalSwiper}
            navigation={true}
            modules={[Navigation]}
            lazyPreloadPrevNext={1}
            zoom={true}
            spaceBetween={10}
            slidesPerView={1}>
            {listing.images.map((src, index) => (
              <SwiperSlide
                key={index}
                className={styles.modalSwiperSlide}
                draggable>
                <img
                  defaultValue={DEFAULT_IMAGE}
                  onError={ev => {
                    (ev.target as HTMLImageElement).src = DEFAULT_IMAGE;
                  }}
                  src={ListingsHelper.buildImageUrl(src, "large")}
                  alt="Listing"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </IonModal>
      <div className={styles.backButton} onClick={handleNavigateBack}>
        <IonIcon icon={chevronBack} />
      </div>
      {isMobile && <Buttons />}
      {!isMobile && (
        <>
          <div className={styles.slider}>
            <Buttons />
            {listing.images.map((src, index) => (
              <div
                ref={selectedImageRef}
                key={index}
                className={`${styles.slide} ${index === currentIndex && styles.active
                  }`}>
                {loadedImages[index] && (
                  <img
                    src={ListingsHelper.buildImageUrl(src, "large")}
                    alt="Listing"
                    defaultValue={DEFAULT_IMAGE}
                    onError={ev => {
                      (ev.target as HTMLImageElement).src = DEFAULT_IMAGE;
                    }}
                  />
                )}
              </div>
            ))}
            <div className={styles.controls}>
              <span className={styles.navigation} onClick={previous}>
                <IonIcon icon={chevronBack} />
              </span>
              <span className={styles.counter}>
                {currentIndex + 1} / {listing.images.length}
              </span>
              <span className={styles.navigation} onClick={next}>
                <IonIcon icon={chevronForward} />
              </span>
            </div>
          </div>{" "}
          {listing.images.length > 1 && (
            <div ref={thumbnailsRef} className={styles.thumbnails}>
              {listing.images.map((src, index) => (
                <div
                  key={index}
                  className={`${styles.thumbnail} ${index === currentIndex && styles.active
                    }`}
                  onClick={() => setCurrentIndex(index)}>
                  <img
                    src={ListingsHelper.buildImageUrl(src, "small")}
                    alt="Listing"
                    defaultValue={DEFAULT_IMAGE}
                    onError={ev => {
                      (ev.target as HTMLImageElement).src = DEFAULT_IMAGE;
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {isMobile && (
        <Swiper
          className={styles.swiper}
          cssMode
          loop
          lazyPreloadPrevNext={1}
          spaceBetween={0}
          slidesPerView={1}>
          {listing.images.map((src, index) => (
            <SwiperSlide key={index} className={styles.swiperSlide} draggable>
              <img
                src={ListingsHelper.buildImageUrl(src, "large")}
                alt="Listing"
                defaultValue={DEFAULT_IMAGE}
                onError={ev => {
                  (ev.target as HTMLImageElement).src = DEFAULT_IMAGE;
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default ListingGallery;
