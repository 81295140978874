import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

const takePhoto = async () => {
  const image = await Camera.getPhoto({
    quality: 75,
    allowEditing: false,
    source: CameraSource.Prompt,
    resultType: CameraResultType.Base64,
    height: 1280,
    width: 1280,
    correctOrientation: true,
  });

  return image;
};

export const MediaService = {
    takePhoto,
};
