import { Listing } from "models/listings/listing.model";
import contentStyles from "./tour-stops-modal.module.scss";
import styles from "../modals.module.scss";
import { TourItemStatus } from "API";
import MediaQuery, { useMediaQuery } from "react-responsive";
import AgentTourStopCard from "components/shared/Tours/AgentTourStopCard/agent-tour-stop-card.component";
import Modal from "components/ui/modal/modal.component";
import { IonIcon, IonList, useIonRouter } from "@ionic/react";
import { TourStop } from "models/tours/tour-stop.model";
import { closeOutline } from "ionicons/icons";

const TourStopsModal = ({
  tourId,
  isOpen,
  onDismiss,
  stops,
}: {
  tourId: string;
  isOpen: boolean;
  onDismiss: () => void;
  stops: {
    id: string;
    listing: Listing;
    order?: number | null;
    status?: TourItemStatus | null;
  }[];
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const router = useIonRouter();

  const onNavigateToStop = (stop: TourStop) => {
    router.push(`/tours/details/${tourId}/${stop.id}`);
    onDismiss();
  };

  return (
    <Modal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      breakpoints={isMobile ? [0, 1] : undefined}
      initialBreakpoint={isMobile ? 1 : undefined}>
      <div className={styles.sheetContent}>
        <div className={styles.modalHeader}>
          Stops
          <MediaQuery minWidth={768}>
            <IonIcon icon={closeOutline} onClick={onDismiss} />
          </MediaQuery>
        </div>
        <IonList
          className={contentStyles.stops}
          onTouchMove={(e) => e.stopPropagation()}>
          {stops.map((stop) => (
            <AgentTourStopCard
              key={stop.listing.mlsNumber}
              onNavigate={onNavigateToStop}
              stop={stop}
            />
          ))}
        </IonList>
      </div>
    </Modal>
  );
};

export default TourStopsModal;
