import { Listing } from "models/listings/listing.model";
import styles from "../charts.module.scss";
import { ListingStatisticsData } from "models/listings/listing-statistics.model";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { PriceDataFormater } from "utils/functions";

const MedianSoldPriceWithAverageDOMChart = ({
  listing,
  data,
  numOfYears,
}: {
  listing: Listing;
  data: {
    soldPrice: ListingStatisticsData;
    dom: ListingStatisticsData;
  };
  numOfYears: 5 | 10 | 15;
}) => {
  const yearsData = Object.keys(data.soldPrice.mth)
    .sort()
    .slice(-(numOfYears * 12));

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <div className={styles.tooltip}>
          <span>{`${label}`}</span>
          <span>{`Median Sold Price: ${payload[0] && payload[0].value ? PriceDataFormater(payload[0].value.toFixed(2)) : "N/A"}`}</span>
          <span>{`Average DOM: ${payload[1] && payload[1].value ? payload[1].value + ' days' : "N/A"}`}</span>
        </div>
      );
    } else {
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={yearsData.map((month) => ({
            month,
            "Median Sold Price":
              data.soldPrice.mth[month].med,

            "Average DOM":
              data.dom.mth[month].avg,
          }))}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            scale="band"
            angle={-20}
            fontSize={10}
            tickMargin={10}
            color="#878787"
            stroke="#6e6e6e"
          />
          <YAxis yAxisId="left" tickFormatter={PriceDataFormater} />
          <YAxis yAxisId="right" orientation="right" />
          <Legend verticalAlign="bottom" align="center" />
          <Area
            yAxisId="left"
            type="monotone"
            dataKey="Median Sold Price"
            fill="#DAF6EC"
            stroke="#00D5AA"
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar yAxisId="right" dataKey="Average DOM" barSize={20} fill="#000" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MedianSoldPriceWithAverageDOMChart;
