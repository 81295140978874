import {
  CheckboxCustomEvent,
  IonButton,
  IonCheckbox,
  IonChip,
  IonContent,
  IonIcon,
  IonLabel,
  IonRange,
  IonToggle,
} from "@ionic/react";
import styles from "./schools-filters-modal.module.scss";
import modalStyles from "../modals.module.scss";
import { closeOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import {
  selectIsSchoolsActive,
  selectSchoolsFilters,
} from "redux/schools/schools.selectors";
import { SchoolsFiltersService } from "services/schoolsFiltersService";
import { SchoolLevel } from "enums/Schools/SchoolLevel.enum";
import { SchoolType } from "enums/Schools/SchoolType.enum";
import { SchoolProgram } from "enums/Schools/SchoolProgram.enum";

interface SchoolsModalProps {
  dismiss: () => void;
}

const SchoolsFiltersModal = (props: SchoolsModalProps) => {
  const isSchoolsFilterActive = useSelector(selectIsSchoolsActive);
  const schoolsFilters = useSelector(selectSchoolsFilters);

  const handleToggle = () => {
    SchoolsFiltersService.toggle();
  };

  const handleRatingRangeChange = (e: CustomEvent) => {
    SchoolsFiltersService.updateRateFilter(e.detail.value);
  };

  const handleShowUnratedUpdate = (e: CheckboxCustomEvent) => {
    SchoolsFiltersService.updateShowUnrated(e.detail.checked);
  };

  return (
    <>
      <div className={modalStyles.header}>
        <IonIcon icon={closeOutline} onClick={props.dismiss} />
        <div>Schools Filters</div>
      </div>
      <IonContent>
        <div className={styles.filters}>
          <div className={styles.filter}>
            <div className={styles.labelContainer}>
              <IonLabel className={styles.label}>Show schools</IonLabel>
              <IonToggle
                checked={isSchoolsFilterActive}
                onIonChange={handleToggle}
                className="aecorn-toggle"
              />
            </div>
          </div>
          <div className={styles.filter}>
            <IonLabel className={styles.label}>Level</IonLabel>
            <div className={styles.chips}>
              <IonChip
                disabled={!isSchoolsFilterActive}
                className={`aecorn-chip ${
                  schoolsFilters.levels.length === 0 && "active"
                }`}
                onClick={() => SchoolsFiltersService.updateLevelFilter(null)}
                >
                Any level
              </IonChip>
              {Object.values(SchoolLevel).map((level) => (
                <IonChip
                  disabled={!isSchoolsFilterActive}
                  key={level}
                  className={`aecorn-chip ${
                    schoolsFilters.levels.includes(level) && "active"
                  }`}
                  onClick={() => SchoolsFiltersService.updateLevelFilter(level)}
                  >
                  {level}
                </IonChip>
              ))}
            </div>
          </div>
          <div className={styles.filter}>
            <IonLabel className={styles.label}>Type</IonLabel>
            <div className={styles.chips}>
              <IonChip
                disabled={!isSchoolsFilterActive}
                className={`aecorn-chip ${
                  schoolsFilters.types.length === 0 && "active"
                }`}
                onClick={() => SchoolsFiltersService.updateTypeFilter(null)}
                >
                Any type
              </IonChip>
              {Object.values(SchoolType).map((type) => (
                <IonChip
                  disabled={!isSchoolsFilterActive}
                  key={type}
                  className={`aecorn-chip ${
                    schoolsFilters.types.includes(type) && "active"
                  }`}
                  onClick={() => SchoolsFiltersService.updateTypeFilter(type)}
                  >
                  {type}
                </IonChip>
              ))}
            </div>
          </div>
          <div className={styles.filter}>
            <IonLabel className={styles.label}>Special Programs</IonLabel>
            <div className={styles.chips}>
              <IonChip
                disabled={!isSchoolsFilterActive}
                className={`aecorn-chip ${
                  schoolsFilters.programs.length === 0 && "active"
                }`}
                onClick={() => SchoolsFiltersService.updateProgramFilter(null)}
                >
                Any program
              </IonChip>
              {Object.values(SchoolProgram).map((program) => (
                <IonChip
                  disabled={!isSchoolsFilterActive}
                  key={program}
                  className={`aecorn-chip ${
                    schoolsFilters.programs.includes(program) && "active"
                  }`}
                  onClick={() => SchoolsFiltersService.updateProgramFilter(program)}
                  >
                  {program}
                </IonChip>
              ))}
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.item}>
              <div className={styles.labelContainer}>
                <IonLabel className={styles.label}>EQAO Rating</IonLabel>
                <IonLabel className={styles.label}>
                  {schoolsFilters.rate ? `${schoolsFilters.rate}+` : "Any"}
                </IonLabel>
              </div>
              <IonRange
                disabled={!isSchoolsFilterActive}
                min={0}
                max={10}
                snaps
                value={schoolsFilters.rate}
                onIonChange={handleRatingRangeChange}></IonRange>
                <div className={styles.inputs}>
                  <IonCheckbox checked={schoolsFilters.showUnrated} onIonChange={handleShowUnratedUpdate} disabled={!isSchoolsFilterActive} className="aecorn-checkbox" labelPlacement="end">Show unrated schools</IonCheckbox>
                </div>
            </div>
          </div>
        </div>
      </IonContent>
      <div className={modalStyles.footer}>
        <IonButton onClick={() => props.dismiss()} size="large" shape="round" className="aecorn-button">
          Search
        </IonButton>
        <IonButton
          size="large"
          shape="round"
          className="aecorn-button clear"
          onClick={() => SchoolsFiltersService.reset()}>
          Reset
        </IonButton>
      </div>
    </>
  );
};

export default SchoolsFiltersModal;
