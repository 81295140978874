import { useState } from "react";
import styles from "./listing-mortgage-calculator.module.scss";
import { useSelector } from "react-redux";
import { selectMortgageRate } from "redux/ui/ui.selectors";
import { Listing } from "models/listings/listing.model";
import { IonInput, IonRange } from "@ionic/react";
import { numberWithCommas } from "utils/functions";
import { CalculatorsService } from "services/calculatorsService";

const ListingMortgageCalculator = ({ listing }: { listing: Listing }) => {
  const defaultRate = useSelector(selectMortgageRate) || 4;
  const [price, setPrice] = useState<string>(
    numberWithCommas(+listing.listPrice, false)
  );
  const [term, setTerm] = useState<number>(25);
  const [rate, setRate] = useState<number>(defaultRate);
  const [downPayment, setDownPayment] = useState<string>(
    numberWithCommas(+listing.listPrice * 0.2, false)
  );
  const [downPaymentPercentage, setDownPaymentPercentage] =
    useState<number>(20);

  const handlePriceChange = (e: CustomEvent) => {
    const value = e.detail.value!;

    if (!value) {
      setPrice("0");
      return;
    }

    try {
      let price = value.replace(/,/g, "");
      const parsedValue = parseFloat(price);
      const formattedValue = numberWithCommas(parsedValue, false);
      setPrice(formattedValue);
      const newDownPayment = (parsedValue * downPaymentPercentage) / 100;
      setDownPayment(numberWithCommas(newDownPayment, false));
    } catch (error) {
      setPrice("0");
    }
  };

  const handlePriceRangeChange = (e: CustomEvent) => {
    const value = e.detail.value as number;
    const formattedValue = numberWithCommas(value, false);
    setPrice(formattedValue);
    const newDownPayment = (value * downPaymentPercentage) / 100;
    setDownPayment(numberWithCommas(newDownPayment, false));
  };

  const handleDownpaymentChange = (e: CustomEvent) => {
    const value = e.detail.value!;

    if (!value) {
      setDownPayment("0");
      return;
    }

    try {
      let downPayment = value.replace(/,/g, "");
      const parsedValue = parseFloat(downPayment);
      const formattedValue = numberWithCommas(parsedValue, false);
      setDownPayment(formattedValue);
      const newDownPaymentPercentage =
        (parsedValue / +price.replace(/,/g, "")) * 100;
      setDownPaymentPercentage(newDownPaymentPercentage);
    } catch (error) {
      setDownPayment("0");
    }
  };

  const handleDownpaymentRangeChange = (e: CustomEvent) => {
    const value = e.detail.value as number;
    const newDownPayment = numberWithCommas(
      (+price.replace(/,/g, "") * value) / 100,
      false
    );
    setDownPayment(newDownPayment);
    setDownPaymentPercentage(value);
  };

  const handleDownpaymentPercentageChange = (e: CustomEvent) => {
    const value = e.detail.value!;

    if (!value) {
      setDownPaymentPercentage(0);
      return;
    }

    try {
      let downPaymentPercentage = parseFloat(value);
      if (downPaymentPercentage > 100) {
        downPaymentPercentage = 100;
      }
      if (downPaymentPercentage < 0) {
        downPaymentPercentage = 0;
      }
      setDownPaymentPercentage(downPaymentPercentage);
      const newDownPayment = numberWithCommas(
        (+price.replace(/,/g, "") * downPaymentPercentage) / 100,
        false
      );
      setDownPayment(newDownPayment);
    } catch (error) {
      setDownPaymentPercentage(0);
    }
  };

  const calculateMonthlyPayment = () => {
    return numberWithCommas(
      CalculatorsService.calculateMortgageMonthlyPayment(
        +price.replace(/,/g, ""),
        +downPayment.replace(/,/g, ""),
        rate,
        term
      ),
      false
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <label>Home price</label>
        <div>
          <IonInput
            className="aecorn-input dark"
            pattern="(\d+(?:[\.\,]\d+)+)"
            inputMode="numeric"
            value={price}
            onIonInput={handlePriceChange}>
            <span slot="start">$</span>
          </IonInput>
          <IonRange
            min={0}
            max={10000000}
            step={10000}
            value={+price.replace(/,/g, "")}
            onIonInput={handlePriceRangeChange}
          />
        </div>
      </div>
      <div className={styles.item}>
        <label>Term</label>
        <div>
          <IonInput
            className="aecorn-input dark"
            type="number"
            value={term}
            onIonInput={(e) => setTerm(+e.detail.value!)}>
            <span slot="end">years</span>
          </IonInput>
          <IonRange
            min={1}
            max={30}
            step={1}
            value={term}
            onIonInput={(e) => setTerm(+e.detail.value!)}
          />
        </div>
      </div>
      <div className={styles.item}>
        <label>Rate</label>
        <div>
          <IonInput
            className="aecorn-input dark"
            type="number"
            value={rate}
            onIonInput={(e) => setRate(+e.detail.value!)}>
            <span slot="end">%</span>
          </IonInput>
          <IonRange
            min={0}
            max={20}
            step={0.1}
            value={rate}
            onIonInput={(e) => setRate(+e.detail.value!)}
          />
        </div>
      </div>
      <div className={styles.item}>
        <label>Down payment</label>
        <div>
          <div className={styles.inputs}>
            <IonInput
              className="aecorn-input dark"
              value={downPayment}
              onIonInput={handleDownpaymentChange}>
              <span slot="start">$</span>
            </IonInput>
            <IonInput
              className="aecorn-input dark"
              value={downPaymentPercentage}
              type="number"
              onIonInput={handleDownpaymentPercentageChange}>
              <span slot="end">%</span>
            </IonInput>
          </div>
          <IonRange
            min={0}
            max={100}
            step={1}
            value={downPaymentPercentage}
            onIonInput={handleDownpaymentRangeChange}
          />
        </div>
      </div>
      <div className={styles.payment}>
        <span>Mortgage payment:</span>
        <span>${calculateMonthlyPayment()}*</span>
      </div>
      <p>
        *Calculation formula is created by AECORN. This is for estimation
        purpose only.
      </p>
    </div>
  );
};

export default ListingMortgageCalculator;
