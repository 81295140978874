import {
  InputCustomEvent,
  IonButton,
  IonChip,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonToggle,
  useIonToast,
} from "@ionic/react";
import styles from "./save-search-modal.module.scss";
import modalStyles from "../modals.module.scss";
import { closeOutline } from "ionicons/icons";
import { useState } from "react";
import { ListingsHelper } from "utils/ListingsHelper";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "redux/client/client.selectors";
import { User } from "API";
import { capitalize } from "utils/functions";
import { SavedSearchesService } from "services/savedSearchesService";

interface SaveSearchModalProps {
  dismiss: () => void;
}

const SaveSearchModal = (props: SaveSearchModalProps) => {
  const [presentToast] = useIonToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>("");
  const [isSearchNameTouched, setIsSearchNameTouched] =
    useState<boolean>(false);
  const [isSearchNameValid, setIsSearchNameValid] = useState<boolean>(false);
  const [isAlertsEnabled, setIsAlertsEnabled] = useState<boolean>(false);

  const currentUser: User | null = useSelector(selectCurrentClient);

  const handleSearchNameChange = (e: InputCustomEvent) => {
    const value = e.detail.value || "";
    setSearchName(value);
    setIsSearchNameValid(value.length >= 3);
  };

  const handleSaveSearch = async () => {
    try {
      setLoading(true);
      await SavedSearchesService.createSavedSearch(searchName, isAlertsEnabled);
      presentToast({
        message: "Search saved successfully",
        duration: 3000,
        cssClass: "aecorn-success-toast",
        position: "top",
      });
      props.dismiss();
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while saving the search",
        duration: 3000,
        cssClass: "aecorn-error-toast",
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={modalStyles.header}>
        <IonIcon icon={closeOutline} onClick={props.dismiss} />
        <div>Save Search</div>
      </div>
      <IonContent>
        <IonLoading
          isOpen={loading}
          spinner="circles"
          message={"Saving search..."}
        />
        <div className={styles.items}>
          <div className={styles.item}>
            <p>
              Quickly re-load your search parameters later, or set up alerts and
              receive up to 100 relevant listings a day per saved search.
            </p>
          </div>
          <div className={styles.item}>
            <IonInput
              placeholder="Search name"
              required
              autocapitalize="words"
              minlength={3}
              onIonInput={handleSearchNameChange}
              onFocus={() => setIsSearchNameTouched(true)}
              className={`aecorn-input ${
                !isSearchNameValid &&
                isSearchNameTouched &&
                "ion-invalid ion-touched"
              }`}
              errorText="Minimum 3 characters required"></IonInput>
          </div>
          <div className={styles.item}>
            <IonLabel className={styles.label}>
              Parameters saved for applicable area
            </IonLabel>
            <div className={styles.chips}>
              {ListingsHelper.getAppliedFilters().map((filter, index) => (
                <IonChip key={index} className="aecorn-chip active">
                  {filter}
                </IonChip>
              ))}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.labelContainer}>
              <IonLabel className={styles.label}>
                Alerts for this search
              </IonLabel>
              <IonToggle
                checked={isAlertsEnabled}
                onIonChange={() => setIsAlertsEnabled(!isAlertsEnabled)}
                className="aecorn-toggle"
              />
            </div>
            {isAlertsEnabled && (
              <div className={styles.chips}>
                {currentUser?.notifications?.schedule && (
                  <IonChip className="aecorn-chip active">
                    {capitalize(currentUser?.notifications.schedule)}{" "}
                    notification of new listings
                  </IonChip>
                )}
                {currentUser?.notifications?.type?.map((type, index) => (
                  <IonChip key={index} className="aecorn-chip active">
                    Notification by {type}
                  </IonChip>
                ))}
              </div>
            )}
          </div>
        </div>
      </IonContent>
      <div className={modalStyles.footer}>
        <IonButton
          size="large"
          shape="round"
          className="aecorn-button"
          disabled={!isSearchNameValid}
          onClick={handleSaveSearch}>
          Save
        </IonButton>
      </div>
    </>
  );
};

export default SaveSearchModal;
