import { Listing } from "models/listings/listing.model";
import styles from "./listing-rooms-details.module.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "redux/client/client.selectors";
import { GeneralConstants } from "utils/constants";
import { IonToggle } from "@ionic/react";

const ListingRoomsDetails = ({ listing }: { listing: Listing }) => {
  const client = useSelector(selectCurrentClient);
  const [measurementUnit, setMeasurementUnit] = useState<"imperial" | "metric">(
    client?.measurement || "imperial"
  );

  const rooms =
    (Object.values(listing.rooms).filter((room) => room.description) || []).map(room => ({ ...room, features: [room.features, room.features2, room.features3].filter(feature => feature) }));

  const getMeasurement = (value: number) => {
    if (measurementUnit === "imperial") {
      return `${value} ft`;
    } else {
      const meters = value * GeneralConstants.FEET_TO_METERS;
      return `${meters.toFixed(2)} m`;
    }
  };

  const toggle = () => (
    <IonToggle
      className={`aecorn-toggle aecorn-toggle-label ${measurementUnit === "metric" && "toggle-checked"
        }`}
      checked={measurementUnit === "metric"}
      onIonChange={(e) =>
        setMeasurementUnit(e.detail.checked ? "metric" : "imperial")
      }
      labelPlacement="stacked">
      {measurementUnit === "metric" ? "Meters" : "Feet"}
    </IonToggle>
  );

  return (
    <div className={styles.container}>
      {rooms.length > 0 ? (
        <div className={styles.toggleContainer}>
          {toggle()}
        </div>
      ) : <div className="aecorn-listing-detail-tab">
        No rooms available
      </div>}
      <div className={styles.header}>
        <span>Name</span>
        <span><span>Size</span>{toggle()}</span>
        <span>Feature</span>
      </div>
      {rooms.map((room, index) => {
        return (
          <div key={index} className={`aecorn-listing-detail-tab ${styles.room}`}>
            <div className={styles.description}>
              {room.description}
            </div>
            <div className={styles.size}>
              {room.length && room.width
                ? `${getMeasurement(+room.length)} x ${getMeasurement(
                  +room.width
                )}`
                : ""}
            </div>
            <div className={styles.features}>
              {room.features.join(", ")}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListingRoomsDetails;
