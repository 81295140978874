import { Listing } from 'models/listings/listing.model';
import styles from './listing-share-modal.module.scss';
import modalStyles from '../modals.module.scss';
import { IonIcon, useIonToast } from '@ionic/react';
import { closeOutline, linkOutline } from 'ionicons/icons';
import MediaQuery from 'react-responsive';
import EmailIcon from 'assets/svg/social/Email.svg';
import FacebookIcon from 'assets/svg/social/Facebook.svg';
import LinkedInIcon from 'assets/svg/social/Linkedin.svg';
import TwitterIcon from 'assets/svg/social/Twitter.svg';
import WhatsAppIcon from 'assets/svg/social/Whatsapp.svg';
import { ListingsHelper } from 'utils/ListingsHelper';

const ListingShareModal = ({ listing, onDismiss }: { listing: Listing, onDismiss: () => void }) => {
    const [presentToast] = useIonToast();

    const getSubject = () => {
        const address = ListingsHelper.getListingAddress(listing.address);

        return `Check out this listing at ${address} for $${listing.listPrice}`;
    };

    const handleShare = (type: string) => {
        switch (type) {
            case "link":
                navigator.clipboard.writeText(window.location.href);
                presentToast({
                    message: "Link copied to clipboard",
                    duration: 2000,
                    position: "top",
                    cssClass: "aecorn-success-toast"
                });
                break;
            case "email":
                window.open(`mailto:?subject=${getSubject()}&body=${window.location.href}`);
                break;
            case "facebook":
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`);
                break;
            case "linkedin":
                window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&summary=${getSubject()}`);
                break;
            case "twitter":
                window.open(`https://twitter.com/intent/tweet?url=${window.location.href}&text=${getSubject()}`);
                break;
            case "whatsapp":
                window.open(`https://api.whatsapp.com/send?text=${getSubject()} ${window.location.href}`);
                break;
            default:
                break;
        }

        onDismiss();
    };

    return (
        <div className={modalStyles.sheetContent}>
            <div className={modalStyles.modalHeader}>
                Share listing
                <MediaQuery minWidth={768}>
                    <IonIcon icon={closeOutline} onClick={onDismiss} />
                </MediaQuery>
            </div>
            <div className={styles.container}>
                <div className={styles.type} onClick={() => handleShare("link")}>
                    <IonIcon icon={linkOutline} />
                    Copy link
                </div>
                <div className={styles.type} onClick={() => handleShare("email")}>
                    <IonIcon icon={EmailIcon} />
                </div>
                <div className={styles.type} onClick={() => handleShare("facebook")}>
                    <IonIcon icon={FacebookIcon} />
                </div>
                <div className={styles.type} onClick={() => handleShare("linkedin")}>
                    <IonIcon icon={LinkedInIcon} />
                </div>
                <div className={styles.type} onClick={() => handleShare("twitter")}>
                    <IonIcon icon={TwitterIcon} />
                </div>
                <div className={styles.type} onClick={() => handleShare("whatsapp")}>
                    <IonIcon icon={WhatsAppIcon} />
                </div>
            </div>
        </div>
    )
}

export default ListingShareModal;