import { useState } from "react";
import styles from "../../authentication-modal.module.scss";
import { useForm } from "react-hook-form";
import { AuthenticationService } from "services/authenticationService";
import {
  IonButton,
  IonInput,
  IonLabel,
  IonNote,
  IonSpinner,
  useIonToast,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const SignUpConfirmationForm = ({
  onDismiss,
  onError,
  email,
  password,
  phoneNumber
}: {
  onDismiss: () => void;
  onError: (message: string | null) => void;
  email: string;
  password: string;
  phoneNumber: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [presentToast] = useIonToast();

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && isValid) {
      handleSubmit(onSubmit);
    }
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<{
    code: string;
  }>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const codeFormControl = register("code", {
    required: {
      value: true,
      message: "Code is required",
    },
  });

  const onSubmit = async (data: { code: string }) => {
    setLoading(true);
    clearErrors();
    try {
      await AuthenticationService.confirmRegistration(email, data.code);
      presentToast({
        message:
          "Your account has been successfully created. You will be signed in a moment!",
        duration: 5000,
        cssClass: "aecorn-success-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
      await AuthenticationService.login(email, password);
      onDismiss();
    } catch (error: any) {
      onError(error.message);
    }
    setLoading(false);
  };

  const handleResendCode = async () => {
    try {
      await AuthenticationService.resendConfirmationCode(email);
      presentToast({
        message: "A new code has been sent to your email",
        duration: 5000,
        cssClass: "aecorn-success-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    } catch (error: any) {
      onError(error.message);
    }
  };

  return (
    <div>
      <p>
        We've sent a confirmation code to your email - enter the code below. Add
        AECORN to your safe-sender list to ensure important messages don't go to
        your junk folder.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} onKeyUp={handleKeyPress} className={styles.form}>
        <div className={styles.formItem}>
          <IonLabel>Verification code*</IonLabel>
          <IonInput
            className={`aecorn-input dark ${
              errors.code?.message && "ion-invalid ion-touched"
            }`}
            required
            type="number"
            placeholder="Enter the code"
            {...codeFormControl}
            onIonInput={codeFormControl.onChange}
            disabled={loading}
          />
          {errors.code && <IonNote>{errors.code.message}</IonNote>}
        </div>
        <IonButton
          className="aecorn-button primary"
          expand="block"
          type="submit"
          disabled={!isValid || loading}>
          {loading ? <IonSpinner name="crescent" /> : "Confirm"}
        </IonButton>
        <span className={styles.resendCode} onClick={handleResendCode}>
          Resend code
        </span>
      </form>
    </div>
  );
};

export default SignUpConfirmationForm;
