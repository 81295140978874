import { GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  deleteProfile,
  updateUser,
  deleteUser as deleteUserMutation,
  updateClient,
  createNotification,
  updateNotification,
} from "../graphql/mutations";
import { AuthenticationService } from "./authenticationService";
import { listUsers } from "../graphql/queries";
import {
  CreateNotificationMutation,
  GetUserQuery,
  ListUsersQuery,
  NotificationSchedule,
  UpdateClientMutation,
  UpdateNotificationMutation,
  UpdateUserMutation,
  User,
  UserRole,
  NotificationType,
  GetUserByEmailQuery
} from "../API";
import { GeneralConstants } from "utils/constants";
import { Photo } from "@capacitor/camera";
import { StorageService } from "./storageService";
import { store } from "redux/store";
import { fetchClientSuccess } from "redux/client/client.actions";
import { getUser, getUserPublicProfile, getUsersByEmail, listAgentUsers } from "./queries/users.queries";

const getCurrentUser = async () => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  const response = await API.graphql<GraphQLQuery<GetUserQuery>>({
    query: getUser,
    variables: { id: cognitoUser.username },
  });
  return {
    ...response.data?.getUser,
    isClient: response.data?.getUser?.role === "client",
    isAdmin: response.data?.getUser?.role === "admin",
    isAgent: response.data?.getUser?.role === "agent",
  } as User;
};

const getCurrentUserId = async () => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  return cognitoUser.username;
};

const fetchAndUpdateUserInStore = async () => {
  const user = await getCurrentUser();
  updateUserInStore(user);
};

const updateGivenName = async (name: string) => {
  const updatedUser = await AuthenticationService.updateUserAttribute(
    "given_name",
    name
  );

  const newDisplayName = `${name} ${updatedUser.attributes?.family_name}`;
  const newSearchName =
    `${name} ${updatedUser.attributes?.family_name}`.toLowerCase();

  await API.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: updateUser,
    variables: {
      input: {
        id: updatedUser.username,
        givenName: name.trim(),
        name: newSearchName.trim(),
        displayName: newDisplayName.trim(),
      },
    },
  });

  await fetchAndUpdateUserInStore();
};

const updateFamilyName = async (name: string) => {
  const updatedUser = await AuthenticationService.updateUserAttribute(
    "family_name",
    name
  );

  const newDisplayName = `${updatedUser.attributes?.given_name} ${name}`;
  const newSearchName =
    `${updatedUser.attributes?.given_name} ${name}`.toLowerCase();

  await API.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: updateUser,
    variables: {
      input: {
        id: updatedUser.username,
        familyName: name.trim(),
        name: newSearchName.trim(),
        displayName: newDisplayName.trim(),
      },
    },
  });

  await fetchAndUpdateUserInStore();
};

const updatePhoneNumber = async (phoneNumber: string) => {
  const updatedUser = await AuthenticationService.updateUserAttribute(
    "phone_number",
    phoneNumber
  );

  await API.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: updateUser,
    variables: {
      input: {
        id: updatedUser.username,
        phone: phoneNumber,
      },
    },
  });

  await fetchAndUpdateUserInStore();
}

const updateFullName = async (givenName: string, familyName: string) => {
  const updatedUser = await AuthenticationService.updateUserAttributes({
    given_name: givenName,
    family_name: familyName,
  });
  await API.graphql({
    query: updateUser,
    variables: {
      input: {
        id: updatedUser.username,
        givenName: givenName,
        familyName: familyName,
      },
    },
  });
};

const updateCurrency = async (currency: string) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();

  await API.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: updateUser,
    variables: {
      input: {
        id: cognitoUser.username,
        currency,
      },
    },
  });

  await fetchAndUpdateUserInStore();
};

const updateMeasurementUnit = async (measurement: string) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();

  await API.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: updateUser,
    variables: {
      input: {
        id: cognitoUser.username,
        measurement,
      },
    },
  });

  await fetchAndUpdateUserInStore();
}

const updateNotificationSchedule = async (schedule: NotificationSchedule) => {
  const user = await getCurrentUser();

  if (user.notifications) {
    await API.graphql<GraphQLQuery<UpdateNotificationMutation>>({
      query: updateNotification,
      variables: {
        input: {
          id: user.notifications.id,
          schedule,
          type: user.notifications.type,
        },
      },
    });
  } else {
    await API.graphql<GraphQLQuery<CreateNotificationMutation>>({
      query: createNotification,
      variables: {
        input: {
          id: user.id,
          schedule,
          type: [],
        },
      },
    })
  }

  await fetchAndUpdateUserInStore();
}

const updateNotificationType = async (type: NotificationType[]) => {
  const user = await getCurrentUser();

  if (user.notifications) {
    await API.graphql<GraphQLQuery<UpdateNotificationMutation>>({
      query: updateNotification,
      variables: {
        input: {
          id: user.notifications.id,
          type,
          schedule: user.notifications.schedule,
        },
      },
    });
  } else {
    await API.graphql<GraphQLQuery<CreateNotificationMutation>>({
      query: createNotification,
      variables: {
        input: {
          id: user.id,
          type,
          schedule: NotificationSchedule.instantly,
        },
      },
    });
  }

  await fetchAndUpdateUserInStore();
}

const updateProfilePicture = async (photo: Photo) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  const result = await StorageService.addPhoto(photo);

  const response = await API.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: updateUser,
    variables: {
      input: { id: cognitoUser.username, profilePicture: result.key },
    },
  });

  await fetchAndUpdateUserInStore();

  return response.data?.updateUser;
};

const deleteProfilePicture = async (existingToken?: string | null) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  if (existingToken) {
    await StorageService.deleteItem(existingToken);
  }
  const response = await API.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: updateUser,
    variables: { input: { id: cognitoUser.username, profilePicture: null } },
  });

  await fetchAndUpdateUserInStore();

  return response.data?.updateUser;
};

const updateProfileId = async (id: string) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  await API.graphql({
    query: updateUser,
    variables: { input: { id: cognitoUser.username, userProfileId: id } },
  });
};

const updateDeviceToken = async (token: string) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  await API.graphql({
    query: updateUser,
    variables: { input: { id: cognitoUser.username, deviceToken: token } },
  });
};

const updateTourGeneralNote = async (tourNote: string) => {
  const cognitoUser = await AuthenticationService.getCognitoUser();
  await API.graphql({
    query: updateUser,
    variables: { input: { id: cognitoUser.username, toursNote: tourNote } },
  });
};

const updateClientAgent = async (
  clientId: string,
  agentId: string | undefined
) => {
  let clientAgentId = agentId;

  if (!clientAgentId) {
    const response = await API.get(
      GeneralConstants.REST_API_NAME,
      "/default-agent",
      {}
    );
    clientAgentId = response.defaultAgent;
  }

  await API.graphql({
    query: updateClient,
    variables: { input: { id: clientId, agentId: clientAgentId } },
  });

  await fetchAndUpdateUserInStore();
};

const updateClientAvailability = async (
  clientId: string,
  availability: string[]
) => {
  const response = await API.graphql<GraphQLQuery<UpdateClientMutation>>({
    query: updateClient,
    variables: {
      input: { id: clientId, availability: JSON.stringify(availability) },
    },
  });

  return response;
};

const deleteUser = async (password: string) => {
  const user = await AuthenticationService.getCognitoUser();
  await AuthenticationService.login(user.username, password);

  await API.graphql({
    query: deleteUserMutation,
    variables: { input: { id: user.username } },
  });
  await API.graphql({
    query: deleteProfile,
    variables: { input: { id: user.username } },
  });
  await AuthenticationService.deleteAccount();
};

const getAgentUsers = async (searchTerm?: string) => {
  let filter;

  if (searchTerm) {
    filter = {
      role: { eq: UserRole.agent },
      and: [
        {
          or: [
            { givenName: { contains: searchTerm } },
            { familyName: { contains: searchTerm } },
            { email: { contains: searchTerm } },
            { name: { contains: searchTerm.toLowerCase() } },
          ],
        },
      ],
    };
  } else {
    filter = { role: { eq: UserRole.agent } };
  }

  const response = await API.graphql<GraphQLQuery<ListUsersQuery>>({
    query: listAgentUsers,
    variables: {
      filter,
    },
  });
  return response.data?.listUsers?.items || [];
};

export const getClientUsers = async (searchTerm?: string) => {
  let filter;

  if (searchTerm) {
    filter = {
      role: { eq: UserRole.client },
      and: [
        {
          or: [
            { givenName: { contains: searchTerm } },
            { familyName: { contains: searchTerm } },
            { email: { contains: searchTerm } },
            { name: { contains: searchTerm.toLowerCase() } },
          ],
        },
      ],
    };
  } else {
    filter = { role: { eq: UserRole.client } };
  }

  const response = await API.graphql<GraphQLQuery<ListUsersQuery>>({
    query: listUsers,
    variables: {
      filter,
    },
  });

  return response.data?.listUsers?.items || [];
};

const getUserPublicProfileById = async (id: string) => {
  const response = await API.graphql<GraphQLQuery<GetUserQuery>>({
    query: getUserPublicProfile,
    variables: { id },
  });
  return response.data?.getUser;
}

const getUserById = async (id: string) => {
  const response = await API.graphql<GraphQLQuery<GetUserQuery>>({
    query: getUser,
    variables: { id },
  });
  return response.data?.getUser;
};

const getUserByEmail = async (email: string) => {
  const response = await API.graphql<GraphQLQuery<GetUserByEmailQuery>>({
    query: getUsersByEmail,
    variables: { email },
  });
  return response.data?.getUserByEmail?.items ? response.data?.getUserByEmail?.items[0] : null;
};

const updateUserInStore = (update: User) => {
  store.dispatch(fetchClientSuccess(update));
};

export const UserService = {
  getCurrentUser,
  getCurrentUserId,
  updateGivenName,
  updateFamilyName,
  updatePhoneNumber,
  updateFullName,
  updateCurrency,
  updateMeasurementUnit,
  updateNotificationSchedule,
  updateNotificationType,
  updateProfilePicture,
  deleteProfilePicture,
  updateProfileId,
  updateDeviceToken,
  updateTourGeneralNote,
  updateClientAgent,
  updateClientAvailability,
  deleteUser,
  getAgentUsers,
  getClientUsers,
  getUserById,
  getUserByEmail,
  getUserPublicProfileById
};
