import { ActivityStatus, ActivityType, Activity as APIActivity } from "API";
import styles from "./activity.module.scss";
import { useEffect, useState } from "react";
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { personOutline } from "ionicons/icons";
import { ActivityService } from "services/activityService";

const Activity = ({
  activity,
  hideButtons = false,
  onDelete,
}: {
  activity: APIActivity;
  hideButtons?: boolean;
  onDelete?: (id: string) => void;
}) => {
  const [parsedActivity, setParsedActivity] = useState<{
    title: string;
    sender: string;
    date: string;
    content: Object;
  }>();

  useEffect(() => {
    const content = activity.content ? JSON.parse(activity.content) : {};
    let title = "";

    switch (activity.type) {
      case ActivityType.tourRequest:
        title = `Viewing request #${content.mlsNumber}`;
        break;
      case ActivityType.listingInformation:
        title = `Information request #${content.mlsNumber}`;
        break;
      case ActivityType.mlsReport:
        title = `MLS Report ${content.listingAddress}`;
        break;
      case ActivityType.tourShare: 
        title = `Tour '${content.tourName}' has been shared with you`;
        break;
      case ActivityType.tourCancel:
        title = `Tour '${content.tourName}' has been cancelled`;
        break;
      case ActivityType.offer:
        title = `Offer for ${content.mlsNumber}`;
        break;
    }
    // if date is less than 1 day, show how many hours ago, otherwise show how many days ago using moment.js
    const date = new Date(activity.createdAt);
    const currentDate = new Date();
    const diff = currentDate.getTime() - date.getTime();
    const diffHours = Math.floor(diff / (1000 * 60 * 60));

    let dateText = "";

    if (diffHours < 24) {
      dateText =
        diffHours < 1 ? "less than an hour ago" : `${diffHours} hours ago`;
    } else {
      const days = Math.floor(diffHours / 24);
      dateText = `${days} ${days > 1 ? "days" : "day"} ago`;
    }

    setParsedActivity({
      title,
      sender: content.email,
      date: dateText,
      content,
    });
  }, []);

  const onDeleteActivity = async (id: string) => {
    try {
      await ActivityService.deleteActivity(id);
      if (onDelete) {
        onDelete(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={`${styles.container} ${
        activity.status === ActivityStatus.read && styles.readContainer
      }`}>
      <IonGrid>
        <IonRow>
          <IonCol size={hideButtons ? "12" : "9"}>
            <div className={styles.header}>
              <div
                className={
                  activity.status === ActivityStatus.read
                    ? styles.read
                    : styles.unread
                }></div>
              <div className={styles.title}>{parsedActivity?.title}</div>
            </div>
            <div className={styles.content}>
              {parsedActivity?.sender && (
                <div className={styles.sender}>
                  <IonIcon icon={personOutline} />
                  {parsedActivity.sender}
                </div>
              )}
              <div className={styles.date}>{parsedActivity?.date}</div>
            </div>
          </IonCol>
          {!hideButtons && (
            <IonCol size="3">
              <IonRow>
                <IonCol>
                  <IonButton
                    className={`aecorn-button ${
                      activity.status === ActivityStatus.read &&
                      styles.readButton
                    }`}
                    routerLink={`/activities/${activity.id}`}>
                    View
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    className={`aecorn-button danger danger-border`}
                    onClick={() => onDeleteActivity(activity.id)}>
                    Delete
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Activity;
