import { Listing } from "models/listings/listing.model";
import styles from "./listing-history.module.scss";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "redux/user/user.selectors";
import { IonRouterLink } from "@ionic/react";
import moment from "moment";
import { ListingsConstants } from "constants/Listings.constants";
import { ListingType } from "enums/Listings/ListingType.enum";
import { ListingsHelper } from "utils/ListingsHelper";
import RestrictedAccessCard from "components/shared/RestrictedAccessCard/restricted-access-card.component";

const ListingHistory = ({ listing }: { listing: Listing }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <div className={styles.container}>
      {isAuthenticated ? (
        <>
        <div className={styles.history}>
          <span>Type</span>
          <span>Original price</span>
          <span>Listing date</span>
          <span>Sold / Leased date</span>
          <span>Sold / Leased price</span>
          <span>Status</span>
          <span>MLS#</span>
        </div>
        {listing.history.length === 0 && (
          <div className="aecorn-listing-detail-tab">
            No history available
          </div>
        )}
        {listing.history.map((history, index) => (
          <div key={index} className={`aecorn-listing-detail-tab ${styles.history}`}>
            <div className={styles.historyInfo}>
              <span className={styles.historyInfo_value}>
                For {history.type.toLocaleLowerCase()}
              </span>
            </div>
            <div className={styles.historyInfo}>
              <span className={styles.historyInfo_label}>Original price: </span>
              <span className={styles.historyInfo_value}>
                {ListingsHelper.getFormattedPrice(+history.listPrice)}
              </span>
            </div>
            <div className={styles.historyInfo}>
              <span className={styles.historyInfo_label}>Listing date: </span>
              <span className={styles.historyInfo_value}>
                {moment(history.listDate).format("MMM DD, YYYY")}
              </span>
            </div>
            <div className={styles.historyInfo}>
              <span className={styles.historyInfo_label}>Status: </span>
              <span className={styles.historyInfo_value}>
                {ListingsConstants.LastStatus[history.lastStatus]}
              </span>
            </div>
            {history.soldDate && history.soldPrice ? (
              <div className={styles.historyInfo}>
                <span className={styles.historyInfo_label}>
                  {history.type === ListingType.Sale ? "Sold" : "Leased"} price:{" "}
                </span>
                <span className={styles.historyInfo_value}>
                  {ListingsHelper.getFormattedPrice(+history.soldPrice)}
                </span>
              </div>
            ) : <span className={styles.historyInfo_label_empty}>-</span>}
            {history.soldDate ? (
              <div className={styles.historyInfo}>
                <span className={styles.historyInfo_label}>Sold date: </span>
                <span className={styles.historyInfo_value}>
                  {moment(history.soldDate).format("MMM DD, YYYY")}
                </span>
              </div>
            ) : <span className={styles.historyInfo_label_empty}>-</span>}
            <div className={styles.historyInfo}>
              <span className={styles.historyInfo_label}>MLS#: </span>
              <span className={styles.historyInfo_value}>
                <IonRouterLink routerLink={`/listings/${history.mlsNumber}`}>
                  {history.mlsNumber}
                </IonRouterLink>
              </span>
            </div>
          </div>
        ))}
        </>
      ) : (
        <div className="aecorn-listing-detail-tab vertical">
          <RestrictedAccessCard />
        </div>
      )}
    </div>
  );
};

export default ListingHistory;
