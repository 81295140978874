import { Listing } from "models/listings/listing.model";
import styles from "./listing-essentials.module.scss";
import { ListingsHelper } from "utils/ListingsHelper";
import moment from "moment";
import { ListingsConstants } from "constants/Listings.constants";
import { formatPrice } from "utils/functions";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import { ListingLastStatus } from "enums/Listings/ListingLastStatus.enum";

const ListingEssentials = ({ listing, restricted = false } : { listing: Listing, restricted: boolean }) => {
  const days = ListingsHelper.getListingFormattedListDate(listing);

  const getListingType = () => {
    const allPropertyTypes = [
      ...ListingsConstants.ResidentialPropertyTypes,
      ...ListingsConstants.CommercialPropertyTypes,
      ...ListingsConstants.CondoPropertyTypes,
    ];

    return allPropertyTypes.find(
      (type) => type.value === listing.details.propertyType
    )?.label;
  };

  const getListingLastStatusTerm = () => {
    const lastStatus = listing.lastStatus;

    switch (lastStatus) {
      case (ListingLastStatus.DealFellThrough,
      ListingLastStatus.Expired,
      ListingLastStatus.Suspended,
      ListingLastStatus.Terminated):
        return "de-listed";
      case ListingLastStatus.Sold:
        return "sold";
      case ListingLastStatus.Leased:
        return "leased";
    }
  };

  return (
    <div className={styles.container}>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">
          MLS<>&reg;</> Number
        </div>
        <div
          className="aecorn-listing-detail-tab_value"
          style={{ textDecoration: "underline" }}>
          {listing.mlsNumber}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Listed</div>
        <div className={`aecorn-listing-detail-tab_value ${restricted && 'scattered'}`}>
          {days === 0 ? "today" : `${days} ${days > 1 ? "days" : "day"} ago`}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Days on market</div>
        <div className={`aecorn-listing-detail-tab_value ${restricted && 'scattered'}`}>
          {`${listing.daysOnMarket} ${listing.daysOnMarket > 1 ? "days" : "day"}`}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Date listed</div>
        <div className={`aecorn-listing-detail-tab_value ${restricted && 'scattered'}`}>
          {moment(listing.listDate).format("MMM DD YYYY")}
        </div>
      </div>
      {listing.status === ListingStatus.Unavailable && (
        <div className="aecorn-listing-detail-tab">
          <div className="aecorn-listing-detail-tab_label">
            Date {getListingLastStatusTerm()}
          </div>
          <div className={`aecorn-listing-detail-tab_value ${restricted && 'scattered'}`}>
            {moment(listing.soldDate ? listing.soldDate : listing.updatedOn).format("MMM DD YYYY")}
          </div>
        </div>
      )}
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Property type</div>
        <div className="aecorn-listing-detail-tab_value">
          {getListingType()}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Taxes</div>
        <div className={`aecorn-listing-detail-tab_value ${restricted && 'scattered'}`}>
          {listing.taxes
            ? `${formatPrice(listing.taxes.annualAmount)}/${
                listing.taxes.assessmentYear
              }`
            : "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Sqft</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.details.sqft ? `${listing.details.sqft} sqft` : "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Lot frontage</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.lot?.width ? `${listing.lot.width} ft` : "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Lot depth</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.lot?.depth ? `${listing.lot.depth} ft` : "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Basement</div>
        <div className="aecorn-listing-detail-tab_value">{`${listing.details.basement1} ${listing.details.basement2}`}</div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Posession date</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.occupancy ? listing.occupancy : "-"}
        </div>
      </div>
      <div className="aecorn-listing-detail-tab">
        <div className="aecorn-listing-detail-tab_label">Brokerage</div>
        <div className="aecorn-listing-detail-tab_value">
          {listing.office?.brokerageName ? listing.office.brokerageName : "-"}
        </div>
      </div>
    </div>
  );
};

export default ListingEssentials;
