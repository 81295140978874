import { GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import {
  CreateSearchMutation,
  CreateSearchMutationVariables,
  DeleteSearchMutation,
  ListSearchesQuery,
  OnCreateSearchSubscription,
  OnDeleteSearchSubscription,
  OnUpdateSearchSubscription,
  Search,
  UpdateSearchInput,
  UpdateSearchMutation,
} from "API";
import { API } from "aws-amplify";
import { ListingType } from "enums/Listings/ListingType.enum";
import { createSearch, deleteSearch, updateSearch } from "graphql/mutations";
import { listSearches } from "graphql/queries";
import {
  onCreateSearch,
  onDeleteSearch,
  onUpdateSearch,
} from "graphql/subscriptions";
import { store } from "redux/store";

const getSavedSearches = async (): Promise<Search[]> => {
  const res = await API.graphql<GraphQLQuery<ListSearchesQuery>>({
    query: listSearches,
  });

  return res.data?.listSearches?.items as Search[];
};

const createSavedSearch = async (
  searchName: string,
  notification: boolean
): Promise<Search> => {
  const repliersClientId: number =
    store.getState().client.currentClient.repliersID;
  const filters = store.getState().filters.value;
  const drawingBoundary = store.getState().map.drawingBoundary;

  const searchValue: any = {
    drawingBoundary: drawingBoundary,
    filters: {
      status: true,
      type: filters.type === ListingType.Sale ? "sale" : "lease",
      class: filters.class,
      minPrice: filters.minPrice ? filters.minPrice : 0,
      maxPrice: filters.maxPrice ? filters.maxPrice : 999999999,
      minBedrooms: filters.minBedrooms,
      maxBedrooms: filters.maxBedrooms,
      minBathrooms: filters.minBathrooms,
      maxBathrooms: filters.maxBathrooms,
      propertyType: filters.propertyType,
      maxMaintenanceFee: filters.maxMaintenanceFee,
      area: filters.area,
      city: filters.city,
      neighborhood: filters.neighborhood,
    },
  };

  Object.keys(searchValue.filters).forEach((key: string) => {
    if (searchValue.filters[key] === null) {
      delete searchValue.filters[key];
    }
  });

  const body = {
    clientId: repliersClientId,
    name: searchName,
    ...searchValue,
  };

  const result = await API.post("ettieREST", "/searches", {
    body,
  });

  const repliersSearchId = result.searchId;

  const variables: CreateSearchMutationVariables = {
    input: {
      name: searchName,
      notification: notification,
      repliersID: repliersSearchId,
      value: JSON.stringify(searchValue),
    },
  };

  const res = await API.graphql<GraphQLQuery<CreateSearchMutation>>({
    query: createSearch,
    variables,
  });
  return res.data?.createSearch as Search;
};

const updateSavedSearch = async (update: UpdateSearchInput) => {
  const result = await API.graphql<GraphQLQuery<UpdateSearchMutation>>({
    query: updateSearch,
    variables: { input: update },
  });

  return result.data?.updateSearch as Search;
};

const deleteSavedSearch = async (search: Search) => {
  if (search.repliersID) {
    await API.del("ettieREST", `/searches/${search.repliersID}`, {});
  }

  await API.graphql<GraphQLQuery<DeleteSearchMutation>>({
    query: deleteSearch,
    variables: { input: { id: search.id } },
  });
};

const onCreateSavedSearch = async (callback: (data: Search) => void) => {
  const res = API.graphql<GraphQLSubscription<OnCreateSearchSubscription>>({
    query: onCreateSearch,
  });

  const subscription = res.subscribe(async (data) => {
    const search = data.value.data?.onCreateSearch;
    if (search) {
      callback(search);
    }
  });

  return subscription;
};

const onUpdateSavedSearch = async (callback: (data: Search) => void) => {
  const res = API.graphql<GraphQLSubscription<OnUpdateSearchSubscription>>({
    query: onUpdateSearch,
  });

  const subscription = res.subscribe(async (data) => {
    const search = data.value.data?.onUpdateSearch;
    if (search) {
      callback(search);
    }
  });

  return subscription;
};

const onDeleteSavedSearch = async (callback: (data: Search) => void) => {
  const res = API.graphql<GraphQLSubscription<OnDeleteSearchSubscription>>({
    query: onDeleteSearch,
  });

  const subscription = res.subscribe(async (data) => {
    const search = data.value.data?.onDeleteSearch;
    if (search) {
      callback(search);
    }
  });

  return subscription;
};

const getListingsBySavedSearch = async (
  repliersID: string,
  pageNumber: number
) => {
  const result = await API.get(
    "ettieREST",
    `/searches/${repliersID}?pageNumber=${pageNumber}`,
    {}
  );
  return result;
};

export const SavedSearchesService = {
  getSavedSearches,
  createSavedSearch,
  updateSavedSearch,
  deleteSavedSearch,
  onCreateSavedSearch,
  onUpdateSavedSearch,
  onDeleteSavedSearch,
  getListingsBySavedSearch,
};
