import { IonButton, IonIcon } from "@ionic/react";
import TourStatusPill from "components/shared/TourStatusPill/tour-status-pill.component";
import { chevronForwardOutline } from "ionicons/icons";
import { ListingsHelper } from "utils/ListingsHelper";
import styles from "./agent-tour-stop-card.module.scss";
import { TourStop } from "models/tours/tour-stop.model";
import moment from "moment";

const AgentTourStopCard = ({
  stop,
  onNavigate
}: {
  stop: TourStop;
  onNavigate: (stop: TourStop) => void;
}) => {
  return (
    <div className={styles.stop}>
      <div className={styles.header}>
        <span>Stop {stop.order}</span>
        <TourStatusPill size="small" status={stop.status!} />
      </div>
      <div className={styles.body}>
        <div>
          <span>{ListingsHelper.getListingAddress(stop.listing.address)}</span>
          <span>
            {stop.startTime &&
              moment(stop.startTime, "HH:mm").format("hh:mm A")}{" "}
            - {stop.endTime && moment(stop.endTime, "HH:mm").format("hh:mm A")}
          </span>
        </div>
        <div>
          <span>
            {stop.listing.address.zip}, {stop.listing.address.city}
          </span>
          <IonButton
            className="aecorn-button clear"
            onClick={() => onNavigate(stop)}>
            Details
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonButton>
        </div>
      </div>
    </div>
  );
};

export default AgentTourStopCard;
