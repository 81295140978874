import { useEffect } from "react";
import { PushNotifications } from "@capacitor/push-notifications";
import { getPlatforms, useIonRouter } from "@ionic/react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "redux/user/user.selectors";
import { AuthenticationService } from "services/authenticationService";

const usePushNotificationListener = () => {
  const router = useIonRouter();
  let user = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!getPlatforms().includes("capacitor")) return;

    const handleNotification = async (notification: any) => {
      if (!user) {
        // If user is not available, it might be the initial load. Try to get the user using Auth service
        let user = await AuthenticationService.getCognitoUser();
        if (!user) {
          // If user is still not available, it means the user is not logged in. Do not proceed.
          return;
        }
      }
      const data = notification.notification.data;
      if (data.category === "report") {
        window.open(data.url, "_blank");
      }
      if (data.id) {
        if (data.category === "listings") {
          console.log("pushing to:", `/listings/${data.id}`);
          router.push(`/listings/${data.id}`);
        } else if (data.category === "tours") {
          router.push(`/tours/details/${data.id}`);
        } else if (data.category === "activities") {
          router.push(`/activities/${data.id}`);
        }
      }
    };

    PushNotifications.addListener("pushNotificationActionPerformed", handleNotification);

  }, [router]);
};

export default usePushNotificationListener;
