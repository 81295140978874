import {
  CheckboxCustomEvent,
  IonButton,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonLoading,
  IonPopover,
  IonSegment,
  IonSegmentButton,
  useIonToast,
} from "@ionic/react";
import styles from "./client-tours.module.scss";
import { fileTrayStackedOutline, funnelOutline } from "ionicons/icons";
import MediaQuery from "react-responsive";
import Header from "components/web/Header/header.component";
import { useEffect, useRef, useState } from "react";
import { Tour, TourPendingClient, TourStatus } from "API";
import { TourService } from "services/tourService";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import ActiveTourCard from "components/shared/Tours/ActiveTourCard/active-tour-card.component";
import ClientTourCard from "components/shared/Tours/ClientTourCard/client-tour-card.component";
import emitter from "services/emitterService";
import { Events } from "enums/General/Events.enum";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "redux/user/user.selectors";

const ClientTours = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [presentToast] = useIonToast();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<"upcoming" | "past">(
    "upcoming"
  );
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<TourStatus[]>([]);
  const [activeTour, setActiveTour] = useState<Tour | null | undefined>(null);
  const [showArchivedTours, setShowArchivedTours] = useState(false);
  const [tours, setTours] = useState<Tour[]>([]);
  const [invitations, setInvitations] = useState<TourPendingClient[]>([]);
  const token = useRef<string | undefined | null>();
  const subscriptions = useRef<any[]>([]);
  const tempSelectedFilters = useRef<TourStatus[]>([]);

  const handleFilterChange = (e: CheckboxCustomEvent) => {
    const value = e.detail.value as TourStatus;
    const checked = e.detail.checked;

    if (checked) {
      tempSelectedFilters.current = [...tempSelectedFilters.current, value];
    } else {
      tempSelectedFilters.current = tempSelectedFilters.current.filter(
        (f) => f !== value
      );
    }
  };

  const onApplyFilters = () => {
    setSelectedFilters(tempSelectedFilters.current);
    setIsFilterPopoverOpen(false);
    tempSelectedFilters.current = [];
  };

  const onAcceptInvitation = async (invitation: TourPendingClient) => {
    try {
      setSubmitLoading(true);
      await TourService.acceptTourInvitation(invitation.tourId, invitation.id);
      setInvitations((invitations) =>
        invitations.filter((i) => i.id !== invitation.id)
      );
      await fetchTours(false);
      await fetchActiveTour();
      presentToast({
        message: "Invitation accepted successfully",
        duration: 2000,
        cssClass: "aecorn-success-toast",
      });
      emitter.emit(Events.UPDATE_PENDING_TOURS);
    } catch (e) {
      console.error(e);
      presentToast({
        message: "Failed to accept invitation",
        duration: 2000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const onRejectInvitation = async (invitation: TourPendingClient) => {
    try {
      setSubmitLoading(true);
      await TourService.rejectTourInvitation(invitation.tourId, invitation.id);
      setInvitations((invitations) =>
        invitations.filter((i) => i.id !== invitation.id)
      );
      presentToast({
        message: "Invitation rejected successfully",
        duration: 2000,
        cssClass: "aecorn-success-toast",
      });
      emitter.emit(Events.UPDATE_PENDING_TOURS);
    } catch (e) {
      console.error(e);
      presentToast({
        message: "Failed to reject invitation",
        duration: 2000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const fetchTours = async (background = false) => {
    if (!isAuthenticated) {
      setTours([]);
      token.current = null;
      return;
    }
    try {
      if (!token.current && !background) {
        setLoading(true);
      }
      const currentDate = new Date().toISOString();
      const filter = {
        date: { [selectedTab === "upcoming" ? "ge" : "le"]: currentDate },
        ...(selectedFilters.length > 0 && {
          or: selectedFilters.map((status) => ({
            status: { eq: status },
          })),
        }),
        ...(showArchivedTours && {
          status: { eq: TourStatus.archived },
        }),
        ...(selectedFilters.length === 0 &&
          !showArchivedTours && {
            status: { ne: TourStatus.archived },
          }),
      };

      const { tours, nextToken } = await TourService.getClientTours(
        filter,
        token.current
      );

      const invitations = await TourService.getPendingTours();
      setInvitations(invitations);

      setTours(tours);
      token.current = nextToken;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchActiveTour = async () => {
    try {
      const tour = await TourService.getActiveTour();
      setActiveTour(tour);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    token.current = null;
    fetchActiveTour();
    fetchTours();
  }, [selectedTab, showArchivedTours, selectedFilters]);

  useEffect(() => {
    if (showArchivedTours && selectedFilters.length > 0) {
      setSelectedFilters([]);
    }
  }, [showArchivedTours]);

  useEffect(() => {
    tours.forEach((tour) => {
      const sub = TourService.onTourUpdate(tour.id, (data) => {
        if (data) {
          setTours((tours) => tours.map((t) => (t.id === data.id ? data : t)));
        }
      });
      subscriptions.current.push(sub);
    });
    return () => {
      subscriptions.current.forEach((sub) => sub.unsubscribe());
    };
  }, [tours]);

  const TopSection = () => (
    <div className={styles.topSection}>
      <div className={styles.top}>
        <span className={styles.title}>My Tours</span>
        <div className={styles.buttons}>
          <IonButton
            className={`aecorn-button ${
              showArchivedTours ? "clear border" : "dark"
            }`}
            onClick={() => setShowArchivedTours((prev) => !prev)}>
            <IonIcon icon={fileTrayStackedOutline} slot="icon-only" />
          </IonButton>
          <IonButton
            className={`aecorn-button ${
              selectedFilters.length ? "clear border" : "dark"
            }`}
            disabled={showArchivedTours}
            onClick={() => setIsFilterPopoverOpen(true)}>
            <IonIcon icon={funnelOutline} slot="icon-only" />
          </IonButton>
        </div>
      </div>
      {activeTour && (
        <div className={styles.activeTour}>
          <ActiveTourCard tour={activeTour} />
        </div>
      )}
      <div className={styles.filters}>
        <div className={styles.tabs}>
          <MediaQuery minWidth={768}>
            <div className={styles.title}>Other tours</div>
          </MediaQuery>
          <IonSegment
            className="aecorn-segment dark"
            mode="ios"
            value={selectedTab}
            onIonChange={(e) => setSelectedTab(e.detail.value as any)}>
            <IonSegmentButton value="upcoming">Upcoming</IonSegmentButton>
            <IonSegmentButton value="past">Past</IonSegmentButton>
          </IonSegment>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <IonLoading message="Please wait..." isOpen={submitLoading} />
      <IonHeader mode="ios" className={styles.header}>
        <MediaQuery minWidth={768}>
          <Header />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <TopSection />
        </MediaQuery>
      </IonHeader>

      <IonContent className={styles.content} scrollY>
        <IonPopover
          isOpen={isFilterPopoverOpen}
          onWillPresent={() => (tempSelectedFilters.current = selectedFilters)}
          onDidDismiss={() => setIsFilterPopoverOpen(false)}>
          <IonContent className={styles.popoverContent}>
            <div className={styles.title}>Filter by status</div>
            <IonList>
              <IonCheckbox
                className="aecorn-checkbox"
                labelPlacement="end"
                value={TourStatus.ready}
                checked={selectedFilters.includes(TourStatus.ready)}
                onIonChange={handleFilterChange}>
                Ready
              </IonCheckbox>
              <IonCheckbox
                className="aecorn-checkbox"
                labelPlacement="end"
                value={TourStatus.pending}
                checked={selectedFilters.includes(TourStatus.pending)}
                onIonChange={handleFilterChange}>
                Pending
              </IonCheckbox>
              <IonCheckbox
                className="aecorn-checkbox"
                labelPlacement="end"
                value={TourStatus.draft}
                checked={selectedFilters.includes(TourStatus.draft)}
                onIonChange={handleFilterChange}>
                Draft
              </IonCheckbox>
              <IonCheckbox
                className="aecorn-checkbox"
                labelPlacement="end"
                value={TourStatus.completed}
                checked={selectedFilters.includes(TourStatus.completed)}
                onIonChange={handleFilterChange}>
                Completed
              </IonCheckbox>
              <IonCheckbox
                className="aecorn-checkbox"
                labelPlacement="end"
                value={TourStatus.cancelled}
                checked={selectedFilters.includes(TourStatus.cancelled)}
                onIonChange={handleFilterChange}>
                Cancelled
              </IonCheckbox>
              <IonButton
                className="aecorn-button dark"
                onClick={onApplyFilters}>
                Apply
              </IonButton>
            </IonList>
          </IonContent>
        </IonPopover>
        <div className={styles.container}>
          <MediaQuery minWidth={768}>
            <TopSection />
          </MediaQuery>
          {!loading && invitations.length > 0 && (
            <div className={styles.invitations}>
              {invitations.map((invitation) => (
                <div key={invitation.id} className={styles.invitation}>
                  <div className={styles.text}>
                    You have been invited to join{" "}
                    <span>"{invitation.tourTitle || "---"}" </span>
                    tour.
                  </div>
                  <div className={styles.buttons}>
                    <IonButton
                      className="aecorn-button dark"
                      onClick={() => onAcceptInvitation(invitation)}>
                      Accept
                    </IonButton>
                    <IonButton
                      className="aecorn-button clear border"
                      onClick={() => onRejectInvitation(invitation)}>
                      Reject
                    </IonButton>
                  </div>
                </div>
              ))}
            </div>
          )}
          {loading ? (
            <LoadingScreen />
          ) : tours.length > 0 ? (
            <>
              <IonList className={styles.tours}>
                {tours.map((tour) => (
                  <ClientTourCard key={tour.id} tour={tour} />
                ))}
              </IonList>
              <IonInfiniteScroll
                disabled={!token.current}
                onIonInfinite={(ev) => {
                  fetchTours();
                  setTimeout(() => ev.target.complete(), 500);
                }}>
                <IonInfiniteScrollContent
                  loadingText="Please wait..."
                  loadingSpinner="bubbles"></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </>
          ) : (
            <div className={styles.noTours}>
              You have no {selectedTab} tours right now.
            </div>
          )}
        </div>
      </IonContent>
    </>
  );
};

export default ClientTours;
