import {
  IonContent,
  IonIcon,
  IonLabel,
  IonItem,
  IonList,
  IonPage,
  IonSkeletonText,
  IonThumbnail,
  IonSpinner,
  IonFooter,
  useIonRouter,
} from "@ionic/react";
import Header from "components/web/Header/header.component";
import Map from "components/shared/Map/Map/map.component";
import styles from "./search.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectExtraFilters,
  selectFiltersValue,
} from "redux/filters/filters.selectors";
import {
  selectMapLocations,
  selectMapRef,
  selectGoogleMapsRef,
} from "redux/map/map.selectors";
import { updateFilters } from "redux/filters/filters.actions";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import googleMapReact from "google-map-react";
import { updateMapRef, updateGoogleMapsRef } from "redux/map/map.actions";
import { Listing } from "models/listings/listing.model";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import ListingListItem from "components/shared/Listings/ListingListItem/listing-list-item.component";
import { MapService } from "services/mapService";
import SortButton from "components/web/SortButton/sort-button.component";
import { GeneralConstants, ListingsSortItems } from "utils/constants";
import { SortOptionValue } from "types/shared/SortOption.type";
import { ListingsSortOptions } from "enums/Listings/ListingsSortOptions.enum";
import SearchFilters from "components/web/SearchFilters/search-filters.component";
import ListingCard from "components/shared/Listings/ListingCard/listing-card.component";
import SkinnyFooter from "components/web/SkinnyFooter/skinny-footer.component";
import {
  selectIsSchoolsActive,
  selectSchoolsFilters,
} from "redux/schools/schools.selectors";
import { SchoolsService } from "services/schoolsService";
import { School } from "API";
import { ListingService } from "services/listingService";
import { Location, Locations } from "models/locations/locations.model";
import LocationSearchResult from "components/shared/Listings/LocationSearchResult/location-search-result.component";
import { ListingsFiltersService } from "services/listingsFiltersService";
import { API } from "aws-amplify";

const Search = (props: {
  location: {
    search?: string;
  };
}) => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const mapRef = useSelector(selectMapRef);
  const googleMapsRef = useSelector(selectGoogleMapsRef);

  const [mapReady, setMapReady] = useState(false);
  const [sortBy, setSortBy] = useState<ListingsSortOptions>(
    ListingsSortOptions.CREATED_ON
  );
  const [view, setView] = useState<"map" | "list">("map");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const [clusters, setClusters] = useState<any[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<any>();
  const [clustersLoading, setClustersLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");

  const [listingsLoading, setListingsLoading] = useState(true);
  const [listings, setListings] = useState<Listing[]>([]);
  const [locations, setLocations] = useState<Locations>({
    cities: [],
    neighborhoods: [],
  });
  const [selectedListings, setSelectedListings] = useState<Listing[]>([]);
  const [totalListingsPages, setTotalListingPages] = useState(1);
  const [listingsCollapsed, setListingsCollapsed] = useState(false);

  const [schools, setSchools] = useState<School[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<School | null>(null);
  const [toggledSchools, setToggledSchools] = useState<
    (School & { fillColor: string; strokeColor: string })[]
  >([]);
  const currentRequest = useRef<Promise<any> | null>(null);

  const filters = useSelector(selectFiltersValue);
  const extraFilters = useSelector(selectExtraFilters);
  const schoolsFilters = useSelector(selectSchoolsFilters);
  const isSchoolsActive = useSelector(selectIsSchoolsActive);
  const mapLocations = useSelector(selectMapLocations);

  const updateMapFilter = (update?: { map: any[][][] }) => {
    if (update) {
      if (update.map.flat(2).includes(undefined)) {
        return;
      }
      dispatch(updateFilters(update));
    }
  };

  const handleNavigateToListing = (listing: Listing) => {
    router.push(`/listings/${listing.mlsNumber}`);
  };

  const handleMapReady = (
    mapRef: MutableRefObject<googleMapReact>,
    mapsRef: typeof google.maps
  ) => {
    dispatch(updateMapRef(mapRef));
    dispatch(updateGoogleMapsRef(mapsRef));
    setTimeout(() => {
      setMapReady(true);
    }, 500);
  };

  const handleSort = (value: SortOptionValue) => {
    setSortBy(value as ListingsSortOptions);
  };

  const handleChangeSortOrder = () => {
    setSortOrder((order) => (order === "asc" ? "desc" : "asc"));
  };

  const handleListingsListScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current!;

    const nextPage = listings.length / 10 + 1;
    if (
      scrollTop + clientHeight >= scrollHeight - 100 &&
      nextPage <= totalListingsPages
    ) {
      fetchListings(nextPage);
    }
  };

  const handleSelectCluster = async (cluster: any) => {
    try {
      setSelectedListings([]);
      setSelectedCluster(cluster);
      const clusterListings = await MapService.getClusterListings(cluster.map);
      setSelectedListings(clusterListings);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectListings = async (listings: Listing[]) => {
    setSelectedCluster(null);
    if (listings.length === 1) {
      const listing = await ListingService.getListingByMlsNumber(
        listings[0].mlsNumber
      );
      setSelectedListings([listing]);
    } else {
      setSelectedListings(listings);
    }
  };

  const handleDeselectClusterAndListings = () => {
    setSelectedCluster(undefined);
    setSelectedListings([]);
  };

  const handleUpdateView = (view: "map" | "list") => {
    setListingsCollapsed(false);
    setView(view);
  };

  const handleSchoolToggle = (school: School) => {
    if (toggledSchools.find((s) => s.id === school.id)) {
      setToggledSchools((prev) => prev.filter((s) => s.id !== school.id));
    } else {
      const randomFillColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;
      const oppositeStrokeColor = `#${(
        0xffffff - parseInt(randomFillColor.slice(1), 16)
      ).toString(16)}`;
      setToggledSchools((prev) => [
        ...prev,
        {
          ...school,
          fillColor: randomFillColor,
          strokeColor: oppositeStrokeColor,
        },
      ]);
    }
  };

  const fetchSchools = async () => {
    try {
      const schools = (await SchoolsService.getSchools()) as School[];
      setSchools(schools);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClusters = async () => {
    try {
      setClustersLoading(true);
      const clusters = await MapService.getClusters();
      setClusters(clusters);
    } catch (error) {
      console.error(error);
    } finally {
      setClustersLoading(false);
    }
  };

  const fetchListings = async (page: number) => {
    try {
      setListingsLoading(true);
      const response = await MapService.getListings(page, sortBy, sortOrder);
      setListings((prevListings) => [...prevListings, ...response.listings]);
      const totalPages = Math.ceil(response.count / 10);
      setTotalListingPages(totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setListingsLoading(false);
    }
  };

  const handleSearch = async (term: string) => {
    const resetSearchResults = () => {
      setListings([]);
      setLocations({
        cities: [],
        neighborhoods: [],
      });
    };

    setSearchTerm(term);

    if (!term) {
      resetSearchResults();
      fetchClusters();
      return;
    }
    if (currentRequest.current) {
      API.cancel(currentRequest.current, "Cancelled");
      currentRequest.current = null;
    }

    try {
      setListingsLoading(true);

      if (term.length < 3) {
        setListings([]);
        setLocations({ cities: [], neighborhoods: [] });
      } else {
        const requestPromise = API.get(
          GeneralConstants.REST_API_NAME,
          "/listings/search",
          {
            queryStringParameters: { term, activeListingsOnly: false },
          }
        );
        currentRequest.current = requestPromise;
        const response = await requestPromise;
        setListings(response.data.listings);
        setLocations(response.data.locations);
        setListingsLoading(false);
      }
    } catch (error: any) {
      if (error.name !== 'CanceledError') {
        console.error(error);
        setListingsLoading(false);
      }
    }
  };

  const handleLocationClick = (location: Location) => {
    setSearchTerm("");
    mapRef?.setCenter({
      lat: location.location?.lat,
      lng: location.location?.lng,
    });
  };

  useEffect(() => {
    if (mapReady && filters.map) {
      fetchClusters();
    }
  }, [filters, extraFilters, schoolsFilters.showListingsWithin, mapReady]);

  useEffect(() => {
    if (mapReady && isSchoolsActive) {
      fetchSchools();
    }
  }, [isSchoolsActive, schoolsFilters, filters.map, mapReady]);

  useEffect(() => {
    if (mapReady && !searchTerm) {
      setTotalListingPages(1);
      setListings([]);
      fetchListings(1);
    }
  }, [clusters]);

  useEffect(() => {
    if (mapReady) {
      setListings([]);
      fetchListings(1);
    }
  }, [sortBy, sortOrder]);

  useEffect(() => {
    if (mapReady) {
      const params: any[] = new URLSearchParams(
        props.location.search
      ).entries() as unknown as any[];

      const validParams = [
        "type",
        "propertyType",
        "minPrice",
        "maxPrice",
        "minBeds",
        "maxBeds",
        "area",
        "city",
        "zoom",
      ];

      const filtersToUpdate: {
        [key: string]: string | number | undefined | string[];
      } = {};
      let lat: number | undefined;
      let lng: number | undefined;
      let zoom: number | undefined;

      params.forEach(([key, value]: [string, string]) => {
        if (validParams.includes(key) && key !== "zoom") {
          filtersToUpdate[key] =
            key === "propertyType"
              ? value.split(",").map((type) => type.trim())
              : key === "city" ? [value] : value;
        }

        if (key === "lat") {
          lat = parseFloat(value);
        }
        if (key === "lng") {
          lng = parseFloat(value);
        }
        if (key === "zoom") {
          zoom = parseInt(value);
        }
      });

      if (Object.keys(filtersToUpdate).length) {
        ListingsFiltersService.reset();
        ListingsFiltersService.updateMultiple(filtersToUpdate);
      }

      if (lat && lng) {
        mapRef?.setCenter({ lat, lng });
        setTimeout(() => {
          (googleMapsRef as typeof google.maps)?.event.trigger(
            mapRef,
            "center_changed"
          );
        }, 1000);
      }
      if (zoom) {
        mapRef?.setZoom(zoom);
        setTimeout(() => {
          (googleMapsRef as typeof google.maps)?.event.trigger(
            mapRef,
            "center_changed"
          );
        }, 1000);
      }

      if (Object.keys(filtersToUpdate).length || lat || lng) {
        window.history.replaceState({}, "", "/listings");
      }
    }
  }, [props.location.search, mapReady]);

  return (
    <IonPage>
      <IonContent className={styles.content}>
        <div className={styles.container}>
          <Header type="search" />
          <SearchFilters
            view={view}
            updateView={handleUpdateView}
            searchTerm={searchTerm}
            onSearch={handleSearch}
          />
          <div
            className={`${styles.listingsMap} ${listingsCollapsed && styles.listingsCollapsed
              }`}>
            <div
              ref={scrollRef}
              onScroll={handleListingsListScroll}
              className={`${styles.listings} ${view === "list" && styles.fullSizeListings
                }`}>
              <div className={styles.listingsHeader}>
                <div className={styles.titleSort}>
                  <h3>{searchTerm ? "Search results" : "Listings"}</h3>
                  {!searchTerm && (
                    <SortButton
                      value={sortBy}
                      order={sortOrder}
                      items={ListingsSortItems}
                      onSelect={handleSort}
                      onChangeOrder={handleChangeSortOrder}
                    />
                  )}
                </div>
                <div className={styles.collapseExpandButton}>
                  <button
                    onClick={() =>
                      setListingsCollapsed((collapsed) => !collapsed)
                    }>
                    <IonIcon
                      icon={
                        listingsCollapsed
                          ? chevronForwardOutline
                          : chevronBackOutline
                      }
                    />
                  </button>
                </div>
              </div>

              <div className={styles.listingsList}>
                {listingsLoading &&
                  !listings.length &&
                  !locations.cities.length &&
                  !locations.neighborhoods.length ? (
                  <div className={styles.loading}>
                    <IonList>
                      {Array.from({ length: 10 }).map((_, i) => (
                        <IonItem key={i} lines="none">
                          <IonThumbnail slot="start">
                            <IonSkeletonText animated />
                          </IonThumbnail>
                          <IonLabel>
                            <h3>
                              <IonSkeletonText
                                animated={true}
                                style={{ width: "80%" }}></IonSkeletonText>
                            </h3>
                            <p>
                              <IonSkeletonText
                                animated={true}
                                style={{ width: "60%" }}></IonSkeletonText>
                            </p>
                            <p>
                              <IonSkeletonText
                                animated={true}
                                style={{ width: "30%" }}></IonSkeletonText>
                            </p>
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  </div>
                ) : searchTerm ? (
                  !listings.length &&
                    !locations.cities.length &&
                    !locations.neighborhoods.length ? (
                    <div className={styles.noListings}>
                      Try typing the location, finding the property on map, or
                      use filters to get desired results.
                    </div>
                  ) : (
                    <>
                      {locations.cities.map((location, index) => (
                        <LocationSearchResult
                          term={searchTerm}
                          key={index}
                          location={location}
                          type="city"
                          onClick={handleLocationClick}
                        />
                      ))}
                      {locations.neighborhoods.map((location, index) => (
                        <LocationSearchResult
                          term={searchTerm}
                          key={index}
                          location={location}
                          type="neighborhood"
                          onClick={handleLocationClick}
                        />
                      ))}
                      {listings.map((listing, i) =>
                        view === "list" ? (
                          <ListingCard key={i} listing={listing} />
                        ) : (
                          <ListingListItem
                            key={i}
                            listing={listing}
                            onClick={handleNavigateToListing}
                          />
                        )
                      )}
                    </>
                  )
                ) : listings.length > 0 ? (
                  listings.map((listing, i) =>
                    view === "list" ? (
                      <ListingCard key={i} listing={listing} />
                    ) : (
                      <ListingListItem
                        key={i}
                        listing={listing}
                        onClick={handleNavigateToListing}
                      />
                    )
                  )
                ) : (
                  <div className={styles.noListings}>
                    Uh oh! There are no matching results for your current
                    search. Try zooming out or adjusting your filters.
                  </div>
                )}
                {listingsLoading && listings.length > 0 && (
                  <IonSpinner name="bubbles" />
                )}
              </div>
            </div>
            <div className={styles.map}>
              {mapReady && !clustersLoading && !clusters.length && (
                <div className={styles.noClusters}>
                  No listings found in the current map area.
                </div>
              )}
              <Map
                loading={clustersLoading}
                filters={filters}
                extraFilters={extraFilters}
                mapLocations={mapLocations}
                updateBoundaryFilter={updateMapFilter}
                clusters={clusters}
                selectedCluster={selectedCluster}
                onSelectCluster={handleSelectCluster}
                selectedListings={selectedListings}
                onSelectListings={handleSelectListings}
                onDeselectClusterAndListings={handleDeselectClusterAndListings}
                schools={schools}
                selectedSchool={selectedSchool}
                onSelectSchool={setSelectedSchool}
                toggledSchools={toggledSchools}
                onToggleSchool={(school: School) => handleSchoolToggle(school)}
                onClearSchoolsBoundaries={() => setToggledSchools([])}
                view={view}
                setView={setView}
                onMapReady={handleMapReady}
              />
            </div>
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <SkinnyFooter />
      </IonFooter>
    </IonPage>
  );
};

export default Search;
