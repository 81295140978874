import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectCurrentUsername = createSelector(
  [selectUser],
  (user) => user.currentUser?.username
);

export const selectUserState = createSelector(
  [selectUser],
  (user) => user.state
);

export const selectUserError = createSelector(
  [selectUser],
  (user) => user.error
);

export const selectGuest = createSelector([selectUser], (user) => user.guest);

export const selectIsClient = createSelector(
  [selectUser],
  (user) => user.isClient
);

export const selectIsAgent = createSelector(
  [selectUser],
  (user) => user.isAgent
);

export const selectIsAdmin = createSelector(
  [selectUser],
  (user) => user.isAdmin
);

export const selectIsAuthenticated = createSelector(
  [selectUser],
  (user) => {
    return user.currentUser !== null;
  }
);