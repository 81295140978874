import { API } from "aws-amplify";
import { createReport } from "graphql/mutations";
import { Listing } from "models/listings/listing.model";
import { GeneralConstants } from "utils/constants";
import { ListingsHelper } from "utils/ListingsHelper";

const checkReportAvailability = async (mlsNumber: string) => {
    const response = await API.get(GeneralConstants.REST_API_NAME, `/reports/check/${mlsNumber}`, {});

    return response.data;
};

const generateReport = async (listing: Listing) => {
    const agentEmail = "sussan.shahi@landlogic.ai";

    const response = await API.post(GeneralConstants.REST_API_NAME, "/reports/generate", {
        body: {
            mlsNumber: listing.mlsNumber,
            emailAddress: agentEmail
        }
    });

    const reportId = response.data.reportId;

    await API.graphql({
        query: createReport, variables: {
            input: {
                reportId,
                mlsNumber: listing.mlsNumber,
                listingAddress: ListingsHelper.getListingAddress(listing.address),
            }
        }
    });

    return reportId;
}

export const ReportsService = {
    checkReportAvailability,
    generateReport
}