import { IonButton, IonDatetime, IonIcon } from "@ionic/react";
import styles from "../modals.module.scss";
import Modal from "components/ui/modal/modal.component";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { closeOutline } from "ionicons/icons";

const TourTimeModal = ({
  title,
  isOpen,
  onDismiss,
  selectedTime,
  onUpdateTime,
  onSave,
}: {
  title: string;
  isOpen: boolean;
  onDismiss: () => void;
  selectedTime: string;
  onUpdateTime: (time: string) => void;
  onSave: () => void;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Modal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      breakpoints={isMobile ? [0, 1] : undefined}
      initialBreakpoint={isMobile ? 1 : undefined}>
      <div className={styles.sheetContent}>
        <div className={styles.modalHeader}>
          {title}
          <MediaQuery minWidth={768}>
            <IonIcon icon={closeOutline} onClick={onDismiss} />
          </MediaQuery>
        </div>
        <IonDatetime
          size="cover"
          color="dark"
          className="aecorn-datetime"
          presentation="time"
          minuteValues={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
          value={selectedTime}
          onIonChange={(ev) => {
            onUpdateTime(ev.detail.value! as string);
          }}
        />
        <IonButton className="aecorn-button" onClick={onSave}>
          Save changes
        </IonButton>
      </div>
    </Modal>
  );
};

export default TourTimeModal;
