import { IonPage, IonContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import AppHeader from "../../components/Header/header.component";
import styles from "./privacy-policy.module.scss";

const AppPrivacyPolicy = ({ modal = false, close }) => {
  return (
    <IonPage>
      <AppHeader
        title="Privacy Policy"
        hasRightButton={modal}
        isModal={modal}
        onClick={close}
      />
      <IonContent>
        <IonGrid className={styles.privacy}>
          <IonRow>
            <IonCol className={styles.header}>Privacy Policy</IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.subHeader}>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.subHeader}>
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.title}>
              Interpretation and Definitions
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.label}>Interpretation</IonCol>
          </IonRow>
          <IonRow className={styles.borderBottom}>
            <IonCol>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.label}>Definitions</IonCol>
          </IonRow>
          <IonRow className={styles.borderBottom}>
            <IonCol>
              <div>For the purposes of this Privacy Policy:</div>
              <p>
                <span className={styles.bold}>Account</span> means a unique
                account created for You to access our Service or parts of our
                Service.
              </p>
              <p>
                <span className={styles.bold}>Affiliate</span> means an entity
                that controls, is controlled by or is under common control with
                a party, where "control" means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </p>
              <p>
                <span className={styles.bold}>Application</span> means the
                software program provided by the Company downloaded by You on
                any electronic device, named AECORN Realty Inc.
              </p>
              <p>
                <span className={styles.bold}>Company</span> (referred to as
                either "the Company", "We", "Us" or "Our" in this Agreement)
                refers to AECORN Realty Inc. 120 East Beaver Creek Rd. Suite 200, Richmond Hill, ON L4B 4V1.
              </p>
              <p>
                <span className={styles.bold}>Cookies</span> are small files
                that are placed on Your computer, mobile device or any other
                device by a website, containing the details of Your browsing
                history on that website among its many uses.
              </p>
              <p>
                <span className={styles.bold}>Country</span> refers to: Ontario,
                Canada
              </p>
              <p>
                <span className={styles.bold}>Device</span> means any device
                that can access the Service such as a computer, a cellphone or a
                digital tablet.
              </p>
              <p>
                <span className={styles.bold}>Personal Data</span> is any
                information that relates to an identified or identifiable
                individual.
              </p>
              <p>
                <span className={styles.bold}>Service</span> refers to the
                Application or the Website or both.
              </p>
              <p>
                <span className={styles.bold}>Service Provider </span> means any
                natural or legal person who processes the data on behalf of the
                Company. It refers to third-party companies or individuals
                employed by the Company to facilitate the Service, to provide
                the Service on behalf of the Company, to perform services
                related to the Service or to assist the Company in analyzing how
                the Service is used.
              </p>
              <p>
                <span className={styles.bold}>
                  Third-party Social Media Service
                </span>{" "}
                refers to any website or any social network website through
                which a User can log in or create an account to use the Service.
              </p>
              <p>
                <span className={styles.bold}>Usage Data</span> refers to data
                collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example,
                the duration of a page visit).
              </p>
              <p>
                <span className={styles.bold}>Website</span> refers to aecorn.ca
                accessible from{" "}
                <a href="https://aecorn.ca" target="_blank" rel="noreferrer">
                  https://aecorn.ca{" "}
                </a>
              </p>

              <p>
                <span className={styles.bold}>You</span> means the individual
                accessing or using the Service, or the company, or other legal
                entity on behalf of which such individual is accessing or using
                the Service, as applicable.
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.title}>
              Collecting and Using Your Personal Data
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.label}>Types of Data Collected</IonCol>
          </IonRow>
          <IonRow className={styles.borderBottom}>
            <IonCol>
              <div>
                <div className={styles.bold}>Personal Data</div>
                <div>
                  While using Our Service, We may ask You to provide Us with
                  certain personally identifiable information that can be used
                  to contact or identify You. Personally identifiable
                  information may include, but is not limited to:
                  <ul>
                    <div>Email address</div>
                    <div>First name and last name</div>
                    <div>Phone numbe</div>
                    <div>Usage Data</div>
                  </ul>
                </div>
              </div>
              <br />
              <div>
                <div className={styles.bold}>Usage Data</div>
                <p>
                  Usage Data is collected automatically when using the Service.
                </p>
                <p>
                  Usage Data may include information such as Your Device's
                  Internet Protocol address (e.g. IP address), browser type,
                  browser version, the pages of our Service that You visit, the
                  time and date of Your visit, the time spent on those pages,
                  unique device identifiers and other diagnostic data.
                </p>
                <p>
                  When You access the Service by or through a mobile device, We
                  may collect certain information automatically, including, but
                  not limited to, the type of mobile device You use, Your mobile
                  device unique ID, the IP address of Your mobile device, Your
                  mobile operating system, the type of mobile Internet browser
                  You use, unique device identifiers and other diagnostic data.
                </p>
                <p>
                  We may also collect information that Your browser sends
                  whenever You visit our Service or when You access the Service
                  by or through a mobile device.
                </p>
              </div>
              <br />
              <div>
                <div className={styles.bold}>
                  Information from Third-Party Social Media Services
                </div>
                <p>
                  The Company allows You to create an account and log in to use
                  the Service through the following Third-party Social Media
                  Services:
                </p>
                <ul>
                  <li>Google</li>
                  <li>Facebook</li>
                  <li>Twitter</li>
                </ul>
                <p>
                  If You decide to register through or otherwise grant us access
                  to a Third-Party Social Media Service, We may collect Personal
                  data that is already associated with Your Third-Party Social
                  Media Service's account, such as Your name, Your email
                  address, Your activities or Your contact list associated with
                  that account.
                </p>
                <p>
                  You may also have the option of sharing additional information
                  with the Company through Your Third-Party Social Media
                  Service's account. If You choose to provide such information
                  and Personal Data, during registration or otherwise, You are
                  giving the Company permission to use, share, and store it in a
                  manner consistent with this Privacy Policy.
                </p>
              </div>
              <br />

              <div>
                <div className={styles.bold}>
                  Information Collected while Using the Application
                </div>
                <p>
                  While using Our Application, in order to provide features of
                  Our Application, We may collect, with Your prior permission:
                </p>
                <ul>
                  <div>Information regarding your location</div>
                  <div>
                    Pictures and other information from your Device's camera and
                    photo library
                  </div>
                </ul>
                <p>
                  We use this information to provide features of Our Service, to
                  improve and customize Our Service. The information may be
                  uploaded to the Company's servers and/or a Service Provider's
                  server or it may be simply stored on Your device.
                </p>
                <p>
                  You can enable or disable access to this information at any
                  time, through Your Device settings.
                </p>
              </div>
              <br />

              <div>
                <div className={styles.bold}>
                  Tracking Technologies and Cookies
                </div>
                <p>
                  We use Cookies and similar tracking technologies to track the
                  activity on Our Service and store certain information.
                  Tracking technologies used are beacons, tags, and scripts to
                  collect and track information and to improve and analyze Our
                  Service. The technologies We use may include:
                </p>
                <ul>
                  <li>
                    <span className={styles.bold}>
                      Cookies or Browser Cookies.
                    </span>{" "}
                    A cookie is a small file placed on Your Device. You can
                    instruct Your browser to refuse all Cookies or to indicate
                    when a Cookie is being sent. However, if You do not accept
                    Cookies, You may not be able to use some parts of our
                    Service. Unless you have adjusted Your browser setting so
                    that it will refuse Cookies, our Service may use Cookies.
                  </li>
                  <li>
                    <span className={styles.bold}>Flash Cookies.</span> Certain
                    features of our Service may use local stored objects (or
                    Flash Cookies) to collect and store information about Your
                    preferences or Your activity on our Service. Flash Cookies
                    are not managed by the same browser settings as those used
                    for Browser Cookies. For more information on how You can
                    delete Flash Cookies, please read "Where can I change the
                    settings for disabling, or deleting local shared objects?"
                    available at{" "}
                    <a
                      href="   https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                      target="_blank"
                      rel="noreferrer">
                      https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                    </a>
                  </li>
                  <li>
                    <span className={styles.bold}> Web Beacons.</span> Certain
                    sections of our Service and our emails may contain small
                    electronic files known as web beacons (also referred to as
                    clear gifs, pixel tags, and single-pixel gifs) that permit
                    the Company, for example, to count users who have visited
                    those pages or opened an email and for other related website
                    statistics (for example, recording the popularity of a
                    certain section and verifying system and server integrity).
                  </li>
                </ul>
                {/* <p>
                  You can instruct Your browser to refuse all Cookies or to
                  indicate when a Cookie is being sent. However, if You do not
                  accept Cookies, You may not be able to use some parts of our
                  Service.
                </p> */}

                <p>
                  Cookies can be "Persistent" or "Session" Cookies. Persistent
                  Cookies remain on your personal computer or mobile device when
                  You go offline, while Session Cookies are deleted as soon as
                  You close your web browser. Learn more about cookies: All
                  About Cookies.
                </p>
                <p>
                  We use both session and persistent Cookies for the purposes
                  set out below:
                </p>
                <div>
                  <div className={styles.bold}>
                    Necessary / Essential Cookies
                  </div>
                  <div>Type: Session Cookies</div>
                  <div>Administered by: Us</div>

                  <div>
                    Purpose: These Cookies are essential to provide You with
                    services available through the Website and to enable You to
                    use some of its features. They help to authenticate users
                    and prevent fraudulent use of user accounts. Without these
                    Cookies, the services that You have asked for cannot be
                    provided, and We only use these Cookies to provide You with
                    those services.
                  </div>
                </div>
                <br />
                <div>
                  <div className={styles.bold}>
                    Cookies Policy / Notice Acceptance Cookies
                  </div>
                  <div>Type: Persistent Cookies</div>
                  <div>Administered by: Us</div>
                  <div>
                    Purpose: These Cookies identify if users have accepted the
                    use of cookies on the Website.
                  </div>
                </div>
                <br />
                <div>
                  <div className={styles.bold}>Functionality Cookies</div>
                  <div>Type: Persistent Cookiess</div>
                  <div>Administered by: Us</div>
                  <div>
                    Purpose: These Cookies allow us to remember choices You make
                    when You use the Website, such as remembering your login
                    details or language preference. The purpose of these Cookies
                    is to provide You with a more personal experience and to
                    avoid You having to re-enter your preferences every time You
                    use the Website.
                  </div>
                </div>
                <br />
                <div>
                  <div className={styles.bold}>
                    Tracking and Performance Cookies
                  </div>
                  <div>Type: Persistent Cookies</div>
                  <div>Administered by: Third-Parties</div>
                  <div>
                    Purpose: These Cookies are used to track information about
                    traffic to the Website and how users use the Website. The
                    information gathered via these Cookies may directly or
                    indirectly identify you as an individual visitor. This is
                    because the information collected is typically linked to a
                    pseudonymous identifier associated with the device you use
                    to access the Website. We may also use these Cookies to test
                    new pages, features or new functionality of the Website to
                    see how our users react to them.
                  </div>
                </div>
                <br />
                <p>
                  For more information about the cookies we use and your choices
                  regarding cookies, please visit our Cookies Policy or the
                  Cookies section of our Privacy Policy.
                </p>
                <br />

                <div>
                  <div className={styles.bold}>Use of Your Personal Data</div>
                  <p>
                    The Company may use Personal Data for the following
                    purposes:
                  </p>
                  <ul>
                    <li>
                      <span className={styles.bold}>
                        To provide and maintain our Service,
                      </span>{" "}
                      including to monitor the usage of our Service.
                    </li>
                    <li>
                      <span className={styles.bold}>
                        To manage Your Account:
                      </span>{" "}
                      to manage Your registration as a user of the Service. The
                      Personal Data You provide can give You access to different
                      functionalities of the Service that are available to You
                      as a registered user.
                    </li>
                    <li>
                      <span className={styles.bold}>
                        For the performance of a contract:
                      </span>{" "}
                      the development, compliance and undertaking of the
                      purchase contract for the products, items or services You
                      have purchased or of any other contract with Us through
                      the Service.
                    </li>
                    <li>
                      <span className={styles.bold}>To contact You:</span> To
                      contact You by email, telephone calls, SMS, or other
                      equivalent forms of electronic communication, such as a
                      mobile application's push notifications regarding updates
                      or informative communications related to the
                      functionalities, products or contracted services,
                      including the security updates, when necessary or
                      reasonable for their implementation.
                    </li>
                    <li>
                      <span className={styles.bold}>To provide You</span> with
                      news, special offers and general information about other
                      goods, services and events which we offer that are similar
                      to those that you have already purchased or enquired about
                      unless You have opted not to receive such information.
                    </li>
                    <li>
                      <span className={styles.bold}>
                        To manage Your requests:{" "}
                      </span>{" "}
                      To attend and manage Your requests to Us.
                    </li>
                    <li>
                      <span className={styles.bold}>
                        For business transfers:
                      </span>{" "}
                      We may use Your information to evaluate or conduct a
                      merger, divestiture, restructuring, reorganization,
                      dissolution, or other sale or transfer of some or all of
                      Our assets, whether as a going concern or as part of
                      bankruptcy, liquidation, or similar proceeding, in which
                      Personal Data held by Us about our Service users is among
                      the assets transferred.
                    </li>
                    <li>
                      <span className={styles.bold}>For other purposes:</span>{" "}
                      We may use Your information for other purposes, such as
                      data analysis, identifying usage trends, determining the
                      effectiveness of our promotional campaigns and to evaluate
                      and improve our Service, products, services, marketing and
                      your experience.
                    </li>
                  </ul>
                </div>

                <p>
                  We may share your personal information in the following
                  situations:
                </p>
                <div>
                  <ul>
                    <li>
                      <span className={styles.bold}>
                        With Service Providers:
                      </span>{" "}
                      We may share Your personal information with Service
                      Providers to monitor and analyze the use of our Service,
                      to contact You.
                    </li>
                    <li>
                      <span className={styles.bold}>
                        For Business transfers:
                      </span>{" "}
                      We may share or transfer Your personal information in
                      connection with, or during negotiations of, any merger,
                      sale of Company assets, financing, or acquisition of all
                      or a portion of our business to another company.
                    </li>
                    <li>
                      <span className={styles.bold}>With Affiliates:</span> We
                      may share Your information with Our affiliates, in which
                      case we will require those affiliates to honor this
                      Privacy Policy. Affiliates include Our parent company and
                      any other subsidiaries, joint venture partners or other
                      companies that We control or that are under common control
                      with Us.
                    </li>
                    <li>
                      <span className={styles.bold}>
                        With Business partners:
                      </span>{" "}
                      We may share Your information with Our business partners
                      to offer You certain products, services or promotions.
                    </li>
                    <li>
                      <span className={styles.bold}>With other users:</span>{" "}
                      when You share personal information or otherwise interact
                      in the public areas with other users, such information may
                      be viewed by all users and may be publicly distributed
                      outside. If You interact with other users or register
                      through a Third-Party Social Media Service, Your contacts
                      on the Third-Party Social Media Service may see Your name,
                      profile, pictures and description of Your activity.
                      Similarly, other users will be able to view descriptions
                      of Your activity, communicate with You and view Your
                      profile.
                    </li>
                    <li>
                      <span className={styles.bold}>With Your consent:</span> We
                      may disclose Your personal information for any other
                      purpose with Your consent.
                    </li>
                  </ul>
                </div>
                <br />
                <div>
                  <div className={styles.bold}>
                    Retention of Your Personal Data
                  </div>
                  <p>
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                  </p>
                  <p>
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                  </p>
                </div>
                <br />
                <div>
                  <div className={styles.bold}>
                    Transfer of Your Personal Data
                  </div>
                  <p>
                    Your information, including Personal Data, is processed at
                    the Company's operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.
                  </p>
                  <p>
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                  </p>
                  <p>
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.
                  </p>
                </div>
                <br />
                <div>
                  <div className={styles.bold}>
                    Disclosure of Your Personal Data
                  </div>
                  <br />
                  <div className={styles.bold}>Business Transactions</div>
                  <p>
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                  </p>

                  <br />
                  <div className={styles.bold}>Law enforcement</div>
                  <p>
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                  </p>

                  <br />
                  <div className={styles.bold}>Other legal requirements</div>
                  <p>
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                  </p>
                  <ul>
                    <li>Comply with a legal obligation</li>
                    <li>
                      Protect and defend the rights or property of the Company
                    </li>
                    <li>
                      Prevent or investigate possible wrongdoing in connection
                      with the Service
                    </li>
                    <li>
                      Protect the personal safety of Users of the Service or the
                      public
                    </li>
                    <li>Protect against legal liability</li>
                  </ul>

                  <br />
                  <div className={styles.bold}>
                    Security of Your Personal Data
                  </div>
                  <p>
                    The security of Your Personal Data is important to Us, but
                    remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While We
                    strive to use commercially acceptable means to protect Your
                    Personal Data, We cannot guarantee its absolute security.
                  </p>
                </div>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.title}>
              Detailed Information on the Processing of Your Personal Data
            </IonCol>
          </IonRow>
          <IonRow className={styles.borderBottom}>
            <IonCol>
              <p>
                The Service Providers We use may have access to Your Personal
                Data. These third-party vendors collect, store, use, process and
                transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.
              </p>
              <div>
                <div className={styles.bold}>Analytics</div>
                <p>
                  We may use third-party Service providers to monitor and
                  analyze the use of our Service.
                </p>
                <ul>
                  <div className={styles.bold}>Google Analytics</div>
                  <div>
                    Google Analytics is a web analytics service offered by
                    Google that tracks and reports website traffic. Google uses
                    the data collected to track and monitor the use of our
                    Service. This data is shared with other Google services.
                    Google may use the collected data to contextualize and
                    personalize the ads of its own advertising network. You can
                    opt-out of having made your activity on the Service
                    available to Google Analytics by installing the Google
                    Analytics opt-out browser add-on. The add-on prevents the
                    Google Analytics JavaScript (ga.js, analytics.js and dc.js)
                    from sharing information with Google Analytics about visits
                    activity. You may opt-out of certain Google Analytics
                    features through your mobile device settings, such as your
                    device advertising settings or by following the instructions
                    provided by Google in their Privacy Policy:
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer">
                      https://policies.google.com/privacy{" "}
                    </a>
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy & Terms web page:
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer">
                      https://policies.google.com/privacy
                    </a>
                  </div>
                </ul>
              </div>
              <br />
              <div>
                <div className={styles.bold}>Email Marketing</div>
                <p>
                  We may use Your Personal Data to contact You with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to You. You may opt-out of receiving any,
                  or all, of these communications from Us by following the
                  unsubscribe link or instructions provided in any email We send
                  or by contacting Us.
                </p>
                <p>
                  We may use Email Marketing Service Providers to manage and
                  send emails to You.
                </p>
                <ul>
                  <div className={styles.bold}>sendgrid</div>
                  <div>
                    Their Privacy Policy can be viewed at{" "}
                    <a href="https://www.twilio.com/legal/privacy">
                      https://www.twilio.com/legal/privacy
                    </a>
                  </div>
                </ul>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.title}>Children's Privacy</IonCol>
          </IonRow>
          <IonRow className={styles.borderBottom}>
            <IonCol>
              <p>
                Our Service does not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 13. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal
                Data, please contact Us. If We become aware that We have
                collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that
                information from Our servers.
              </p>
              <p>
                If We need to rely on consent as a legal basis for processing
                Your information and Your country requires consent from a
                parent, We may require Your parent's consent before We collect
                and use that information.
              </p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.title}>Links to Other Websites</IonCol>
          </IonRow>
          <IonRow className={styles.borderBottom}>
            <IonCol>
              <p>
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party's site. We strongly advise You to
                review the Privacy Policy of every site You visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className={styles.title}>
              Changes to this Privacy Policy
            </IonCol>
          </IonRow>
          <IonRow className={styles.borderBottom}>
            <IonCol>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className={styles.title}>Contact Us</IonCol>
          </IonRow>
          <IonRow className={styles.borderBottom}>
            <IonCol>
              <p>
                If you have any questions about this Privacy Policy, You can
                contact us:
              </p>
              <ul>
                <div>By email: info@aecorn.ca</div>
                <div>
                  By visiting this page on our website:{" "}
                  <a href="https://www.aecorn.ca/contact">
                    https://www.aecorn.ca/contact
                  </a>
                </div>
                <div>
                  By mail: 120 East Beaver Creek Rd. Suite 200, Richmond Hill, ON L4B 4V1
                </div>
              </ul>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AppPrivacyPolicy;
