import { TourItem, TourItemMedia, TourItemMediaType } from "API";
import styles from "../tour-stop-uploads.module.scss";
import { TourStopMessage, TourUserData } from "../tour-stop-uploads.component";
import {
  createGesture,
  Gesture,
  IonAvatar,
  IonContent,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonProgressBar,
  useIonPopover,
  useIonToast,
} from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Storage } from "aws-amplify";
import { useSelector } from "react-redux";
import DefaultProfile from "assets/svg/Avatar.svg";
import moment from "moment";
import {
  camera,
  close,
  closeCircle,
  ellipsisVerticalOutline,
  fileTray,
  mic,
  pause,
  play,
  send,
  trashOutline,
} from "ionicons/icons";
import { TourItemMediaService } from "services/tourItemMediaService";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { MediaService } from "services/mediaService";
import { Photo } from "@capacitor/camera";
import { CapacitorException } from "@capacitor/core";
import { selectCurrentUsername } from "redux/user/user.selectors";
import { VoiceRecorder } from "capacitor-voice-recorder";
import DEFAULT_IMAGE from "assets/img/Image not found.png";

const TourStopUploadsMessages = ({
  tourItem,
  usersData,
}: {
  tourItem: TourItem;
  usersData: TourUserData[];
}) => {
  const Popover = ({
    dismiss,
    onDelete,
  }: {
    dismiss: () => void;
    onDelete: () => void;
  }) => (
    <IonContent className={styles.popover}>
      <IonItem button detailIcon={trashOutline} onClick={onDelete}>
        Delete
      </IonItem>
    </IonContent>
  );

  const currentUserId = useSelector(selectCurrentUsername);
  const [presentToast] = useIonToast();

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<TourStopMessage[]>([]);
  const [text, setText] = useState("");
  const [photoToUpload, setPhotoToUpload] = useState<Photo>();
  const [fullScreenEnabled, setFullScreenEnabled] = useState(false);
  const [showRecordingTimer, setShowRecordingTimer] = useState(false);
  const [recordingLength, setRecordingLength] = useState(0);

  const selectedPhotoIndex = useRef<number>(0);
  const messagesRef = useRef<HTMLDivElement>(null);
  const subscriptionsRef = useRef<any[]>([]);
  const selectedMessageRef = useRef<TourStopMessage | undefined>();
  const recordingTimerRef = useRef<NodeJS.Timeout>();
  const recordButtonRef = useRef<HTMLDivElement>(null);

  const isRecording = useRef(false);
  const [playingRecording, setPlayingRecording] = useState<{
    id: string;
    duration: number;
    elapsed: number;
    audio: HTMLAudioElement;
  } | null>(null);

  const handleStart = useCallback(() => {
    Haptics.impact({ style: ImpactStyle.Heavy });
    if (isRecording.current) {
      stopRecording();
      return;
    }
    startRecording();
  }, []);

  const handleEnd = useCallback(() => {
    Haptics.impact({ style: ImpactStyle.Heavy });
    stopRecording();
  }, []);

  const useLongPressGesture = (
    elRef: React.RefObject<HTMLElement>,
    onStart: () => void,
    onEnd: () => void
  ) => {
    useEffect(() => {
      if (!elRef.current) return;

      const gesture = createGesture({
        gestureName: "longpress",
        el: elRef.current,
        threshold: 0,
        onStart,
        onEnd,
      });
      gesture.enable();
      return () => gesture.destroy();
    }, [elRef, onStart, onEnd]);
  };

  useLongPressGesture(recordButtonRef, handleStart, handleEnd);

  const [presentPopover, dismissPopover] = useIonPopover(Popover, {
    dismiss: () => dismissPopover(),
    onDelete: async () => {
      try {
        setLoading(true);
        Haptics.impact({ style: ImpactStyle.Light });
        if (selectedMessageRef.current) {
          const message = selectedMessageRef.current;

          if (message) {
            if (playingRecording && playingRecording.id === message.id) {
              playingRecording.audio.pause();
            }
            setMessages((prevMessages) =>
              prevMessages.filter(
                (msg) => msg.id !== selectedMessageRef.current?.id
              )
            );
            TourItemMediaService.deleteMediaByTourItemIdAndMediaId(tourItem.id, message.id);
          }
        }
      } catch (error) {
        console.error(error);
        presentToast({
          message: "Failed to delete message",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
      } finally {
        setLoading(false);
        dismissPopover();
      }
    },
  });

  const onOpenPopover = async (message: TourStopMessage, event: any) => {
    event.stopPropagation();
    selectedMessageRef.current = message;

    const popover = presentPopover({
      event,
      cssClass: "aecorn-popover",
      onDidDismiss: () => {
        selectedMessageRef.current = undefined;
      },
    });

    return popover;
  };

  const startRecording = async () => {
    if (isRecording.current) {
      return;
    }

    try {
      const canRecordAudio = await VoiceRecorder.canDeviceVoiceRecord();
      if (!canRecordAudio.value) {
        presentToast({
          message: "This device does not support audio recording",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
        return;
      }
      const hasPermission = await VoiceRecorder.hasAudioRecordingPermission();
      if (!hasPermission.value) {
        await VoiceRecorder.requestAudioRecordingPermission();
      }
      isRecording.current = true;
      await VoiceRecorder.startRecording();
      setShowRecordingTimer(true);
      recordingTimerRef.current = setInterval(
        () => setRecordingLength((prev) => prev + 1),
        1000
      );
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while starting the recording",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const stopRecording = async () => {
    if (!isRecording.current) {
      return;
    }

    try {
      isRecording.current = false;
      clearInterval(recordingTimerRef.current);
      setShowRecordingTimer(false);
      setRecordingLength(0);
      const audio = await VoiceRecorder.stopRecording();
      if (audio) {
        await TourItemMediaService.addRecording(
          tourItem.id,
          audio.value.recordDataBase64
        );
      }
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while stopping the recording",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const onSend = async () => {
    try {
      setLoading(true);
      Haptics.impact({ style: ImpactStyle.Light });
      if (photoToUpload) {
        await TourItemMediaService.addPhoto(tourItem.id, photoToUpload, text);
        return;
      }

      if (text) {
        await TourItemMediaService.addNote(tourItem.id, text);
      }
    } catch (error) {
      console.error(error);
      presentToast({
        message: "Failed to send message",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setText("");
      setLoading(false);
    }
  };

  const onAddPhoto = async () => {
    try {
      const newPhoto = await MediaService.takePhoto();
      if (newPhoto) {
        setPhotoToUpload(newPhoto);
      }
    } catch (error) {
      if (error instanceof CapacitorException) {
        if (error.message === "User cancelled photos app") {
          return;
        }
      }
      presentToast({
        message: "An error occurred while adding the photo",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const onEnlargePhoto = (message: TourStopMessage) => {
    const index = messages
      .filter((msg) => msg.type === "image")
      .findIndex((msg) => msg.id === message.id);
    selectedPhotoIndex.current = index;
    setFullScreenEnabled(true);
  };

  const handleRecording = async (message: TourStopMessage) => {
    if (playingRecording && playingRecording.id === message.id) {
      if (playingRecording.audio.paused) {
        playingRecording.audio.play();
      } else {
        playingRecording.audio.pause();
      }
    } else {
      if (playingRecording) {
        playingRecording.audio.pause();
        setPlayingRecording(null);
      }

      setTimeout(() => {
        const audio = new Audio();
        audio.src = URL.createObjectURL(message.blob!);
        audio.preload = "metadata";
        setPlayingRecording({
          id: message.id,
          audio,
          duration: audio.duration,
          elapsed: 0,
        });
        audio.play();

        audio.onended = () => {
          setPlayingRecording(null);
        };
        audio.ontimeupdate = () => {
          setPlayingRecording((prev) => {
            if (prev) {
              return {
                ...prev,
                elapsed: audio.currentTime,
                duration: audio.duration,
              };
            }
            return null;
          });
        };
      }, 100);
    }
  };

  const mapMessage = async (message: TourItemMedia) => {
    if (message.type === TourItemMediaType.photo && message.path) {
      const url = await Storage.get(message.path, {
        expires: 60 * 60 * 24 * 7,
      });
      return {
        id: message.id,
        type: "image",
        content: message.content || "",
        datetime: message.createdAt,
        from:
          usersData.find((user) => user.userId === message.userId)?.name ||
          "Unknown",
        userId: message.userId!,
        url,
      };
    } else if (message.type === TourItemMediaType.recording && message.path) {
      const file: any = await Storage.get(message.path, {
        download: true,
        expires: 60 * 60 * 24 * 7,
      });
      const blob = file.Body;
      return {
        id: message.id,
        type: "audio",
        datetime: message.createdAt,
        from:
          usersData.find((user) => user.userId === message.userId)?.name ||
          "Unknown",
        userId: message.userId!,
        blob,
      };
    } else if (message.type === TourItemMediaType.text) {
      return {
        id: message.id,
        type: "text",
        content: message.content || "",
        datetime: message.createdAt,
        from:
          usersData.find((user) => user.userId === message.userId)?.name ||
          "Unknown",
        userId: message.userId!,
      };
    }
  };

  useEffect(() => {
    const subscribe = () => {
      const createSubscription =
        TourItemMediaService.onCreateTourItemMediaByTourItemId(
          tourItem.id,
          async (data) => {
            if (data) {
              const newMessage = (await mapMessage(data)) as TourStopMessage;
              if (newMessage) {
                setMessages((prevMessages) => [...prevMessages, newMessage]);
                if (messagesRef.current) {
                  messagesRef.current.scrollTop =
                    messagesRef.current.scrollHeight;
                }
              }
            }
          }
        );

      const deleteSubscription =
        TourItemMediaService.onDeleteTourItemMediaByTourItemId(
          tourItem.id,
          async (data) => {
            if (data) {
              setMessages((prevMessages) =>
                prevMessages.filter((msg) => msg.id !== data.id)
              );
            }
          }
        );

      subscriptionsRef.current.push(createSubscription);
      subscriptionsRef.current.push(deleteSubscription);
    };

    subscribe();

    return () => {
      subscriptionsRef.current.forEach((subscription) => {
        subscription.unsubscribe();
      });
    };
  }, [tourItem]);

  useEffect(() => {
    const mapMessages = async () => {
      const newMessages: TourStopMessage[] = [];

      for (const message of tourItem.media?.items || []) {
        if (message) {
          const newMessage = await mapMessage(message);
          if (newMessage) {
            newMessages.push(newMessage as TourStopMessage);
          }
        }
      }

      newMessages.sort((a, b) => {
        return new Date(a.datetime).getTime() - new Date(b.datetime).getTime();
      });

      setMessages(newMessages);

      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    };
    mapMessages();
  }, [tourItem]);

  const Timer = () => {
    const minutes = Math.floor(recordingLength / 60);
    const seconds = recordingLength % 60;
    return (
      <div>
        {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
      </div>
    );
  };

  return (
    <div className={styles.messagesContainer}>
      <IonModal
        className={styles.galleryModal}
        isOpen={fullScreenEnabled}
        onDidDismiss={() => setFullScreenEnabled(false)}>
        <div className={styles.fullScreenGallery}>
          <IonIcon
            icon={closeCircle}
            onClick={() => setFullScreenEnabled(false)}
          />
          <Swiper
            className={styles.modalSwiper}
            navigation={true}
            modules={[Navigation]}
            initialSlide={selectedPhotoIndex.current}
            lazyPreloadPrevNext={1}
            zoom={true}
            spaceBetween={10}
            slidesPerView={1}>
            {messages
              .filter((msg) => msg.type === "image")
              .map((msg, index) => (
                <SwiperSlide
                  key={index}
                  className={styles.modalSwiperSlide}
                  draggable>
                  <img src={msg.url} alt={tourItem.mlsNumber} />
                </SwiperSlide>
              ))}
          </Swiper>
          {messages.filter((msg) => msg.type === "image")[
            selectedPhotoIndex.current
          ]?.content && (
            <div className={styles.modalCaption}>
              {
                messages.filter((msg) => msg.type === "image")[
                  selectedPhotoIndex.current
                ].content
              }
            </div>
          )}
        </div>
      </IonModal>
      <div className={styles.header}>
        <IonLabel className={styles.label}>Notes</IonLabel>
      </div>
      <div ref={messagesRef} className={styles.messages}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`${styles.message} ${
              message.userId === currentUserId ? styles.right : styles.left
            }`}>
            <IonAvatar className={styles.profilePicture}>
              <img
                src={
                  usersData.find((user) => user.userId === message.userId)
                    ?.profilePicture || DefaultProfile
                }
                alt="profile"
              />
            </IonAvatar>
            <div className={styles.content}>
              <div className={styles.sender}>{message.from}</div>
              <IonIcon
                className={styles.moreIcon}
                icon={ellipsisVerticalOutline}
                onClick={(e: any) => onOpenPopover(message, e)}
              />
              {message.type === "text" ? (
                <div className={styles.text}>{message.content}</div>
              ) : message.type === "image" ? (
                <div className={styles.image}>
                  <IonImg
                    defaultValue={DEFAULT_IMAGE}
                    onIonError={ev => ev.target.src = DEFAULT_IMAGE}
                    onClick={() => onEnlargePhoto(message)}
                    src={message.url}
                    alt="showing image"
                  />
                  <div className={styles.text}>{message.content}</div>
                </div>
              ) : (
                <div className={styles.audio}>
                  <IonIcon
                    icon={
                      playingRecording &&
                      playingRecording.id === message.id &&
                      !playingRecording.audio.paused
                        ? pause
                        : play
                    }
                    onClick={() => handleRecording(message)}
                  />
                  <div className={styles.visualizer}>
                    {[...Array(20)].map((_, i) => (
                      <div
                        key={i}
                        className={`${styles.bar} ${
                          playingRecording &&
                          playingRecording.id === message.id &&
                          playingRecording.elapsed >=
                            i * (playingRecording.duration / 20)
                            ? styles.active
                            : ""
                        }`}
                        style={{
                          height: `${Math.abs(Math.sin(i) * 100)}%`,
                        }}></div>
                    ))}
                  </div>
                </div>
              )}
              <div className={styles.datetime}>
                {moment(message.datetime).format("MMM D, YYYY h:mm A")}
              </div>
            </div>
          </div>
        ))}
        {!messages.length && (
          <div className={styles.noMessages}>
            <IonIcon icon={fileTray} />
            <span>No notes yet.</span>
          </div>
        )}
      </div>
      <div className={styles.input}>
        <IonInput
          value={text}
          readonly={loading}
          autocapitalize="words"
          onIonInput={(ev) => setText(ev.detail.value || "")}
          placeholder={photoToUpload ? "Add a caption" : "Type a message"}
          className={styles.inputField}>
          <div slot="start" style={{ width: showRecordingTimer ? "100%" : 0 }}>
            {showRecordingTimer && (
              <>
                <Timer />
                <IonProgressBar type="indeterminate" />
              </>
            )}
          </div>

          <div slot="end" className={styles.photoPreview}>
            {photoToUpload && (
              <img
                src={`data:image/png;base64, ${photoToUpload.base64String}`}
                alt="photo"
              />
            )}
          </div>
        </IonInput>

        <div className={styles.buttons}>
          {!photoToUpload ? (
            <div
              className={`${styles.sendButton} ${loading && styles.disabled}`}
              onClick={onAddPhoto}>
              <IonIcon icon={camera} />
            </div>
          ) : (
            <div
              className={`${styles.sendButton} ${loading && styles.disabled}`}
              onClick={() => setPhotoToUpload(undefined)}>
              <IonIcon icon={close} />
            </div>
          )}

          <div
            style={{ display: text.length || photoToUpload ? "flex" : "none" }}
            className={`${styles.sendButton} ${loading && styles.disabled}`}
            onClick={onSend}>
            <IonIcon icon={send} />
          </div>
          <div
            style={{ display: text.length || photoToUpload ? "none" : "flex" }}
            ref={recordButtonRef}
            className={`${styles.sendButton} ${loading && styles.disabled}`}>
            <IonIcon icon={mic} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourStopUploadsMessages;
