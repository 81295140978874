import { Listing } from "models/listings/listing.model";
import styles from "./listing-summary.module.scss";
import ListingStatusTag from "components/shared/Listings/ListingStatusTag/listing-status-tag.component";
import { ListingsHelper } from "utils/ListingsHelper";
import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import {
  arrowDownOutline,
  arrowUpOutline,
  bedOutline,
  calendarClearOutline,
  calendarNumberOutline,
  carOutline,
  locationOutline,
  mapOutline,
  navigateOutline,
  videocamOutline,
} from "ionicons/icons";
import BathIcon from "assets/svg/bath.svg";
import MeasurementIcon from "assets/svg/sqft.svg";
import HomeIcon from "assets/svg/home.svg";
import moment from "moment";
import ShowMoreText from "react-show-more-text";
import { DeviceService } from "services/deviceInfoService";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import { ListingLastStatus } from "enums/Listings/ListingLastStatus.enum";
import { ListingType } from "enums/Listings/ListingType.enum";
import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { HelperFunctions } from "utils/HelperFunctions";
import { MapService } from "services/mapService";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsAgent,
  selectIsAuthenticated,
} from "redux/user/user.selectors";
import { updateDetailsListing } from "redux/map/map.actions";

const ListingSummary = ({
  listing,
  estimatedPrice,
  onOpenRequestModal,
  onOpenOfferModal,
}: {
  listing: Listing;
  estimatedPrice: number | null;
  onOpenRequestModal: () => void;
  onOpenOfferModal: () => void;
}) => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const isApp = DeviceService.isApp();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAgent = useSelector(selectIsAgent);
  const openHouses = ListingsHelper.getListingOpenHouses(listing);
  const price = ListingsHelper.getListingFormattedPrice(listing);
  const days = ListingsHelper.getListingFormattedDate(listing);
  const [estimatedPriceDifference, setEstimatedPriceDifference] = useState<
    number | null
  >(null);

  const restrictedAccess =
    listing.status === ListingStatus.Unavailable && !isAuthenticated;

  const { beds, baths, garages, sqft } =
    ListingsHelper.getListingRooms(listing);

  useEffect(() => {
    const percentage = estimatedPrice
      ? (estimatedPrice /
        (listing.soldPrice && +listing.soldPrice
          ? +listing.soldPrice
          : +listing.listPrice)) *
      100 -
      100
      : 0;
    setEstimatedPriceDifference(percentage);
  }, [estimatedPrice]);

  const handleOpenVirtualTour = () => {
    if (Capacitor.isNativePlatform()) {
      Browser.open({
        url: listing.details.virtualTourUrl,
        windowName: "_blank",
      });
    } else {
      window.open(listing.details.virtualTourUrl, "_blank");
    }
  };

  const handleNavigate = () => {
    HelperFunctions.navigateByAddress(
      ListingsHelper.getListingAddress(listing.address)
    );
  };

  const handleMapView = () => {
    dispatch(updateDetailsListing(listing));
    // MapService.updateDetailsListing(listing);
    router.push("/listings");
  };

  const renderListingUpdateDate = () => {
    let type: string;
    if (listing.status === ListingStatus.Active) {
      switch (listing.lastStatus) {
        case (ListingLastStatus.New,
          ListingLastStatus.ComingSoon,
          ListingLastStatus.Extension,
          ListingLastStatus.PriceChange):
          type = "listed";
          break;
        case (ListingLastStatus.SoldConditionally,
          ListingLastStatus.SoldConditionallyWithEscapeClause):
          type = "sold";
          break;
        default:
          type = "listed";
      }
    } else {
      switch (listing.lastStatus) {
        case ListingLastStatus.Sold:
          type = "sold";
          break;
        default:
          type = "delisted";
      }
    }
    return `${type} ${days > 0 ? `${days} ${days > 1 ? "days" : "day"} ago` : "today"
      }`;
  };

  const renderButtons = () => {
    return (
      <div className={styles.iconButtons}>
        <div className={styles.button} onClick={handleMapView}>
          <IonIcon icon={mapOutline} />
          <span>Map view</span>
        </div>
        <div className={styles.button} onClick={handleNavigate}>
          <IonIcon icon={navigateOutline} />
          <span>Navigate</span>
        </div>
        {listing.details.virtualTourUrl && (
          <div className={styles.button} onClick={handleOpenVirtualTour}>
            <IonIcon icon={videocamOutline} />
            <span>Virtual tour</span>
          </div>
        )}
      </div>
    );
  };

  const renderRooms = () => {
    return (
      <div className={styles.rooms}>
        <span>
          <IonIcon icon={bedOutline} />
          {beds} bed
        </span>
        <span>
          <IonIcon icon={BathIcon} />
          {baths} bath
        </span>
        <span>
          <IonIcon icon={carOutline} />
          {garages} garage
        </span>
        {sqft && (
          <span>
            <IonIcon icon={MeasurementIcon} />
            {sqft} sqft
          </span>
        )}
      </div>
    );
  };

  const renderOpenHouse = () => {
    return (
      <div className={styles.openHouses}>
        <div className={styles.openHouseTitle}>Open Houses</div>
        {openHouses.map((openHouse, index) => (
          <div key={index} className={styles.openHouse}>
            <IonIcon icon={calendarClearOutline} />
            <div>
              <span>{openHouse?.date}</span>
              <span>{openHouse?.time}</span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderAddressAndType = () => {
    return (
      <div className={styles.addressType}>
        <span>
          <span>
            <IonIcon icon={calendarNumberOutline} />
          </span>{" "}
          Listed on: {moment(listing.listDate).format("MMM DD YYYY")}
        </span>
        <span>
          <span>
            <IonIcon icon={locationOutline} />
          </span>{" "}
          {ListingsHelper.getListingAddress(listing.address)}
        </span>
        <span>
          <span>
            <IonIcon icon={HomeIcon} />
          </span>{" "}
          {ListingsHelper.getDetailedListingType(listing)}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.address}>
        {ListingsHelper.getListingAddress(listing.address)}
      </div>
      <div className={styles.priceDataButtons}>
        <div className={styles.priceDate}>
          <div className={styles.date}>
            <ListingStatusTag
              size={isApp ? "small" : "large"}
              listing={listing}
            />
            <span
              className={`${styles.days} ${restrictedAccess ? styles.restricted : ""
                }`}>
              {restrictedAccess ? "---" : renderListingUpdateDate()}
            </span>
          </div>
          <div
            className={`${styles.price} ${restrictedAccess ? styles.restricted : ""
              }`}>
            <span>{price}</span>
            {listing.type === ListingType.Sale && estimatedPrice && (
              <span className={styles.estimatedPrice}>
                Estimated value:{" "}
                {ListingsHelper.getFormattedPrice(estimatedPrice)}
                {estimatedPriceDifference && (
                  <span
                    className={
                      estimatedPriceDifference > 0
                        ? styles.increase
                        : styles.decrease
                    }>
                    <IonIcon
                      icon={
                        estimatedPriceDifference > 0
                          ? arrowUpOutline
                          : arrowDownOutline
                      }
                    />
                    {estimatedPriceDifference.toFixed(1)}%
                  </span>
                )}
              </span>
            )}
          </div>
          {!isAgent && (
            <div className={styles.contactButtons}>
              <IonButton
                className="aecorn-button border"
                shape="round"
                size="large"
                onClick={onOpenRequestModal}>
                Contact or request viewing
              </IonButton>
              {listing.status === ListingStatus.Active && (
                <IonButton
                  className="aecorn-button clear border"
                  shape="round"
                  size="large"
                  onClick={onOpenOfferModal}>
                  Make an offer
                </IonButton>
              )}
            </div>
          )}
        </div>
        {renderButtons()}
      </div>
      <div className={styles.descriptionTitle}>Description</div>
      {renderRooms()}
      {openHouses.length > 0 && renderOpenHouse()}
      {renderAddressAndType()}
      <div className={styles.description}>
        <ShowMoreText
          lines={3}
          more="Show more"
          less="Show less"
          anchorClass={styles.showMore}
          expanded>
          {listing.details.description}
        </ShowMoreText>
        {(!isAgent && listing.status === ListingStatus.Active) && (
          <IonButton
            className="aecorn-button clear border"
            shape="round"
            size="large"
            onClick={onOpenOfferModal}>
            Make an offer
          </IonButton>
        )}
      </div>
      {renderButtons()}
    </div>
  );
};

export default ListingSummary;
