import { Listing } from "models/listings/listing.model";
import styles from "./listing-land-transfer-calculator.module.scss";
import { numberWithCommas } from "utils/functions";
import { useState } from "react";
import {
  IonContent,
  IonIcon,
  IonInput,
  IonPopover,
  IonToggle,
} from "@ionic/react";
import { CalculatorsService } from "services/calculatorsService";
import { helpCircleOutline } from "ionicons/icons";

const ListingLandTransferCalculator = ({ listing }: { listing: Listing }) => {
  const [price, setPrice] = useState<string>(
    numberWithCommas(+listing.listPrice, false)
  );
  const [provincalTax, setProvincalTax] = useState<number>(
    CalculatorsService.cacalculateProvincialTaxlculateProvincialTax(
      +listing.listPrice
    )
  );
  const [municipalityTax, setMunicipalityTax] = useState<number>(
    listing.address.city === "Toronto"
      ? CalculatorsService.cacalculateProvincialTaxlculateProvincialTax(
          +listing.listPrice
        )
      : 0
  );
  const [firstTimeHomeBuyer, setFirstTimeHomeBuyer] = useState<boolean>(false);

  const handlePriceChange = (e: CustomEvent) => {
    const value = e.detail.value!;

    if (!value) {
      setPrice("0");
      setProvincalTax(0);
      return;
    }

    try {
      let price = value.replace(/,/g, "");
      const parsedValue = parseFloat(price);
      const formattedValue = numberWithCommas(parsedValue, false);
      setPrice(formattedValue);
      setProvincalTax(
        CalculatorsService.cacalculateProvincialTaxlculateProvincialTax(
          parsedValue
        )
      );
      setMunicipalityTax(
        listing.address.city === "Toronto"
          ? CalculatorsService.cacalculateProvincialTaxlculateProvincialTax(
              parsedValue
            )
          : 0
      );
    } catch (error) {
      setPrice("0");
      setProvincalTax(0);
      setMunicipalityTax(0);
    }
  };

  const calculateRebate = () => {
    return numberWithCommas(
      CalculatorsService.calculateLandTransferRebate(
        +price.replace(/,/g, ""),
        provincalTax,
        municipalityTax,
        firstTimeHomeBuyer
      ),
      false
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <label>Home price</label>
        <div className={styles.value}>
          <IonInput
            className="aecorn-input dark"
            pattern="(\d+(?:[\.\,]\d+)+)"
            value={price}
            onIonInput={handlePriceChange}>
            <span slot="start">$</span>
          </IonInput>
          <IonIcon />
        </div>
      </div>
      <div className={styles.item}>
        <label>
          Province of purchase: <span>{listing.address.state}</span>
        </label>
        <span className={styles.value}>
          <span className={styles.amount}>
            $ {numberWithCommas(provincalTax)}*
          </span>
          <IonInput
            className="aecorn-input dark"
            disabled
            value={numberWithCommas(provincalTax)}>
            <span slot="start">$</span>
          </IonInput>
          <IonIcon icon={helpCircleOutline} id="provincal-tax-trigger" />
          <IonPopover trigger="provincal-tax-trigger" triggerAction="click">
            <IonContent className="ion-padding">
              <div className={styles.popoverContent}>
                <p>
                  Land transfer tax (LTT), typically calculated as a percentage
                  of the purchase price of a home, is required when purchasing a
                  home in Canada. All provinces have a LTT, and the amount
                  varies in each province.
                </p>
              </div>
            </IonContent>
          </IonPopover>
        </span>
      </div>
      <div className={styles.item}>
        <label>
          Municipality of purchase: <span>{listing.address.city}</span>
        </label>
        <span className={styles.value}>
          <span className={styles.amount}>
            $ {numberWithCommas(municipalityTax)}*
          </span>
          <IonInput
            className="aecorn-input dark"
            disabled
            value={numberWithCommas(municipalityTax)}>
            <span slot="start">$</span>
          </IonInput>
          <IonIcon icon={helpCircleOutline} id="municipality-tax-trigger" />
          <IonPopover trigger="municipality-tax-trigger" triggerAction="click">
            <IonContent className="ion-padding">
              <div className={styles.popoverContent}>
                <p>
                  Some municipalities, like Toronto, levy an additional LTT,
                  which is similarly calculated as a percentage of the purchase
                  price of a home.
                </p>
              </div>
            </IonContent>
          </IonPopover>
        </span>
      </div>
      <div className={styles.item}>
        <label>
          First time home buyer?
          <IonToggle
            className={`aecorn-toggle aecorn-toggle-label ${
              firstTimeHomeBuyer && "toggle-checked"
            }`}
            checked={firstTimeHomeBuyer}
            onIonChange={(e) => setFirstTimeHomeBuyer(e.detail.checked)}
            labelPlacement="stacked">
            {firstTimeHomeBuyer ? "Yes" : "No"}
          </IonToggle>
        </label>
        <span className={styles.value}>
          <span className={styles.toggleContainer}>
            <IonToggle
              className={`aecorn-toggle aecorn-toggle-label ${
                firstTimeHomeBuyer && "toggle-checked"
              }`}
              checked={firstTimeHomeBuyer}
              onIonChange={(e) => setFirstTimeHomeBuyer(e.detail.checked)}
              labelPlacement="stacked">
              {firstTimeHomeBuyer ? "Yes" : "No"}
            </IonToggle>
            <span>$ {calculateRebate()} rebate*</span>
          </span>
          <IonInput
            className="aecorn-input dark"
            disabled
            value={`${calculateRebate()} rebate*`}>
            <span slot="start">$</span>
          </IonInput>
          <IonIcon icon={helpCircleOutline} id="fthb-trigger" />
          <IonPopover trigger="fthb-trigger" triggerAction="click">
            <IonContent className="ion-padding">
              <div className={styles.popoverContent}>
                <p>
                  If you are a first-time home buyer in Ontario, you will be
                  eligible for LTT rebates, equal to the value of the LTT up to
                  a maximum amount set by the province.
                </p>
              </div>
            </IonContent>
          </IonPopover>
        </span>
      </div>
      <p>
        *Calculation formula is created by AECORN. This is for estimation
        purpose only.
      </p>
    </div>
  );
};

export default ListingLandTransferCalculator;
