import {
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLoading,
  IonPage,
  IonPopover,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import styles from "./profile.module.scss";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Header from "components/web/Header/header.component";
import { useSelector } from "react-redux";
import { User } from "API";
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import DefaultProfile from "assets/svg/Avatar.svg";
import {
  addCircle,
  chevronForwardOutline,
  closeOutline,
  trashOutline,
} from "ionicons/icons";
import EditIcon from "assets/svg/icons/edit-circle.svg";
import { selectCurrentClient } from "redux/client/client.selectors";
import { UserService } from "services/userService";
import { MediaService } from "services/mediaService";
import PersonalInformation from "./sections/PersonalInformation/personal-information.component";
import GeneralSettings from "./sections/GeneralSettings/general-settings.component";
import AlertSettings from "./sections/AlertSettings/alert-settings.component";
import Modal from "components/ui/modal/modal.component";
import { AuthenticationService } from "services/authenticationService";
import { Redirect } from "react-router";
import { selectIsAuthenticated } from "redux/user/user.selectors";

const Profile = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [presentToast] = useIonToast();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user: User | null = useSelector(selectCurrentClient);
  const [loading, setLoading] = useState(false);
  const [selectedSettings, setSelectedSettings] = useState<
    "personal" | "general" | "alert"
  >("personal");
  const [profilePicture, setProfilePicture] = useState<string | undefined>(
    undefined
  );

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const onLogout = async () => {
    try {
      await AuthenticationService.logout();
    } catch (err) {
      presentToast({
        message: "Failed to log out",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    }
  };

  const onSelectedSettingsChange = (e: CustomEvent) => {
    setSelectedSettings(e.detail.value as "personal" | "general" | "alert");
  };

  const onAddProfilePicture = async () => {
    try {
      const file = await MediaService.takePhoto();
      if (file) {
        setLoading(true);
        await UserService.updateProfilePicture(file);
      }
    } catch (err) {
      presentToast({
        message: "Failed to upload profile picture",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setLoading(false);
    }
  };

  const onDeleteProfilePicture = async () => {
    try {
      setLoading(true);
      await UserService.deleteProfilePicture(user?.profilePicture);
      setProfilePicture(undefined);
    } catch (err) {
      presentToast({
        message: "Failed to delete profile picture",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        const url = await Storage.get(user!.profilePicture!);
        setProfilePicture(url);
      } catch (err) {
        console.log(err);
      }
    };

    if (user?.profilePicture) {
      fetchProfilePicture();
    }
  }, [user]);

  const TopSection = () => (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton
            className="aecorn-back-button"
            text="Back to dashboard"
            defaultHref="/tabs/dashboard"
          />
        </IonButtons>
      </IonToolbar>
      <div className={styles.title}>Profile settings</div>
    </>
  );

  return (
    <IonPage>
      {!isAuthenticated && <Redirect to="/" />}
      <Modal
        isOpen={isLogoutModalOpen}
        onDidDismiss={() => setIsLogoutModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <div className={styles.sheetContent}>
          <div className={styles.modalHeader}>
            Logout
            {!isMobile && (
              <IonIcon
                icon={closeOutline}
                onClick={() => setIsLogoutModalOpen(false)}
              />
            )}
          </div>
          <p>Do you want to log out from your account?</p>
          <div className={styles.buttons}>
            <IonButton
              className="aecorn-button border danger"
              onClick={onLogout}>
              Log out
            </IonButton>
            <IonButton
              className="aecorn-button"
              onClick={() => setIsLogoutModalOpen(false)}>
              Discard
            </IonButton>
          </div>
        </div>
      </Modal>
      <IonHeader mode="ios" className={styles.header}>
        <MediaQuery minWidth={768}>
          <Header />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <TopSection />
        </MediaQuery>
      </IonHeader>
      <IonContent className={styles.content}>
        <IonLoading isOpen={loading} title="Loading..." />
        {user && (
          <div className={styles.container}>
            <IonPopover
              trigger="profile-picture-actions"
              triggerAction="click"
              dismissOnSelect>
              <IonContent className={styles.popover}>
                <IonItem
                  button
                  detailIcon={addCircle}
                  onClick={onAddProfilePicture}>
                  {profilePicture ? "Update" : "Add"}
                </IonItem>
                {profilePicture && (
                  <IonItem
                    button
                    detailIcon={trashOutline}
                    onClick={onDeleteProfilePicture}>
                    Remove
                  </IonItem>
                )}
              </IonContent>
            </IonPopover>
            <MediaQuery minWidth={768}>
              <div className={styles.title}>Profile settings</div>
            </MediaQuery>
            <div className={styles.avatar}>
              <span
                className={styles.avatarPicture}
                id="profile-picture-actions">
                <IonAvatar>
                  <img
                    src={profilePicture ?? DefaultProfile}
                    alt={user.displayName}
                  />
                </IonAvatar>
                <IonIcon icon={profilePicture ? EditIcon : addCircle} />
              </span>
              <span
                className={
                  styles.avatarName
                }>{`${user.givenName} ${user.familyName}`}</span>
              {!isMobile && (
                <IonButton
                  className="aecorn-button clear border"
                  onClick={() => setIsLogoutModalOpen(true)}>
                  Log out
                </IonButton>
              )}
            </div>
            {isMobile && (
              <section className={styles.settings}>
                <IonAccordionGroup
                  value={selectedSettings}
                  onIonChange={onSelectedSettingsChange}>
                  <IonAccordion value="personal">
                    <PersonalInformation
                      user={user}
                      toggled={selectedSettings === "personal"}
                    />
                  </IonAccordion>
                  <IonAccordion value="general">
                    <GeneralSettings
                      user={user}
                      toggled={selectedSettings === "general"}
                    />
                  </IonAccordion>
                  <IonAccordion value="alert">
                    <AlertSettings
                      user={user}
                      toggled={selectedSettings === "alert"}
                    />
                  </IonAccordion>
                </IonAccordionGroup>
              </section>
            )}
            {!isMobile && (
              <section className={styles.settings}>
                <div className={styles.list}>
                  <div
                    className={`${styles.settingsCard} ${
                      selectedSettings === "personal" ? styles.selected : ""
                    }`}
                    onClick={() => setSelectedSettings("personal")}>
                    <div className={styles.cardHeader}>
                      <div className={styles.settingsTitle}>
                        <span>Personal information</span>{" "}
                        <IonIcon icon={chevronForwardOutline} />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.settingsCard} ${
                      selectedSettings === "general" ? styles.selected : ""
                    }`}
                    onClick={() => setSelectedSettings("general")}>
                    <div className={styles.cardHeader}>
                      <div className={styles.settingsTitle}>
                        <span>General settings</span>{" "}
                        <IonIcon icon={chevronForwardOutline} />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.settingsCard} ${
                      selectedSettings === "alert" ? styles.selected : ""
                    }`}
                    onClick={() => setSelectedSettings("alert")}>
                    <div className={styles.cardHeader}>
                      <div className={styles.settingsTitle}>
                        <span>Alert settings</span>{" "}
                        <IonIcon icon={chevronForwardOutline} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.settingsContent}>
                  {selectedSettings === "personal" && (
                    <PersonalInformation user={user} toggled />
                  )}
                  {selectedSettings === "general" && (
                    <GeneralSettings user={user} toggled />
                  )}
                  {selectedSettings === "alert" && (
                    <AlertSettings user={user} toggled />
                  )}
                </div>
              </section>
            )}
          </div>
        )}
      </IonContent>
      <MediaQuery maxWidth={767}>
        <IonFooter className={styles.footer}>
          <IonButton
            size="small"
            className="aecorn-button clear border"
            onClick={() => setIsLogoutModalOpen(true)}>
            Log out
          </IonButton>
        </IonFooter>
      </MediaQuery>
    </IonPage>
  );
};

export default Profile;
