import {
  IonLabel,
  IonInput,
  IonIcon,
  IonList,
  IonButton,
  IonSpinner,
} from "@ionic/react";
import { closeOutline, addOutline } from "ionicons/icons";
import styles from "../modals.module.scss";
import Modal from "components/ui/modal/modal.component";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { User } from "API";
import { HelperFunctions } from "utils/HelperFunctions";

const TourGuestsModal = ({
  isOpen,
  onDismiss,
  searchTerm,
  onSearchTermChange,
  selectedGuests,
  onUpdateGuests,
  onAddEmail,
  onSave,
  loading,
  client,
  primaryAgent,
  showingAgents,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  searchTerm: string;
  onSearchTermChange: (value: string) => void;
  searchResults: User[];
  selectedGuests: { email: string; user?: User }[];
  onUpdateGuests: (guests: { email: string; user?: User }[]) => void;
  onAddEmail: () => void;
  onSave: () => void;
  loading: boolean;
  client?: User | null;
  primaryAgent?: User | null;
  showingAgents: User[];
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Modal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      breakpoints={isMobile ? [0, 1] : undefined}
      initialBreakpoint={isMobile ? 1 : undefined}>
      <div className={styles.sheetContent}>
        <div className={styles.modalHeader}>
          Guests
          <MediaQuery minWidth={768}>
            <IonIcon icon={closeOutline} onClick={onDismiss} />
          </MediaQuery>
        </div>
        <div className={styles.modalInput}>
          <IonLabel>Guests</IonLabel>
          <div className={styles.input}>
            <IonInput
              value={searchTerm}
              onIonInput={(e) => {
                onSearchTermChange(e.target.value?.toString() || "");
              }}
              clearInput
              className="aecorn-input dark"
              placeholder="Enter guest email"></IonInput>
            <IonButton
              className="aecorn-button"
              disabled={
                !searchTerm ||
                selectedGuests.some((g) => g.email === searchTerm) ||
                client?.email === searchTerm ||
                primaryAgent?.email === searchTerm ||
                showingAgents.some((a) => a.email === searchTerm) ||
                !HelperFunctions.validateEmail(searchTerm) ||
                loading
              }
              onClick={onAddEmail}>
              <IonIcon slot="icon-only" icon={addOutline} />
            </IonButton>
          </div>
        </div>
        {selectedGuests.map((guest, index) => (
          <div key={index} className={styles.item}>
            <span>
              <div className={styles.name}>
                {guest.user ? guest.user.displayName : guest.email}
              </div>
              {guest.user && <div className={styles.email}>{guest.email}</div>}
              <div
                className={`${styles.tag} ${
                  guest.user ? styles.verified : styles.external
                }`}>
                {guest.user ? "Verified" : "External"}
              </div>
            </span>
            <IonIcon
              icon={closeOutline}
              onClick={() =>
                onUpdateGuests(
                  selectedGuests.filter((g) => g.email !== guest.email)
                )
              }
            />
          </div>
        ))}
        <IonButton
          disabled={loading}
          className="aecorn-button"
          onClick={onSave}>
          {loading ? <IonSpinner /> : "Save changes"}
        </IonButton>
      </div>
    </Modal>
  );
};

export default TourGuestsModal;
