import { NotificationType, User } from "API";
import styles from "../../profile.module.scss";
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonLabel,
  IonLoading,
  IonRadio,
  IonRadioGroup,
  useIonToast,
} from "@ionic/react";
import { chevronDown, chevronUp, closeOutline } from "ionicons/icons";
import EditIcon from "assets/svg/edit.svg";
import { capitalize } from "utils/functions";
import Modal from "components/ui/modal/modal.component";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { UserService } from "services/userService";
import { DeviceService } from "services/deviceInfoService";

const Schedules = {
  instantly: "Instantly",
  daily: "Daily",
  weekly: "Weekly",
};

const AlertSettings = ({ user, toggled }: { user: User; toggled: boolean }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isCapacitor = DeviceService.isCapacitor();

  const [presentToast] = useIonToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [tempSchedule, setTempSchedule] = useState(
    user.notifications?.schedule
  );

  const onSaveSchedule = async () => {
    try {
      if (tempSchedule === user.notifications?.schedule) {
        setIsScheduleModalOpen(false);
        return;
      } else if (tempSchedule) {
        setIsLoading(true);
        await UserService.updateNotificationSchedule(tempSchedule);
        presentToast({
          message: "Schedule updated successfully.",
          duration: 3000,
          cssClass: "aecorn-success-toast",
        });
        setIsScheduleModalOpen(false);
      }
    } catch (err) {
      presentToast({
        message: "Failed to update schedule.",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onToggleEmailNotification = async (e: CustomEvent) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const types: NotificationType[] = [];
      if (e.detail.checked) {
        types.push(NotificationType.email);
      }
      if (user.notifications?.type?.includes(NotificationType.push)) {
        types.push(NotificationType.push);
      }

      UserService.updateNotificationType(types);
      presentToast({
        message: "Notification type updated successfully.",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
    } catch (err) {
      presentToast({
        message: "Failed to update notification type.",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onTogglePushNotification = async (e: CustomEvent) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      const types: NotificationType[] = [];
      if (e.detail.checked) {
        types.push(NotificationType.push);
      }
      if (user.notifications?.type?.includes(NotificationType.email)) {
        types.push(NotificationType.email);
      }

      await UserService.updateNotificationType(types);
      presentToast({
        message: "Notification type updated successfully.",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
    } catch (err) {
      presentToast({
        message: "Failed to update notification type.",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IonLoading isOpen={isLoading} message={"Loading..."} />
      <Modal
        isOpen={isScheduleModalOpen}
        onDidDismiss={() => setIsScheduleModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <div className={styles.sheetContent}>
          <div className={styles.modalHeader}>
            Set your currency{" "}
            {!isMobile && (
              <IonIcon
                icon={closeOutline}
                onClick={() => setIsScheduleModalOpen(false)}
              />
            )}
          </div>
          <div>
            <p>Set your units. This can be changed at any time.</p>
            <IonRadioGroup
              className="aecorn-radio"
              value={tempSchedule}
              onIonChange={(e) => setTempSchedule(e.detail.value)}>
              {Object.entries(Schedules).map(([key, value]) => (
                <IonRadio
                  key={key}
                  value={key}
                  labelPlacement="end"
                  justify="start">
                  {value}
                </IonRadio>
              ))}
              <IonRadio value={null} labelPlacement="end" justify="start">
                Never
              </IonRadio>
            </IonRadioGroup>
          </div>
          <IonButton className="aecorn-button" onClick={onSaveSchedule}>
            Submit changes
          </IonButton>
        </div>
      </Modal>
      <div slot="header" className={styles.accordionHeader}>
        <span className={styles.sectionTitle}>Alert settings</span>
        <IonIcon icon={toggled ? chevronUp : chevronDown} />
      </div>
      <div slot="content" className={styles.accordionContent}>
        <div className={styles.item}>
          <IonLabel className={styles.label}>Notify me</IonLabel>
          <div className={styles.input}>
            <div>{capitalize(user.notifications?.schedule ?? "Never")}</div>
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsScheduleModalOpen(true)}
            />
          </div>
          <span className={styles.note}>
            *This setting will be applied to favorite and saved searches only.
            Any tour alert will be sent to you instantly by default.
          </span>
        </div>
        <div className={styles.select}>
          <IonLabel className={styles.label}>Notify me by</IonLabel>
          <div className={styles.options}>
            <IonCheckbox
              className="aecorn-checkbox"
              labelPlacement="end"
              justify="start"
              checked={user.notifications?.type?.includes(
                NotificationType.email
              )}
              onIonChange={onToggleEmailNotification}>
              Email
            </IonCheckbox>
            <IonCheckbox
              className="aecorn-checkbox"
              labelPlacement="end"
              justify="start"
              checked={user.notifications?.type?.includes(
                NotificationType.push
              )}
              disabled={
                !user.notifications?.type?.includes(NotificationType.push) &&
                !isCapacitor
              }
              onIonChange={onTogglePushNotification}>
              Push notification
            </IonCheckbox>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertSettings;
