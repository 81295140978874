import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  getPlatforms,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./footer.module.scss";
import { shareSocialOutline } from "ionicons/icons";
import { SocialSharing } from "@ionic-native/social-sharing";
import { useLocation, useHistory } from "react-router-dom";
import { selectMapRef } from "../../../redux/map/map.selectors";
import {
  toggleFilterListings,
  toggleSchools,
  updateSelectedSchool,
} from "../../../redux/schools/schools.actions";
import {
  updateActiveListing,
  updateSelectedListing,
} from "../../../redux/map/map.actions";
import { selectIsSchoolsActive } from "../../../redux/schools/schools.selectors";

const Footer = ({ school, boundary }) => {
  const dispatch = useDispatch();
  const mapRef = useSelector(selectMapRef);
  const schoolsActive = useSelector(selectIsSchoolsActive);
  const history = useHistory();
  const url = useLocation().pathname;

  const handleShare = async () => {
    const text = `Check this school out on AECORN!`;

    let options = {
      message: `${text}`,
      subject: text,
      url: `https://aecorn.ca${url}`,
      chooserTitle: "Pick an app",
    };

    let optionsWithImages = { ...options };

    SocialSharing.shareWithOptions(optionsWithImages).catch((err) =>
      SocialSharing.shareWithOptions(options)
    );
  };

  return (
    <>
      <IonGrid className={styles.footer}>
        <IonRow>
          {getPlatforms().includes("capacitor") && (
            <IonCol className={styles.icon}>
              <IonIcon icon={shareSocialOutline} onClick={handleShare} />
            </IonCol>
          )}

          {mapRef && boundary && (
            <IonCol className={styles.icon}>
              <IonButton
                shape="round"
                className={styles.button}
                onClick={() => {
                  dispatch(updateSelectedListing(null));
                  dispatch(updateActiveListing(null));
                  !schoolsActive && dispatch(toggleSchools());
                  dispatch(updateSelectedSchool(school));
                  dispatch(toggleFilterListings(school));
                  history.push("/tabs/listings", { direction: "back" });
                }}>
                See listings in boundary
              </IonButton>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </>
  );
};

export default Footer;
