import { Location } from "models/locations/locations.model";
import styles from "./location-search-result.module.scss";
import { capitalize } from "lodash";

const LocationSearchResult = ({
  term,
  location,
  type,
  onClick,
}: {
  term: string;
  location: Location;
  type: "city" | "neighborhood" | "address";
  onClick: (location: Location) => void;
}) => {

  const HighlightedText = () => {
    const index = location.name.toLowerCase().indexOf(term.toLowerCase());
    if (index === -1) {
      return <>{location.name}</>;
    }

    const beforeMatch = location.name.substring(0, index);
    const match = location.name.substring(index, index + term.length);
    const afterMatch = location.name.substring(index + term.length);

    return (
      <>
        {beforeMatch}
        <strong>{match}</strong>
        {afterMatch}
      </>
    );
  }


  return (
    <div className={styles.container} onClick={() => onClick(location)}>
      <div className={styles.name}>
        <HighlightedText />
      </div>
      <span className={styles.type}>
        {capitalize(type)}
      </span>
    </div>
  );
};

export default LocationSearchResult;
