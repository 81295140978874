import TourAvailability from "components/shared/Tours/TourAvailability/tour-availability.component";
import styles from "./available-timeframes.module.scss";
import { IonButton, IonLoading, IonSpinner, useIonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import { UserService } from "services/userService";
import { useSelector } from "react-redux";
import { selectCurrentClientId } from "redux/client/client.selectors";

const AvailableTimeframes = () => {
  const currentUserId = useSelector(selectCurrentClientId);

  const [presentToast] = useIonToast();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [availability, setAvailability] = useState<string[]>([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [updatedAvailability, setUpdatedAvailability] = useState(availability);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        setLoading(true);
        const user = await UserService.getCurrentUser();
        const availability = user.client?.availability
          ? JSON.parse(user.client.availability)
          : [];
        setAvailability(availability);
      } catch (error) {
        console.log(error);
        presentToast({
          message: "Failed to load availability.",
          duration: 3000,
          cssClass: "aecorn-error-toast",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAvailability();
  }, []);

  const onChange = (update: string[]) => {
    setUpdatedAvailability(update);
    setHasChanged(true);
  };

  const onSave = async () => {
    try {
      setSubmitLoading(true);
      await UserService.updateClientAvailability(
        currentUserId!,
        updatedAvailability
      );
      setHasChanged(false);
      setAvailability(updatedAvailability);
      presentToast({
        message: "Availability saved.",
        duration: 3000,
        cssClass: "aecorn-success-toast",
      });
    } catch (error) {
      console.log(error);
      presentToast({
        message: "Failed to save availability.",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <IonLoading isOpen={submitLoading} title="Saving..." />
      {loading ? (
        <IonSpinner name="crescent" />
      ) : (
        <>
          <TourAvailability
            availability={availability}
            onAvailabilityUpdate={onChange}
          />
          <IonButton
            className="aecorn-button"
            disabled={!hasChanged}
            onClick={onSave}>
            Save
          </IonButton>
        </>
      )}
    </div>
  );
};

export default AvailableTimeframes;
