import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  useIonModal,
  useIonRouter,
} from "@ionic/react";
import LogoWithText from "../../Logo/LogoWithText";
import styles from "./header.module.scss";
import {
  chevronDown,
  logOutOutline,
  notificationsOutline,
  personOutline,
} from "ionicons/icons";
import ToursIcon from "assets/svg/tours.svg";
import ToursIconLight from "assets/svg/tours-light.svg";
import ContactUsIcon from "assets/svg/contact-us.svg";
import FeedbackIcon from "assets/svg/feedback.svg";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsAgent,
} from "../../../redux/user/user.selectors";
import { selectFiltersValue } from "../../../redux/filters/filters.selectors";
import { useEffect, useRef, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import AuthenticationModal from "components/shared/Modals/AuthenticationModal/authentication-modal.component";
import { ListingsFiltersService } from "services/listingsFiltersService";
import { ListingType } from "enums/Listings/ListingType.enum";
import { AuthenticationService } from "services/authenticationService";
import MoreIcon from "assets/svg/profile_dark.svg";
import { ActivityService } from "services/activityService";
import { Activity, ActivityStatus } from "API";

const Header = ({ type }: { type?: "search" }) => {
  const currentUser = useSelector(selectCurrentUser);
  const isAgent = useSelector(selectIsAgent);
  const router = useIonRouter();
  const route = useRouteMatch();
  const dropdownPopover = useRef<HTMLIonPopoverElement>(null);
  const { type: searchType } = useSelector(selectFiltersValue);
  const [authMode, setAuthMode] = useState<"login" | "register" | null>(null);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const subscriptions = useRef<any[]>([]);
  const [presentAuthenticationModal, dismiss] = useIonModal(
    AuthenticationModal,
    {
      onDismiss: () => {
        setAuthMode(null);
        dismiss();
      },
      mode: authMode,
    }
  );

  const updateSearchType = (value: ListingType) => {
    ListingsFiltersService.updateType(value);
  };

  const openAuthenticationModal = (mode: "login" | "register") => {
    setAuthMode(mode);
    presentAuthenticationModal({
      cssClass: "auth-modal",
      onDidDismiss: () => setAuthMode(null),
    });
  };

  const handleLogout = () => {
    AuthenticationService.logout();
  };

  const navigateToActivitiesPage = () => {
    router.push("/activities");
  };

  useEffect(() => {
    const fetchActivities = async () => {
      const result = await ActivityService.getActivities({
        status: {
          eq: ActivityStatus.sent,
        },
      });
      setActivities(result.activities);
    };
    if (currentUser) {
      fetchActivities();
      const newActivitySubscription = ActivityService.onNewActivity(
        currentUser.username,
        () => {
          fetchActivities();
        }
      );
      const updateActivitySubscription = ActivityService.onUpdateActivity(
        currentUser.username,
        (activity) => {
          setActivities((prev) =>
            prev.map((a) => (a.id === activity.id ? activity : a))
          );
        }
      );

      subscriptions.current = [
        newActivitySubscription,
        updateActivitySubscription,
      ];
    } else {
      subscriptions.current.forEach((subscription) =>
        subscription.unsubscribe()
      );
    }

    return () => {
      subscriptions.current.forEach((subscription) =>
        subscription.unsubscribe()
      );
    };
  }, [currentUser]);

  const renderLeftSideCustomContent = () => {
    if (type === "search") {
      return (
        <>
          <IonButton
            size="small"
            id="filter-trigger"
            className="aecorn-button clear border">
            <IonLabel>
              {searchType === ListingType.Sale ? "For Sale" : "For Lease"}
            </IonLabel>
            <IonIcon icon={chevronDown} />
          </IonButton>
          <IonPopover
            dismissOnSelect
            trigger="filter-trigger"
            triggerAction="click"
            showBackdrop={false}>
            <IonContent className={styles.popoverContent}>
              <IonList>
                <IonItem
                  button
                  lines="none"
                  onClick={() => updateSearchType(ListingType.Sale)}>
                  <IonLabel>For Sale</IonLabel>
                </IonItem>
                <IonItem
                  button
                  lines="none"
                  onClick={() => updateSearchType(ListingType.Lease)}>
                  <IonLabel>For Lease</IonLabel>
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>
        </>
      );
    }
    return null;
  };

  const onSelectTour = (ev: any) => {
    if (!currentUser) {
      ev.preventDefault();
      openAuthenticationModal("login");
    }
  };

  return (
    <div className={`${styles.container} ${type && styles[type]}`}>
      <div className={styles.content}>
        <div>
          <Link to="/">
            <LogoWithText dark={true} />
          </Link>
          {renderLeftSideCustomContent()}
        </div>
        <div className={styles.links}>
          <Link className={route.path === "/" ? styles.active : ""} to="/">
            Home
          </Link>
          <Link
            className={route.path === "/listings" ? styles.active : ""}
            to="/listings">
            Map
          </Link>
          <Link
            className={route.path === "/tours" ? styles.active : ""}
            onClick={onSelectTour}
            to="/tours">
            Tours
          </Link>
          <Link
            className={route.path === "/contact" ? styles.active : ""}
            to="/contact">
            Contact
          </Link>
        </div>
        <div>
          {currentUser && !isAgent && (
            <IonButton
              shape="round"
              size="small"
              routerLink="/tours"
              onClick={onSelectTour}
              className="aecorn-button dark">
              <IonIcon slot="start" icon={ToursIconLight} />
              Take a Tour
            </IonButton>
          )}
          {currentUser && (
            <div
              className={styles.notifications}
              onClick={navigateToActivitiesPage}>
              <IonIcon icon={notificationsOutline} />
              {activities.some((a) => a.status === ActivityStatus.sent) && (
                <div className={styles.notificationDot} />
              )}
            </div>
          )}
          {currentUser ? (
            <IonButton
              className={styles.profileDropdown}
              onClick={(e) => {
                dropdownPopover.current!.event = e;
                setIsUserDropdownOpen(true);
              }}
              color="dark"
              fill="clear">
              <IonIcon icon={personOutline} />
              <IonIcon icon={chevronDown} />
            </IonButton>
          ) : (
            <div className={styles.authButtons}>
              <IonButton
                className="aecorn-button clear-dark-border"
                onClick={() => openAuthenticationModal("login")}
                size="small">
                Sign in
              </IonButton>
              <IonButton
                className="aecorn-button clear-dark"
                onClick={() => openAuthenticationModal("register")}
                size="small">
                Register
              </IonButton>
            </div>
          )}
          <IonPopover
            isOpen={isUserDropdownOpen}
            ref={dropdownPopover}
            onDidDismiss={() => setIsUserDropdownOpen(false)}
            dismissOnSelect
            showBackdrop={false}>
            <IonContent className={styles.popoverContent}>
              <IonList>
                <IonItem lines="none" routerLink="/dashboard">
                  <IonIcon slot="start" icon={MoreIcon} />
                  <IonLabel>Dashboard</IonLabel>
                </IonItem>
                <IonItem lines="none" routerLink="/profile">
                  <IonIcon slot="start" icon={personOutline} />
                  <IonLabel>Profile</IonLabel>
                </IonItem>
                <div className={styles.divider} />
                <IonItem lines="none" routerLink="/tours">
                  <IonIcon slot="start" icon={ToursIcon} />
                  <IonLabel>My tours</IonLabel>
                </IonItem>
                <div className={styles.divider} />
                <IonItem lines="none" routerLink="/contact">
                  <IonIcon slot="start" icon={ContactUsIcon} />
                  <IonLabel>Contact us</IonLabel>
                </IonItem>
                {/* <IonItem lines="none" routerLink="/feedback">
                  <IonIcon slot="start" icon={FeedbackIcon} />
                  <IonLabel>Feedback</IonLabel>
                </IonItem> */}
                <div className={styles.divider} />
                <IonItem lines="none" button onClick={handleLogout}>
                  <IonIcon slot="start" icon={logOutOutline} />
                  <IonLabel>Log out</IonLabel>
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>
        </div>
      </div>
    </div>
  );
};

export default Header;
