import { Listing } from "models/listings/listing.model";
import styles from "./listing-calculators.module.scss";
import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import { useState } from "react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import ListingMortgageCalculator from "./ListingMortgageCalculator/listing-mortgage-calculator.component";
import ListingLandTransferCalculator from "./ListingLandTransferCalculator/listing-land-transfer-calculator.component";
import { DeviceService } from "services/deviceInfoService";

const ListingCalculators = ({ listing }: { listing: Listing }) => {
  const isNative = DeviceService.isApp();
  const [calculator, setCalculator] = useState<"mortgage" | "landTransfer">(
    "mortgage"
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
      <IonItem className="aecorn-select border" lines="none">
        <IonSelect
          value={calculator}
          interface={isNative ? "alert" : "popover"}
          interfaceOptions={{
            cssClass: isNative ? "aecorn-alert" : "aecorn-select-popover",
          }}
          justify="space-between"
          toggleIcon={chevronDownOutline}
          expandedIcon={chevronUpOutline}
          onIonChange={(e) => setCalculator(e.detail.value)}>
          <IonSelectOption value="mortgage">
            Mortgage calculator
          </IonSelectOption>
          <IonSelectOption value="landTransfer">Land Transfer</IonSelectOption>
        </IonSelect>
      </IonItem>
      </div>
      <div className={styles.calculator}>
        <div className={`aecorn-listing-detail-tab vertical ${styles.calculatorContainer}`}>
          {calculator === "mortgage" ? (
            <ListingMortgageCalculator listing={listing} />
          ) : (
            <ListingLandTransferCalculator listing={listing} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ListingCalculators;
