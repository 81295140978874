import React, { useEffect, useState, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonToast,
  IonIcon,
  IonAlert,
  IonLoading,
  useIonToast,
} from "@ionic/react";
import { Market } from "@ionic-native/market";
import "./App.scss";
import { API } from "aws-amplify";
import "@ionic/react/css/core.css";
import { selectLocationsValue } from "./redux/locations/locations.selectors";
import { fetchCurrencyStart } from "./redux/currency/currency.actions";
import {
  isAndroid,
  isApp as checkPlatform,
  isCapacitor,
} from "./utils/functions";
import styles from "./App.module.scss";
import { selectCurrentUser, selectIsAgent } from "./redux/user/user.selectors";
import { selectUIToast } from "./redux/ui/ui.selectors";
import {
  updateToast,
  updateChangedFavourite,
  fetchRateStart,
  resetNotification,
} from "./redux/ui/ui.actions";
import { GraphQLResult, GraphQLSubscription } from "@aws-amplify/api";
import { selectCurrentClient } from "./redux/client/client.selectors";
import AppTermsOfService from "./app/pages/TermsOfService/terms-of-service.component";
import AppPrivacyPolicy from "./app/pages/PrivacyPolicy/privacy-policy.component";
import { clearClient } from "./redux/client/client.actions";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { StatusBar } from "@ionic-native/status-bar";
import { App as CapacitorApp } from "@capacitor/app";
import AppTabs from "./routes/Tabs";
import SchoolDetails from "./pages/shared/SchoolDetails/school-details.component";
import { getPlatforms } from "@ionic/core/components";
import { browsersOutline, closeOutline } from "ionicons/icons";
import { Helmet } from "react-helmet";
import { storeIds, storeUrls } from "./utils/constants";
import {
  onFavouriteUpdateByUserId,
  onUpdateReport,
} from "./graphql/subscriptions";
import SchoolDisclaimer from "./pages/SchoolDisclaimer/school-disclaimer.component";
import {
  OnUpdateFavouriteSubscription,
  OnUpdateReportSubscription,
  UserRole,
} from "./API";
import LogoIconOnly from "./components/Logo/LogoIconOnly";
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { LocalNotifications } from "@capacitor/local-notifications";
import Join from "./app/components/CustomModals/Join/join.component";
import { UserService } from "./services/userService";
import { IonReactRouter } from "@ionic/react-router";
import Search from "./pages/web/Search/search.page";
import { checkUserSession } from "redux/user/user.actions";
import { Hub } from "aws-amplify";
import Listing from "pages/shared/Listing/listing.page";
import Home from "pages/shared/Home/home.page";
import SelectAgentModal from "components/shared/Modals/SelectAgentModal/select-agent-modal.component";
import TourStop from "pages/shared/TourStop/tour-stop.page";
import NewTour from "pages/shared/NewTour/new-tour.page";
import Dashboard from "pages/shared/Dashboard/dashboard.page";
import Profile from "pages/shared/Profile/profile.page";
import PrivateRoute from "routes/PrivateRoute";
import PublicRoute from "routes/PublicRoute";
import { ListingsFiltersService } from "services/listingsFiltersService";
import Tours from "pages/shared/Tours/tours.page";
import TourDetails from "pages/shared/TourDetails/tour-details.page";
import Activities from "pages/shared/Activities/activities.page";
import Activity from "pages/shared/Activity/activity.page";
import Modal from "components/ui/modal/modal.component";
import { useMediaQuery } from "react-responsive";
import ContactUs from "pages/shared/ContactUs/contact-us.page";
import emitter from "services/emitterService";
import { Events } from "enums/General/Events.enum";
import APPURLListener from "components/AppURLListener";

export const PlatformContext = createContext(false);

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isApp = checkPlatform();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isAgent = useSelector(selectIsAgent);
  const [presentToast] = useIonToast();
  const [showAuthLoading, setShowAuthLoading] = useState(false);
  const [showSelectAgentModal, setShowSelectAgentModal] = useState(false);
  const [showDownloadApp, setShowDownloadApp] = useState({
    show: false,
    os: "",
  });
  const handleUpdate = async () => {
    await Market.open(
      getPlatforms().includes("ios") ? storeIds.apple : storeIds.android
    );
  };
  const [update, setUpdate] = useState({
    isAvailable: false,
    type: "",
    value: {
      title: "",
      msg: "",
      btn: "",
      onClick: handleUpdate,
      forced: false,
    },
  });
  const locations = useSelector(selectLocationsValue);
  const user = useSelector(selectCurrentUser);
  const client = useSelector(selectCurrentClient);
  const toast = useSelector(selectUIToast);

  const closeToast = () => {
    dispatch(
      updateToast({
        open: false,
        type: undefined,
        message: "",
        header: undefined,
        position: undefined,
      })
    );
  };

  useEffect(() => {
    if (client?.role === UserRole.client) {
      const clientModel = client.client;
      if (!clientModel?.agentId) {
        setShowSelectAgentModal(true);
      }
    }
  }, [client]);

  useEffect(() => {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        dispatch(checkUserSession());
      }
      if (payload.event === "signOut") {
        dispatch(clearClient());
        dispatch(resetNotification());
      }
    });
  });

  useEffect(() => {
    console.log("Checking user session");
    emitter.on(Events.DISMISS_AUTH_LOADING as any, () => setShowAuthLoading(false));
    setShowAuthLoading(true);
    dispatch(checkUserSession());
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        await ListingsFiltersService.fetchRegionsFilterItems();
      } catch (err) {
        console.error(err);
      }
    };
    if (!locations.length) {
      fetchLocations();
    }
  }, [locations]);

  useEffect(() => {
    const getCurrentAppVersion = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      if (Capacitor.getPlatform() === "android") {
        return result.currentVersionCode;
      } else {
        return result.currentVersionName;
      }
    };

    const openAppStore = async () => {
      await AppUpdate.openAppStore();
    };

    const compareVersions = (
      currentVersion: string,
      minStableVersion: string
    ) => {
      const currentParts = currentVersion.split(".").map(Number);
      const minParts = minStableVersion.split(".").map(Number);

      for (let i = 0; i < minParts.length; i++) {
        if (currentParts[i] > minParts[i]) return true;
        if (currentParts[i] < minParts[i]) return false;
      }

      return true;
    };

    const checkAppUpdates = async () => {
      if (getPlatforms().includes("capacitor")) {
        try {
          const { minStableVersion } = await API.get(
            "ettieREST",
            "/checkupdates",
            {}
          );

          const currentVersion = await getCurrentAppVersion();

          const updatedNeeded = !compareVersions(
            currentVersion,
            minStableVersion
          );

          if (updatedNeeded) {
            setUpdate({
              isAvailable: true,
              type: "update",
              value: {
                title: "New Update Available",
                msg: "A new version of AECORN is available. Please update to continue using the app.",
                btn: "Update",
                onClick: openAppStore,
                forced: true,
              },
            });
          }
        } catch (err) {
          console.log("Error checking for updates", err);
        }
      }
    };

    dispatch(fetchCurrencyStart());
    dispatch(fetchRateStart());
    checkAppUpdates();
  }, []);

  useEffect(() => {
    if (isCapacitor()) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY);
      if (isAndroid()) {
        StatusBar.overlaysWebView(false);
        StatusBar.show();
      }
    }
  }, []);

  useEffect(() => {
    if (!user) {
      dispatch(clearClient());
      dispatch(resetNotification());
    }
  }, [dispatch, user]);

  useEffect(() => {
    CapacitorApp.addListener("backButton", (e) => {
      const selectAgentModal = document.getElementById(
        "selectAgent"
      );

      if (selectAgentModal) {
        setShowSelectAgentModal(true);
      }

      if (
        window.location.pathname === "/home" ||
        window.location.pathname === "/listings"
      )
        CapacitorApp.exitApp();
    });
  }, []);

  useEffect(() => {
    if (getPlatforms().includes("mobileweb")) {
      const os = getPlatforms().includes("ios")
        ? "ios"
        : getPlatforms().includes("android")
          ? "android"
          : null;

      if (os === "ios" || os === "android") {
        setShowDownloadApp({
          show: true,
          os: os,
        });
      }
    }
  }, []);

  const handleOpenApp = async () => {
    if (showDownloadApp.os === "ios") {
      window.open(storeUrls.apple, "_blank");
    } else if (showDownloadApp.os === "android") {
      window.open(storeUrls.android, "_blank");
    }
    setShowDownloadApp({
      ...showDownloadApp,
      show: false,
    });
  };

  ///CHECK FOR FAVOURITE UPDATES
  useEffect(() => {
    const subscriptions: GraphQLSubscription<any>[] = [];

    const subscribe = async () => {
      const favouriteSubscription = API.graphql<
        GraphQLSubscription<OnUpdateFavouriteSubscription>
      >({
        query: onFavouriteUpdateByUserId,
        variables: { userId: client?.id },
      }).subscribe({
        next: ({ value }: { value: any }) => {
          const item = value.data.onFavouriteUpdateByUserId;
          dispatch(updateChangedFavourite(item.mlsNumber));
        },
      });

      subscriptions.push(favouriteSubscription);

      const reportsSubscription = API.graphql<
        GraphQLSubscription<OnUpdateReportSubscription>
      >({
        query: onUpdateReport,
        variables: { userId: client?.id },
      }).subscribe({
        next: ({
          value,
        }: {
          value: GraphQLResult<GraphQLSubscription<OnUpdateReportSubscription>>;
        }) => {
          const report = value.data?.onUpdateReport;
          if (report && report.status === "Generated" && report.url) {
            const url = report.url;
            presentToast({
              message: `Your requested report for ${report.listingAddress || report.mlsNumber
                } is ready.`,
              cssClass: "aecorn-success-toast",
              position: "top",
              buttons: [
                {
                  text: "Download",
                  handler: () => {
                    window.open(url, "_blank");
                  },
                },
                {
                  role: "cancel",
                  icon: closeOutline,
                },
              ],
            });

            try {
              LocalNotifications.schedule({
                notifications: [
                  {
                    id: 1,
                    title: "Report Ready",
                    body: "Your requested report is ready. Click here to download it.",
                    extra: { url },
                    actionTypeId: "report",
                  },
                ],
              });
            } catch (error) {
              console.error("Error scheduling local notification:", error);
            }
          }
        },
      });

      subscriptions.push(reportsSubscription);
    };

    if (client) {
      subscribe();
    }

    return () => {
      subscriptions.forEach((sub) => sub.unsubscribe());
    };
  }, [client, dispatch]);

  const registerDeviceForNotifications = async () => {
    if (Capacitor.isNativePlatform()) {
      try {
        PushNotifications.removeAllListeners();
        const permissionStatus = await PushNotifications.requestPermissions();
        if (permissionStatus.receive === "granted") {
          await PushNotifications.register();

          PushNotifications.addListener("registration", async (token) => {
            localStorage.setItem("deviceToken", token.value);
          });
          PushNotifications.addListener("registrationError", (error) => {
            console.error("Registration error:", error);
          });
        }
      } catch (error) {
        console.error("Error registering device:", error);
      }
    }

    try {
      const permission = await LocalNotifications.requestPermissions();
      if (permission.display) {
        LocalNotifications.addListener(
          "localNotificationActionPerformed",
          (notification) => {
            if (notification.notification.actionTypeId === "report") {
              const url = notification.notification.extra?.url;
              if (url) {
                window.open(url, "_blank");
              }
            }
          }
        );
      }
    } catch (error) {
      console.error("Error registering device local notification:", error);
    }
  };

  useEffect(() => {
    registerDeviceForNotifications();
  }, []);

  useEffect(() => {
    const deviceToken = localStorage.getItem("deviceToken");
    if (user && deviceToken) {
      try {
        UserService.updateDeviceToken(deviceToken);
      } catch (error) {
        console.error("Error updating device token:", error);
      }
    }
  }, [user]);

  return (
    <IonApp className={styles.app}>
      <IonReactRouter>
        <Helmet>
          <title>AECORN - Properties in GTA</title>
          <meta
            name="description"
            content="Find real estate listings for sale in GTA. Search MLS real estate listings for homes, condos and properties."
          />
          <meta
            name="keywords"
            content="mls listings, house for sale, mls canada, homes for rent, mls map, mls real estate, homes for sale, condos for sale, homes for sale near me, townhouse for sale, property for sale, apartments for sale, property search, mls online, house prices, duplex for sale, real estate listings, real estate companies, cheap houses for sale, mls house listings, best real estate websites canada, single family homes for sale, mls residential map, detached house for sale, home listings, sold home prices, property listings, sold mls listings, house sale prices"
          />
        </Helmet>
        <IonAlert
          isOpen={update.isAvailable}
          header={update.value.title}
          message={update.value.msg}
          backdropDismiss={false}
          onDidDismiss={() =>
            setUpdate({
              isAvailable: false,
              type: "",
              value: {
                btn: "",
                title: "",
                forced: false,
                msg: "",
                onClick: handleUpdate,
              },
            })
          }
          buttons={
            update.value.forced
              ? [{ text: update.value.btn, handler: update.value.onClick }]
              : [
                { text: update.value.btn, handler: update.value.onClick },
                { text: "Close", role: "cancel" },
              ]
          }
        />
        <APPURLListener></APPURLListener>
        <Modal
          id="selectAgent"
          isOpen={showSelectAgentModal}
          onDidDismiss={() => setShowSelectAgentModal(false)}
          backdropDismiss={false}
          initialBreakpoint={isMobile ? 1 : undefined}
          breakpoints={isMobile ? [1] : undefined}
        >
          <SelectAgentModal
            client={client!}
            dismiss={() => setShowSelectAgentModal(false)}
          />
        </Modal>
        <IonToast isOpen={true} cssClass="connection" />
        <IonToast
          position={toast.position ? toast.position : "top"}
          cssClass={styles[toast.type]}
          isOpen={toast.open}
          onDidDismiss={closeToast}
          header={toast.header}
          message={toast.message}
          buttons={[
            {
              side: "end",
              icon: toast.act && "chevron-forward",
              handler: toast.act && toast.act,
            },
            {
              side: "end",
              icon: closeOutline,
              handler: closeToast,
            },
          ]}
          duration={4000}
        />

        <IonLoading isOpen={showAuthLoading} message={"Loading your user data..."} />
        {isApp && showDownloadApp.show && (
          <>
            <div className={styles.bottomCard}>
              <div className={styles.header}>See AECORN in...</div>
              <div className={styles.content}>
                <div className={styles.item}>
                  <div className={styles.logo}>
                    <span className={styles.appLogo}>
                      <LogoIconOnly />
                    </span>
                    <span className={styles.title}>AECORN</span>
                  </div>

                  <span
                    className={`${styles.label} ${styles.appLabel}`}
                    onClick={() => handleOpenApp()}>
                    Download AECORN
                  </span>
                </div>
                <div className={styles.item}>
                  <div className={styles.logo}>
                    <span className={styles.browserLogo}>
                      <IonIcon icon={browsersOutline} className={styles.icon} />
                    </span>
                    <span className={styles.title}>Browser</span>
                  </div>
                  <span
                    className={`${styles.label} ${styles.browserLabel}`}
                    onClick={() =>
                      setShowDownloadApp({
                        ...showDownloadApp,
                        show: false,
                      })
                    }>
                    Continue
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.backdrop} />
          </>
        )}

        <IonRouterOutlet id="main" animated>
          <Route path="/tabs" component={AppTabs} />
          <PublicRoute path="/join" component={Join} />
          <PublicRoute path="/terms" component={AppTermsOfService} />
          <PublicRoute path="/privacy" component={AppPrivacyPolicy} />
          <PublicRoute path="/contact" component={ContactUs} />
          <PublicRoute path="/schools/:id" component={SchoolDetails} />
          <PublicRoute
            path="/schools/disclaimer"
            component={SchoolDisclaimer}
          />
          <PublicRoute
            path="/listings"
            exact
            component={Search}
            tab="listings"
          />
          <PublicRoute path="/listings/:mlsNumber" component={Listing} />
          <PrivateRoute
            path="/activities"
            component={Activities}
            tab="activities"
            isAuthenticated={user}
            exact
          />
          <PrivateRoute
            path="/activities/:activityId"
            component={Activity}
            isAuthenticated={user}
            exact
          />
          <PrivateRoute
            path="/tours"
            component={Tours}
            tab="tours"
            isAuthenticated={user}
            exact
          />
          <PrivateRoute
            path="/tours/new"
            component={NewTour}
            isAuthenticated={user}
            isAuthorized={isAgent}
            exact
          />
          <PrivateRoute
            path="/tours/details/:tourId"
            exact
            component={TourDetails}
            isAuthenticated={user}
          />
          <PrivateRoute
            path="/tours/details/:tourId/:tourItemId"
            exact
            component={TourStop}
            isAuthenticated={user}
          />
          <PrivateRoute
            path="/dashboard"
            component={Dashboard}
            tab="dashboard"
            isAuthenticated={user}
          />
          <PrivateRoute
            path="/profile"
            component={Profile}
            isAuthenticated={user}
          />
          <PublicRoute exact path="/" component={Home} tab="home" />
          <Redirect to="/" />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
