import {
  IonButton,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import styles from "./regions-filter.module.scss";
import { DeviceService } from "services/deviceInfoService";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { selectLocationsValue } from "redux/locations/locations.selectors";
import { useEffect, useState } from "react";
import { ListingsFiltersService } from "services/listingsFiltersService";
import { selectFiltersValue } from "redux/filters/filters.selectors";

export interface Area {
  name: string;
  center: {
    lat: number;
    lng: number;
  };
  cities: City[];
}

export interface City {
  activeCount: number;
  name: string;
  state: string;
  coordinates: number[][][];
  location: {
    lat: number;
    lng: number;
  };
  neighborhoods: Neighborhood[];
}

export interface Neighborhood {
  activeCount: number;
  name: string;
  coordinates: number[][][];
  location: {
    lat: number;
    lng: number;
  };
}

const RegionsFilter = () => {
  const isNative = DeviceService.isApp();
  const filters = useSelector(selectFiltersValue);
  const locations: Area[] = useSelector(selectLocationsValue);

  const [selectedArea, setSelectedArea] = useState<string | undefined>(
    filters.area
  );
  const [selectedCities, setSelectedCities] = useState<string[]>(
    filters.city || []
  );
  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState<string[]>(
    filters.neighborhood || []
  );

  const onReset = () => {
    setSelectedArea(undefined);
    setSelectedCities([]);
    setSelectedNeighborhoods([]);
  };

  useEffect(() => {
    ListingsFiltersService.updateRegions(
      selectedArea,
      selectedCities,
      selectedNeighborhoods
    );
  }, [selectedArea, selectedCities, selectedNeighborhoods]);

  useEffect(() => {
    if (!filters.area && !filters.city && !filters.neighborhood) {
      onReset();
    }
  }, [filters.area, filters.city, filters.neighborhood]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <IonLabel>Region</IonLabel>
        <IonButton className="aecorn-button clear-dark" onClick={onReset}>
          Reset
        </IonButton>
      </div>
      <div className={styles.regions}>
        <IonItem lines="none" className="aecorn-select">
          <IonSelect
            value={selectedArea}
            aria-label="Area"
            interface={isNative ? "alert" : "popover"}
            interfaceOptions={{
              cssClass: isNative ? "aecorn-alert" : "aecorn-select-popover",
            }}
            justify="space-between"
            toggleIcon={chevronDownOutline}
            expandedIcon={chevronUpOutline}
            label="Area"
            onIonChange={(e) => setSelectedArea(e.detail.value)}>
            {locations.map((location, index) => (
              <IonSelectOption key={index} value={location.name}>
                {location.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem
          lines="none"
          className="aecorn-select"
          disabled={!selectedArea}>
          <IonSelect
            multiple
            value={selectedCities}
            aria-label="City"
            interface={isNative ? "alert" : "popover"}
            interfaceOptions={{
              cssClass: isNative ? "aecorn-alert" : "aecorn-select-popover",
            }}
            justify="space-between"
            toggleIcon={chevronDownOutline}
            expandedIcon={chevronUpOutline}
            label="City"
            onIonChange={(e) => setSelectedCities(e.detail.value)}>
              {
                locations.find((loc) => loc.name === selectedArea)?.cities.map((city, index) => (
                  <IonSelectOption key={index} value={city.name}>
                    {city.name}
                  </IonSelectOption>
                ))
              }
          </IonSelect>
        </IonItem>
        <IonItem
          lines="none"
          className="aecorn-select"
          disabled={!selectedCities.length}>
          <IonSelect
            value={selectedNeighborhoods}
            multiple
            aria-label="Neighborhood"
            interface={isNative ? "alert" : "popover"}
            interfaceOptions={{
              cssClass: isNative ? "aecorn-alert" : "aecorn-select-popover",
            }}
            justify="space-between"
            toggleIcon={chevronDownOutline}
            expandedIcon={chevronUpOutline}
            label="Neighborhood"
            onIonChange={(e) => setSelectedNeighborhoods(e.detail.value)}>
            {selectedCities.map((city) => {
              const region = locations.find(
                (loc) => loc.name === selectedArea
              );
              const selectedCity = region?.cities.find((c) => c.name === city);
              return selectedCity?.neighborhoods.map((neighborhood, index) => (
                <IonSelectOption key={index} value={neighborhood.name}>
                  {neighborhood.name}
                </IonSelectOption>
              ));
            })}
          </IonSelect>
        </IonItem>
      </div>
    </div>
  );
};

export default RegionsFilter;
