const TAX_LIMIT_1 = 275; // 0-55,000
const TAX_LIMIT_2 = 1950; // 55,000-250,000
const TAX_LIMIT_3 = 2250; //250,000-400,000
const TAX_LIMIT_4 = 32000; //400,000-2,000,000

const cacalculateProvincialTaxlculateProvincialTax = (price: number) => {
  if (price > 2000000) {
    return (
      TAX_LIMIT_1 +
      TAX_LIMIT_2 +
      TAX_LIMIT_3 +
      TAX_LIMIT_4 +
      ((price - 2000000) * 2.5) / 100
    );
  } else if (price > 400000 && price <= 2000000) {
    return (
      TAX_LIMIT_1 + TAX_LIMIT_2 + TAX_LIMIT_3 + ((price - 400000) * 2) / 100
    );
  } else if (250000 < price && price <= 400000) {
    return TAX_LIMIT_1 + TAX_LIMIT_2 + ((price - 250000) * 1.5) / 100;
  } else if (55000 < price && price <= 250000) {
    return TAX_LIMIT_1 + ((price - 55000) * 1) / 100;
  } else {
    return (price * 0.5) / 100;
  }
};

const calculateMortgageMonthlyPayment = (
  price: number,
  downpayment: number,
  rate: number,
  term: number
) => {
  const principal = price - downpayment;
  const monthlyRate = rate / 100 / 12;
  const numberOfPayments = term * 12;

  if (monthlyRate === 0) {
    return principal / numberOfPayments;
  }

  const x = Math.pow(1 + monthlyRate, numberOfPayments);
  const monthlyPayment = (principal * x * monthlyRate) / (x - 1);

  return monthlyPayment;
};

const calculateLandTransferRebate = (
  price: number,
  provincialTax: number,
  municipalTax: number,
  firstTimeHomeBuyer: boolean
) => {
  if (!firstTimeHomeBuyer) {
    return 0;
  }

  let totalRebate = 0;

  if (price <= 368000) {
    totalRebate = provincialTax;
  } else if (provincialTax > 4000) {
    totalRebate = 4000;
  } else {
    totalRebate = provincialTax;
  }

  if (municipalTax !== 0) {
    if (municipalTax > 4475) {
      totalRebate += 4475;
    } else {
      totalRebate += municipalTax;
    }
  }

  return totalRebate;
};

export const CalculatorsService = {
  cacalculateProvincialTaxlculateProvincialTax,
  calculateMortgageMonthlyPayment,
  calculateLandTransferRebate,
};
