import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  ItemReorderEventDetail,
  useIonRouter,
} from "@ionic/react";
import styles from "./agent-tour-status-and-listings.module.scss";
import { useState } from "react";
import { Tour, TourItem, TourStatus } from "API";
import { DeviceService } from "services/deviceInfoService";
import {
  addOutline,
  chevronDownOutline,
  chevronUpOutline,
  navigateOutline,
} from "ionicons/icons";
import { capitalize } from "lodash";
import { TourStop } from "models/tours/tour-stop.model";
import AgentTourStopCard from "components/shared/Tours/AgentTourStopCard/agent-tour-stop-card.component";
import { TourService } from "services/tourService";
import TourAddShowingModal from "components/shared/Modals/TourAddShowingModal/tour-add-showing-modal.component";
import MediaQuery from "react-responsive";

const AgentTourStatusAndListings = ({
  stops,
  tourItems,
  reorderStops,
  updateStatus,
  tour,
  loading,
}: {
  stops: TourStop[];
  tourItems: TourItem[];
  reorderStops: (stops: TourStop[]) => void;
  updateStatus: (status: TourStatus) => void;
  tour: Tour;
  loading: boolean;
}) => {
  const isNative = DeviceService.isApp();
  const router = useIonRouter();
  const [isReorderDisabled, setIsReorderDisabled] = useState(true);
  const [status, setStatus] = useState<TourStatus>(tour.status!);
  const [reorderedStops, setReorderedStops] = useState<TourStop[]>();
  const [isAddShowingModalOpen, setIsAddShowingModalOpen] = useState(false);

  const handleStopReorder = (e: CustomEvent<ItemReorderEventDetail>) => {
    const updatedStops = e.detail.complete(stops);
    setReorderedStops(updatedStops);
  };

  const handleUpdateStatus = (e: CustomEvent) => {
    const newStatus = e.detail.value as TourStatus;
    setStatus(newStatus);
    updateStatus(newStatus);
  };

  const handleNavigateTour = () => {
    TourService.navigateTourStops(tour, stops);
  };

  const toggleReorder = () => {
    if (!isReorderDisabled) {
      reorderStops(reorderedStops || stops);
    }
    setIsReorderDisabled(!isReorderDisabled);
  };

  const onNavigateToStop = (stop: TourStop) => {
    router.push(`/tours/details/${tour.id}/${stop.id}`);
  };

  return (
    <div className={styles.container}>
      <MediaQuery minWidth={768}>
        <IonModal
          className="aecorn-modal"
          style={{ '--width': "75%" }}
          isOpen={isAddShowingModalOpen}
          onDidDismiss={() => setIsAddShowingModalOpen(false)}>
          <TourAddShowingModal
            tour={tour}
            tourItems={tourItems}
            stops={stops}
            dismiss={() => setIsAddShowingModalOpen(false)}
          />
        </IonModal>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <IonModal
          isOpen={isAddShowingModalOpen}
          onDidDismiss={() => setIsAddShowingModalOpen(false)}>
          <TourAddShowingModal
            tour={tour}
            tourItems={tourItems}
            stops={stops}
            dismiss={() => setIsAddShowingModalOpen(false)}
          />
        </IonModal>
      </MediaQuery>
      <div className={styles.status}>
        <IonLabel>Tour Status</IonLabel>
        <IonItem className="aecorn-select dark" lines="none">
          <IonSelect
            value={status}
            interface={isNative ? "alert" : "popover"}
            interfaceOptions={{
              cssClass: isNative ? "aecorn-alert" : "aecorn-select-popover",
            }}
            justify="space-between"
            toggleIcon={chevronDownOutline}
            expandedIcon={chevronUpOutline}
            onIonChange={handleUpdateStatus}>
            {Object.values(TourStatus).map((status) => (
              <IonSelectOption key={status} value={status}>
                {capitalize(status)}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </div>
      <div className={styles.note}>
        <div>
          <span className={styles.title}>Stops</span>
          <span className={styles.text}>
            You can reorder the stops and navigation will be reset to the new
            one.
          </span>
        </div>
        <div className={styles.noteButtons}>
          <IonButton
            className={`aecorn-button clear-primary ${styles.reorderButton}`}
            disabled={loading || stops.length < 2}
            onClick={toggleReorder}>
            {isReorderDisabled ? "Reorder" : "Done"}
          </IonButton>
          <IonButton
            className={`aecorn-button ${styles.addButton}`}
            onClick={() => setIsAddShowingModalOpen(true)}
            expand="block">
            <IonIcon slot="start" icon={addOutline} />
            Add showings
          </IonButton>
        </div>
      </div>
      <div className={styles.stops}>
        <IonReorderGroup
          disabled={isReorderDisabled}
          onIonItemReorder={handleStopReorder}
          className={isReorderDisabled ? styles.disabled : ""}>
          {loading ? (
            <div className={styles.loading}>
              <IonSpinner title="Loading..." />
            </div>
          ) : (
            stops.map((stop) => {
              return isReorderDisabled ? (
                <IonItem key={stop.id} lines="none">
                  <AgentTourStopCard onNavigate={onNavigateToStop} stop={stop} />
                </IonItem>
              ) : (
                <IonReorder key={stop.id} slot="end">
                  <IonItem lines="none">
                    <AgentTourStopCard onNavigate={onNavigateToStop} stop={stop} />
                  </IonItem>
                </IonReorder>
              );
            })
          )}
        </IonReorderGroup>
      </div>
      <div className={styles.buttons}>
        <IonButton
          className="aecorn-button"
          onClick={() => setIsAddShowingModalOpen(true)}
          expand="block">
          <IonIcon slot="start" icon={addOutline} />
          Add showings
        </IonButton>
        <IonButton
          className="aecorn-button clear border"
          expand="block"
          onClick={handleNavigateTour}
          disabled={!stops.length}>
          <IonIcon slot="start" icon={navigateOutline} />
          Navigate Tour
        </IonButton>
      </div>
    </div>
  );
};

export default AgentTourStatusAndListings;
