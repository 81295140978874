import { takeLatest, put, all, call } from "redux-saga/effects";
import UserActionTypes from "./user.types";
import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure,
  confrimSignUpSuccess,
  confirmSignUpFailure,
  getGuestFailure,
  getGuestSuccess,
} from "./user.actions";
import { clearClient, fetchClientStart } from "../client/client.actions";
import { resetFilters } from "../filters/filters.actions";
import {
  openAuthModal,
  resetNotification,
} from "../ui/ui.actions";
import { getGuestUser } from "../../amplify/auth.utils";
import { AuthenticationService } from "../../services/authenticationService";
import emitter from "services/emitterService";
import { Events } from "enums/General/Events.enum";

export function* loadGuestUser() {
  try {
    const guest = yield getGuestUser();
    yield put(getGuestSuccess(guest));
  } catch (error) {
    yield put(getGuestFailure(error));
  }
}

export function* reloadGuestUser(data) {
  // try {
  //   const guest = yield getGuestUser();
  //   yield put(getGuestSuccess(guest));
  //   if (data && data.payload) {
  //     yield put(openAuthModal({ current: "register" }));
  //   }
  // } catch (error) {
  //   yield put(getGuestFailure(error));
  // }
}

export function* signUpAsync({ payload }) {
  try {
    const user = yield AuthenticationService.register(payload);

    yield put(
      signUpSuccess({
        user,
        additionalData: {
          username: payload.username,
          password: payload.password,
        },
      })
    );
  } catch (error) {
    const msg = error.message;
    const provider = msg?.includes("Google")
      ? "Google"
      : msg?.includes("Facebook")
      ? "Facebook"
      : msg?.includes("Cognito")
      ? "Cognito"
      : msg?.includes("Apple")
      ? "Apple"
      : null;
    if (provider) {
      if (provider === "Cognito") {
        yield put(
          signUpFailure("There is already an account with this email.")
        );
      } else {
        yield put(
          signUpFailure({
            ...error,
            message: `There is an external provider account (${provider}) linked to this email.`,
          })
        );
      }
    } else yield put(signUpFailure(error));
  }
}

export function* confirmSignUpAsync({ payload: { username, password, code } }) {
  try {
    const user = yield AuthenticationService.confirmRegistration(
      username,
      code
    );
    yield put(
      confrimSignUpSuccess({ user, additionalData: { username, password } })
    );
  } catch (error) {
    yield put(confirmSignUpFailure(error));
  }
}

export function* signInWithEmail({ payload: { username, password, router } }) {
  try {
    const user = yield AuthenticationService.login(username, password);
    yield put(fetchClientStart(user));
    yield put(signInSuccess({ user, router }));
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInAfterSignUp({
  payload: {
    user,
    additionalData: { username, password },
  },
}) {
  try {
    const user = yield AuthenticationService.login(username, password);
    yield put(fetchClientStart());
    yield put(signInSuccess({ user }));
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signOutAsync({ payload }) {
  try {
    yield put(resetFilters());
    yield put(clearClient());
    yield put(resetNotification());
    yield AuthenticationService.logout();
    yield put(signOutSuccess(payload));
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* signInWithFederated({ payload }) {
  try {
    const user = yield AuthenticationService.federatedLogin(payload);
    yield put(fetchClientStart());
    yield put(signInSuccess({ user }));
  } catch (error) {
    put(signInFailure(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const user = yield call(AuthenticationService.getCognitoUser);
    yield put(fetchClientStart());
    yield put(signInSuccess({ user }));
  } catch (error) {
    console.log("isUserAuthenticated error", error);
    yield put(signInFailure(error));
  } finally {
    emitter.emit(Events.DISMISS_AUTH_LOADING);
  }
}

export function* onFederatedSignInStart() {
  yield takeLatest(
    UserActionTypes.FEDERATED_SIGN_IN_START,
    signInWithFederated
  );
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOutAsync);
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUpAsync);
}

export function* onConfirmSignUpStart() {
  yield takeLatest(UserActionTypes.CONFIRM_SIGN_UP_START, confirmSignUpAsync);
}

export function* onConfirmSignUpSuccess() {
  yield takeLatest(UserActionTypes.CONFIRM_SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* onGetGuestStart() {
  yield takeLatest(UserActionTypes.GET_GUEST_START, loadGuestUser);
}

export function* onSignInSuccess() {
  yield takeLatest(UserActionTypes.SIGN_IN_SUCCESS, reloadGuestUser);
}

export function* onSignOutSuccess() {
  // yield takeLatest(UserActionTypes.SIGN_OUT_SUCCESS, reloadGuestUser);
}

export function* userSagas() {
  yield all([
    call(onFederatedSignInStart),
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onConfirmSignUpStart),
    call(onConfirmSignUpSuccess),
    call(onGetGuestStart),
    call(onSignInSuccess),
    call(onSignOutSuccess),
  ]);
}
