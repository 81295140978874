import { Tour } from "API";
import styles from "./active-tour-card.module.scss";
import { useEffect, useState } from "react";
import { Listing } from "models/listings/listing.model";
import { ListingService } from "services/listingService";
import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import moment from "moment";
import { TourService } from "services/tourService";
import { navigateOutline } from "ionicons/icons";

const ActiveTourCard = ({ tour }: { tour: Tour }) => {
  const router = useIonRouter();
  const [listings, setListings] = useState<
    {
      listing: Listing;
      order?: number | null;
      status?: string | null;
    }[]
  >([]);

  const tourClient = TourService.getTourClient(tour);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const tourItems = tour.tourItems?.items ?? [];
        const tourStops = await Promise.all(
          tourItems
            .filter((item) => item)
            .map(async (item) => ({
              listing: await ListingService.getListingByMlsNumber(
                item!.mlsNumber
              ),
              order: item?.order,
              status: item?.status,
            }))
        );

        setListings(tourStops);
      } catch (e) {
        console.error(e);
      }
    };

    fetchListings();
  }, []);

  const navigateToTour = () => {
    router.push(`/tours/details/${tour.id}`);
  };

  const navigateTourStops = async (event: any) => {
    event.stopPropagation();

    TourService.navigateTourStops(tour, listings);
  };

  return (
    <div className={styles.container} onClick={navigateToTour}>
      <section>
        <div className={styles.header}>ACTIVE TOUR</div>
        <div className={styles.content}>
          <div className={styles.info}>
            <span>{tour.title}</span>
            <span>
              {moment(tour.startTime, "HH:mm").format("h:mm A")} -{" "}
              {moment(tour.endTime, "HH:mm").format("h:mm A")}
            </span>
          </div>
          <div className={styles.info}>
            <span>{tourClient?.displayName}</span>
            <span>{tourClient?.email}</span>
          </div>
        </div>
      </section>
      <section>
        <IonButton
          className="aecorn-button primary"
          onClick={(e) => navigateTourStops(e)}
          disabled={!listings.length}>
          <IonIcon icon={navigateOutline} slot="start" />
          Navigate Tour
        </IonButton>
      </section>
    </div>
  );
};

export default ActiveTourCard;
