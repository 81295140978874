import { IonLabel, IonInput, IonIcon, IonList, IonButton } from "@ionic/react";
import { addOutline, closeOutline } from "ionicons/icons";
import styles from "../modals.module.scss";
import Modal from "components/ui/modal/modal.component";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { User } from "API";
import { HelperFunctions } from "utils/HelperFunctions";

const TourClientModal = ({
  isOpen,
  onDismiss,
  searchTerm,
  onSearchTermChange,
  searchResults,
  selectedClient,
  onUpdateClient,
  onSave,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  searchTerm: string;
  onSearchTermChange: (value: string) => void;
  searchResults: User[];
  selectedClient?: {
    email: string;
    user?: User;
  } | null;
  onUpdateClient: (client: { email: string; user?: User }) => void;
  onSave: () => void;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Modal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      breakpoints={isMobile ? [0, 1] : undefined}
      initialBreakpoint={isMobile ? 1 : undefined}>
      <div className={styles.sheetContent}>
        <div className={styles.modalHeader}>
          Client
          <MediaQuery minWidth={768}>
            <IonIcon icon={closeOutline} onClick={onDismiss} />
          </MediaQuery>
        </div>
        <div className={styles.modalInput}>
          <IonLabel>Client</IonLabel>
          <IonInput
            debounce={500}
            value={searchTerm}
            onIonInput={(e) => {
              onSearchTermChange(e.target.value?.toString() || "");
            }}
            clearInput
            className="aecorn-input dark"
            placeholder="Enter client name or email"></IonInput>
        </div>
        {selectedClient && (
          <div className={styles.item}>
            <span>
              <div className={styles.name}>
                {selectedClient.user
                  ? selectedClient.user.displayName
                  : selectedClient.email}
              </div>
              {selectedClient.user && (
                <div className={styles.email}>{selectedClient.email}</div>
              )}
              <div
                className={`${styles.tag} ${
                  selectedClient.user ? styles.verified : styles.external
                }`}>
                {selectedClient.user ? "Verified" : "External"}
              </div>
            </span>
          </div>
        )}
        <IonList>
          {HelperFunctions.validateEmail(searchTerm) &&
            !searchResults.length && (
              <div className={styles.item}>
                <span>
                  <div className={styles.name}>{searchTerm}</div>
                  <div className={`${styles.tag} ${styles.external}`}>
                    External
                  </div>
                </span>
                <IonIcon
                  icon={addOutline}
                  onClick={() => onUpdateClient({ email: searchTerm })}
                />
              </div>
            )}
          {searchResults
            .filter((user) => user.email !== selectedClient?.email)
            .map((user) => (
              <div key={user.id} className={styles.item}>
                <span>
                  <div className={styles.name}>{user.displayName}</div>
                  <div className={`${styles.tag} ${styles.verified}`}>
                    Verified
                  </div>
                </span>
                <IonIcon
                  icon={addOutline}
                  onClick={() => onUpdateClient({ email: user.email, user })}
                />
              </div>
            ))}
        </IonList>
        <IonButton
          disabled={!selectedClient}
          className="aecorn-button"
          onClick={onSave}>
          Save changes
        </IonButton>
      </div>
    </Modal>
  );
};

export default TourClientModal;
