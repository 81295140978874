import { IonButton, IonModal } from "@ionic/react";
import styles from "./map-buttons-bottom.module.scss";
import { useSelector } from "react-redux";
import {
  selectDrawingBoundary,
  selectMapFavourites,
} from "redux/map/map.selectors";
import { selectFiltersValue } from "redux/filters/filters.selectors";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import { ReactComponent as NearbyIcon } from "assets/svg/nearby.svg";
import MapButton from "../MapButton/map-button.component";
import SaveSearchModal from "components/shared/Modals/SaveSearchModal/save-search-modal.component";
import { MutableRefObject, useState } from "react";

const MapButtonsBottom = ({
  isApp,
  pageRef,
  isCentered,
  onLocate,
  mapType
}: {
  isApp: boolean;
  pageRef: MutableRefObject<HTMLElement | undefined>;
  isCentered: boolean;
  onLocate: () => void;
  mapType: "roadmap" | "satellite";
}) => {
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
  const isFavouritesOnlyEnabled = useSelector(selectMapFavourites);
  const filters = useSelector(selectFiltersValue);
  const drawingBoundary = useSelector(selectDrawingBoundary);

  const handleSaveSearch = () => {
    setShowSaveSearchModal(true);
  };

  return (
    <div className={styles.container}>
      <IonModal
        className="aecorn-modal"
        isOpen={showSaveSearchModal}
        presentingElement={isApp ? pageRef.current : undefined}
        onDidDismiss={() => setShowSaveSearchModal(false)}>
        <SaveSearchModal dismiss={() => setShowSaveSearchModal(false)} />
      </IonModal>
      <IonButton
        onClick={handleSaveSearch}
        disabled={
          isFavouritesOnlyEnabled ||
          filters.status === ListingStatus.Unavailable ||
          (!filters.area?.length &&
            !filters.city?.length &&
            !filters.neighborhood?.length &&
            !drawingBoundary)
        }
        shape="round"
        size={isApp ? "small" : "default"}
        fill="outline"
        color={mapType === "roadmap" ? "dark" : "light"}>
        Save Search
      </IonButton>
      <MapButton
        icon={<NearbyIcon />}
        onClick={onLocate}
        disabled={false}
        active={isCentered}
      />
    </div>
  );
};

export default MapButtonsBottom;
