import { User } from "API";
import styles from "../../profile.module.scss";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonLoading,
  IonRadio,
  IonRadioGroup,
  useIonToast,
} from "@ionic/react";
import { chevronDown, chevronUp, closeOutline } from "ionicons/icons";
import EditIcon from "assets/svg/edit.svg";
import { capitalize } from "utils/functions";
import Modal from "components/ui/modal/modal.component";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { UserService } from "services/userService";

const Currencies = {
  cad: "CAD - Canadian Dollar",
  usd: "USD - US Dollar",
  aud: "AUD - Australian Dollar",
  cny: "CNY - Chinese Renminbi",
  eur: "EUR - Euro (European)",
  gbp: "GBP - UK Pound Sterling",
  inr: "INR - Indian Rupee",
  jpy: "JPY - Japanese Yen",
  irr: "IRR - Iranian Rial",
  mxn: "MXN - Mexican Peso",
};

const GeneralSettings = ({
  user,
  toggled,
}: {
  user: User;
  toggled: boolean;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [presentToast] = useIonToast();

  const [isLoading, setIsLoading] = useState(false);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [tempCurrency, setTempCurrency] = useState(user.currency);

  const [isMeasurementUnitModalOpen, setIsMeasurementUnitModalOpen] =
    useState(false);
  const [tempMeasurementUnit, setTempMeasurementUnit] = useState(
    user.measurement
  );

  const onSaveCurrency = () => {
    try {
      if (tempCurrency === user.currency) {
        setIsCurrencyModalOpen(false);
        return;
      } else if (tempCurrency) {
        setIsLoading(true);
        UserService.updateCurrency(tempCurrency);
        presentToast({
          message: "Currency updated successfully.",
          duration: 3000,
          cssClass: "aecorn-success-toast",
        });
      }
    } catch (err) {
      presentToast({
        message: "Failed to update currency.",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setIsCurrencyModalOpen(false);
      setIsLoading(false);
    }
  };

  const onSaveMeasurementUnit = () => {
    try {
      if (tempMeasurementUnit === user.measurement) {
        setIsMeasurementUnitModalOpen(false);
        return;
      } else if (tempMeasurementUnit) {
        setIsLoading(true);
        UserService.updateMeasurementUnit(tempMeasurementUnit);
        presentToast({
          message: "Measurement unit updated successfully.",
          duration: 3000,
          cssClass: "aecorn-success-toast",
        });
      }
    } catch (err) {
      presentToast({
        message: "Failed to update measurement unit.",
        duration: 3000,
        cssClass: "aecorn-error-toast",
      });
    } finally {
      setIsMeasurementUnitModalOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <IonLoading isOpen={isLoading} message="Loading..." />
      <Modal
        isOpen={isCurrencyModalOpen}
        onDidDismiss={() => setIsCurrencyModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <div className={styles.sheetContent}>
          <div className={styles.modalHeader}>
            Set your currency
            {!isMobile && (
              <IonIcon
                icon={closeOutline}
                onClick={() => setIsCurrencyModalOpen(false)}
              />
            )}
          </div>
          <div>
            <p>Set your units. This can be changed at any time.</p>
            <IonRadioGroup
              className="aecorn-radio"
              value={tempCurrency}
              onIonChange={(e) => setTempCurrency(e.detail.value)}>
              {Object.entries(Currencies).map(([key, value]) => (
                <IonRadio
                  key={key}
                  value={key}
                  labelPlacement="end"
                  justify="start">
                  {value}
                </IonRadio>
              ))}
            </IonRadioGroup>
          </div>
          <IonButton
            className="aecorn-button"
            onClick={onSaveCurrency}
            disabled={!tempCurrency}>
            Submit changes
          </IonButton>
        </div>
      </Modal>
      <Modal
        isOpen={isMeasurementUnitModalOpen}
        onDidDismiss={() => setIsMeasurementUnitModalOpen(false)}
        breakpoints={isMobile ? [0, 1] : undefined}
        initialBreakpoint={isMobile ? 1 : undefined}>
        <div className={styles.sheetContent}>
          <div className={styles.modalHeader}>
            Units of measure{" "}
            {!isMobile && (
              <IonIcon
                icon={closeOutline}
                onClick={() => setIsMeasurementUnitModalOpen(false)}
              />
            )}
          </div>
          <div>
            <p>Set your units. This can be changed at any time.</p>
            <IonRadioGroup
              className="aecorn-radio"
              value={tempMeasurementUnit}
              onIonChange={(e) => setTempMeasurementUnit(e.detail.value)}>
              <IonRadio value="imperial" labelPlacement="end" justify="start">
                Imperial
              </IonRadio>
              <IonRadio value="metric" labelPlacement="end" justify="start">
                Metric
              </IonRadio>
            </IonRadioGroup>
          </div>
          <IonButton
            className="aecorn-button"
            onClick={onSaveMeasurementUnit}
            disabled={!tempMeasurementUnit}>
            Submit changes
          </IonButton>
        </div>
      </Modal>
      <div slot="header" className={styles.accordionHeader}>
        <span className={styles.sectionTitle}>General settings</span>
        <IonIcon icon={toggled ? chevronUp : chevronDown} />
      </div>
      <div slot="content" className={styles.accordionContent}>
        <div className={styles.item}>
          <IonLabel className={styles.label}>Currency</IonLabel>
          <div className={styles.input}>
            <div>{user.currency?.toLocaleUpperCase()}</div>
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsCurrencyModalOpen(true)}
            />
          </div>
        </div>
        <div className={styles.item}>
          <IonLabel className={styles.label}>Unit of measure</IonLabel>
          <div className={styles.input}>
            <div>{capitalize(user.measurement)}</div>
            <IonIcon
              icon={EditIcon}
              onClick={() => setIsMeasurementUnitModalOpen(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettings;
