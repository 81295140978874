import CardContainer from "../Tours/component/CardContainer/card-container.component";
import Footer from "../../components/Footer/footer.component";
import styles from "./school-disclaimer.module.scss";
import { IonContent, IonPage } from "@ionic/react";

const SchoolDisclaimer = () => {
  const sendEmailHandler = async () => {
    try {
      let Link = "mailto:support@aecorn.ca";
      window.open(Link, "_system");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div className={styles.container}>
          <div className={styles.terms}>
            <CardContainer>
              <div className={styles.content}>
                <div className={styles.title}>
                  Fraser Institute Rating Discontinued
                </div>
                <div className={styles.subtitle}>
                  November 10, 2020 Admin Real Estate
                </div>
                <div className={styles.greeting}>Hi AECORN users,</div>
                <div className={styles.body}>
                  <p>
                    AECORN uses data from{" "}
                    <a
                      href="https://www.eqao.com/"
                      target="_blank"
                      rel="noreferrer">
                      EQAO
                    </a>{" "}
                    for school ratings. The EQAO rating is a widely recognized
                    rating system for public schools in Ontario.
                  </p>
                  <p>
                    Our platform considers all of the recent 5 years of ranking
                    based on EQAO scores and compiles them into an overall AECO
                    Realty rating based on the scale of 10.
                    <div>
                      If you have any further questions, please reach out to us
                      at{" "}
                      <span className={styles.email} onClick={sendEmailHandler}>
                        info@aecorn.ca
                      </span>
                      .
                    </div>
                  </p>
                </div>
              </div>
            </CardContainer>
          </div>
          <div className={styles.footer}>
            <Footer />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SchoolDisclaimer;
