import {
  IonButton,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonModal,
  useIonToast,
} from "@ionic/react";
import styles from "./listing-offer-modal.module.scss";
import modalStyles from "../modals.module.scss";
import { checkmarkCircle, closeOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { selectCurrentClient } from "redux/client/client.selectors";
import { Listing } from "models/listings/listing.model";
import { useState } from "react";
import { ListingsHelper } from "utils/ListingsHelper";
import { useForm } from "react-hook-form";
import { ActivityService } from "services/activityService";
import { ListingType } from "enums/Listings/ListingType.enum";

const ListingOfferModal = ({
  dismiss,
  listing,
}: {
  dismiss: () => void;
  listing: Listing;
}) => {
  const client = useSelector(selectCurrentClient);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(
    `I'd like to make an offer for ${ListingsHelper.getListingAddress(
      listing.address
    )} (${listing.mlsNumber}).`
  );
  const [date, setDate] = useState<string | null>(null);

  const [presentToast] = useIonToast();

  const {
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm<{
    name: string;
    email: string;
    phone: string | null;
    offer: number | null;
  }>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      name: client?.name,
      email: client?.email,
      phone: client?.phone ? client.phone.replace("+1", "") : null,
      offer: +listing.listPrice,
    },
  });

  const nameFormControl = register("name", {
    required: {
      value: true,
      message: "Name is required",
    },
  });

  const emailFormControl = register("email", {
    required: {
      value: true,
      message: "Email is required",
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: "Invalid email address",
    },
  });

  const phoneFormControl = register("phone", {
    pattern: {
      value: /^\d{10}$/,
      message: "Invalid phone number",
    },
  });

  const offerFormControl = register("offer", {
    min: {
      value: 0,
      message: "Offer amount must be greater than 0",
    },
  });

  const submitOffer = async () => {
    try {
      setLoading(true);
      const phone = getValues("phone") ? `+1${getValues("phone")}` : undefined;

      await ActivityService.createOfferRequest({
        mlsNumber: listing.mlsNumber,
        name: getValues("name"),
        email: getValues("email"),
        phone: phone === "" ? undefined : phone,
        offer: getValues("offer") ?? undefined,
        date: date ?? undefined,
        message,
      });
      setStep(2);
    } catch (error) {
      console.error(error);
      presentToast({
        message: "An error occurred while submitting your request.",
        duration: 5000,
        cssClass: "aecorn-error-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {step !== 2 && (
        <div className={modalStyles.header}>
          <IonIcon icon={closeOutline} onClick={dismiss} />
          <div>Make an offer</div>
        </div>
      )}
      <IonContent>
        <IonLoading isOpen={loading} message="Loading..." />
        <div className={styles.content}>
          {step === 2 && (
            <div className={styles.success}>
              <IonIcon icon={checkmarkCircle} />
              <span>Offer submitted successfully</span>
              <ul>
                <li>An agent will get in touch within 24 hours.</li>
              </ul>
            </div>
          )}
          {step === 1 && (
            <div className={styles.form}>
              <label>Contact information</label>
              <IonInput
                className={`aecorn-input dark ${errors.name?.message && "ion-invalid ion-touched"
                  }`}
                {...nameFormControl}
                placeholder="Name*"
                required
                autocapitalize="words"
                onIonInput={nameFormControl.onChange}
                disabled={client ? true : false}
              />
              <IonInput
                className={`aecorn-input dark ${errors.email?.message && "ion-invalid ion-touched"
                  }`}
                placeholder="Email*"
                required
                {...emailFormControl}
                onIonInput={emailFormControl.onChange}
                type="email"
                disabled={client ? true : false}
              />
              <IonInput
                className={`aecorn-input dark ${
                  errors.phone?.message && "ion-invalid ion-touched"
                }`}
                placeholder="Phone"
                required
                {...phoneFormControl}
                onIonInput={phoneFormControl.onChange}
                type="tel"
                disabled={client && client.phone ? true : false}
              >
                <span slot="start">+1</span>
              </IonInput>
              <IonInput
                className={`aecorn-input dark ${errors.offer?.message && "ion-invalid ion-touched"
                  }`}
                pattern="(\d+(?:[\.\,]\d+)+)"
                inputMode="numeric"
                placeholder="Offer amount"
                {...offerFormControl}
                onIonInput={offerFormControl.onChange}>
                <span slot="start">$</span>
              </IonInput>
              <div className={styles.input}>
                <IonLabel>{listing.type === ListingType.Sale ? "Preferred closing date" : "Preferred lease start date"}</IonLabel>
                <IonDatetimeButton datetime="preferred-date"></IonDatetimeButton>
                <IonModal keepContentsMounted>
                  <IonDatetime
                    id="preferred-date"
                    showDefaultButtons
                    size="cover"
                    color="dark"
                    className="aecorn-datetime"
                    presentation="date"
                    value={date}
                    onIonChange={(ev) => {
                      setDate(ev.detail.value as string);
                    }}
                  />
                </IonModal>
              </div>
            </div>
          )}

          {step === 1 && (<span className={styles.note}>
            By submitting this form, I understand AECORN will share my
            information with registered real estate professionals.
          </span>)}
        </div>
      </IonContent>
      <div className={modalStyles.footer}>
        <IonButton
          onClick={dismiss}
          size="large"
          shape="round"
          className="aecorn-button border clear">
          {step === 2 ? "Close" : "Cancel"}
        </IonButton>

        {step === 1 && (<IonButton
          size="large"
          shape="round"
          className="aecorn-button"
          disabled={!isValid}
          onClick={submitOffer}>
          Submit
        </IonButton>)}
      </div>
    </>
  );
};

export default ListingOfferModal;
