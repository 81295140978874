import { TourStop } from "models/tours/tour-stop.model";
import styles from "./client-tour-stop-card.module.scss";
import { ListingsHelper } from "utils/ListingsHelper";
import {
  chevronForwardOutline,
} from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/react";
import DefaultImage from "assets/img/no-photo.png";
import moment from "moment";
import TourStatusPill from "components/shared/TourStatusPill/tour-status-pill.component";
import DEFAULT_IMAGE from "assets/img/Image not found.png";

const ClientTourStopCard = ({
  stop,
  onNavigate,
  details,
}: {
  stop: TourStop;
  onNavigate: () => void;
  details?: boolean;
}) => {
  return (
    <div className={styles.stop}>
      <div className={styles.header}>
        <div className={styles.address}>
          {ListingsHelper.getListingAddress(stop.listing.address)}
        </div>
        {!details && (
          <IonButton className="aecorn-button clear-dark" onClick={onNavigate}>
            Details
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonButton>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.image}>
          <img
            src={
              stop.listing.images[0]
                ? ListingsHelper.getListingThumbnail(stop.listing)
                : DefaultImage
            }
            alt={stop.listing.mlsNumber}
            defaultValue={DEFAULT_IMAGE}
            onError={(ev) => {
              (ev.target as HTMLImageElement).src = DEFAULT_IMAGE;
            }}
          />
        </div>
        <div className={styles.details}>
          <TourStatusPill status={stop.status!} />
          {stop.startTime && stop.endTime && (
            <span>
              {moment(stop.startTime, "HH:mm").format("hh:mm A")}{" "}
              {details ? "- " : "to "}
              {moment(stop.endTime, "HH:mm").format("hh:mm A")}
            </span>
          )}
          <span>{`${stop.listing.address.zip}, ${stop.listing.address.city}`}</span>
        </div>
      </div>
      {details && (
        <IonButton className="aecorn-button clear border" onClick={onNavigate}>
          Listing details
        </IonButton>
      )}
    </div>
  );
};

export default ClientTourStopCard;
