import LogoWithText from "components/shared/LogoWithText/logo-with-text.component";
import styles from "./listing-report.module.scss";
import { IonButton, IonSpinner, useIonModal, useIonToast } from "@ionic/react";
import { useState } from "react";
import { closeOutline } from "ionicons/icons";
import { ReportsService } from "services/reportsService";
import { AuthenticationService } from "services/authenticationService";
import { Listing } from "models/listings/listing.model";
import AuthenticationModal from "components/shared/Modals/AuthenticationModal/authentication-modal.component";

const ListingReport = ({ listing }: { listing: Listing }) => {
  const isAuthenticated = AuthenticationService.isAuthenticated();
  const [loading, setLoading] = useState(false);
  const [presentToast] = useIonToast();
  const [presentAuthenticationModal, dismiss] = useIonModal(
    AuthenticationModal,
    {
      onDismiss: () => {
        dismiss();
      },
      mode: "login",
    }
  );

  const handleGenerateReport = async () => {
    if (!isAuthenticated) {
      presentAuthenticationModal({
        cssClass: "auth-modal",
      });
      return;
    }
    try {
      setLoading(true);
      await ReportsService.generateReport(listing);
      presentToast({
        message:
          "Your report is being generated. You will receive a notification once it is ready.",
        duration: 5000,
        cssClass: "aecorn-success-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    } catch (error) {
      presentToast({
        message: "An error occurred while generating the report",
        duration: 5000,
        cssClass: "aecorn-error-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="aecorn-listing-detail-tab black">
      <div className={styles.container}>
        <div className={styles.content}>
          <LogoWithText />
          <span className={styles.title}>Real estate report</span>
          <p>
            A specialized real estate report is available for this address.
            Uncover data-driven insight about this property and reveal
            value-impacting factors not available anywhere else.
          </p>
          <ul>
            <li>Property information</li>
            <li>Market snapshot</li>
            <li>Zoning overview</li>
            <li>Land analysis</li>
            <li>Demographics overview</li>
          </ul>
          <IonButton
            className="aecorn-button primary"
            expand="block"
            size="large"
            disabled={loading}
            onClick={handleGenerateReport}>
            {loading ? <IonSpinner name="dots" /> : "Generate report"}
          </IonButton>
          <p className={styles.footnote}>
            After requesting, the report will be generated and sent to the email
            that you have registered with AECORN.
          </p>
          <p>
            Interested in other reports for this address? Check the link below
          </p>
          <span>
            Visit{" "}
            <a href="https://app.landlogic.ai/marketplace" target="_blank" rel="noreferrer">
              LandLogic
            </a>
          </span>
        </div>
        <div className={styles.bg}></div>
      </div>
    </div>
  );
};

export default ListingReport;
