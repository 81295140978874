import TourActionTypes from "./tour.types";

const INITIAL_STATE = {
  tours: undefined,
  currentTour: undefined,
  tourRequest: undefined,
  loading: true,
  error: null,
};

const tourReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TourActionTypes.FETCH_TOURS_START:
    case TourActionTypes.ADD_TOUR_START:
    case TourActionTypes.UPDATE_TOUR_START:
    case TourActionTypes.ADD_CLIENTS_START:
      return { ...state, loading: true };
    case TourActionTypes.FETCH_TOURS_SUCCESS:
      return {
        ...state,
        tours: action.payload,
        loading: false,
        error: null,
      };
    case TourActionTypes.ADD_TOUR_SUCCESS:
      return {
        ...state,
        currentTour: action.payload,
        loading: false,
        error: null,
      };
    case TourActionTypes.UPDATE_TOUR_SUCCESS:
      return {
        ...state,
        currentTour: action.payload,
        loading: false,
        error: null,
      };
    case TourActionTypes.ADD_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TourActionTypes.FETCH_TOURS_FAILURE:
    case TourActionTypes.ADD_TOUR_FAILURE:
    case TourActionTypes.UPDATE_TOUR_FAILURE:
    case TourActionTypes.ADD_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TourActionTypes.CLEAR_CURRENT_TOUR:
      return {
        ...state,
        currentTour: undefined,
      };
    case TourActionTypes.UPDATE_TOUR_REQUEST:
      return {
        ...state,
        tourRequest: action.payload
      };
    default:
      return state;
  }
};

export default tourReducer;
