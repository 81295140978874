import { useDispatch, useSelector } from "react-redux";
import styles from "./search.module.scss";
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { ListingsSortOptions } from "enums/Listings/ListingsSortOptions.enum";
import { Listing } from "models/listings/listing.model";
import { School } from "API";
import {
  selectExtraFilters,
  selectFiltersValue,
} from "redux/filters/filters.selectors";
import {
  selectIsSchoolsActive,
  selectSchoolsFilters,
} from "redux/schools/schools.selectors";
import {
  selectGoogleMapsRef,
  selectMapLocations,
  selectMapRef,
} from "redux/map/map.selectors";
import { updateFilters } from "redux/filters/filters.actions";
import googleMapReact from "google-map-react";
import { updateMapRef, updateGoogleMapsRef } from "redux/map/map.actions";
import { SortOptionValue } from "types/shared/SortOption.type";
import { MapService } from "services/mapService";
import { SchoolsService } from "services/schoolsService";
import {
  createAnimation,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSkeletonText,
  IonSpinner,
  IonThumbnail,
  useIonModal,
  useIonRouter,
  useIonViewDidEnter,
} from "@ionic/react";
import Map from "components/shared/Map/Map/map.component";
import ListingsSheet from "components/app/Map/ListingsSheet/listings-sheet.component";
import { ListingService } from "services/listingService";
import MapSearchBar from "components/shared/Map/MapSearchBar/map-searchbar.component";
import ListingListItem from "components/shared/Listings/ListingListItem/listing-list-item.component";
import { mapOutline, optionsOutline, searchOutline } from "ionicons/icons";
import SortButton from "components/app/SortButton/sort-button.component";
import { ListingsSortItems } from "utils/constants";
import ListingsFiltersModal from "components/shared/Modals/ListingsFiltersModal/listings-filters-modal.component";
import SchoolSheet from "components/shared/Map/SchoolSheet/school-sheet.component";
import ListingSearchModal from "components/app/modals/ListingSearchModal/listing-search-modal.component";
import { Location } from "models/locations/locations.model";
import { ListingsFiltersService } from "services/listingsFiltersService";

const Search = (props: {
  location: {
    search?: string;
  };
}) => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const pageRef = useRef<HTMLElement | undefined>(undefined);
  const modalRef = useRef<HTMLIonModalElement | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const mapRef = useSelector(selectMapRef);
  const googleMapsRef = useSelector(selectGoogleMapsRef);
  const [mapReady, setMapReady] = useState(false);
  const [sortBy, setSortBy] = useState<{
    sort: ListingsSortOptions;
    order: "asc" | "desc";
  }>({ sort: ListingsSortOptions.CREATED_ON, order: "desc" });
  const [view, setView] = useState<"map" | "list">("map");

  const [showSearchModal, setShowSearchModal] = useState(false);

  const [clusters, setClusters] = useState<any[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<any>();
  const [clustersLoading, setClustersLoading] = useState(true);
  const [clusterListingsLoading, setClusterListingsLoading] = useState(false);

  const [listingsLoading, setListingsLoading] = useState(true);
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedListings, setSelectedListings] = useState<Listing[]>([]);
  const [totalListingsPages, setTotalListingPages] = useState(1);

  const [schools, setSchools] = useState<School[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<School | null>(null);
  const [toggledSchools, setToggledSchools] = useState<
    (School & { fillColor: string; strokeColor: string })[]
  >([]);

  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const filters = useSelector(selectFiltersValue);
  const extraFilters = useSelector(selectExtraFilters);
  const isSchoolsActive = useSelector(selectIsSchoolsActive);
  const schoolsFilters = useSelector(selectSchoolsFilters);
  const mapLocations = useSelector(selectMapLocations);

  const [presentListingsSheet, dismissSheet] = useIonModal(ListingsSheet, {
    modalRef,
    count: selectedCluster
      ? selectedCluster.count
      : selectedListings.length
        ? selectedListings.length
        : undefined,
    listings: selectedListings,
    loading: clusterListingsLoading,
    navigate: (mlsNumber: string) => {
      modalRef.current?.dismiss();
      router.push(`/listings/${mlsNumber}`);
    },
  });

  useIonViewDidEnter(() => {
    if (selectedCluster) {
      presentListingsSheet({
        showBackdrop: false,
        breakpoints: [0, 0.35, 1],
        initialBreakpoint: 0.35,
        backdropBreakpoint: 1,
        cssClass: "aecorn-listings-sheet",
        onDidPresent: (event) => {
          modalRef.current = event.target as HTMLIonModalElement;
        },
        onWillDismiss: (event) => {
          if (event.detail.role === "gesture") {
            clearListingsAndClusters();
          }
        },
      });
    } else if (selectedListings.length === 1) {
      presentListingsSheet({
        showBackdrop: false,
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        backdropBreakpoint: 1,
        cssClass: "aecorn-single-listing-sheet",
        onDidPresent: (event) => {
          modalRef.current = event.target as HTMLIonModalElement;
        },
        onWillDismiss: (event) => {
          if (event.detail.role === "gesture") {
            clearListingsAndClusters();
          }
        },
      });
    }
  }, [selectedCluster, selectedListings]);

  useEffect(() => {
    if (view === "list") {
      scrollRef.current?.scrollTo({
        top: 0,
      });
    }
  }, [view]);

  useEffect(() => {
    if (mapReady && filters.map) {
      fetchClusters();
    }
  }, [filters, extraFilters, mapReady]);

  useEffect(() => {
    if (mapReady && isSchoolsActive) {
      fetchSchools();
    }
  }, [isSchoolsActive, schoolsFilters, filters.map, mapReady]);

  useEffect(() => {
    if (mapReady) {
      setTotalListingPages(1);
      setListings([]);
      fetchListings(1);
    }
  }, [clusters]);

  useEffect(() => {
    if (mapReady) {
      setListings([]);
      fetchListings(1);
    }
  }, [sortBy]);

  useEffect(() => {
    if (!isSchoolsActive) {
      if (toggledSchools.length > 0) {
        setToggledSchools([]);
      }
      if (selectedSchool) {
        setSelectedSchool(null);
      }
    }
  }, [isSchoolsActive]);

  useEffect(() => {
    if (mapReady) {
      const params: [string, string][] = Array.from(new URLSearchParams(props.location.search).entries());
      if (params && params.length > 0) {
        const validParams = [
          "type",
          "propertyType",
          "minPrice",
          "maxPrice",
          "minBeds",
          "maxBeds",
          "area",
          "city",
        ];

        const filtersToUpdate: {
          [key: string]: string | number | undefined | string[];
        } = {};
        let lat: number | undefined;
        let lng: number | undefined;

        params.forEach(([key, value]: [string, string]) => {
          if (validParams.includes(key)) {
            filtersToUpdate[key] =
              key === "propertyType"
                ? value.split(",").map((type) => type.trim())
                : key === "city" ? [value] : value;
          }

          if (key === "lat") {
            lat = parseFloat(value);
          }
          if (key === "lng") {
            lng = parseFloat(value);
          }
        });

        ListingsFiltersService.reset();
        ListingsFiltersService.updateMultiple(filtersToUpdate);

        if (lat && lng) {
          mapRef?.setCenter({ lat, lng });
          setTimeout(() => {
            (googleMapsRef as typeof google.maps)?.event.trigger(
              mapRef,
              "center_changed"
            );
          }, 1500);
        }

        if (Object.keys(filtersToUpdate).length || lat || lng) {
          window.history.replaceState({}, "", "/listings");
        }
      }
    }
  }, [props.location.search, mapReady]);

  const dismissListingsSheet = async (callback: () => void) => {
    if (modalRef.current) {
      modalRef.current.leaveAnimation = () => {
        return createAnimation();
      };
      await modalRef.current.dismiss();
    }

    callback();
  };

  const updateMapFilter = useCallback((update?: { map: any[][][] }) => {
    if (update) {
      if (update.map.flat(2).includes(undefined)) {
        return;
      }
      dispatch(updateFilters(update));
    }
  }, []);

  const handleMapReady = (
    mapRef: MutableRefObject<googleMapReact>,
    mapsRef: typeof google.maps
  ) => {
    dispatch(updateMapRef(mapRef));
    dispatch(updateGoogleMapsRef(mapsRef));
    setTimeout(() => {
      setMapReady(true);
    }, 500);
  };

  const handleViewChange = async (view: "map" | "list") => {
    await dismissListingsSheet(() => {
      clearListingsAndClusters();
      setView(view);
    });
  };

  const handleSort = (value: SortOptionValue) => {
    const [sort, order] = value.toString().split("-");
    setSortBy({
      sort: sort as ListingsSortOptions,
      order: order as "asc" | "desc",
    });
  };

  const handleListingsListScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current!;

    const nextPage = listings.length / 10 + 1;
    if (
      scrollTop + clientHeight >= scrollHeight - 100 &&
      nextPage <= totalListingsPages
    ) {
      fetchListings(nextPage);
    }
  };

  const handleSelectCluster = async (cluster: any) => {
    try {
      setClusterListingsLoading(true);
      setSelectedListings([]);
      setSelectedCluster(cluster);

      dismissListingsSheet(() =>
        presentListingsSheet({
          showBackdrop: false,
          breakpoints: [0, 0.35, 1],
          initialBreakpoint: 0.35,
          backdropBreakpoint: 1,
          cssClass: "aecorn-listings-sheet",
          onDidPresent: (event) => {
            modalRef.current = event.target as HTMLIonModalElement;
          },
          onWillDismiss: (event) => {
            if (event.detail.role === "gesture") {
              clearListingsAndClusters();
            }
          },
        })
      );

      const clusterListings = await MapService.getClusterListings(cluster.map);
      setSelectedListings(clusterListings);
    } catch (error) {
      console.error(error);
    } finally {
      setClusterListingsLoading(false);
    }
  };

  const handleSelectListings = async (listings: Listing[]) => {
    if (listings.length === 1) {
      openSingleListingSheet(listings[0]);
    }
  };

  const openSingleListingSheet = async (listing: Listing) => {
    try {
      setSelectedCluster(null);
      setClusterListingsLoading(true);
      dismissListingsSheet(() =>
        presentListingsSheet({
          showBackdrop: false,
          breakpoints: [0, 1],
          initialBreakpoint: 1,
          backdropBreakpoint: 1,
          cssClass: "aecorn-single-listing-sheet",
          onDidPresent: (event) => {
            modalRef.current = event.target as HTMLIonModalElement;
          },
          onWillDismiss: (event) => {
            if (event.detail.role === "gesture") {
              clearListingsAndClusters();
            }
          },
        })
      );

      const listingDetails = await ListingService.getListingByMlsNumber(
        listing.mlsNumber
      );
      setSelectedListings([listingDetails]);
    } catch (error) {
      console.error(error);
    } finally {
      setClusterListingsLoading(false);
    }
  };

  const clearListingsAndClusters = () => {
    setSelectedCluster(null);
    setSelectedListings([]);
  };

  const handleDeselectClusterAndListings = () => {
    clearListingsAndClusters();
    dismissSheet();
  };

  const fetchSchools = async () => {
    try {
      const schools = (await SchoolsService.getSchools()) as School[];
      setSchools(schools);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClusters = async () => {
    try {
      setClustersLoading(true);
      const clusters = await MapService.getClusters();
      setClusters(clusters);
    } catch (error) {
      console.error(error);
    } finally {
      setClustersLoading(false);
    }
  };

  const fetchListings = async (page: number) => {
    try {
      setListingsLoading(true);
      const response = await MapService.getListings(
        page,
        sortBy.sort,
        sortBy.order
      );
      setListings((prevListings) => [...prevListings, ...response.listings]);
      const totalPages = Math.ceil(response.count / 10);
      setTotalListingPages(totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setListingsLoading(false);
    }
  };

  const handleSearchInputClick = () => {
    setShowSearchModal(true);
  };

  const handleSchoolToggle = (school: School) => {
    if (toggledSchools.find((s) => s.id === school.id)) {
      setToggledSchools((prev) => prev.filter((s) => s.id !== school.id));
    } else {
      const randomFillColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;
      const oppositeStrokeColor = `#${(
        0xffffff - parseInt(randomFillColor.slice(1), 16)
      ).toString(16)}`;
      setToggledSchools((prev) => [
        ...prev,
        {
          ...school,
          fillColor: randomFillColor,
          strokeColor: oppositeStrokeColor,
        },
      ]);
    }
  };

  const handleSearchLocationSelect = (location: Location) => {
    setShowSearchModal(false);
    dismissListingsSheet(() => clearListingsAndClusters());
    if (location.location) {
      mapRef?.setCenter({
        lat: location.location.lat,
        lng: location.location.lng,
      });
      mapRef?.setZoom(15);
    }
  };

  const handleSearchListingSelect = (listing: Listing) => {
    setShowSearchModal(false);
    dismissListingsSheet(() => clearListingsAndClusters());
    router.push(`/listings/${listing.mlsNumber}`);
  };

  return (
    <IonPage ref={pageRef}>
      <IonContent fullscreen scrollY={false}>
        <IonModal
          className="aecorn-modal"
          isOpen={showFiltersModal}
          onDidDismiss={() => setShowFiltersModal(false)}
          presentingElement={pageRef.current}>
          <ListingsFiltersModal dismiss={() => setShowFiltersModal(false)} />
        </IonModal>
        <IonModal
          showBackdrop={false}
          className="aecorn-school-sheet"
          isOpen={selectedSchool !== null}
          onDidDismiss={() => setSelectedSchool(null)}
          breakpoints={[0, 1]}
          initialBreakpoint={1}
          backdropBreakpoint={1}>
          {selectedSchool && (
            <SchoolSheet
              school={selectedSchool}
              toggled={
                toggledSchools.find((school) => school.id === selectedSchool.id)
                  ? true
                  : false
              }
              onToggleBoundary={() => handleSchoolToggle(selectedSchool)}
              disabled={toggledSchools.length >= 10}
            />
          )}
        </IonModal>
        <IonModal
          className="aecorn-modal"
          isOpen={showSearchModal}
          onDidDismiss={() => setShowSearchModal(false)}
          presentingElement={pageRef.current}>
          <ListingSearchModal
            dismiss={() => setShowSearchModal(false)}
            onSelectLocation={handleSearchLocationSelect}
            onSelectListing={handleSearchListingSelect}
          />
        </IonModal>
        <div
          className={`${styles.container} ${view === "list" && styles.noOverflow
            }`}>
          <div className={`${styles.map} ${view === "list" && styles.hidden}`}>
            <MapSearchBar onOpenSearchModal={() => setShowSearchModal(true)} />
            <Map
              isApp
              pageRef={pageRef}
              loading={clustersLoading}
              filters={filters}
              extraFilters={extraFilters}
              mapLocations={mapLocations}
              updateBoundaryFilter={updateMapFilter}
              clusters={clusters}
              selectedCluster={selectedCluster}
              onSelectCluster={handleSelectCluster}
              selectedListings={selectedListings}
              onSelectListings={handleSelectListings}
              onDeselectClusterAndListings={handleDeselectClusterAndListings}
              schools={schools}
              selectedSchool={selectedSchool}
              onSelectSchool={setSelectedSchool}
              toggledSchools={toggledSchools}
              onClearSchoolsBoundaries={() => setToggledSchools([])}
              view={view}
              setView={handleViewChange}
              onMapReady={handleMapReady}
            />
          </div>
          <div
            className={`${styles.listings} ${view === "map" && styles.hidden}`}>
            <div className={styles.listingsHeader}>
              <div className={styles.item}>
                <div className={styles.search}>
                  <IonItem
                    lines="none"
                    className="aecorn-search-input"
                    onClick={handleSearchInputClick}>
                    <IonIcon icon={searchOutline} />
                    <IonLabel>Search by location or MLS#</IonLabel>
                  </IonItem>
                </div>
                <IonButton
                  onClick={() => setShowFiltersModal(true)}
                  fill="clear"
                  className="aecorn-button clear">
                  <IonIcon slot="icon-only" icon={optionsOutline} />
                </IonButton>
              </div>
              <div className={styles.item}>
                <div className={styles.sort}>
                  <SortButton
                    value={sortBy.sort}
                    order={sortBy.order}
                    items={ListingsSortItems}
                    onSelect={handleSort}
                  />
                </div>
                <IonButton
                  onClick={() => setView("map")}
                  fill="clear"
                  className="aecorn-button clear">
                  <IonIcon slot="icon-only" icon={mapOutline} />
                </IonButton>
              </div>
            </div>
            <div
              className={styles.listingsList}
              ref={scrollRef}
              onScroll={handleListingsListScroll}>
              {listingsLoading && !listings.length ? (
                <IonList>
                  {Array.from({ length: 10 }).map((_, i) => (
                    <IonItem key={i} lines="none">
                      <IonThumbnail slot="start">
                        <IonSkeletonText animated />
                      </IonThumbnail>
                      <IonLabel>
                        <h3>
                          <IonSkeletonText
                            animated={true}
                            style={{ width: "80%" }}></IonSkeletonText>
                        </h3>
                        <p>
                          <IonSkeletonText
                            animated={true}
                            style={{ width: "60%" }}></IonSkeletonText>
                        </p>
                        <p>
                          <IonSkeletonText
                            animated={true}
                            style={{ width: "30%" }}></IonSkeletonText>
                        </p>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              ) : listings.length > 0 ? (
                listings.map((listing, index) => (
                  <ListingListItem
                    key={index}
                    listing={listing}
                    onClick={() =>
                      router.push(`/listings/${listing.mlsNumber}`)
                    }
                  />
                ))
              ) : (
                <div className={styles.noListings}>
                  Uh oh! There are no matching results for your current search.
                  Try zooming out or adjusting your filters.
                </div>
              )}
            </div>
            {listingsLoading && listings.length > 0 && (
              <IonSpinner name="bubbles" />
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Search;
