import { TourService } from "services/tourService";
import styles from "./client-tour.module.scss";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import { useHistory } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Tour, TourItem, TourRole, UserTour } from "API";
import { TourStop } from "models/tours/tour-stop.model";
import { TourItemService } from "services/tourItemService";
import { ListingService } from "services/listingService";
import MediaQuery from "react-responsive";
import Header from "components/web/Header/header.component";
import TourStatusPill from "components/shared/TourStatusPill/tour-status-pill.component";
import {
  calendarNumberOutline,
  ellipsisHorizontalCircleOutline,
  mailOutline,
  navigateOutline,
  timeOutline,
} from "ionicons/icons";
import moment from "moment";
import TourAttendee from "components/shared/Tours/TourAttendee/tour-attendee.component";
import ClientTourStopCard from "components/shared/Tours/ClientTourStopCard/client-tour-stop-card.component";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import NoNoteIcon from "assets/svg/icons/no-notes.svg";
import { markNotification } from "redux/ui/ui.actions";
import { useDispatch } from "react-redux";

enum TourTabs {
  Overview = 1,
  Listings = 2,
  GeneralNotes = 3,
}

const ClientTour = ({ tourId }: { tourId: string }) => {
  const router = useIonRouter();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [listingsLoading, setListingsLoading] = useState(true);
  const [tour, setTour] = useState<Tour>();
  const [tourItems, setTourItems] = useState<TourItem[]>();
  const [stops, setStops] = useState<TourStop[]>([]);

  const [activeTab, setActiveTab] = useState<TourTabs>(TourTabs.Overview);

  const segmentRef = useRef<HTMLIonSegmentElement>(null);

  const subscriptions = useRef<any[]>([]);

  const fetchTour = async () => {
    try {
      setLoading(true);
      const tour = await TourService.getTourById(tourId);
      if (tour) {
        await TourService.markTourAsSeen(tour);
        dispatch(markNotification(tour.id));
        setTour(tour);
      } else {
        history.replace("/tours");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchTourItems = async () => {
    try {
      setListingsLoading(true);
      const tourItems = await TourItemService.getTourItemsByTourId(tourId);
      setTourItems(tourItems);
      const tourStops = await Promise.all(
        tourItems
          .filter((item) => item)
          .map(async (item) => ({
            id: item!.id,
            listing: await ListingService.getListingByMlsNumber(
              item!.mlsNumber
            ),
            order: item?.order,
            status: item?.status,
            startTime: item?.startTime,
            endTime: item?.endTime,
          }))
      );

      setStops(tourStops.sort((s1, s2) => s1.order! - s2.order!));
    } catch (e) {
      console.error(e);
    } finally {
      setListingsLoading(false);
    }
  };

  const navigateTourStops = async () => {
    TourService.navigateTourStops(tour!, stops);
  };

  const handleSegmentScroll = () => {
    const scrollLeft = segmentRef.current?.scrollLeft || 0;

    const firstIonSegmentButton = segmentRef.current?.querySelector(
      "ion-segment-button"
    ) as HTMLElement;

    const firstIonSegmentButtonWidth = firstIonSegmentButton.clientWidth;

    if (scrollLeft > firstIonSegmentButtonWidth) {
      segmentRef.current?.classList.add(styles.noMargin);
    } else if (scrollLeft === 0) {
      segmentRef.current?.classList.remove(styles.noMargin);
    }
  };

  useEffect(() => {
    fetchTour();
  }, [tourId]);

  useIonViewWillEnter(() => {
    fetchTourItems();
  });

  useEffect(() => {
    if (tour) {
      const tourChangeSubscription = TourService.onTourUpdate(
        tour.id,
        (data) => {
          if (data) {
            setTour(data);
          }
        }
      );

      const tourPendingClientsSubscription =
        TourService.onTourPendingClientsUpdate(tour.id, (tour) => {
          if (tour) {
            setTour(tour);
          }
        });

      subscriptions.current.push(
        tourChangeSubscription,
        tourPendingClientsSubscription
      );
    }

    return () => {
      subscriptions.current.forEach((sub) => sub.unsubscribe());
    };
  }, [tour]);

  const TopSection = () => (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton
            className="aecorn-back-button"
            text="Back to My Tours"
            defaultHref="/tabs/tours"
          />
        </IonButtons>
      </IonToolbar>
      <div className={styles.summary}>
        <span className={styles.title}>
          <span>{tour?.title}</span>
          {tour?.status && <TourStatusPill status={tour.status} />}
          {/* <MediaQuery minWidth={1240}>
            <IonIcon
              className={styles.moreIcon}
              icon={ellipsisHorizontalCircleOutline}
            />
          </MediaQuery> */}
        </span>

        <div className={styles.topButtons}>
          <MediaQuery minWidth={1240}>
            <IonButton
              size="small"
              className="aecorn-button"
              disabled={!stops.length}
              onClick={navigateTourStops}>
              Navigate Tour
              <IonIcon icon={navigateOutline} slot="start" />
            </IonButton>
            <IonButton
              size="small"
              className="aecorn-button border clear"
              href={`mailto:${
                tour?.users?.items.find(
                  (user) => user?.role === TourRole.primaryagent
                )?.user?.email
              }`}>
              Contact agent
              <IonIcon icon={mailOutline} slot="start" />
            </IonButton>
          </MediaQuery>
          {/* <MediaQuery maxWidth={1239}>
            <IonIcon
              className={styles.moreIcon}
              icon={ellipsisHorizontalCircleOutline}
            />
          </MediaQuery> */}
        </div>
      </div>
      <div className={styles.datetime}>
        <div>
          {tour && (
            <span>
              <IonIcon icon={calendarNumberOutline} />{" "}
              {moment(tour.date).format("ddd, MMMM DD")}
            </span>
          )}
          {tour && tour.startTime && tour.endTime && (
            <span>
              <IonIcon icon={timeOutline} />{" "}
              {`${moment(tour.startTime, "HH:mm").format("hh:mm A")} - ${moment(
                tour.endTime,
                "HH:mm"
              ).format("hh:mm A")}`}
            </span>
          )}
        </div>
        <IonButton className="aecorn-button clear-dark">
          Add to Calendar
        </IonButton>
      </div>
      <MediaQuery maxWidth={1239}>
        <IonSegment
          ref={segmentRef}
          className={`${styles.segment} aecorn-segment dark`}
          mode="ios"
          scrollable
          value={activeTab}
          onIonChange={(ev) => setActiveTab(ev.detail.value as TourTabs)}
          onScroll={handleSegmentScroll}>
          <IonSegmentButton value={TourTabs.Overview}>
            Overview
          </IonSegmentButton>
          <IonSegmentButton value={TourTabs.Listings}>
            Listings
          </IonSegmentButton>
          <IonSegmentButton value={TourTabs.GeneralNotes}>
            General notes
          </IonSegmentButton>
        </IonSegment>
      </MediaQuery>
    </>
  );

  const Attendees = () => (
    <div className={styles.attendees}>
      <IonLabel className={styles.label}>Attendees</IonLabel>
      <div className={styles.items}>
        {tour?.users?.items.map((attendee: UserTour | null, index: number) =>
          attendee ? <TourAttendee key={index} attendee={attendee!} /> : null
        )}
      </div>
    </div>
  );

  const MeetupDetails = () => (
    <div className={styles.meetupDetails}>
      <IonLabel className={styles.label}>Meetup details</IonLabel>
      <div className={styles.content}>
        <span>Location: {tour?.meetupLocation}</span>
        <span>
          Time:{" "}
          {tour?.meetupTime &&
            moment(tour.meetupTime, "HH:mm").format("hh:mm A")}
        </span>
        <span>Notes: {tour?.meetupDescription}</span>
      </div>
    </div>
  );

  const Stops = () => (
    <div className={styles.stops}>
      <div className={styles.items}>
        {stops.map((stop) => (
          <ClientTourStopCard
            details
            key={stop.id}
            stop={stop}
            onNavigate={() =>
              router.push(`/tours/details/${tourId}/${stop.id}`)
            }
          />
        ))}
      </div>
    </div>
  );

  const GeneralNotes = () => (
    <div className={styles.generalNotes}>
      <div className={styles.text}>
        {tour?.generalNote ? <div>{tour.generalNote}</div> : <IonIcon icon={NoNoteIcon} />}
      </div>
    </div>
  );

  return (
    <>
      <IonLoading isOpen={submitLoading} message="Loading..." />
      <IonHeader mode="ios" className={styles.header}>
        <MediaQuery minWidth={1240}>
          <Header />
        </MediaQuery>
        <MediaQuery maxWidth={1239}>
          <TopSection />
        </MediaQuery>
      </IonHeader>
      <IonContent className={styles.content}>
        <MediaQuery minWidth={1240}>
          <TopSection />
        </MediaQuery>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <MediaQuery minWidth={1240}>
                <div className={styles.card}>
                    <section>
                        <IonLabel className={styles.sectionHeader}>Overview</IonLabel>
                        <div className={styles.overview}>
                            <Attendees />
                            <MeetupDetails />
                        </div>
                    </section>
                    <section>
                        <IonLabel className={styles.sectionHeader}>Listings</IonLabel>
                        <Stops />
                    </section>
                    <section>
                        <IonLabel className={styles.sectionHeader}>General notes</IonLabel>
                        <GeneralNotes />
                    </section>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1239}>
              {activeTab === TourTabs.Overview && (
                <div className={styles.overview}>
                  <Attendees />
                  <MeetupDetails />
                </div>
              )}
              {activeTab === TourTabs.Listings && <Stops />}
                {activeTab === TourTabs.GeneralNotes && <GeneralNotes />}
            </MediaQuery>
          </>
        )}
      </IonContent>
      <MediaQuery maxWidth={1239}>
        <IonFooter className={styles.footer}>
          <IonButton
            size="small"
            className="aecorn-button"
            disabled={!stops.length}
            onClick={navigateTourStops}>
            Navigate Tour
            <IonIcon icon={navigateOutline} slot="start" />
          </IonButton>
          <IonButton
            size="small"
            className="aecorn-button border clear"
            href={`mailto:${
              tour?.users?.items.find(
                (user) => user?.role === TourRole.primaryagent
              )?.user?.email
            }`}>
            Contact agent
            <IonIcon icon={mailOutline} slot="start" />
          </IonButton>
        </IonFooter>
      </MediaQuery>
    </>
  );
};

export default ClientTour;
