import { ShareStatus, Tour } from "API";
import styles from "./agent-tour-card.module.scss";
import { IonButton, IonIcon } from "@ionic/react";
import {
  calendarNumberOutline,
  chevronDownOutline,
  chevronForwardOutline,
  chevronUpOutline,
  documentTextOutline,
  ellipsisHorizontalCircleOutline,
  locationOutline,
  personOutline,
} from "ionicons/icons";
import moment from "moment";
import { TourService } from "services/tourService";
import { useEffect, useState } from "react";
import { ListingService } from "services/listingService";
import TourStopsModal from "components/shared/Modals/TourStopsModal/tour-stops-modal.component";
import TourStatusPill from "components/shared/TourStatusPill/tour-status-pill.component";
import { TourStop } from "models/tours/tour-stop.model";
import AddToCalendarButton from "components/shared/AddToCalendarButton/add-to-calendar-button.component";

const AgentTourCard = ({ tour }: { tour: Tour }) => {
  const [latestActivityCollapsed, setLatestActivityCollapsed] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stops, setStops] = useState<TourStop[]>([]);

  const client = TourService.getTourClient(tour);
  const primaryAgent = TourService.getTourPrimaryAgent(tour);
  const guests = TourService.getTourGuests(tour);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const tourItems = tour.tourItems?.items ?? [];
        const tourStops = await Promise.all(
          tourItems
            .filter((item) => item)
            .map(async (item) => ({
              id: item!.id,
              listing: await ListingService.getListingByMlsNumber(
                item!.mlsNumber
              ),
              order: item?.order,
              status: item?.status,
              startTime: item?.startTime,
              endTime: item?.endTime,
            }))
        );

        setStops(tourStops);
      } catch (e) {
        console.error(e);
      }
    };

    fetchListings();
  }, [tour]);

  return (
    <div className={styles.container}>
      <TourStopsModal
        tourId={tour.id}
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        stops={stops}
      />
      <div className={styles.header}>
        <div className={styles.title}>
          <span>{tour.title}</span>{" "}
          {tour.shared === ShareStatus.unshared && (
            <span>Not shared with client</span>
          )}
        </div>
        <div className={styles.status}>
          <TourStatusPill status={tour.status!} />
          <IonIcon icon={ellipsisHorizontalCircleOutline} />
        </div>
      </div>
      <div className={styles.date}>
        <div className={styles.icon}>
          <IonIcon icon={calendarNumberOutline} />
        </div>
        <div className={styles.info}>
          <span>{moment(tour.date).format("ddd, MMMM DD, YYYY")}</span>
          {tour.startTime && tour.endTime && (
            <span>
              {moment(tour.startTime, "HH:mm").format("hh:mm A")} to{" "}
              {moment(tour.endTime, "HH:mm").format("hh:mm A")}
            </span>
          )}
          {tour.date && (
            <div className={styles.addToCalendarButton}>
              <AddToCalendarButton
                iconOnly
                event={{
                  title: tour.title ?? "",
                  startDate: tour.startTime
                    ? moment(tour.date)
                        .set("hours", +tour.startTime.split(":")[0])
                        .set("minutes", +tour.startTime.split(":")[1])
                        .toDate()
                        .getTime()
                    : new Date(tour.date).getUTCMilliseconds(),
                  endDate: tour.endTime
                    ? moment(tour.date)
                        .set("hours", +tour.endTime.split(":")[0])
                        .set("minutes", +tour.endTime.split(":")[1])
                        .toDate()
                        .getTime()
                    : new Date(tour.date).getTime(),
                  location: tour.meetupLocation ?? "",
                  notes: tour.generalNote ?? "",
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.attendees}>
        <div className={styles.icon}>
          <IonIcon icon={personOutline} />
        </div>
        <div className={styles.info}>
          {client && (
            <div>
              <span>Client:</span>
              <span>{client.displayName}</span>
            </div>
          )}
          {primaryAgent && (
            <div>
              <span>Lead Agent:</span>
              <span>{primaryAgent.displayName}</span>
            </div>
          )}
          {guests && guests.length > 0 && (
            <div>
              <span>Guests:</span>
              <span>
                {guests.map((guest) => guest?.displayName).join(", ")}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.showings}>
        <div className={styles.icon}>
          <IonIcon icon={locationOutline} />
        </div>
        <div className={styles.info}>
          <div>
            <span
              className={
                tour.tourItems?.items.length
                  ? styles.withStops
                  : styles.withoutStops
              }>
              {tour.tourItems?.items.length ?? 0}
            </span>
            <span>Stops</span>
          </div>
          <IonButton
            className="aecorn-button clear"
            disabled={!stops.length}
            onClick={() => setIsModalOpen(true)}>
            Details
            <IonIcon slot="end" icon={chevronForwardOutline} />
          </IonButton>
        </div>
      </div>
      {/* <div className={styles.activity}>
        <div className={styles.icon}>
          <IonIcon icon={documentTextOutline} />
        </div>
        <div className={styles.info}>
          <div
            onClick={() =>
              setLatestActivityCollapsed(!latestActivityCollapsed)
            }>
            <div>Latest activity</div>
            <IonIcon
              icon={
                latestActivityCollapsed ? chevronDownOutline : chevronUpOutline
              }
            />
          </div>
          {!latestActivityCollapsed && (
            <div className={styles.items}>
              <div className={styles.activityItem}>
                <span>John Doe</span>
                <span>2 Days ago</span>
              </div>
              <div className={styles.activityItem}>
                <span>John Doe</span>
                <span>2 Days ago</span>
              </div>
            </div>
          )}
        </div>
      </div> */}
      <div className={styles.viewButtonContainer}>
        <IonButton
          className="aecorn-button dark"
          routerLink={`/tours/details/${tour.id}`}>
          View
        </IonButton>
      </div>
    </div>
  );
};

export default AgentTourCard;
