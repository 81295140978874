import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";
import { useHistory } from "react-router"

const APPURLListener = () => {
    const history = useHistory();

    useEffect(() => {
        App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            const slug = event.url.split(".ca").pop();

            if (slug) {
                if (slug.startsWith("/?") || slug.startsWith("/oauth")) {
                    const code = slug.split("/?")[1];
                    document.location.href = `/?${code}`;
                } else {
                    if (slug === "/") {
                        history.push("/home");
                    } else {
                        console.log("slug", slug);
                        history.push(slug);
                    }
                }

                if (Capacitor.isNativePlatform()) {
                    Browser.close();
                }
            }
        });
    }, []);

    return null;
}

export default APPURLListener;