import { TourItem } from "API";
import styles from "./tour-stop-uploads.module.scss";
import TourStopUploadsPhotos from "./components/tour-stop-uploads-photos.component";
import { useEffect, useState } from "react";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import TourStopUploadsMessages from "./components/tour-stop-uploads-messages.component";
import { Storage } from "aws-amplify";

export type TourStopMessage = {
  id: string;
  type: "text" | "image" | "audio";
  content?: string;
  url?: string;
  blob?: Blob;
  duration?: string;
  datetime: string;
  from: string;
  userId: string;
};

export type TourUserData = {
  userId: string;
  name: string;
  profilePicture: string;
};

const TourStopUploads = ({
  tourItem,
  hidden,
  isClient
}: {
  tourItem: TourItem;
  hidden: boolean;
  isClient?: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState<TourUserData[]>([]);

  useEffect(() => {
    const mapUsersData = async () => {
      try {
        setLoading(true);
        const usersData: TourUserData[] = [];
        for (const user of tourItem.tour?.users?.items || []) {
          if (user) {
            let profilePicture = "";
            if (user.user?.profilePicture) {
              profilePicture = await Storage.get(user.user.profilePicture);
            }
            usersData.push({
              userId: user.userId,
              name: user.user?.displayName || "Unknown",
              profilePicture,
            });
          }
        }

        setUsersData(usersData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    mapUsersData();
  }, [tourItem.tourId]);

  return (
    <div
      className={`${styles.container} ${!isClient && styles.agentContainer}`}
      style={{ display: hidden ? "none" : "flex" }}>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className={styles.card}>
          {/* <TourStopUploadsPhotos tourItem={tourItem} usersData={usersData} /> */}
          <TourStopUploadsMessages tourItem={tourItem} usersData={usersData} />
        </div>
      )}
    </div>
  );
};

export default TourStopUploads;
