import MapButton from "../MapButton/map-button.component";
import { ReactComponent as DrawIcon } from "assets/svg/draw.svg";
import { ReactComponent as SchoolIcon } from "assets/svg/school.svg";
import { ReactComponent as SatelliteIcon } from "assets/svg/satellite-view.svg";
import { ReactComponent as MapIcon } from "assets/svg/map-view.svg";
import { ReactComponent as FiltersIcon } from "assets/svg/filters.svg";
import { ReactComponent as ListViewIcon } from "assets/svg/grid.svg";
import { useDispatch, useSelector } from "react-redux";
import styles from "./map-buttons-top.module.scss";
import { IonModal } from "@ionic/react";
import SchoolsFiltersModal from "components/shared/Modals/SchoolsFiltersModal/schools-filters-modal.component";
import { selectIsSchoolsActive } from "redux/schools/schools.selectors";
import { selectDrawMode } from "redux/map/map.selectors";
import { toggleDrawMode } from "redux/map/map.actions";
import ListingsFiltersModal from "components/shared/Modals/ListingsFiltersModal/listings-filters-modal.component";
import { MutableRefObject, useState } from "react";
import { ListingsFiltersService } from "services/listingsFiltersService";

const MapButtonsTop = ({
  isApp,
  pageRef,
  onChangeMapTyoe,
  isDrawingDisabled,
  mapType,
  view,
  setView
}: {
  isApp: boolean;
  pageRef: MutableRefObject<HTMLElement | undefined>;
  onChangeMapTyoe: () => void;
  isDrawingDisabled: boolean;
  mapType: "roadmap" | "satellite";
  view: "map" | "list";
  setView: (view: "map" | "list") => void;
}) => {
  const dispatch = useDispatch();
  const isSchoolsEnabled = useSelector(selectIsSchoolsActive);
  const isDrawModeEnabled = useSelector(selectDrawMode);
  const [showListingsFilterModal, setShowListingsFilterModal] = useState(false);
  const [showSchoolsFilterModal, setShowSchoolsFilterModal] = useState(false);

  const handleListingsFilter = () => {
    setShowListingsFilterModal(true);
  };

  const handleSchoolsFilter = () => {
    setShowSchoolsFilterModal(true);
  };

  const handleToggleDrawMode = () => {
    dispatch(toggleDrawMode());
  };

  return (
    <div className={styles.container}>
      <IonModal
        className="aecorn-modal"
        isOpen={showListingsFilterModal}
        presentingElement={isApp ? pageRef.current : undefined}
        onDidDismiss={() => setShowListingsFilterModal(false)}>
        <ListingsFiltersModal
          dismiss={() => setShowListingsFilterModal(false)}
        />
      </IonModal>
      <IonModal
        className="aecorn-modal"
        isOpen={showSchoolsFilterModal}
        presentingElement={isApp ? pageRef.current : undefined}
        onDidDismiss={() => setShowSchoolsFilterModal(false)}>
        <SchoolsFiltersModal dismiss={() => setShowSchoolsFilterModal(false)} />
      </IonModal>
      {isApp && (
        <>
          <MapButton icon={<FiltersIcon />} hasUpdates={ListingsFiltersService.hasActiveFilters()} onClick={handleListingsFilter} />
          <MapButton
            icon={view === "map" ? <ListViewIcon /> : <MapIcon />}
            onClick={() => setView(view === "map" ? "list" : "map")}
            disabled={isDrawingDisabled}
          />
        </>
      )}
      <MapButton
        icon={<SchoolIcon />}
        onClick={handleSchoolsFilter}
        active={isSchoolsEnabled}
      />
      <MapButton
        icon={<DrawIcon />}
        onClick={handleToggleDrawMode}
        active={isDrawModeEnabled}
        disabled={isDrawingDisabled}
      />
      <MapButton
        icon={mapType === "roadmap" ? <SatelliteIcon /> : <MapIcon />}
        onClick={onChangeMapTyoe}
      />
    </div>
  );
};

export default MapButtonsTop;
