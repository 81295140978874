import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import styles from "./dashboard.module.scss";
import MediaQuery from "react-responsive";
import Header from "components/web/Header/header.component";
import { personOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import Favorites from "./segments/Favorites/favorites.component";
import { Favourite, Search } from "API";
import LoadingScreen from "components/shared/LoadingScreen/loading-screen.component";
import { FavoritesService } from "services/favoritesService";
import SortIcon from "assets/svg/sort.svg";
import { Listing } from "models/listings/listing.model";
import { ListingService } from "services/listingService";
import SavedSearches from "./segments/SavedSearches/saved-searches.component";
import { SavedSearchesService } from "services/savedSearchesService";
import AvailableTimeframes from "./segments/AvailableTimeframes/available-timeframes.component";
import { useSelector } from "react-redux";
import { selectIsAuthenticated, selectIsClient } from "redux/user/user.selectors";
import { Redirect } from "react-router";

enum DashboardTabs {
  Favorites = "favorites",
  SavedSearches = "savedSearches",
  AvailableTimeframes = "availableTimeframes",
}

const Dashboard = () => {
  const segmentRef = useRef<HTMLIonSegmentElement>(null);
  const [activeTab, setActiveTab] = useState<DashboardTabs>(
    DashboardTabs.Favorites
  );
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isUserClient = useSelector(selectIsClient);

  const [loading, setLoading] = useState<boolean>(true);

  const [savedSearches, setSavedSearches] = useState<Search[]>([]);

  const [favorites, setFavorites] = useState<
    { item: Favourite; listing: Listing }[]
  >([]);
  const [favoritesSortBy, setFavoritesSortBy] = useState<
    "price" | "date" | "status" | "beds" | "baths"
  >("date");

  const subscriptions = useRef<any[]>([]);

  const handleSegmentScroll = () => {
    const scrollLeft = segmentRef.current?.scrollLeft || 0;

    const firstIonSegmentButton = segmentRef.current?.querySelector(
      "ion-segment-button"
    ) as HTMLElement;

    const firstIonSegmentButtonWidth = firstIonSegmentButton.clientWidth;

    if (scrollLeft > firstIonSegmentButtonWidth) {
      segmentRef.current?.classList.add(styles.noMargin);
    } else if (scrollLeft === 0) {
      segmentRef.current?.classList.remove(styles.noMargin);
    }
  };

  const onChangeFavoriteSort = (e: CustomEvent) => {
    const popover = document.querySelector("ion-popover");
    popover?.dismiss();
    setFavoritesSortBy(e.detail.value);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const userFavorites = await FavoritesService.getFavorites();
        const listingsPromises = userFavorites.map(async (favorite) => {
          const listing = await ListingService.getListingByMlsNumber(
            favorite.mlsNumber
          );
          return { item: favorite, listing };
        });
        const items = await Promise.all(listingsPromises);
        setFavorites(
          items.sort(
            (a, b) =>
              new Date(
                b.listing.updatedOn ? b.listing.updatedOn : b.listing.listDate
              ).getTime() -
              new Date(
                a.listing.updatedOn ? a.listing.updatedOn : a.listing.listDate
              ).getTime()
          )
        );

        const userSavedSearches = await SavedSearchesService.getSavedSearches();
        setSavedSearches(userSavedSearches);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      loadData();
    } else {
      setFavorites([]);
      setSavedSearches([]);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setFavorites((prev) => {
      const sortedFavorites = [...prev].sort((a, b) => {
        let result = 0;
        switch (favoritesSortBy) {
          case "price":
            result = +b.listing.listPrice - +a.listing.listPrice;
            break;
          case "date":
            result =
              new Date(b.listing.updatedOn || b.listing.listDate).getTime() -
              new Date(a.listing.updatedOn || a.listing.listDate).getTime();
            break;
          case "status":
            result = b.listing.lastStatus.localeCompare(a.listing.lastStatus);
            break;
          case "beds":
            result =
              +b.listing.details.numBedrooms - +a.listing.details.numBedrooms;
            break;
          case "baths":
            result =
              +b.listing.details.numBathrooms - +a.listing.details.numBathrooms;
            break;
          default:
            result = 0;
        }

        if (result === 0) {
          return a.listing.mlsNumber.localeCompare(b.listing.mlsNumber);
        }

        return result;
      });

      return sortedFavorites;
    });
  }, [favoritesSortBy]);

  useEffect(() => {
    const subscribe = async () => {
      const addFavoriteSubscription = await FavoritesService.onCreateFavorite(
        async (favorite) => {
          const listing = await ListingService.getListingByMlsNumber(
            favorite.mlsNumber
          );
          setFavorites((prev) => [...prev, { item: favorite, listing }]);
        }
      );
      const deleteFavoriteSubscription =
        await FavoritesService.onDeleteFavorite(async (favorite) => {
          setFavorites((prev) =>
            prev.filter((fav) => fav.item.id !== favorite.id)
          );
        });
      const updateFavoriteSubscription =
        await FavoritesService.onUpdateFavorite(async (favorite) => {
          const listing = await ListingService.getListingByMlsNumber(
            favorite.mlsNumber
          );
          setFavorites((prev) =>
            prev.map((fav) =>
              fav.item.id === favorite.id ? { item: favorite, listing } : fav
            )
          );
        });

      const addSavedSearchSubscription =
        await SavedSearchesService.onCreateSavedSearch(async (search) => {
          setSavedSearches((prev) => [...prev, search]);
        });

      const deleteSavedSearchSubscription =
        await SavedSearchesService.onDeleteSavedSearch(async (search) => {
          setSavedSearches((prev) => prev.filter((s) => s.id !== search.id));
        });

      const updateSavedSearchSubscription =
        await SavedSearchesService.onUpdateSavedSearch(async (search) => {
          setSavedSearches((prev) =>
            prev.map((s) => (s.id === search.id ? search : s))
          );
        });

      const subcriptions = [
        addFavoriteSubscription,
        deleteFavoriteSubscription,
        updateFavoriteSubscription,
        addSavedSearchSubscription,
        deleteSavedSearchSubscription,
        updateSavedSearchSubscription,
      ];
      subscriptions.current.push(...subcriptions);
    };

    subscribe();

    return () => {
      subscriptions.current.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  const FavoritesSort = () => {
    return (
      <div className={styles.favoritesSort}>
        <IonPopover
          dismissOnSelect
          trigger="favorites-sort-trigger"
          triggerAction="click"
          showBackdrop={false}>
          <IonContent className={styles.popoverContent}>
            <IonRadioGroup
              className="aecorn-radio"
              value={favoritesSortBy}
              onIonChange={(e) => onChangeFavoriteSort(e)}>
              <IonItem lines="none">
                <IonRadio labelPlacement="end" value={"price"}>
                  Price
                </IonRadio>
              </IonItem>
              <IonItem lines="none">
                <IonRadio labelPlacement="end" value={"date"}>
                  Date
                </IonRadio>
              </IonItem>
              <IonItem lines="none">
                <IonRadio labelPlacement="end" value={"status"}>
                  Status
                </IonRadio>
              </IonItem>
              <IonItem lines="none">
                <IonRadio labelPlacement="end" value={"beds"}>
                  Beds
                </IonRadio>
              </IonItem>
              <IonItem lines="none">
                <IonRadio labelPlacement="end" value={"baths"}>
                  Baths
                </IonRadio>
              </IonItem>
            </IonRadioGroup>
          </IonContent>
        </IonPopover>
        {/* <IonButton className="aecorn-button clear-dark">
          <IonIcon slot="start" icon={mapOutline} />
          View on map
        </IonButton> */}
        <IonButton className="aecorn-button white" id="favorites-sort-trigger">
          <IonIcon icon={SortIcon} />
        </IonButton>
      </div>
    );
  };

  const TopSection = () => (
    <div className={styles.topSection}>
      <div className={styles.summary}>
        <span className={styles.title}>Dashboard</span>
        <MediaQuery maxWidth={767}>
          <IonButton size="small" className="aecorn-button" routerLink="/profile">
            <IonIcon icon={personOutline} />
          </IonButton>
        </MediaQuery>
      </div>
      <IonSegment
        ref={segmentRef}
        className={`${styles.segment} aecorn-segment dark`}
        mode="ios"
        scrollable
        value={activeTab}
        onIonChange={(ev) => setActiveTab(ev.detail.value as DashboardTabs)}
        onScroll={handleSegmentScroll}>
        <IonSegmentButton value={DashboardTabs.Favorites}>
          Favorites
        </IonSegmentButton>
        <IonSegmentButton value={DashboardTabs.SavedSearches}>
          Saved searches
        </IonSegmentButton>
        {isUserClient && (
          <IonSegmentButton value={DashboardTabs.AvailableTimeframes}>
            Available timeframes
          </IonSegmentButton>
        )}
      </IonSegment>
      {activeTab === DashboardTabs.Favorites && (
        <MediaQuery minWidth={768}>
          <FavoritesSort />
        </MediaQuery>
      )}
    </div>
  );

  return (
    <IonPage>
      {!isAuthenticated && <Redirect to="/" />}
      <IonHeader mode="ios" className={styles.header}>
        <MediaQuery minWidth={768}>
          <Header />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <TopSection />
        </MediaQuery>
      </IonHeader>
      <IonContent className={styles.content}>
        <MediaQuery minWidth={768}>
          <TopSection />
        </MediaQuery>
        {loading ? <LoadingScreen /> : <></>}
        {activeTab === DashboardTabs.Favorites && (
          <div className={styles.segmentContainer}>
            <MediaQuery maxWidth={767}>
              <FavoritesSort />
            </MediaQuery>
            <Favorites favorites={favorites} loading={loading} />
          </div>
        )}
        {activeTab === DashboardTabs.SavedSearches && (
          <div className={styles.segmentContainer}>
            <SavedSearches savedSearches={savedSearches} />
          </div>
        )}
        {activeTab === DashboardTabs.AvailableTimeframes && (
          <div className={styles.segmentContainer}>
            <AvailableTimeframes />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
