import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonLoading,
  IonRadio,
  IonRadioGroup,
  useIonToast,
} from "@ionic/react";
import styles from "./select-agent-modal.module.scss";
import modalStyles from "../modals.module.scss";
import { User } from "API";
import { useEffect, useState } from "react";
import { closeOutline, searchOutline } from "ionicons/icons";
import { UserService } from "services/userService";

const SelectAgentModal = ({
  dismiss,
  client,
}: {
  dismiss: () => void;
  client: User;
}) => {
  const [presentToast] = useIonToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasAgent, setHasAgent] = useState<boolean | null>(null);
  const [agent, setAgent] = useState<User | null>(null);
  const [agentName, setAgentName] = useState<string>("");
  const [agents, setAgents] = useState<User[]>([]);

  const toggleHasAgent = (value: boolean) => {
    setHasAgent(value);
    if (!value) {
      setAgent(null);
      setAgentName("");
      setAgents([]);
    }
  };

  const onSelectAgent = (agent: User) => {
    setAgent(agent);
    setAgentName("");
  };

  const findAgents = async () => {
    const agents = (await UserService.getAgentUsers(agentName)) as User[];
    setAgents(agents);
  };

  useEffect(() => {
    if (!agentName) {
      setAgents([]);
      return;
    }
    findAgents();
  }, [agentName]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await UserService.updateClientAgent(client.id, agent?.id);
      presentToast({
        message: "Agent updated successfully",
        duration: 5000,
        cssClass: "aecorn-success-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
      dismiss();
    } catch (error) {
      console.log(error);
      presentToast({
        message: "An error occurred while upading the agent",
        duration: 5000,
        cssClass: "aecorn-error-toast",
        position: "top",
        buttons: [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={modalStyles.modalHeader}>
        <div className={styles.title}>Agent</div>
      </div>
      <IonLoading isOpen={loading} message="Loading..." />{" "}
      <div className={styles.form}>
        <label>Already have an AECORN agent?</label>
        <IonRadioGroup
          className="aecorn-radio"
          value={hasAgent}
          onIonChange={(e) => toggleHasAgent(e.detail.value)}>
          <IonItem lines="none">
            <IonRadio value={true}>Yes</IonRadio>
          </IonItem>
          <IonItem lines="none">
            <IonRadio value={false}>No</IonRadio>
          </IonItem>
        </IonRadioGroup>
        {hasAgent && (
          <>
            <label>Type or search your agent name here.</label>
            <IonInput
              className="aecorn-input dark"
              placeholder="Agent name"
              value={agentName}
              clearInput
              debounce={500}
              onIonInput={(e) => setAgentName(e.detail.value ?? "")}>
              <IonIcon icon={searchOutline} slot="start" />
            </IonInput>
            <IonList>
              {agent && (
                <div className={styles.agent}>
                  <span>
                    {agent.givenName} {agent.familyName}
                  </span>
                  <IonButton
                    size="small"
                    shape="round"
                    disabled
                    className="aecorn-button">
                    Selected
                  </IonButton>
                </div>
              )}
              {agents
                .filter((item) => item.id !== agent?.id)
                .map((item) => (
                  <div key={item.id} className={styles.agent}>
                    <span>
                      {item.givenName} {item.familyName}
                    </span>
                    <IonButton
                      size="small"
                      shape="round"
                      onClick={() => onSelectAgent(item)}
                      disabled={item.id === agent?.id}
                      className="aecorn-button">
                      {item.id === agent?.id ? "Selected" : "Select"}
                    </IonButton>
                  </div>
                ))}
            </IonList>
          </>
        )}
      </div>
      <div className={modalStyles.footer}>
        <IonButton
          size="large"
          shape="round"
          className="aecorn-button"
          disabled={hasAgent === null || (hasAgent === true && !agent)}
          onClick={onSubmit}>
          Continue
        </IonButton>
      </div>
    </>
  );
};

export default SelectAgentModal;
