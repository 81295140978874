import styles from "./error-screen.module.scss";
import { IonButton, useIonModal, useIonRouter } from "@ionic/react";
import { ReactComponent as NotFoundSVG } from "assets/svg/not-found.svg";
import { ReactComponent as ServerSVG } from "assets/svg/server-error.svg";
import { ReactComponent as AccessDeniedSVG } from "assets/svg/restricted.svg";
import AuthenticationModal from "../Modals/AuthenticationModal/authentication-modal.component";
import { AuthenticationService } from "services/authenticationService";

const ErrorScreen = ({ errorCode, onSignIn }: { errorCode: number, onSignIn?: () => void}) => {
  const router = useIonRouter();
  const [presentAuthenticationModal, dismiss] = useIonModal(
    AuthenticationModal,
    {
      onDismiss: () => {
        dismiss();
      },
      mode: "login",
    }
  );

  const getIllustration = () => {
    switch (errorCode) {
      case 404:
        return <NotFoundSVG />;
      case 403:
      case 401:
        return <AccessDeniedSVG />;
      default:
        return <ServerSVG />;
    }
  };

  const getMessage = () => {
    switch (errorCode) {
      case 404:
        return "The page you are looking for does not exist.";
      case 403:
      case 401:
        return "You are not authorized to view this page.";
      default:
        return "Something went wrong. Please try again later.";
    }
  };

  const buttonAction = () => {
    switch (errorCode) {
      case 404:
        if (router.canGoBack()) {
          router.goBack();
        } else {
          router.push("/");
        }
        break;
      case 403:
      case 401:
        presentAuthenticationModal({
          cssClass: "auth-modal",
          onDidDismiss: () => {
            if (onSignIn && AuthenticationService.isAuthenticated()) {
                onSignIn();
            }
          }
        });
        break;
      default:
        return {
          label: "Reload",
          onClick: () => {
            window.location.reload();
          },
        };
    }
  };

  const getButtonLabel = () => {
    switch (errorCode) {
      case 404:
        return "Back";
      case 403:
      case 401:
        return "Sign In";
      default:
        return "Reload";
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.illustrationContainer}>{getIllustration()}</div>
      <div className={styles.title}>Oops!</div>
      <div className={styles.message}>{getMessage()}</div>
      <IonButton className="aecorn-button" onClick={buttonAction}>
        {getButtonLabel()}
      </IonButton>
    </div>
  );
};

export default ErrorScreen;
