import { GraphQLQuery } from "@aws-amplify/api";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import { API } from "aws-amplify";
import { store } from "redux/store";
import { GetSchoolQuery, ListSchoolsQuery } from "./types/schools.types";
import { School, SchoolRating } from "API";
import { getSchool, listSchools } from "./queries/schools.queries";
import { Listing } from "models/listings/listing.model";
import { getBoundingCoords } from "utils/functions";

const getSchools = async () => {
  try {
    const boundary = store.getState().map.boundary;
    const data: School[] = [];
    let token: string | null | undefined = "";

    const {
      elementary,
      middle,
      high,
      frenchImmersion,
      ib,
      ap,
      sports,
      arts,
      gifted,
      public: isPublic,
      private: isPrivate,
      catholic,
      rate,
      showUnrated,
    } = store.getState().schools.filters;
    const isAuthenticated = store.getState().user.currentUser;
    const selectedSchools = store.getState().schools.filterListings.schools;

    const filter: any = {
      and: [
        { longitude: { gt: boundary[0][0][0] } },
        { longitude: { lt: boundary[0][1][0] } },
        { latitude: { gt: boundary[0][2][1] } },
        { latitude: { lt: boundary[0][0][1] } },
      ],
    };

    if (rate && !showUnrated) {
      filter.and.push({ ettieOverallAvg: { ge: rate * 10 } });
    }

    if (rate && showUnrated) {
      filter.and.push({
        and: [
          {
            or: [
              { ettieOverallAvg: { ge: rate * 10 } },
              { ettieOverallAvg: { eq: 0 } },
            ],
          },
        ],
      });
    }

    if (!rate && !showUnrated) {
      filter.and.push({ ettieOverallAvg: { gt: 0 } });
    }

    if (elementary || middle || high) {
      filter.and.push({
        and: [
          {
            or: [
              elementary ? { isElementary: { eq: elementary } } : null,
              middle ? { isMiddle: { eq: middle } } : null,
              high ? { isHigh: { eq: high } } : null,
            ].filter((item) => item !== null),
          },
        ],
      });
    }

    if (isPublic || catholic) {
      filter.and.push({
        and: [
          {
            or: [
              isPublic ? { isPublic: { eq: isPublic } } : null,
              catholic ? { isCatholic: { eq: catholic } } : null,
            ].filter((item) => item !== null),
          },
        ],
      });
    }

    if (frenchImmersion || gifted || ap || ib || arts || sports) {
      filter.and.push({
        and: [
          {
            or: [
              frenchImmersion
                ? { isFrenchImmersion: { eq: frenchImmersion } }
                : null,
              gifted ? { isGifted: { eq: gifted } } : null,
              ap ? { isAP: { eq: ap } } : null,
              ib ? { isIB: { eq: ib } } : null,
              sports ? { isSport: { eq: sports } } : null,
              arts ? { isArts: { eq: arts } } : null,
            ].filter((item) => item !== null),
          },
        ],
      });
    }

    do {
      const variables: any = {
        filter,
        limit: 200,
        ...(token && { nextToken: token }),
      };

      const response = await API.graphql<GraphQLQuery<ListSchoolsQuery>>({
        query: listSchools,
        authMode: isAuthenticated
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
        variables: variables,
      });

      const schools = response.data?.listSchools?.items.filter(
        (s) => s !== null
      ) as School[];

      if (schools && schools.length > 0) {
        data.push(...schools);
      }

      if (response.data?.listSchools?.nextToken == token) {
        token = null;
      } else {
        token = response.data?.listSchools?.nextToken;
      }
    } while (token !== null);

    return data
      .filter(
        (s) => !selectedSchools.find((school: School) => school.id === s.id)
      )
      .concat(selectedSchools)
      .reverse();
  } catch (err) {
    console.log(err);
    return;
  }
};

export const getSchoolById = async (id: string) => {
  const isAuthenticated = store.getState().user.currentUser;
  const response = await API.graphql<GraphQLQuery<GetSchoolQuery>>({
    query: getSchool,
    authMode: isAuthenticated
      ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      : GRAPHQL_AUTH_MODE.AWS_IAM,
    variables: { id },
  });

  return response.data?.getSchool;
};

const findNearbySchools = async ({ boundary }: { boundary: number[][] }) => {
  try {
    const currentUser = store.getState().user.currentUser;
    const data: School[] = [];
    let token: string | null | undefined = "";

    const filter = {
      and: [
        { longitude: { gt: boundary[0][0] } },
        { longitude: { lt: boundary[1][0] } },
        { latitude: { gt: boundary[2][1] } },
        { latitude: { lt: boundary[0][1] } },
      ],
    };

    do {
      const variables: any = {
        filter,
        limit: 200,
        ...(token && { nextToken: token }),
      };

      const response = await API.graphql<GraphQLQuery<ListSchoolsQuery>>({
        query: listSchools,
        authMode: currentUser
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
        variables: variables,
      });

      const schools = response.data?.listSchools?.items.filter(
        (s) => s !== null
      ) as School[];

      if (schools && schools.length > 0) {
        data.push(...schools);
      }

      if (response.data?.listSchools?.nextToken == token) {
        token = null;
      } else {
        token = response.data?.listSchools?.nextToken;
      }
    } while (token !== null);

    return data;
  } catch (err) {
    console.log(err);
    return;
  }
};

const findListingNearbySchools = async (listing: Listing) => {
  const { latitude, longitude } = listing.map;

  const { topLeft, topRight, bottomRight, bottomLeft } = getBoundingCoords(
    parseFloat(latitude),
    parseFloat(longitude),
    8000
  );

  return await findNearbySchools({
    boundary: [
      [topLeft.lng, topLeft.lat],
      [topRight.lng, topRight.lat],
      [bottomRight.lng, bottomRight.lat],
      [bottomLeft.lng, bottomLeft.lat],
    ],
  });
};

const getSchoolGradeRange = (school: School) => {
  const from = school.gradeFrom === -1 ? "JK" : school.gradeFrom;
  const to = school.gradeEnd;

  return { from, to };
};

const getSchoolLastYearScores = (school: School) => {
  const ratings = school.ratings?.items;
  if (!ratings) return;

  const compareYears = (a: SchoolRating | null, b: SchoolRating | null) => {
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;
    const year1 = a.year || 0;
    const year2 = b.year || 0;
    return year2 - year1;
  };

  const lastYear = ratings.sort(compareYears)[0] as SchoolRating;

  const scores = [];

  if (lastYear.G3Rank && lastYear.G3Rank !== -1 && lastYear.G3Total) {
    scores.push({
      yearMark: lastYear.yearMark,
      grade: "G3",
      rank: `${lastYear.G3Rank}/${lastYear.G3Total}`,
    });
  }

  if (lastYear.G6Rank && lastYear.G6Rank !== -1 && lastYear.G6Total) {
    scores.push({
      yearMark: lastYear.yearMark,
      grade: "G6",
      rank: `${lastYear.G6Rank}/${lastYear.G6Total}`,
    });
  }

  if (lastYear.G9Rank && lastYear.G9Rank !== -1 && lastYear.G9Total) {
    scores.push({
      yearMark: lastYear.yearMark,
      grade: "G9",
      rank: `${lastYear.G9Rank}/${lastYear.G9Total}`,
    });
  }

  return scores;
};

const getSchoolBoundary = (school: School) => {
  const boundary = school.boundaryArray;

  if (!boundary || boundary === "[]") return;

  return school.boundaryArray;
};

export const SchoolsService = {
  getSchoolById,
  getSchools,
  findNearbySchools,
  findListingNearbySchools,
  getSchoolGradeRange,
  getSchoolLastYearScores,
  getSchoolBoundary,
};
