import { useState } from "react";
import styles from "../../authentication-modal.module.scss";
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonLabel,
  IonNote,
  IonSpinner,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { AuthenticationService } from "services/authenticationService";

const SignUpForm = ({
  onSuccess,
  onError,
  onSwitch,
}: {
  onSuccess: (accountInfo: { email: string; password: string, phone_number?: string }) => void;
  onError: (message: string | null) => void;
  onSwitch: (mode: "login") => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && isValid) {
      handleSubmit(onSignUp);
    }
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm<{
    email: string;
    password: string;
    confirm_password: string;
    given_name: string;
    family_name: string;
    phone_number: string;
    subscribed: boolean;
  }>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const emailFormControl = register("email", {
    required: {
      value: true,
      message: "Email is required",
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: "Invalid email address",
    },
  });

  const passwordFormControl = register("password", {
    required: {
      value: true,
      message: "Password is required",
    },
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters long",
    },
    validate: {
      hasUppercase: (value) =>
        /[A-Z]/.test(value) ||
        "Password must contain at least one uppercase letter",
      hasLowercase: (value) =>
        /[a-z]/.test(value) ||
        "Password must contain at least one lowercase letter",
      hasNumber: (value) =>
        /\d/.test(value) || "Password must contain at least one number",
      hasSpecialCharacter: (value) =>
        /[^A-Za-z0-9]/.test(value) ||
        "Password must contain at least one special character",
    },
  });

  const confirm_passwordFormControl = register("confirm_password", {
    required: {
      value: true,
      message: "Confirm password is required",
    },
    validate: (value) =>
      value === getValues().password || "Passwords do not match",
  });

  const givenNameFormControl = register("given_name", {
    required: {
      value: true,
      message: "First name is required",
    },
    minLength: {
      value: 2,
      message: "First name must be at least 2 characters long",
    },
  });

  const familyNameFormControl = register("family_name", {
    required: {
      value: true,
      message: "Last name is required",
    },
    minLength: {
      value: 2,
      message: "Last name must be at least 2 characters long",
    },
  });

  const phoneFormControl = register("phone_number", {
    pattern: {
      value: /^\d{10}$/,
      message: "Invalid phone number",
    },
  });

  const subscribedFormControl = register("subscribed", {
    validate: (value) =>
      value || "You must consent to receive email communications",
  });

  const onSignUp = async (data: {
    email: string;
    password: string;
    given_name: string;
    family_name: string;
    phone_number: string;
    subscribed: boolean;
  }) => {
    try {
      clearErrors();
      onError(null);
      setLoading(true);
      await AuthenticationService.register({
        username: data.email,
        password: data.password,
        given_name: data.given_name,
        family_name: data.family_name,
        phone_number: data.phone_number ? `+1${data.phone_number}` : undefined,
        subscribed: data.subscribed,
      });
      onSuccess({
        email: data.email,
        password: data.password,
      });
    } catch (error: any) {
      const errorMessage = error.message;
      const providers = ["Google", "Cognito", "Apple"];
      const provider = providers.find((p) => errorMessage?.includes(p)) || null;
      if (!provider) {
        onError(errorMessage);
      } else {
        if (provider === "Cognito") {
          onError("There is already an account with this email address.");
        } else {
          onError(
            `There is an external provider account (${provider}) linked to this email.`
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSignUp)} onKeyUp={handleKeyPress} className={styles.form}>
      <div className={styles.formItem}>
        <IonLabel>Given name*</IonLabel>
        <IonInput
          className={`aecorn-input dark ${errors.given_name?.message && "ion-invalid ion-touched"
            }`}
          required
          autocapitalize="words"
          placeholder="Enter your first name"
          {...givenNameFormControl}
          onIonInput={givenNameFormControl.onChange}
          disabled={loading}
        />
        {errors.given_name && <IonNote>{errors.given_name.message}</IonNote>}
      </div>
      <div className={styles.formItem}>
        <IonLabel>Family name*</IonLabel>
        <IonInput
          className={`aecorn-input dark ${errors.family_name?.message && "ion-invalid ion-touched"
            }`}
          required
          autocapitalize="words"
          placeholder="Enter your last name"
          {...familyNameFormControl}
          onIonInput={familyNameFormControl.onChange}
          disabled={loading}
        />
        {errors.family_name && <IonNote>{errors.family_name.message}</IonNote>}
      </div>
      <div className={styles.formItem}>
        <IonLabel>Email address*</IonLabel>
        <IonInput
          className={`aecorn-input dark ${errors.email?.message && "ion-invalid ion-touched"
            }`}
          required
          placeholder="Enter your email address"
          {...emailFormControl}
          onIonInput={emailFormControl.onChange}
          type="email"
          disabled={loading}
        />
        {errors.email && <IonNote>{errors.email.message}</IonNote>}
      </div>
      <div className={styles.formItem}>
        <IonLabel>Phone number</IonLabel>
        <IonInput
          className={`aecorn-input dark ${errors.phone_number?.message && "ion-invalid ion-touched"
            }`}
          placeholder="Enter your phone number"
          {...phoneFormControl}
          onIonInput={phoneFormControl.onChange}

          type="tel"
          disabled={loading}
        >
          <span slot="start">+1</span>
        </IonInput>
        {errors.phone_number && <IonNote>{errors.phone_number.message}</IonNote>}
      </div>
      <div className={styles.formItem}>
        <IonLabel>Password*</IonLabel>
        <IonInput
          className={`aecorn-input dark ${errors.password?.message && "ion-invalid ion-touched"
            }`}
          required
          placeholder="Enter your password"
          {...passwordFormControl}
          onIonInput={passwordFormControl.onChange}
          type={isPasswordVisible ? "text" : "password"}
          disabled={loading}
        >
          <IonIcon
            icon={isPasswordVisible ? eyeOffOutline : eyeOutline}
            slot="end"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        </IonInput>
        {errors.password && <IonNote>{errors.password.message}</IonNote>}
      </div>
      <div className={styles.formItem}>
        <IonLabel>Confirm password*</IonLabel>
        <IonInput
          className={`aecorn-input dark ${errors.confirm_password?.message && "ion-invalid ion-touched"
            }`}
          required
          placeholder="Confirm your password"
          {...confirm_passwordFormControl}
          onIonInput={confirm_passwordFormControl.onChange}
          type={isPasswordVisible ? "text" : "password"}
          disabled={loading}
        >
          <IonIcon
            icon={isPasswordVisible ? eyeOffOutline : eyeOutline}
            slot="end"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        </IonInput>
        {errors.confirm_password && (
          <IonNote>{errors.confirm_password.message}</IonNote>
        )}
      </div>
      <div className={styles.formItem}>
        <IonCheckbox
          labelPlacement="end"
          className="aecorn-checkbox"
          {...subscribedFormControl}
          onIonChange={(event) =>
            setValue("subscribed", event.detail.checked, {
              shouldValidate: true,
            })
          }
          disabled={loading}
        >
          I consent to receive email communications
        </IonCheckbox>
        {errors.subscribed && <IonNote>{errors.subscribed.message}</IonNote>}
      </div>
      <IonButton
        className="aecorn-button primary"
        expand="block"
        type="submit"
        disabled={!isValid || loading}>
        {loading ? <IonSpinner name="crescent" /> : "Create account"}
      </IonButton>
      <span className={styles.switch}>
        Already have an account?{" "}
        <span onClick={() => onSwitch("login")}>Sign in</span>
      </span>
    </form>
  );
};

export default SignUpForm;
