import { ListingLastStatus } from "enums/Listings/ListingLastStatus.enum";
import { ListingType } from "enums/Listings/ListingType.enum";
import { Listing } from "models/listings/listing.model";
import styles from "./listing-status-tag.module.scss";

const StatusTagMap = {
    [`${ListingType.Sale}_${ListingLastStatus.ComingSoon}`]: "Coming Soon",
    [`${ListingType.Sale}_${ListingLastStatus.New}`]: "For Sale",
    [`${ListingType.Sale}_${ListingLastStatus.Extension}`]: "Extension",
    [`${ListingType.Sale}_${ListingLastStatus.PriceChange}`]: "Price Change",
    [`${ListingType.Sale}_${ListingLastStatus.SoldConditionally}`]: "Sold Conditionally",
    [`${ListingType.Sale}_${ListingLastStatus.SoldConditionallyWithEscapeClause}`]: "Sold Conditionally With Escape Clause",
    [`${ListingType.Sale}_${ListingLastStatus.Sold}`]: "Sold",
    [`${ListingType.Sale}_${ListingLastStatus.DealFellThrough}`]: "Deal Fell Through",
    [`${ListingType.Sale}_${ListingLastStatus.Terminated}`]: "Terminated",
    [`${ListingType.Sale}_${ListingLastStatus.Expired}`]: "Expired",
    [`${ListingType.Sale}_${ListingLastStatus.Suspended}`]: "Suspended",
    [`${ListingType.Lease}_${ListingLastStatus.New}`]: "For Lease",
    [`${ListingType.Lease}_${ListingLastStatus.Extension}`]: "Extension",
    [`${ListingType.Lease}_${ListingLastStatus.PriceChange}`]: "Price Change",
    [`${ListingType.Lease}_${ListingLastStatus.DealFellThrough}`]: "Deal Fell Through",
    [`${ListingType.Lease}_${ListingLastStatus.LeasedConditionally}`]: "Leased Conditionally",
    [`${ListingType.Lease}_${ListingLastStatus.Leased}`]: "Leased",
    [`${ListingType.Lease}_${ListingLastStatus.Terminated}`]: "Terminated",
    [`${ListingType.Lease}_${ListingLastStatus.Expired}`]: "Expired",
    [`${ListingType.Lease}_${ListingLastStatus.Suspended}`]: "Suspended",
    [`${ListingType.Lease}_${ListingLastStatus.ComingSoon}`]: "Coming Soon",
    // Extra unused statuses
    [`${ListingType.Sale}_${ListingLastStatus.LeasedConditionally}`]: "",
    [`${ListingType.Sale}_${ListingLastStatus.Leased}`]: "",
    [`${ListingType.Lease}_${ListingLastStatus.Sold}`]: "",
    [`${ListingType.Lease}_${ListingLastStatus.SoldConditionally}`]: "",
    [`${ListingType.Lease}_${ListingLastStatus.SoldConditionallyWithEscapeClause}`]: "",
}

const ListingStatusTag = ({listing, size = "small"}:{listing:Listing, size?: "small" | "large"}) => {
    const statusTag = StatusTagMap[`${listing.type ?? "Sale"}_${listing.lastStatus}`];

    return (
        <span className={`${styles.container} ${styles[listing.lastStatus]} ${styles[size]}`}>{statusTag}</span>
    );
};

export default ListingStatusTag;