export const SchoolsActionTypes = {
  FETCH_SCHOOLS_START: "FETCH_SCHOOLS_START",
  FETCH_SCHOOLS_SUCCESS: "FETCH_SCHOOLS_SUCCESS",
  FETCH_SCHOOLS_FAILURE: "FETCH_SCHOOLS_FAILURE",
  UPDATE_SCHOOLS_FILTERS: "UPDATE_SCHOOLS_FILTERS",
  UPDATE_SCHOOLS_LEVELS: "UPDATE_SCHOOLS_LEVELS",
  UPDATE_SCHOOLS_TYPES: "UPDATE_SCHOOLS_TYPES",
  UPDATE_SCHOOLS_PROGRAMS: "UPDATE_SCHOOLS_PROGRAMS",
  UPDATE_SCHOOLS_RATE: "UPDATE_SCHOOLS_RATE",
  UPDATE_SCHOOLS_SHOW_UNRATED: "UPDATE_SCHOOLS_SHOW_UNRATED",
  UPDATE_SELECTED_SCHOOL: "UPDATE_SELECTED_SCHOOL",
  TOGGLE_SCHOOLS: "TOGGLE_SCHOOLS",
  TOGGLE_FILTER_LISTINGS: "TOGGLE_FILTER_LISTINGS",
  ADD_SCHOOL_BOUNDARY: "ADD_SCHOOL_BOUNDARY",
  REMOVE_SCHOOL_BOUNDARY: "REMOVE_SCHOOL_BOUNDARY",
  RESET_SCHOOLS_FILTERS: "RESET_SCHOOLS_FILTERS",
};
