import { API, GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import {
  CreateReviewMutation,
  CreateTourItemInput,
  CreateTourItemMutation,
  GetTourItemQuery,
  ListTourItemsQuery,
  OnCreateTourItemByTourIdSubscription,
  OnDeleteTourItemByIdSubscription,
  OnUpdateTourItemByIdSubscription,
  OnUpdateTourItemByTourIdSubscription,
  TourItem,
  UpdateReviewMutation,
  UpdateTourItemInput,
  UpdateTourItemMutation,
} from "../API";
import {
  createTourItem as createTourItemMutation,
  deleteTourItem as deleteTourItemMutation,
  createReview,
  updateReview as updateReviewMutation,
  deleteReview as deleteReviewMutation
} from "../graphql/mutations";
import { TourItemMediaService } from "./tourItemMediaService";
import { UserService } from "./userService";
import { getAgentTourItem, getClientTourItem, listAgentTourItems, listClientTourItems } from "./queries/tourItem.queries";
import { updateTourItem as updateTourItemMutation } from "./mutations/tourItem.mutations";
import { AuthenticationService } from "./authenticationService";
import { onCreateTourItemByTourId, onDeleteTourItemById, onUpdateTourItemById, onUpdateTourItemByTourId } from "graphql/subscriptions";

const getTourItemsByTourId = async (tourId: string) => {
  const isUserAgent = await AuthenticationService.isCurrentUserAgent();
  const response = await API.graphql<GraphQLQuery<ListTourItemsQuery>>({
    query: isUserAgent ? listAgentTourItems : listClientTourItems,
    variables: { filter: { tourId: {eq: tourId} } },
  });

  return response.data?.listTourItems?.items as TourItem[];
};

const getTourItemById = async (id: string) => {
  const isUserAgent = await AuthenticationService.isCurrentUserAgent();
  const response = await API.graphql<GraphQLQuery<GetTourItemQuery>>({
    query: isUserAgent ? getAgentTourItem : getClientTourItem,
    variables: { id },
  });

  return response.data?.getTourItem as TourItem;
};

const createTourItem = async (tourItem: CreateTourItemInput) => {
  const currentUserId = await UserService.getCurrentUserId();
  const response = await API.graphql<GraphQLQuery<CreateTourItemMutation>>({
    query: createTourItemMutation,
    variables: { input: {...tourItem, creator: currentUserId} },
  });
  return response.data?.createTourItem;
};

export const updateTourItem = async (tourItem: UpdateTourItemInput) => {
  const response = await API.graphql<GraphQLQuery<UpdateTourItemMutation>>({
    query: updateTourItemMutation,
    variables: { input: tourItem },
  });

  return response.data?.updateTourItem;
};

export const deleteTourItem = async (tourItem: TourItem) => {
  await TourItemMediaService.deleteAllMedia(tourItem);
  await API.graphql({
    query: deleteTourItemMutation,
    variables: { input: { id: tourItem.id } },
  });

  // remove all media and reviews associated with the tour item
  const mediaPromises: Promise<any>[] = [];
  const reviewsPromises: Promise<any>[] = [];

  tourItem.media?.items.forEach((media) => {
    if (media) {
      mediaPromises.push(TourItemMediaService.deleteMedia(media));
    }
  });

  tourItem.reviews?.items.forEach((review) => {
    if (review) {
      reviewsPromises.push(deleteReview(review.id));
    }
  });


  // re-arrange the order of the remaining tour items
  const tourItems = await getTourItemsByTourId(tourItem.tourId);
  const updatedTourItems = tourItems
    .sort((a, b) => a.order! - b.order!)
    .map((item, index) => ({ id: item.id, order: index + 1 }));

  await Promise.all([...updatedTourItems.map((item) => updateTourItem(item)), ...mediaPromises, ...reviewsPromises]);
};

const addReview = async (tourItemId: string, rate: number) => {
  const response = await API.graphql<GraphQLQuery<CreateReviewMutation>>({query: createReview, variables: {input: {tourItemId, rate}}});
  return response.data?.createReview;
}

const updateReview = async (reviewId: string, rate: number) => {
  const response = await API.graphql<GraphQLQuery<UpdateReviewMutation>>({query: updateReviewMutation, variables: {input: {id: reviewId, rate}}});
  return response.data?.updateReview;
}

const deleteReview = async (reviewId: string) => {
  await API.graphql({query: deleteReviewMutation, variables: {input: {id: reviewId}}});
}
// #region subscriptions
const onTourItemCreateByTourId = (tourId: string, callback: (data: any) => void) => {
  const result = API.graphql<
    GraphQLSubscription<OnCreateTourItemByTourIdSubscription>
  >({
    query: onCreateTourItemByTourId,
    variables: { tourId },
  });

  const subscription = result.subscribe(async (data) => {
    const id = data.value.data?.onCreateTourItemByTourId?.id;
    if (id) {
      const tourItem = await getTourItemById(id);
      callback(tourItem);
    }
  });

  return subscription;
};

const onTourItemUpdateById = (tourItemId: string, callback: (data: any) => void) => {
  const result = API.graphql<
    GraphQLSubscription<OnUpdateTourItemByIdSubscription>
  >({
    query: onUpdateTourItemById,
    variables: { id: tourItemId },
  });

  const subscription = result.subscribe(async (data) => {
    const tourItem = await getTourItemById(tourItemId);
    callback(tourItem);
  });

  return subscription;
};

const onTourItemDeleteById = (tourItemId: string, callback: () => void) => {
  const result = API.graphql<
    GraphQLSubscription<OnDeleteTourItemByIdSubscription>
  >({
    query: onDeleteTourItemById,
    variables: { id: tourItemId },
  });

  const subscription = result.subscribe(async () => {
    callback();
  });

  return subscription;
};

const onTourItemUpdateByTourId = (tourId: string, callback: (data: any) => void) => {
  const result = API.graphql<
    GraphQLSubscription<OnUpdateTourItemByTourIdSubscription>
  >({
    query: onUpdateTourItemByTourId,
    variables: { tourId },
  });

  const subscription = result.subscribe(async (data) => {
    const tourItems = await getTourItemsByTourId(tourId);
    callback(tourItems);
  });

  return subscription;
};
// #endregion

export const TourItemService = {
  getTourItemsByTourId,
  getTourItemById,
  createTourItem,
  updateTourItem,
  deleteTourItem,
  addReview,
  updateReview,
  onTourItemUpdateByTourId,
  onTourItemCreateByTourId,
  onTourItemUpdateById,
  onTourItemDeleteById,
};
