import { CSSProperties } from "react";
import { Listing } from "models/listings/listing.model";
import styles from "./listing-card.module.scss";
import ListingStatusTag from "../ListingStatusTag/listing-status-tag.component";
import { useIonRouter } from "@ionic/react";
import ListingFavouriteIcons from "../ListingFavouriteIcons/listing-favourite-icons.component";
import { ListingStatus } from "enums/Listings/ListingStatus.enum";
import ListingPriceDifference from "../ListingPriceDifference/listing-price-difference.component";
import { ListingsHelper } from "utils/ListingsHelper";
import { AuthenticationService } from "services/authenticationService";
import RestrictedAccessCard from "components/shared/RestrictedAccessCard/restricted-access-card.component";
import DEFAULT_IMAGE from "assets/img/Image not found.png";

const ListingCard = ({
  listing,
  style,
}: {
  listing: Listing;
  style?: CSSProperties;
}) => {
  const router = useIonRouter();
  const isAuthenticated = AuthenticationService.isAuthenticated();

  const displayData = ListingsHelper.displayData(listing);

  const image = ListingsHelper.getListingThumbnail(listing);
  const price = ListingsHelper.getListingFormattedPrice(listing);
  const days = ListingsHelper.getListingFormattedDate(listing);
  const openHouses = ListingsHelper.getListingOpenHouses(listing);

  const handleNavigateToListing = () => {
    if (!displayData) {
      return;
    }
    router.push(`/listings/${listing.mlsNumber}`);
  };

  const renderRooms = () => {
    const bedrooms =
      +listing.details.numBedrooms +
      (listing.details.numBedroomsPlus ? +listing.details.numBedroomsPlus : 0);
    const bathrooms =
      +listing.details.numBathrooms +
      (listing.details.numBathroomsPlus
        ? +listing.details.numBathroomsPlus
        : 0);
    const parkings =
      +listing.details.numParkingSpaces +
      (listing.details.numGarageSpaces ? +listing.details.numGarageSpaces : 0);

    return (
      <>
        <span>{bedrooms} Bed</span>
        <span>{bathrooms} Bath</span>
        <span>{parkings} Parking</span>
      </>
    );
  };

  return (
    <div
      onClick={handleNavigateToListing}
      className={styles.container}
      style={style}>
      {!displayData && !isAuthenticated && (
        <div className={styles.restrictedAccess}>
          <RestrictedAccessCard />
        </div>
      )}
      <div
        className={`${styles.image} ${!displayData && styles.scatteredData}`}>
        <div className={styles.status}>
          <ListingStatusTag listing={listing} size="large" />
        </div>
        <img
          src={image}
          defaultValue={DEFAULT_IMAGE}
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DEFAULT_IMAGE;
          }}
          alt={listing.mlsNumber}
        />
        {openHouses.length > 0 && (
          <div className={styles.openHouses}>
            <span className={styles.title}>OPEN HOUSE: </span>
            <span>
              {openHouses.map((openHouse, i) => (
                <span key={i}>{`${openHouse.date} ${openHouse.time} `}</span>
              ))}
            </span>
          </div>
        )}
      </div>
      <div
        className={`${styles.details} ${!displayData && styles.scatteredData}`}>
        <div className={styles.price}>
          <div className={styles.priceValue}>{price}</div>
          {isAuthenticated && <ListingFavouriteIcons listing={listing} />}
        </div>
        {listing.status === ListingStatus.Unavailable &&
          listing.lastStatus === "Sld" && (
            <ListingPriceDifference listing={listing} />
          )}
        <div className={styles.address}>
          {ListingsHelper.getListingAddress(listing.address)}
        </div>
        <div className={styles.type}>
          {ListingsHelper.getListingPropertyType(listing.details.propertyType)}
        </div>
        <div className={styles.rooms}>{renderRooms()}</div>
        <div className={styles.tag}>
          <ListingStatusTag listing={listing} />
          {days > 0 ? <span>{days} day</span> : <span>Today</span>}
        </div>
        <div className={styles.mlsNumber}>
          MLS number: {displayData ? listing.mlsNumber : "123456789"}
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
