import { Capacitor } from "@capacitor/core";
import {
  LaunchNavigatorOptions,
  LaunchNavigator,
} from "@ionic-native/launch-navigator";

export class HelperFunctions {
  public static validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  public static navigateByLocation = (lat: number, lng: number) => {
    if (Capacitor.isNativePlatform()) {
      LaunchNavigator.appSelection.userPrompted.get((prompted) => {
        if (!prompted) {
          LaunchNavigator.appSelection.userChoice.exists((exists) => {
            if (exists) {
              LaunchNavigator.navigate([lat, lng], {
                app: LaunchNavigator.APP.USER_CHOICE,
              } as LaunchNavigatorOptions);
            } else {
              LaunchNavigator.navigate([lat, lng]);
            }
          });
        } else {
          LaunchNavigator.navigate([lat, lng]);
        }
      });
    } else {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`
      );
    }
  };

  public static navigateByAddress = (address: string) => {
    if (Capacitor.isNativePlatform()) {
      LaunchNavigator.appSelection.userPrompted.get((prompted) => {
        if (!prompted) {
          LaunchNavigator.appSelection.userChoice.exists((exists) => {
            if (exists) {
              LaunchNavigator.navigate(address, {
                app: LaunchNavigator.APP.USER_CHOICE,
              } as LaunchNavigatorOptions);
            } else {
              LaunchNavigator.navigate(address);
            }
          });
        } else {
          LaunchNavigator.navigate(address);
        }
      });
    } else {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${address}`
      );
    }
  };

  public static numberWithCommas = (number: number, addCurrencySign: boolean) => {
    const n = number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .split(".")[0];
  
    return addCurrencySign ? `$${n}` : n;
  };
}

