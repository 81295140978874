import { Tour, UserTour } from "API";
import styles from "./client-tour-card.module.scss";
import { IonIcon, IonButton, IonLabel, useIonRouter } from "@ionic/react";
import TourStopsModal from "components/shared/Modals/TourStopsModal/tour-stops-modal.component";
import TourStatusPill from "components/shared/TourStatusPill/tour-status-pill.component";
import {
  calendarNumberOutline,
  chevronForwardOutline,
  timeOutline,
  navigateOutline,
  mailOutline,
  chevronUpOutline,
  chevronDownOutline,
} from "ionicons/icons";
import { TourStop } from "models/tours/tour-stop.model";
import moment from "moment";
import { useState, useEffect } from "react";
import { ListingService } from "services/listingService";
import { TourService } from "services/tourService";
import AddToCalendarButton from "components/shared/AddToCalendarButton/add-to-calendar-button.component";
import TourAttendee from "components/shared/Tours/TourAttendee/tour-attendee.component";
import MediaQuery from "react-responsive";
import ClientTourStopCard from "../ClientTourStopCard/client-tour-stop-card.component";

const ClientTourCard = ({ tour }: { tour: Tour }) => {
  const router = useIonRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stops, setStops] = useState<TourStop[]>([]);
  const [showAllStops, setShowAllStops] = useState(false);

  const primaryAgent = TourService.getTourPrimaryAgent(tour);

  const onNavigate = () => {
    TourService.navigateTourStops(tour, stops!);
  };

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const tourItems = tour.tourItems?.items ?? [];
        const tourStops = await Promise.all(
          tourItems
            .filter((item) => item)
            .map(async (item) => ({
              id: item!.id,
              listing: await ListingService.getListingByMlsNumber(
                item!.mlsNumber
              ),
              order: item?.order,
              status: item?.status,
              startTime: item?.startTime,
              endTime: item?.endTime,
            }))
        );

        setStops(tourStops);
      } catch (e) {
        console.error(e);
      }
    };

    fetchListings();
  }, [tour]);

  const ShowMoreStopsButton = () => {
    return (
      <IonButton
        className={`aecorn-button clear-dark ${styles.stopsShowMoreButton}`}
        onClick={() => setShowAllStops(!showAllStops)}>
        {showAllStops ? "Show less stops" : "Show all stops"}
        <IonIcon
          slot="end"
          icon={showAllStops ? chevronUpOutline : chevronDownOutline}
        />
      </IonButton>
    );
  };

  return (
    <div className={styles.container}>
      <TourStopsModal
        tourId={tour.id}
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        stops={stops}
      />
      <div className={styles.header}>
        <div className={styles.title}>
          <span>{tour.title}</span> <TourStatusPill status={tour.status!} />
        </div>
        <IonButton
          className="aecorn-button clear-dark"
          routerLink={`/tours/details/${tour.id}`}>
          Details
          <IonIcon slot="end" icon={chevronForwardOutline} />
        </IonButton>
      </div>
      <div className={styles.datetime}>
        <div className={styles.content}>
          <span>
            <IonIcon icon={calendarNumberOutline} />
            <span>{moment(tour.date).format("ddd, MMMM DD, YYYY")}</span>
          </span>
          <span>
            <IonIcon icon={timeOutline} />
            {tour.startTime && tour.endTime && (
              <span>
                {moment(tour.startTime, "HH:mm").format("hh:mm A")} to{" "}
                {moment(tour.endTime, "HH:mm").format("hh:mm A")}
              </span>
            )}
          </span>
        </div>
        {tour.date && (
          <AddToCalendarButton
            event={{
              title: tour.title ?? "",
              startDate: tour.startTime
                ? moment(tour.date)
                    .set("hours", +tour.startTime.split(":")[0])
                    .set("minutes", +tour.startTime.split(":")[1])
                    .toDate()
                    .getTime()
                : new Date(tour.date).getUTCMilliseconds(),
              endDate: tour.endTime
                ? moment(tour.date)
                    .set("hours", +tour.endTime.split(":")[0])
                    .set("minutes", +tour.endTime.split(":")[1])
                    .toDate()
                    .getTime()
                : new Date(tour.date).getTime(),
              location: tour.meetupLocation ?? "",
              notes: tour.generalNote ?? "",
            }}
          />
        )}
      </div>
      <div className={styles.attendees}>
        <IonLabel className={styles.label}>Attendees</IonLabel>
        <div className={styles.items}>
          {tour.users?.items.map((attendee: UserTour | null, index: number) =>
            attendee ? <TourAttendee key={index} attendee={attendee!} /> : null
          )}
        </div>
      </div>
      <div className={styles.showings}>
        <div className={styles.summary}>
          <div className={styles.title}>
            <div className={styles.label}>Stops</div>
            <div className={styles.count}>{stops.length}</div>
          </div>
          <MediaQuery minWidth={769}>
            {stops.length > 3 && <ShowMoreStopsButton />}
          </MediaQuery>
        </div>
        <div className={styles.stops}>
          {stops
            .slice(0, showAllStops ? stops.length : 2)
            .map((stop, index) => (
              <ClientTourStopCard
                key={index}
                stop={stop}
                onNavigate={() =>
                  router.push(`/tours/details/${tour.id}/${stop.id}`)
                }
              />
            ))}
        </div>
        <MediaQuery maxWidth={767}>
          {stops.length > 3 && <ShowMoreStopsButton />}
        </MediaQuery>
      </div>
      <div className={styles.buttons}>
        <IonButton
          className="aecorn-button dark"
          disabled={!stops.length}
          onClick={onNavigate}>
          <IonIcon slot="start" icon={navigateOutline} />
          Navigate Tour
        </IonButton>
        <IonButton
          className="aecorn-button clear border"
          disabled={!stops.length}
          href={`mailto:${primaryAgent?.email}`}
          target="_blank"
          onClick={onNavigate}>
          <IonIcon slot="start" icon={mailOutline} />
          Contact Agent
        </IonButton>
      </div>
    </div>
  );
};

export default ClientTourCard;
